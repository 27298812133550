export default {
  name: "electric_pump_maintenance",
  title: "Mantenimiento de Bomba Eléctrica",
  checks: {
    autopilotLight: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Luz de piloto automático",
      unit: null
    },
    suctionValve: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Válvula de succión abierta",
      unit: null
    },
    dischargeValve: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Válvula de descarga abierta",
      unit: null
    },
    fireNetworkPressure: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Presión de la red de incendio",
      unit: "P.S.I."
    },
    engineTemperature: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Temperatura del motor en reposo",
      unit: "°C"
    },
    oilLevel: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Nivel de aceite",
      unit: "%"
    },
    waterLevel: {
      section: "Bomba Eléctrica",
      required: true,
      warning: true,
      type: "NUMBER",
      label: "Nivel de agua",
      unit: null
    },
    engineHorometer: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Horometro del motor",
      unit: null
    },
    turnOnTime: {
      section: "Bomba Eléctrica",
      type: "NUMBER",
      label: "Tiempo de encendido de la bomba",
      unit: "minutos"
    },
    systemOp30MIN: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Operación del sistema por 10min",
      unit: null
    },
    engineSpeed: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Velocidad del motor",
      unit: "R.P.M."
    },
    engineOpTemp: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Temperatura del motor en operación",
      unit: "°C"
    },
    reliefValveOp: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Operación de la válvula de alivio",
      unit: "P.S.I."
    },
    suctionRecords: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Lectura de los registros de la succión",
      unit: "P.S.I."
    },
    dischargeRecords: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Lectura de los registros de la descarga",
      unit: "P.S.I."
    },
    waterLeaks: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Fugas de agua",
      unit: null
    },
    nutTightening: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Ajustar tuercas si es necesario",
      unit: null
    },
    noisesOrVibrations: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Ruidos extraños o vibraciones",
      unit: null
    },
    pumpPressure: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Registro de la presión de la bomba",
      unit: "P.S.I."
    },
    engineTimeToMaxSpeed: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Observe el tiempo en el que el motor alcanza su velocidad",
      unit: "segundos"
    },
    automaticOp: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Funcion en Operación Automatica",
      unit: null
    },
    manualOp: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Funcionamiento en Operación Manual Arranque / Paro",
      unit: null
    },
    startPressure: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Presión de Arranque",
      unit: "P.S.I."
    },
    stopPressure: {
      section: "Bomba Eléctrica",
      type: "NUMBER",
      label: "Presión de Paro",
      unit: "P.S.I."
    },
    stopDelay: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Tiempo de Retardo al paro / Paro Manual",
      unit: "minutos"
    },
    enginePower: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Potencia del motor",
      unit: "H.P."
    },
    supplyVoltage: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Voltaje  de Alimentacion",
      unit: "Volts"
    },
    ABVoltage: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Voltaje línea A - B",
      unit: "Volts"
    },
    BCVoltage: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Voltaje línea B - C",
      unit: "Volts"
    },
    CAVoltage: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Voltaje línea C - A",
      unit: "Volts"
    },
    plateCurrent: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente de placa",
      unit: "Ampers"
    },
    bimetallicPumpAdjustment: {
      section: "Bomba Eléctrica",
      required: false,
      warning: false,
      type: "NUMBER",
      label: "Ajuste de Bimetalico Bomba",
      unit: "Ampers"
    },
    currentLine1: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente Línea 1",
      unit: "Ampers"
    },
    currentLine2: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente Línea 2",
      unit: "Ampers"
    },
    currentLine3: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente Línea 3",
      unit: "Ampers"
    },
    engineCurrent100: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente del motor al 100% del Gasto",
      unit: "Ampers"
    },
    engineCurrentClosingPressure: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente del Motor a la presion de Cierre",
      unit: "Ampers"
    },
    clossingPressure: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Presión de Cierre",
      unit: "P.S.I"
    },
    operationVoltage: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Voltaje de Operación",
      unit: "Volts"
    },
    operationCurrent: {
      section: "Bomba Eléctrica",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente de Operación",
      unit: "Ampers"
    },
    abnormalities: {
      section: "Bomba Eléctrica",
      required: false,
      warning: false,
      type: "STRING",
      label: "Registrar cualquier anormalidad",
      unit: null
    }
  }
};
