import React, { Component } from "react";
import PropTypes from "prop-types";
import fuzzy from "fuzzy";

import Helmet from "react-helmet";
import DayPicker from "react-day-picker";
import { parseMilliseconds } from "../../utils/date";
import { CHECKLIST_FORMATS } from "../../utils/checklists";

import styles from "./InspectionsTemplateStyles.scss";

import {
  AvatarBubble,
  Button,
  Container,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  Heading,
  Table,
  Modal,
  Layout,
  LabeledInput,
  PlusIcon,
  FilterIcon,
  Switch,
  TextInput,
  BigRemoveButton
} from "../atoms";
import {
  FacilitySelector,
  TableContainerHeader,
  Spinner,
  ModalPortal,
  UploadButton
} from "../molecules";
import Delete from "../atoms/Icons/Delete";
import { bugsnagClient } from "../../bugsnag";

const ACTIVITIES = {
  Carretes: "Carretes de Manguera",
  Lamina: "Lámina de 1/2 pulgada"
};

const LOCATIONS = {
  "Zona Norte, Chihuahua": "Zona Norte, Chihuahua"
};

const ASIGNEDTECHNICIAN = {
  "Evan Andrews": "Evan Andrews"
};

const DROPDOWNYEARS = {
  "2019": "2019",
  "2020": "2020"
};

const DROPDOWNMONTHS = {
  Enero: "Enero",
  Febrero: "Febrero",
  Marzo: "Marzo",
  Abril: "Abril",
  Mayo: "Mayo",
  Junio: "Junio",
  Julio: "Julio",
  Agosto: "Agosto",
  Septiembre: "Septiembre",
  Octubre: "Octubre",
  Noviembre: "Noviembre",
  Diciembre: "Diciembre"
};

const VISITDAYS = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "10": "10",
  "11": "11",
  "12": "12",
  "13": "13",
  "14": "14",
  "15": "15",
  "16": "16",
  "17": "17",
  "18": "18",
  "19": "19",
  "20": "20",
  "21": "21",
  "22": "22",
  "23": "23",
  "24": "24",
  "25": "25",
  "26": "26",
  "27": "27",
  "28": "28",
  "29": "29",
  "30": "30",
  "31": "31"
};

class DelayedInspectionsTemplate extends Component {
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  onChange = date => this.setState({ date });

  state = {
    date: new Date(),
    filter: undefined,
    dateRangeFilter: {},
    addContractModal: {
      visible: false,
      addButtonDisabled: true
    },
    addInspectionChecklistModal: {
      visible: false,
      addButtonDisabled: false,
      formats: {}
    }
  };

  static propTypes = {
    loadingInspections: PropTypes.bool.isRequired,
    inspections: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    loadingInspections: true,
    inspections: []
  };

  componentDidUpdate(_, prevState) {
    const { refetch } = this.props;
    const { dateRangeFilter: prevDateRangeFilter } = prevState;
    const { dateRangeFilter } = this.state;

    if (
      (!prevDateRangeFilter.startDate || !prevDateRangeFilter.endDate) &&
      dateRangeFilter.startDate &&
      dateRangeFilter.endDate
    ) {
      refetch({
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
    }

    if (prevDateRangeFilter.startDate && prevDateRangeFilter.endDate) {
      if (
        prevDateRangeFilter.startDate !== dateRangeFilter.startDate ||
        prevDateRangeFilter.endDate !== dateRangeFilter.endDate
      ) {
        refetch({
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
      }
    }

    if (
      prevDateRangeFilter.startDate &&
      prevDateRangeFilter.endDate &&
      (!dateRangeFilter.startDate && !dateRangeFilter.endDate)
    ) {
      refetch({ startDate: undefined, endDate: undefined });
    }
  }

  render() {
    const {
      filter,
      addContractModal,
      addInspectionChecklistModal,
      dateRangeFilter
    } = this.state;
    const { loadingInspections, inspections } = this.props;

    const filteredInspections =
      inspections &&
      fuzzy.filter(filter, inspections, {
        extract: el => el.fTec.technician.firstName
      });

    const data = filter
      ? filteredInspections &&
        filteredInspections.map(result => result.original)
      : inspections;

    return (
      <>
        <ModalPortal
          modal={this._renderAddChecklistModal}
          visible={addContractModal.visible}
        />
        <ModalPortal
          modal={this._renderAddInspectionChecklistModal}
          visible={addInspectionChecklistModal.visible}
        />
        <Heading>Inspecciones Retrasadas</Heading>
        <Container noPadding>
          <div style={{ overflow: "hidden", margin: "30px" }}>
            <TextInput
              width={580}
              placeholder="Buscar por nombre de técnico"
              onChange={e => this._filterInspections(e)}
            />
            <div
              style={{
                display: "inline-block",
                textAlign: "center",
                marginLeft: "30px",
                position: "absolute"
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "20px",
                  fontWeight: "bold"
                }}
              >
                Filtrar por rango de fechas
              </div>
              <LabeledInput
                index={1}
                type="date-picker"
                name="startDate"
                defaultValue={dateRangeFilter.startDate}
                componentProps={{
                  dayPickerProps: {
                    disabledDays: {
                      after: new Date(dateRangeFilter.endDate)
                    }
                  }
                }}
                onChange={this._handleOnDateInputChange}
              />
              <span style={{ marginLeft: "15px" }}>
                <LabeledInput
                  index={2}
                  type="date-picker"
                  name="endDate"
                  defaultValue={dateRangeFilter.endDate}
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(dateRangeFilter.startDate)
                      }
                    }
                  }}
                  onChange={this._handleOnDateInputChange}
                />
              </span>
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "5px"
                }}
              >
                <BigRemoveButton onClick={this._handleOnResetDateRangeFilter} />
              </span>
            </div>
          </div>
          <Table.Main
            columns={[
              null,
              { "fTec.technician.firstName": "Técnico" },
              { "fTec.facility.name": "Planta" },
              {
                "inspectionChecklist.checklistFormat": "Checklist de Inspección"
              },
              { scheduledDate: "Fecha" },
              { "fTec.facility.name": "Planta" }
            ]}
            data={data}
            row={(inspection, lastRow) =>
              this._renderInspectionRow(inspection, lastRow)
            }
          />
        </Container>
      </>
    );
  }

  _filterInspections = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnResetDateRangeFilter = () => {
    this.setState({
      dateRangeFilter: {}
    });
  };

  _handleOnDateInputChange = e => {
    if (e.props) {
      return this.setState(state => ({
        dateRangeFilter: {
          ...state.dateRangeFilter,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _renderInspectionRow = (inspection, lastRow) => {
    const { firstName, lastName } = inspection.fTec.technician;
    const inspectionTitle =
      inspection.__typename === "Inspection"
        ? CHECKLIST_FORMATS[inspection.inspectionChecklist.checklistFormat]
        : inspection.__typename === "CorrectiveActionInspection"
          ? "Acción correctiva"
          : "Servicio adicional";

    const scheduledDate = new Date(
      `${inspection.scheduledDate}T00:00`
    ).getTime();

    return (
      <tr
        testid="inspections-table-row"
        key={inspection.id}
        style={{ borderBottom: !lastRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble
              url={
                inspection.fTec.technician.avatarUrl &&
                inspection.fTec.technician.avatarUrl
              }
            />
          </div>
        </Table.Cell>
        <Table.Cell>
          {firstName} {lastName}
        </Table.Cell>
        <Table.Cell>{inspection.fTec.facility.name}</Table.Cell>
        <Table.Cell>{inspectionTitle}</Table.Cell>
        <Table.Cell>{parseMilliseconds(scheduledDate)}</Table.Cell>
        <Table.Cell>{inspection.fTec.facility.name}</Table.Cell>
      </tr>
    );
  };
}

export default DelayedInspectionsTemplate;
