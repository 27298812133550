import { gql } from "apollo-boost";

const getTechnicians = gql`
  query getTechnicians {
    technicians {
      id
      userId
      avatarUrl
      firstName
      lastName
      email
      mobilePhone
      officePhone
      address
      deleted
      facilities {
        id
        name
        zone
        avatarUrl
        businessName {
          id
          name
          client {
            id
            name
          }
        }
      }
    }
  }
`;

export default getTechnicians;
