import React from "react";
import format from "date-fns/format";

import {
  Button,
  Container,
  CloseIcon,
  Modal,
  Layout,
  TextArea,
  AvatarBubble
} from "../../atoms";

const Field = ({ label, value }) => (
  <div style={{ width: "100%", margin: "0 auto", marginBottom: 40 }}>
    <div style={{ color: "#5A5E61", marginBottom: 10 }}>{label}</div>
    <div style={{ color: "#5A5E61", fontWeight: "bold" }}>{value}</div>
  </div>
);

const Facility = ({ avatarUrl, clientName, facilityName }) => (
  <div style={{ marginBottom: 40 }}>
    <div style={{ float: "left" }}>
      <AvatarBubble url={avatarUrl} />
    </div>
    <div style={{ display: "inline-block", marginLeft: 20 }}>
      <div style={{ marginBottom: 10, color: "#5A5E61" }}>{clientName}</div>
      <div style={{ color: "#5A5E61", fontWeight: "bold" }}>{facilityName}</div>
    </div>
  </div>
);

const ADDITIONAL_SERVICE_STATUS = {
  null: "Pendiente",
  PURCHASE_ORDER: "Orden de compra",
  JOB_FINISHED: "Trabajo terminado",
  INVOICED: "Facturado"
};

const ViewAdditionalServiceModal = ({
  viewAdditionalServiceModalState: viewAdditionalServiceModal,
  additionalService,
  viewAdditionalServiceId,
  onViewCloseButton
}) => {
  const { facility: fac, fTec } = additionalService;
  const facility = fac || fTec.facility;

  const assignedTechnician =
    additionalService.additionalServiceInspection &&
    additionalService.additionalServiceInspection.fTec.technician;

  const purchaseOrderStatus = additionalService.purchaseOrder;
  const jobFinishedStatus = additionalService.jobFinished;
  const invoicedStatus = additionalService.invoiced;

  return (
    <Container width={1118}>
      <div
        onClick={onViewCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>
        Ver datos del servicio adicional{" "}
        <span style={{ color: "rgba(43, 48, 52, 0.4)", fontSize: "13px" }}>
          {additionalService.title}
        </span>
      </Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <div style={{ marginTop: "20px" }}>
          <span style={{ display: "inline-block", marginLeft: "70" }}>
            <Facility
              avatarUrl={
                facility.businessName.client.avatarUrl &&
                facility.businessName.client.avatarUrl
              }
              clientName="Cliente"
              facilityName={facility.businessName.client.name}
            />
          </span>
          <span style={{ display: "inline-block", marginLeft: "70" }}>
            <Field label="Razon Social" value={facility.businessName.name} />
          </span>
          <span style={{ display: "inline-block", marginLeft: "70" }}>
            <Field label="Planta" value={facility.name} />
          </span>
        </div>
      </Modal.Section>
      <Modal.Section
        style={{ margin: "0 50px", borderTop: "1px solid #d7d7d7" }}
        paddingVertical={30}
        paddingHorizontal={100}
      >
        <Layout.Grid>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <Field
                label="Tecnico Asignado"
                value={
                  assignedTechnician
                    ? `${assignedTechnician.firstName} ${assignedTechnician.lastName}`
                    : "N/A"
                }
              />
            </div>
            <div style={{ paddingRight: "70px", marginBottom: "40px" }}>
              <Field label="Monto MXN" value={additionalService.budget} />
            </div>
            <Field
              label="PEP base"
              value={
                additionalService.contract
                  ? additionalService.contract.baseBudget
                  : "N/A"
              }
            />
          </Layout.Column>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <Field label="Concepto" value={additionalService.title} />
            </div>
            <Field label="Monto USD" value={additionalService.budgetUSD} />
          </Layout.Column>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <Field
                label="Fecha"
                value={
                  additionalService.additionalServiceInspection
                    ? format(
                      additionalService.additionalServiceInspection
                        .scheduledDate,
                      "MM/DD/YYYY"
                    )
                    : "N/A"
                }
              />
            </div>
            <Field
              label="Tipo de cambio"
              value={additionalService.exchangeRate}
            />
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Header>Información Adicional</Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <Layout.Grid>
          <Layout.Column>
            <Field label="Detalles" value={additionalService.details} />
          </Layout.Column>
          <Layout.Column style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                position: "absolute",
                top: "50%",
                left: "50%",
                width: 100,
                transform: "translate(-50%, -50%)"
              }}
            >
              <div>
                <div style={{ marginBottom: 10, fontWeight: "700" }}>OC</div>
                <div>
                  <input
                    type="checkbox"
                    checked={purchaseOrderStatus}
                    onChange={() =>
                      handleOnEditStatusChange(
                        "purchaseOrder",
                        purchaseOrderStatus
                      )
                    }
                  />
                </div>
              </div>
              <div>
                <div style={{ marginBottom: 10, fontWeight: "700" }}>TT</div>
                <div>
                  <input
                    type="checkbox"
                    checked={jobFinishedStatus}
                    onChange={() =>
                      handleOnEditStatusChange("jobFinished", jobFinishedStatus)
                    }
                  />
                </div>
              </div>
              <div>
                <div style={{ marginBottom: 10, fontWeight: "700" }}>F</div>
                <div>
                  <input
                    type="checkbox"
                    checked={invoicedStatus}
                    onChange={() =>
                      handleOnEditStatusChange("invoiced", invoicedStatus)
                    }
                  />
                </div>
              </div>
            </div>
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            style="outline"
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={onViewCloseButton}
          >
            Volver
          </Button>
        </div>
      </Modal.Footer>
    </Container>
  );
};

export default ViewAdditionalServiceModal;
