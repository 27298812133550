import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { logout } from "../../store/actions/user";

class LogoutView extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { logout, user } = this.props;

    if (!user.loggedIn) this._handleRedirect();

    logout();
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (!user.loggedIn) this._handleRedirect();
  }

  render() {
    return null;
  }

  _handleRedirect = () => window.location.replace("/iniciar-sesion");
}

const mapStateToProps = store => ({
  user: store.user
});

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutView);
