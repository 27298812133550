import { gql } from "apollo-boost";
import { client } from "../apollo";

const setAdditionalServiceStatus = async (additionalServiceId, status, state) =>
  await client.mutate({
    mutation: gql`
      mutation setAdditionalServiceStatus(
        $additionalServiceId: ID!
        $status: String!
        $state: Boolean!
      ) {
        setAdditionalServiceStatus(
          additionalServiceId: $additionalServiceId
          status: $status
          state: $state
        )
      }
    `,
    variables: {
      additionalServiceId,
      status,
      state
    }
  });

export default setAdditionalServiceStatus;
