import { gql } from "apollo-boost";

const getContractsByStatus = gql`
  query getContractsByStatus(
    $status: Boolean
    $startDate: String
    $endDate: String
  ) {
    contractsByStatus(
      status: $status
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      annualCost
      monthlyCost

      facility {
        id
        name
      }
    }
    previousYearContracts {
      id
      annualCost
      monthlyCost

      facility {
        id
        name
      }
    }
    additionalServices(startDate: $startDate, endDate: $endDate) {
      id
      budget
      budgetUSD
      exchangeRate
      contract {
        id
        facility {
          id
          name
        }
      }
      facility {
        id
        name
      }
    }
  }
`;

export default getContractsByStatus;
