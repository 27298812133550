import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Modal } from "../../atoms";

const DeleteAdditionalServiceModal = ({
  deleteButtonDisabled,
  deleteAdditionalServiceId,
  additionalService,
  onBackButton,
  onDeleteAdditionalServiceButton
}) => {
  return (
    <Container width={540}>
      <Modal.Section paddingVertical={80} paddingHorizontal={80}>
        <div style={{ fontSize: "20px", textAlign: "center" }}>
          ¿Estas seguro que deseas eliminar el servicio adicional{" "}
          <div style={{ fontWeight: "bold", display: "inline-block" }}>
            {additionalService.title}
          </div>{" "}
          de tu lista?
        </div>
      </Modal.Section>
      <div style={{ marginBottom: "37px", marginLeft: "95px" }}>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={8}
            radius={7}
            paddingHorizontal={35}
            onClick={onBackButton}
            style="outline"
          >
            No, volver
          </Button>
        </div>
        <Button
          paddingVertical={8}
          radius={7}
          paddingHorizontal={30}
          onClick={() => onDeleteAdditionalServiceButton(deleteAdditionalServiceId)}
          disabled={deleteButtonDisabled}
        >
          Sí, continuar
        </Button>
      </div>
    </Container>
  );
};

DeleteAdditionalServiceModal.PropTypes = {
  deleteButtonDisabled: PropTypes.bool.isRequired,
  deleteAdditionalServiceId: PropTypes.string.isRequired,
  additionalService: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  onBackButton: PropTypes.func.isRequired,
  onDeleteAdditionalServiceButton: PropTypes.func.isRequired
};

export default DeleteAdditionalServiceModal;