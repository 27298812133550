import React from "react";

const Facility = props => (
  <svg width={20} height={21} {...props}>
    <defs>
      <path
        id="facility_svg__a"
        d="M55.454 337.36h-1.679a.24.24 0 0 1-.24-.24v-1.68a.24.24 0 0 1 .24-.24h1.679a.24.24 0 0 1 .24.24v1.68a.24.24 0 0 1-.24.24zm-6.035-.24a.24.24 0 0 1-.241.24H47.5a.24.24 0 0 1-.241-.24v-1.68a.24.24 0 0 1 .24-.24h1.679a.24.24 0 0 1 .24.24zm8.055-6.794a.912.912 0 0 0-.941.046l-4.954 3.306v-2.538a.926.926 0 0 0-1.44-.767l-5.093 3.395v-7.112c0-.188-.145-.356-.333-.356h-5.961c-.189 0-.342.168-.342.356v14.376c0 .189.153.34.342.34h18.872a.334.334 0 0 0 .334-.34v-9.893a.917.917 0 0 0-.484-.813z"
      />
      <path
        id="facility_svg__b"
        d="M38.751 324.96h5.961c.189 0 .334-.129.334-.317v-2.722a.914.914 0 0 0-.916-.921h-4.797a.921.921 0 0 0-.923.92v2.723c0 .188.153.318.341.318z"
      />
    </defs>
    <use
      fill="#cacfd9"
      xlinkHref="#facility_svg__a"
      transform="translate(-38 -321)"
    />
    <use
      fill="#cacfd9"
      xlinkHref="#facility_svg__b"
      transform="translate(-38 -321)"
    />
  </svg>
);

export default Facility;
