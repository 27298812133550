import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";

import {
  CloseIcon,
  Container,
  Modal,
  Layout,
  LabeledInput,
  Button,
  PlusIcon,
  ViewIcon
} from "../../atoms";
import { parseMilliseconds } from "../../../utils/date";
import { UploadAsset, FacilitySelector, UploadButton } from "../../molecules";

const EditContractModal = ({
  editContractModalState: editContractModal,
  facilitySelectors,
  facilitySelector,
  editContractId,
  onCloseButton,
  onInputChange,
  onFacilitySelectorChange,
  onViewChecklistButton,
  onFacilitySelect,
  contract,
  showPreviewModal,
  handleDocumentUploaded,
  documentRenderer
}) => {
  const startDate = new Date(`${contract.startDate}T00:00`).getTime();
  const endDate = new Date(`${contract.endDate}T00:00`).getTime();
  return (
    <Container width={1118}>
      <div
        onClick={onCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>
        Editar datos del contrato{" "}
        <span style={{ color: "rgba(43, 48, 52, 0.4)", fontSize: "13px" }}>
          {contract.policyNumber}
        </span>
      </Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        {Object.keys(facilitySelectors).map((selector, idx) => (
          <FacilitySelector
            selected={[
              contract.facility.businessName.client.name,
              contract.facility.businessName.name,
              contract.facility.id
            ]}
            onChange={(key, value) =>
              onFacilitySelectorChange(selector, key, value)
            }
            onFacilitySelect={facilityId =>
              onFacilitySelect(selector, facilityId)
            }
          />
        ))}
      </Modal.Section>
      <Modal.Section
        style={{ margin: "0 50px", borderTop: "1px solid #d7d7d7" }}
        paddingVertical={30}
        paddingHorizontal={100}
      >
        <Layout.Grid>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={1}
                type="date-picker"
                name="startDate"
                label="Fecha de inicio de contrato"
                defaultValue={format(contract.startDate, "MM/DD/YYYY")}
                onChange={onInputChange}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={4}
                type="currency"
                name="monthlyCost"
                label="Costo total mensual"
                defaultValue={contract.monthlyCost}
                onChange={onInputChange}
              />
            </div>
          </Layout.Column>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={2}
                type="date-picker"
                name="endDate"
                label="Fecha de finalización de contrato"
                defaultValue={format(contract.endDate, "MM/DD/YYYY")}
                onChange={onInputChange}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={5}
                name="baseBudget"
                label="PEP base"
                defaultValue={contract.baseBudget}
                onChange={onInputChange}
              />
            </div>
          </Layout.Column>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={3}
                type="currency"
                name="annualCost"
                label="Costo total anual"
                defaultValue={contract.annualCost}
                onChange={onInputChange}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={6}
                name="policyNumber"
                label="Nombre de póliza"
                defaultValue={contract.policyNumber}
                onChange={onInputChange}
              />
            </div>
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Header>Documentos</Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <div>
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                marginBottom: 20,
                fontSize: 16,
                fontWeight: 700,
                color: "#5A5E61"
              }}
            >
              Póliza del contrato
            </div>
            <div>
              <UploadButton
                label="Adjuntar póliza"
                onSuccessfulUpload={(fileName, uri) =>
                  handleDocumentUploaded("contractPolicyInputs", fileName, uri)
                }
              />
            </div>
            {editContractModal.contractPolicyInputs.length > 0 &&
              documentRenderer(
                "contractPolicyInputs",
                editContractModal.contractPolicyInputs
              )}
          </div>
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                marginBottom: 20,
                fontSize: 16,
                fontWeight: 700,
                color: "#5A5E61"
              }}
            >
              Información del SCI
            </div>
            <div>
              <UploadButton
                label="Adjuntar información"
                onSuccessfulUpload={(fileName, uri) =>
                  handleDocumentUploaded("SCINotesInputs", fileName, uri)
                }
              />
            </div>
            {editContractModal.SCINotesInputs.length > 0 &&
              documentRenderer(
                "SCINotesInputs",
                editContractModal.SCINotesInputs
              )}
          </div>
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                marginBottom: 20,
                fontSize: 16,
                fontWeight: 700,
                color: "#5A5E61"
              }}
            >
              Condiciones comerciales
            </div>
            <div>
              <UploadButton
                label="Adjuntar condiciones"
                onSuccessfulUpload={(fileName, uri) =>
                  handleDocumentUploaded(
                    "commercialConditionsInputs",
                    fileName,
                    uri
                  )
                }
              />
            </div>
            {editContractModal.commercialConditionsInputs.length > 0 &&
              documentRenderer(
                "commercialConditionsInputs",
                editContractModal.commercialConditionsInputs
              )}
          </div>
          <div>
            <div
              style={{
                marginBottom: 20,
                fontSize: 16,
                fontWeight: 700,
                color: "#5A5E61"
              }}
            >
              Planos de planta
            </div>
            <div>
              <UploadButton
                label="Adjuntar planos"
                onSuccessfulUpload={(fileName, uri) =>
                  handleDocumentUploaded("blueprintsInputs", fileName, uri)
                }
              />
            </div>
            {editContractModal.blueprintsInputs.length > 0 &&
              documentRenderer(
                "blueprintsInputs",
                editContractModal.blueprintsInputs
              )}
          </div>
        </div>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            style="outline"
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={onCloseButton}
          >
            Volver
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={48}
          onClick={() => onViewChecklistButton(contract)}
        >
          Ver Checklist
        </Button>
      </Modal.Footer>
    </Container>
  );
};

EditContractModal.propTypes = {
  onCloseButton: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onViewChecklistButton: PropTypes.func.isRequired,
  editContractId: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired
};

export default EditContractModal;
