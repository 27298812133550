import React, { Component } from "react";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import { getReports } from "../../gql/queries";
import { ReportsTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class ReportsView extends Component {
  static propTypes = {
    reportId: PropTypes.string
  };

  render() {
    const { reportId } = this.props;

    return (
      <Query query={getReports}>
        {({ loading, error, data, refetch }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div testid="error-wrapper">error</div>;
          }

          return (
            <ReportsTemplate
              loadingReports={loading}
              reports={data && data.reports}
              reportId={reportId}
              refetch={refetch}
            />
          );
        }}
      </Query>
    );
  }
}

export default ReportsView;
