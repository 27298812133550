import { gql } from "apollo-boost";

const getClients = gql`
  query getClients {
    clients {
      id
      userId
      name
      avatarUrl
      state
      city
      address
      updatedAt
      createdAt
      facilityManagers {
        user {
          emailAddress
        }
      }
      user {
        emailAddress
      }

      businessNames {
        id
        name
      }
    }
  }
`;

export default getClients;
