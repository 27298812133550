import React from "react";

import {
  Button,
  Container,
  CloseIcon,
  Modal,
  Layout,
  AvatarBubble
} from "../../atoms";

const Field = ({ label, value }) => (
  <div style={{ width: "100%", margin: "0 auto", marginBottom: 40 }}>
    <div style={{ color: "#5A5E61", marginBottom: 10 }}>
      {label}
    </div>
    <div style={{ color: "#5A5E61", fontWeight: "bold" }}>
      {value}
    </div>
  </div>
);

const ViewClientModal = ({
  viewClientModalState: viewClientModal,
  client,
  viewClientId,
  onClickCloseButton,
  businessNameInputs
}) => {
  const photoURL = client.avatarUrl == null ? "" : client.avatarUrl;
  return (
    <Container width={1100}>
      <div
        onClick={onClickCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>
        Ver cliente{" "}
        <span style={{ color: "#5A5E61", fontSize: "13px" }}>
          {client.name}
        </span>
      </Modal.Header>{" "}
      <Modal.Section
        style={{ display: "flex" }}
        paddingVertical={40}
        paddingHorizontal={100}
      >
        <AvatarBubble url={photoURL} width={140} height={140} />
        <Layout.Grid>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <Field label="Nombre del cliente" value={client.name} />
            </div>
            <Field label="Municipio" value={client.city} />
            <Field
              label="Correo Electrónico"
              value={client.user.emailAddress}
            />
          </Layout.Column>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <Field label="Estado" value={client.state} />
            </div>
            <Field label="Dirección" value={client.address} />
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Header>Razones sociales</Modal.Header>
      <Modal.Section paddingVertical={40} paddingHorizontal={100}>
        {businessNameInputs.map((businessName, idx) => {
          if (idx === 0) {
            return (
              <div
                key={Object.keys(businessName)[0]}
                style={{
                  display: "inline-block",
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                <Field
                  label="Razón social"
                  value={Object.values(businessName)[0]}
                />
              </div>
            );
          } else {
            return (
              <div
                key={Object.keys(businessName)[0]}
                style={{
                  display: "inline-block",
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                <Field
                  label="Razón social"
                  value={Object.values(businessName)[0]}
                />
              </div>
            );
          }
        })}
      </Modal.Section>
      <Modal.Footer>
        <Button
          paddingVertical={10}
          paddingHorizontal={48}
          onClick={onClickCloseButton}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default ViewClientModal;
