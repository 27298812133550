import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import esLocale from "date-fns/locale/es";

import { Button, Container, Modal } from "../../atoms";
import { parseMilliseconds } from "../../../utils/date";

const RescheduleInspectionModal = ({
  addButtonDisabled,
  inspectionRescheduleId,
  inspectionReschedule,
  onDeclineInspectionReschedule,
  onRescheduleInspectionButton
}) => {
  return (
    <Container width={540}>
      <Modal.Section paddingVertical={80} paddingHorizontal={80}>
        <div style={{ fontSize: 20, textAlign: "center" }}>
          <span style={{ fontWeight: "bold" }}>Inspección</span> reagendada
        </div>
        <div style={{ textAlign: "center", fontSize: 16, marginTop: 20 }}>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            {inspectionReschedule.note}
          </div>
          <div>Nueva fecha sugerida</div>
          <div
            style={{
              fontWeight: "bold",
              display: "inline-block",
              marginTop: 5
            }}
          >
            {format(
              parseInt(inspectionReschedule.requestedDate),
              "MMMM DD, YYYY",
              { locale: esLocale }
            )}
          </div>
        </div>
      </Modal.Section>
      <div style={{ marginBottom: 40, textAlign: "center" }}>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={8}
            radius={7}
            paddingHorizontal={35}
            onClick={onDeclineInspectionReschedule}
            style="outline"
          >
            RECHAZAR
          </Button>
        </div>
        <Button
          paddingVertical={8}
          radius={7}
          paddingHorizontal={30}
          onClick={() => onRescheduleInspectionButton(inspectionRescheduleId)}
          disabled={addButtonDisabled}
        >
          ACEPTAR
        </Button>
      </div>
    </Container>
  );
};

RescheduleInspectionModal.PropTypes = {
  addButtonDisabled: PropTypes.bool.isRequired,
  inspectionRescheduleId: PropTypes.string.isRequired,
  inspectionReschedule: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  onDeclineInspectionReschedule: PropTypes.func.isRequired,
  onRescheduleInspectionButton: PropTypes.func.isRequired
};

export default RescheduleInspectionModal;
