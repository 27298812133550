import React, { Component } from "react";
import PropTypes from "prop-types";

import { Layout } from "../atoms";
import { Spinner } from "../molecules";
import { ProjectCard } from "../organisms";

class ProjectsTemplate extends Component {
  static propTypes = {
    loadingData: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired
  };

  static defaultProps = {
    loadingData: true,
    data: {}
  };

  render() {
    const { loadingData, data } = this.props;
    const facilities = data.clientByUserId
      ? data.clientByUserId.businessNames.map(bName => bName.facilities).reduce((bName, facilities) => bName.concat(facilities), [])
      : data.facilityManager && data.facilityManager.facility
        ? [data.facilityManager.facility]
        : [];

    return (
      <React.Fragment>
        {loadingData && <Spinner />}
        {!loadingData && (
          <Layout.Grid>
            {facilities.length === 0 && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                Aún no tienes plantas asignadas
              </div>
            )}
            {facilities.map(facility => {
              // TODO: We're using the client's address because
              // we currently don't have it on the facilities
              return (
                <ProjectCard
                  thumbnailUrl={facility.avatarUrl}
                  facilityId={facility.id}
                  facilityName={facility.name}
                  facilityAddress={
                    data.clientByUserId
                      ? data.clientByUserId.address
                      : data.facilityManager.client.address
                  }
                />
              );
            })}
          </Layout.Grid>
        )}
      </React.Fragment>
    );
  }
}

export default ProjectsTemplate;
