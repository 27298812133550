import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import fuzzy from "fuzzy";
import format from "date-fns/format";
import esLocale from "date-fns/locale/es";
import mixpanel from "mixpanel-browser";
import ReactTooltip from "react-tooltip";
import Query from "react-apollo";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";

import * as styles from "./ReportsTemplateStyles.scss";

import { bugsnagClient } from "../../bugsnag";
import {
  CHECKLIST_FORMATS,
  CHECKLIST_FORMAT_SCHEMAS
} from "../../utils/checklists";
import { tsToHumanReadableDate, parseMilliseconds } from "../../utils/date";
import {
  approveReport,
  disapproveReport,
  updateReport,
  addAlarmsReportUrl,
  removeAlarmsReportUrl
} from "../../gql/mutations";
import {
  Button,
  Container,
  Heading,
  CloseIcon,
  Modal,
  Table,
  Dropdown,
  AvatarBubble,
  Switch,
  LabeledInput,
  TextArea,
  TextInput,
  AttachmentIcon,
  PlusIcon,
  BigRemoveButton,
  DownloadIcon,
  WhiteEditIcon
} from "../atoms";
import { ModalPortal, Spinner, TableContainerHeader } from "../molecules";
import { ApproveReportModal, DisapproveReportModal } from "../organisms";
import { getReport } from "../../gql/queries";
import { client } from "../../gql/apollo";
import { PDFReportTemplate } from "./";

export const reportsShape = {
  id: PropTypes.string,
  pdfUrl: PropTypes.string,
  date: PropTypes.string,
  approved: PropTypes.bool
};

const initialModalState = {
  filter: undefined,
  dateRangeFilter: {},
  viewReportModal: {
    id: undefined,
    visible: false,
    loading: true,
    editMode: false,
    saving: false,
    report: {},
    bak: {},
    inspections: {},
    introMessage: undefined,
    uploadingReportFile: false,
    reportFile: undefined
  },
  approveReportModal: {
    visible: false,
    addButtonDisabled: false
  },
  disapproveReportModal: {
    visible: false,
    addButtonDisabled: false
  }
};

const initialState = {
  ...initialModalState
};

const REPORT_STATUS = {
  true: "Aprobado",
  false: "Por aprobar"
};

class ReportsTemplate extends Component {
  state = initialState;

  static propTypes = {
    loadingReports: PropTypes.bool.isRequired,
    reports: PropTypes.arrayOf(PropTypes.shape(reportsShape)).isRequired,
    reportId: PropTypes.string
  };

  static defaultProps = {
    loadingReports: true
  };

  componentDidUpdate(prevProps, prevState) {
    const { dateRangeFilter: prevDateRangeFilter } = prevState;
    const { dateRangeFilter } = this.state;
    const { loadingReports, reportId, refetch } = this.props;

    if (prevProps.loadingReports && !loadingReports) {
      if (reportId) {
        this._handleOnPressViewReport(reportId);
      }
    }

    if (
      (!prevDateRangeFilter.startDate || !prevDateRangeFilter.endDate) &&
      dateRangeFilter.startDate &&
      dateRangeFilter.endDate
    ) {
      refetch({
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
    }

    if (prevDateRangeFilter.startDate && prevDateRangeFilter.endDate) {
      if (
        prevDateRangeFilter.startDate !== dateRangeFilter.startDate ||
        prevDateRangeFilter.endDate !== dateRangeFilter.endDate
      ) {
        refetch({
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
      }
    }

    if (
      prevDateRangeFilter.startDate &&
      prevDateRangeFilter.endDate &&
      (!dateRangeFilter.startDate && !dateRangeFilter.endDate)
    ) {
      refetch({ startDate: undefined, endDate: undefined });
    }
  }

  render() {
    const {
      filter,
      viewReportModal,
      approveReportModal,
      disapproveReportModal,
      dateRangeFilter
    } = this.state;
    const { loadingReports, reports } = this.props;

    const filteredReports =
      reports &&
      fuzzy.filter(filter, reports, {
        extract: el => el.fTec.facility.name
      });

    const data = filter
      ? filteredReports && filteredReports.map(result => result.original)
      : reports;

    return (
      <>
        <ReactTooltip />
        <ModalPortal
          modal={this._renderViewReportModal}
          visible={viewReportModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderApproveReportModal}
          visible={approveReportModal.visible}
          onBackdropClick={this._handleOnBackApproveReportButton}
        />
        <ModalPortal
          modal={this._renderDisapproveReportModal}
          visible={disapproveReportModal.visible}
          onBackdropClick={this._handleOnBackDisapproveReportButton}
        />
        <Heading>Reportes</Heading>
        <Container noPadding>
          <div style={{ overflow: "hidden", margin: "30px" }}>
            <TextInput
              width={580}
              placeholder="Buscar por nombre de planta"
              onChange={e => this._filterReports(e)}
            />
            <div
              style={{
                display: "inline-block",
                textAlign: "center",
                marginLeft: "30px",
                position: "absolute"
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "20px",
                  fontWeight: "bold"
                }}
              >
                Filtrar por rango de fechas
              </div>
              <LabeledInput
                index={1}
                type="date-picker"
                name="startDate"
                defaultValue={dateRangeFilter.startDate}
                componentProps={{
                  dayPickerProps: {
                    disabledDays: {
                      after: new Date(dateRangeFilter.endDate)
                    }
                  }
                }}
                onChange={this._handleOnDateInputChange}
              />
              <span style={{ marginLeft: "15px" }}>
                <LabeledInput
                  index={2}
                  type="date-picker"
                  name="endDate"
                  defaultValue={dateRangeFilter.endDate}
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(dateRangeFilter.startDate)
                      }
                    }
                  }}
                  onChange={this._handleOnDateInputChange}
                />
              </span>
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "5px"
                }}
              >
                <BigRemoveButton onClick={this._handleOnResetDateRangeFilter} />
              </span>
            </div>
          </div>
          {loadingReports && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
          {reports && (
            <Table.Main
              columns={[
                { date: "Fecha" },
                { "fTec.facility.name": "Planta" },
                { "fTech.technician.firstName": "Técnico" },
                { approved: "Estado" },
                null
              ]}
              data={data}
              row={(report, lastReportRow) =>
                this._renderReportRow(report, lastReportRow)
              }
            />
          )}
        </Container>
      </>
    );
  }

  _handleOnResetDateRangeFilter = () => {
    this.setState({
      dateRangeFilter: {}
    });
  };

  _filterReports = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnDateInputChange = e => {
    if (e.props) {
      return this.setState(state => ({
        dateRangeFilter: {
          ...state.dateRangeFilter,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _renderReportRow = (report, lastReportRow) => {
    const date = new Date(`${report.date}T00:00`).getTime();

    return (
      <tr
        testid="reports-table-row"
        key={report.id}
        style={{ borderBottom: !lastReportRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>{parseMilliseconds(date)}</Table.Cell>
        <Table.Cell>{report.fTec.facility.name}</Table.Cell>
        <Table.Cell>
          {report.fTec.technician.firstName +
            " " +
            report.fTec.technician.lastName}
        </Table.Cell>
        <Table.Cell>{REPORT_STATUS[report.approved]}</Table.Cell>
        <Table.Cell>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={10}
              style="outline"
              onClick={() => this._handleOnPressViewReport(report.id)}
            >
              Ver
            </Button>
          </div>
        </Table.Cell>
      </tr>
    );
  };

  // View

  _renderViewReportModalButtons = () => {
    const {
      viewReportModal: {
        report,
        sendingReport,
        editMode,
        saving,
        uploadingReportFile
      }
    } = this.state;

    return (
      <>
        <div
          onClick={() => this._handleOnCloseButton()}
          style={{ position: "absolute", cursor: "pointer", right: 30 }}
        >
          <CloseIcon />
        </div>
        <div style={{ float: "right" }}>
          {!editMode && !report.approved && (
            <div style={{ display: "inline-block" }}>
              <input
                type="file"
                ref={ref => (this.reportAttachArea = ref)}
                style={{ display: "none" }}
                onChange={this._handleOnReportAttach}
              />
              <span style={{ marginRight: "15px", display: "inline-block" }}>
                <Button
                  radius={10}
                  onClick={this._handleOnPressAttachReport}
                  disabled={uploadingReportFile || report.alarmsReportUrl}
                >
                  <div
                    data-tip="Adjuntar Reporte"
                    style={{
                      display: "inline-block",
                      height: "22px"
                    }}
                  >
                    <AttachmentIcon color="#ffffff" />
                    <ReactTooltip place="top" type="dark" effect="float" />
                  </div>
                </Button>
              </span>
              <PDFDownloadLink
                document={<PDFReportTemplate report={report} />}
                fileName={`${report.date}_${report.fTec.facility.name}`}
              >
                {({ blob, url, loading, error }) => {
                  if (error) {
                    bugsnagClient.notify(error);
                    return <Button>Error...</Button>;
                  }

                  if (loading) {
                    return <Button>Generando PDF...</Button>;
                  } else {
                    return (
                      <Button onClick={this._handleOnClickViewPDFReport}>
                        <DownloadIcon />
                        <ReactTooltip place="top" type="dark" effect="float" />
                      </Button>
                    );
                  }
                }}
              </PDFDownloadLink>
            </div>
          )}
          {!editMode && !report.approved && (
            <div
              data-tip="Editar Reporte"
              style={{ display: "inline-block", marginLeft: "14px" }}
            >
              <Button radius={10} onClick={this._handleOnPressEditButton}>
                <WhiteEditIcon />
                <ReactTooltip place="top" type="dark" effect="float" />
              </Button>
            </div>
          )}
          {editMode && (
            <div style={{ display: "inline-block", marginRight: "45px" }}>
              <Button
                radius={10}
                onClick={this._handleOnPressSaveButton}
                disabled={saving}
              >
                Guardar
              </Button>
            </div>
          )}
          {!editMode && !report.approved && (
            <div
              style={{
                marginLeft: 15,
                display: "inline-block",
                marginRight: 45
              }}
            >
              <Button
                radius={10}
                onClick={this._handleOnPressApproveReportButton}
              >
                Aprobar
              </Button>
            </div>
          )}
          {!editMode && report.approved && (
            <>
              <div
                data-tip="Descargar PDF"
                style={{
                  display: "inline-block"
                }}
              >
                <PDFDownloadLink
                  document={<PDFReportTemplate report={report} />}
                  fileName={`${report.date}_${report.fTec.facility.name}`}
                >
                  {({ blob, url, loading, error }) => {
                    if (error) {
                      bugsnagClient.notify(error);
                      return <Button>Error...</Button>;
                    }

                    if (loading) {
                      return <Button>Generando PDF...</Button>;
                    } else {
                      return (
                        <Button onClick={this._handleOnClickViewPDFReport}>
                          <DownloadIcon />
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="float"
                          />
                        </Button>
                      );
                    }
                  }}
                </PDFDownloadLink>
              </div>
              <div
                style={{
                  display: "inline-block",
                  marginLeft: "7px"
                }}
              >
                <Button
                  radius={10}
                  onClick={this._handleOnPressDisapproveReportButton}
                >
                  Desaprobar
                </Button>
              </div>
              <div
                style={{
                  display: "inline-block",
                  marginRight: "45px",
                  marginLeft: "7px"
                }}
              >
                <Button
                  radius={10}
                  onClick={this._handleOnPressSendReportButton}
                  disabled={sendingReport}
                >
                  Enviar al cliente
                </Button>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  _renderReportTitle = () => {
    const {
      viewReportModal: { report }
    } = this.state;
    const { name: clientName } = report.fTec.facility.businessName.client;
    const reportDate = format(report.date, "D [de] MMMM [de] YYYY", {
      locale: esLocale
    });

    return (
      <div style={{ marginLeft: 40, marginTop: 40 }}>
        <div
          style={{
            color: "#4a4a4a",
            fontFamily: "Open Sans",
            fontSize: "30px",
            fontWeight: "700",
            display: "inline-block"
          }}
        >
          Reporte de inspección
        </div>
        <div
          style={{
            color: "#686868",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontWeight: "400",
            marginTop: 10
          }}
        >
          {clientName} - {reportDate}
        </div>
      </div>
    );
  };

  _renderComplementaryReport = report => {
    const { viewReportModal } = this.state;
    const pathFragments = report.split("/");
    const fileName = pathFragments[pathFragments.length - 1].replace(
      /[0-9]{13}-/g,
      ""
    );

    return (
      <>
        <div
          style={{
            marginTop: "40px",
            marginBottom: "20px",
            fontSize: "16px",
            fontWeight: "700",
            color: "#686868",
            lineHeight: "20px"
          }}
        >
          Reporte complementario
        </div>
        <div
          style={{
            background: "#F8DBDE",
            borderRadius: 8
          }}
        >
          <div>
            <div
              data-tip="Editar Reporte"
              style={{
                marginRight: 10,
                display: "inline-block"
              }}
            >
              <AttachmentIcon style={{ marginBottom: "-10px" }} />
              <ReactTooltip place="top" type="dark" effect="float" />
            </div>
            {fileName}
          </div>
          {!viewReportModal.report.approved && (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: 15,
                cursor: "pointer"
              }}
              onClick={this._handleOnRemoveComplementaryReport}
            >
              <CloseIcon />
            </div>
          )}
        </div>
      </>
    );
  };

  _handleOnRemoveComplementaryReport = () => {
    const { viewReportModal } = this.state;

    removeAlarmsReportUrl(viewReportModal.id)
      .then(() => {
        this.setState(state => ({
          viewReportModal: {
            ...state.viewReportModal,
            report: {
              ...state.viewReportModal.report,
              alarmsReportUrl: undefined
            }
          }
        }));
      })
      .catch(error => bugsnagClient.notify(error));
  };

  _renderTechnicianBubble = () => {
    const {
      viewReportModal: { report }
    } = this.state;
    const firstName = report.fTec.technician.firstName;
    const lastName = report.fTec.technician.lastName;
    const fullName = `${firstName} ${lastName}`;

    return (
      <>
        <div
          style={{
            marginTop: "30px",
            display: "inline-block"
          }}
        >
          <AvatarBubble
            url={
              report.fTec.technician.avatarUrl &&
              report.fTec.technician.avatarUrl
            }
          />
        </div>
        <div
          style={{
            display: "inline-block",
            fontSize: "12px",
            color: "#686868",
            lineHeight: "20px",
            marginLeft: "10px"
          }}
        >
          Ingeniero
          <div style={{ fontSize: "14px", fontWeight: "700" }}>{fullName}</div>
        </div>
      </>
    );
  };

  _renderIntroductoryMessage = () => {
    const {
      viewReportModal: { report, editMode, introMessage }
    } = this.state;
    const { facility } = report.fTec;
    const { client } = facility.businessName;
    const reportDate = format(report.date, "D [de] MMMM [de] YYYY", {
      locale: esLocale
    });

    const introductoryMessage =
      introMessage !== undefined
        ? introMessage
        : report.introMessage ||
          `Por medio de la presente, le informo que el día ${reportDate}, se realizó la revisión de las condiciones en que se encuentra el sistema contra incendios de ${
            facility.name
          }. Nuestro compromiso es brindarle un excelente servicio de seguridad contra incendios es por eso que buscamos revisar cada uno de los equipos dentro de nuestro alcance.`;

    if (editMode) {
      return (
        <textarea
          onChange={evt => this._handleIntroMessageChange(evt.target.value)}
          rows={3}
          style={{
            marginTop: "40px",
            fontSize: "16px",
            color: "#686868",
            lineHeight: "20px"
          }}
          className={styles.editableTextArea}
          value={introductoryMessage}
        />
      );
    }

    return (
      <div
        style={{
          marginTop: "40px",
          fontSize: "16px",
          color: "#686868",
          lineHeight: "20px"
        }}
      >
        {introductoryMessage}
      </div>
    );
  };

  _renderViewReportModal = () => {
    const {
      viewReportModal: { report, inspections, editMode, loading }
    } = this.state;

    const reportFile = report.alarmsReportUrl;

    if (loading) {
      return <Spinner />;
    }

    const hasCorrectiveActionInspections = report.inspections.some(
      inspection => inspection.__typename === "CorrectiveActionInspection"
    );

    const hasAdditionalServiceInspections = report.inspections.some(
      inspection => inspection.__typename === "AdditionalServiceInspection"
    );

    return (
      <Container width={1010}>
        {this._renderViewReportModalButtons()}
        {this._renderReportTitle()}
        <Modal.Section paddingVertical={40} paddingHorizontal={40}>
          {this._renderTechnicianBubble()}
          {this._renderIntroductoryMessage()}
          {reportFile && this._renderComplementaryReport(reportFile)}
          <div style={{ marginBottom: 40 }}>
            <div
              style={{
                marginTop: "40px",
                marginBottom: "20px",
                fontSize: "16px",
                fontWeight: "700",
                color: "#686868",
                lineHeight: "20px"
              }}
            >
              Módulos incluidos en reporte
            </div>
            <div
              style={{
                lineHeight: "20px",
                fontSize: "14px",
                fontFamily: "Open Sans",
                fontWeight: "400",
                color: "#686868"
              }}
            >
              <ul>
                {report.inspections.map(inspection => {
                  if (inspection.__typename === "Inspection") {
                    const { checklistFormat } = inspection.inspectionChecklist;

                    return <li>• {CHECKLIST_FORMATS[checklistFormat]}</li>;
                  }
                })}
              </ul>
              {hasAdditionalServiceInspections && (
                <ul>- Servicios adicionales</ul>
              )}
              {hasCorrectiveActionInspections && (
                <ul>- Acciones correctivas</ul>
              )}
            </div>
          </div>
          {report.inspections.map(inspection => {
            if (inspection.__typename === "Inspection") {
              const { checklistFormat } = inspection.inspectionChecklist;
              const checklistTitle = CHECKLIST_FORMATS[checklistFormat];

              const checklists = inspection.checklists.sort((a, b) => {
                if (a.number < b.number) return -1;
                if (a.number > b.number) return 1;
                return 0;
              });

              return (
                <>
                  <div
                    style={{
                      marginTop: "27px",
                      marginBottom: "21px",
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                      fontWeight: "700",
                      color: "#686868",
                      lineHeight: "20px"
                    }}
                  >
                    <div>{inspection.title}</div>
                  </div>
                  {checklists.map((checklist, idx) => {
                    const checklistSchema = CHECKLIST_FORMAT_SCHEMAS[
                      checklistFormat
                    ]().default.checks;
                    const checklistItems = JSON.parse(checklist.checklistItems);

                    return (
                      <div key={idx} style={{ marginTop: 60 }}>
                        <div
                          style={{
                            marginTop: "15px",
                            fontSize: "16px",
                            fontFamily: "Open Sans",
                            fontWeight: "700",
                            color: "#686868",
                            lineHeight: "20px",
                            marginBottom: 20
                          }}
                        >
                          {checklistTitle} #{checklist.number}
                        </div>
                        {checklistItems.photoEvidence !== undefined && (
                          <div style={{ marginBottom: 40 }}>
                            <div
                              style={{
                                marginTop: "40px",
                                marginBottom: "20px",
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#686868",
                                lineHeight: "20px"
                              }}
                            >
                              Evidencia fotográfica
                            </div>
                            <div
                              style={{
                                lineHeight: "20px",
                                fontSize: "14px",
                                fontFamily: "Open Sans",
                                fontWeight: "400",
                                color: "#686868"
                              }}
                            >
                              <img
                                style={{ width: 240, borderRadius: 10 }}
                                src={checklistItems.photoEvidence}
                              />
                            </div>
                          </div>
                        )}
                        {checklist.unavailable && (
                          <div
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                          >
                            No disponible
                          </div>
                        )}
                        {!checklist.unavailable && (
                          <>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "30px",
                                fontSize: "14px",
                                fontFamily: "Open Sans",
                                fontWeight: "400",
                                color: "#686868",
                                lineHeight: "20px"
                              }}
                            >
                              {Object.keys(checklistSchema).map(item => {
                                const checklst =
                                  inspections[inspection.id][checklist.id];

                                const value =
                                  checklst[item] && checklst[item].value;

                                const correctiveAction =
                                  checklst[item] &&
                                  checklst[item].correctiveAction &&
                                  checklst[item].correctiveAction.value;

                                const observation =
                                  checklst[item] &&
                                  checklst[item].observation &&
                                  checklst[item].observation.value;

                                const regularPhotos =
                                  (checklistItems[item] &&
                                    checklistItems[item].photos) ||
                                  [];

                                const regularPhotosURIs = regularPhotos.map(
                                  photo => Object.values(photo)[0]
                                );

                                const correctiveActionPhotos =
                                  (checklst[item] &&
                                    checklst[item].correctiveAction &&
                                    checklst[item].correctiveAction.photos) ||
                                  [];

                                const observationPhotos =
                                  (checklst[item] &&
                                    checklst[item].observation &&
                                    checklst[item].observation.photos) ||
                                  [];

                                const photos = [
                                  ...regularPhotosURIs,
                                  ...correctiveActionPhotos,
                                  ...observationPhotos
                                ];

                                if (
                                  item === "turnOnTime" &&
                                  typeof value === "boolean"
                                ) {
                                  checklistSchema[item].label =
                                    "Tiempo de encendido de la bomba 10min";
                                  checklistSchema[item].type = "BOOL";
                                }

                                if (
                                  item !== "area" &&
                                  item !== "key" &&
                                  item !== "photoEvidence"
                                ) {
                                  return (
                                    <div
                                      style={{
                                        marginBottom: "40px",
                                        background: "hsl(220, 16%, 95%)",
                                        padding: "20px",
                                        borderRadius: "10px",
                                        border: "solid 1px #cacfd9"
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between"
                                        }}
                                      >
                                        <div style={{ fontWeight: "700" }}>
                                          {checklistSchema[item].label}
                                        </div>
                                        <div>
                                          {value === undefined && (
                                            <div style={{ fontWeight: "bold" }}>
                                              No aplica
                                            </div>
                                          )}
                                          {value !== undefined && (
                                            <>
                                              {checklistSchema[item].type ===
                                                "BOOL" && (
                                                <Switch
                                                  disabled={!editMode}
                                                  onToggle={state =>
                                                    this._handleCheckStateChange(
                                                      state,
                                                      inspection.id,
                                                      checklist.id,
                                                      item
                                                    )
                                                  }
                                                  falseColor="#cacfd9"
                                                  active={value}
                                                />
                                              )}
                                              {checklistSchema[item].type ===
                                                "NUMBER" && (
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    justifyContent: "center",
                                                    width: 34.5 * 2,
                                                    height: 13 * 2,
                                                    borderRadius: 34.5
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      position: "absolute",
                                                      right: 0,
                                                      transform:
                                                        "translateY(-50%)"
                                                    }}
                                                  >
                                                    {!editMode && (
                                                      <div
                                                        style={{
                                                          alignSelf: "center",
                                                          fontWeight: "700"
                                                        }}
                                                      >
                                                        {value}{" "}
                                                        {
                                                          checklistSchema[item]
                                                            .unit
                                                        }
                                                      </div>
                                                    )}
                                                    {editMode && (
                                                      <LabeledInput
                                                        width={100}
                                                        label={
                                                          checklistSchema[item]
                                                            .unit
                                                        }
                                                        defaultValue={
                                                          checklistItems[item]
                                                            .value
                                                        }
                                                        onChange={evt =>
                                                          this._handleCheckStateChange(
                                                            evt.target.value,
                                                            inspection.id,
                                                            checklist.id,
                                                            item
                                                          )
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      {value !== undefined && (
                                        <div
                                          style={{
                                            display: "flex",
                                            marginTop: 20
                                          }}
                                        >
                                          <div
                                            style={{
                                              flex: 1,
                                              padding: 10,
                                              margin: "0 10px",
                                              borderRadius: 8
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontWeight: "700",
                                                marginBottom: 10
                                              }}
                                            >
                                              Evidencia fotográfica
                                            </div>
                                            {photos.length === 0 &&
                                              "Sin evidencia fotográfica"}
                                            {photos.length > 0 && (
                                              <div>
                                                {photos.map(photo => (
                                                  <div
                                                    style={{
                                                      overflow: "hidden",
                                                      position: "relative",
                                                      marginRight: 10,
                                                      width: "calc(33% - 10px)",
                                                      float: "left",
                                                      borderRadius: 8
                                                    }}
                                                  >
                                                    <img
                                                      style={{ width: "100%" }}
                                                      src={photo}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              flex: 1,
                                              padding: 10,
                                              margin: "0 10px"
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontWeight: "700",
                                                marginBottom: 10
                                              }}
                                            >
                                              Observación
                                            </div>
                                            {!editMode &&
                                              (observation ||
                                                "Sin observación")}
                                            {editMode && (
                                              <textarea
                                                onChange={evt =>
                                                  this._handleCheckActionChange(
                                                    "observation",
                                                    evt.target.value,
                                                    inspection.id,
                                                    checklist.id,
                                                    item
                                                  )
                                                }
                                                className={
                                                  styles.editableTextArea
                                                }
                                                value={observation}
                                              />
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              flex: 1,
                                              padding: 10,
                                              margin: "0 10px"
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontWeight: "700",
                                                marginBottom: 10
                                              }}
                                            >
                                              Acción correctiva
                                            </div>
                                            {!editMode &&
                                              (correctiveAction ||
                                                "Sin acción correctiva")}
                                            {editMode && (
                                              <textarea
                                                onChange={evt =>
                                                  this._handleCheckActionChange(
                                                    "correctiveAction",
                                                    evt.target.value,
                                                    inspection.id,
                                                    checklist.id,
                                                    item
                                                  )
                                                }
                                                className={
                                                  styles.editableTextArea
                                                }
                                                value={correctiveAction}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              );
            } else if (
              inspection.__typename === "AdditionalServiceInspection"
            ) {
              const photos = inspections[inspection.id].photos || [];
              const observation = inspections[inspection.id].observation;

              return (
                <div
                  style={{
                    marginBottom: "40px",
                    background: "hsl(220, 16%, 95%)",
                    padding: "20px",
                    borderRadius: "10px",
                    border: "solid 1px #cacfd9"
                  }}
                >
                  <div
                    style={{
                      marginBottom: "20px",
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                      fontWeight: "700",
                      color: "#686868",
                      lineHeight: "20px"
                    }}
                  >
                    Servicio adicional – {inspection.additionalService.details}
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "30px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                      color: "#686868",
                      lineHeight: "20px"
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: "0 10px",
                          borderRadius: 8
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: 10
                          }}
                        >
                          Evidencia fotográfica
                        </div>
                        {photos.length === 0 && "Sin evidencia fotográfica"}
                        {photos.length > 0 && (
                          <div>
                            {photos.map(photo => (
                              <div
                                style={{
                                  overflow: "hidden",
                                  position: "relative",
                                  marginRight: 10,
                                  width: "calc(33% - 10px)",
                                  float: "left",
                                  borderRadius: 8
                                }}
                              >
                                <img
                                  style={{ width: "100%" }}
                                  src={Object.values(photo)[0]}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: "0 10px"
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: 10
                          }}
                        >
                          Observación
                        </div>
                        {!editMode && (observation || "Sin observación")}
                        {editMode && (
                          <textarea
                            onChange={evt =>
                              this._handleObservationChange(
                                evt.target.value,
                                inspection.id
                              )
                            }
                            className={styles.editableTextArea}
                            value={observation}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (inspection.__typename === "CorrectiveActionInspection") {
              const photos = inspections[inspection.id].photos || [];
              const observation = inspections[inspection.id].observation;

              return (
                <div
                  style={{
                    marginBottom: "40px",
                    background: "hsl(220, 16%, 95%)",
                    padding: "20px",
                    borderRadius: "10px",
                    border: "solid 1px #cacfd9"
                  }}
                >
                  <div
                    style={{
                      marginBottom: "20px",
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                      fontWeight: "700",
                      color: "#686868",
                      lineHeight: "20px"
                    }}
                  >
                    Acción correctiva – {inspection.correctiveAction.details}
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "30px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                      color: "#686868",
                      lineHeight: "20px"
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: "0 10px",
                          borderRadius: 8
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: 10
                          }}
                        >
                          Evidencia fotográfica
                        </div>
                        {photos.length === 0 && "Sin evidencia fotográfica"}
                        {photos.length > 0 && (
                          <div>
                            {photos.map(photo => (
                              <div
                                style={{
                                  overflow: "hidden",
                                  position: "relative",
                                  marginRight: 10,
                                  width: "calc(33% - 10px)",
                                  float: "left",
                                  borderRadius: 8
                                }}
                              >
                                <img
                                  style={{ width: "100%" }}
                                  src={Object.values(photo)[0]}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: "0 10px"
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: 10
                          }}
                        >
                          Observación
                        </div>
                        {!editMode && (observation || "Sin observación")}
                        {editMode && (
                          <textarea
                            onChange={evt =>
                              this._handleObservationChange(
                                evt.target.value,
                                inspection.id
                              )
                            }
                            className={styles.editableTextArea}
                            value={observation}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </Modal.Section>
      </Container>
    );
  };

  _handleOnPressViewReport = reportId => {
    this.setState(state => ({
      viewReportModal: {
        ...state.viewReportModal,
        id: reportId,
        visible: true,
        loading: true
      }
    }));

    client
      .query({
        query: getReport,
        variables: { reportId },
        fetchPolicy: "network-only"
      })
      .then(result => {
        const inspections = {};
        result.data.report &&
          result.data.report.inspections.map(inspection => {
            inspections[inspection.id] = inspections[inspection.id] || {};
            if (inspection.__typename === "Inspection") {
              inspection.checklists.map(checklist => {
                inspections[inspection.id] = {
                  ...inspections[inspection.id],
                  [checklist.id]: JSON.parse(checklist.checklistItems)
                };
              });
            } else if (
              inspection.__typename === "AdditionalServiceInspection" ||
              inspection.__typename === "CorrectiveActionInspection"
            ) {
              inspections[inspection.id] = {
                ...inspections[inspection.id],
                __typename: inspection.__typename,
                photos: JSON.parse(inspection.photos) || [],
                observation: inspection.observation
              };
            }
          });

        this.setState(state => ({
          viewReportModal: {
            ...state.viewReportModal,
            visible: true,
            loading: false,
            report: result.data.report,
            inspections
          }
        }));
      })
      .catch(err => bugsnagClient.notify(err));
  };

  _handleOnCloseButton = () => {
    const { viewReportModal } = this.state;

    const areEqual = isEqual(viewReportModal.bak, viewReportModal.inspections);
    if (viewReportModal.editMode && !areEqual) {
      const exitConfirm = confirm(
        "Los cambios no guardados se perderán, ¿Deseas continuar?"
      );

      if (exitConfirm) {
        this.setState(state => ({
          viewReportModal: initialModalState.viewReportModal
        }));
      }
    } else {
      this.setState(state => ({
        viewReportModal: initialModalState.viewReportModal
      }));
    }
  };

  // Approve

  _renderApproveReportModal = () => {
    const {
      viewReportModal: { report },
      approveReportModal: { addButtonDisabled }
    } = this.state;

    const approveReportId = report.id;

    return (
      <ApproveReportModal
        approveReportId={approveReportId}
        addButtonDisabled={addButtonDisabled}
        report={report}
        onBackApproveButton={this._handleOnBackApproveReportButton}
        onApproveReportButton={this._handleOnApproveReportButton}
      />
    );
  };

  // Approve

  _handleOnBackApproveReportButton = () => {
    this.setState(state => ({
      approveReportModal: {
        ...state.approveReportModal,
        visible: false
      }
    }));
  };

  _handleOnPressApproveReportButton = () => {
    this.setState(state => ({
      approveReportModal: {
        ...state.approveReportModal,
        visible: true
      }
    }));
  };

  _handleOnApproveReportButton = reportId => {
    if (!!reportId) {
      this.setState(
        (state, _) => ({
          approveReportModal: {
            ...state.approveReportModal,
            addButtonDisabled: true
          }
        }),
        () =>
          approveReport(reportId)
            .then(() => {
              window.location = "/reportes";
              mixpanel.track("Aprobar reporte");
            })
            .catch(err => {
              bugsnagClient.notify(err);
              this.setState(state => ({
                approveReportModal: {
                  visible: false
                }
              }));
            })
      );
    }
  };

  // Disapprove
  _renderDisapproveReportModal = () => {
    const {
      viewReportModal: { report },
      disapproveReportModal: { addButtonDisabled }
    } = this.state;

    const disapproveReportId = report.id;

    return (
      <DisapproveReportModal
        disapproveReportId={disapproveReportId}
        addButtonDisabled={addButtonDisabled}
        report={report}
        onBackDisapproveButton={this._handleOnBackDisapproveReportButton}
        onDisapproveReportButton={this._handleOnDisapproveReportButton}
      />
    );
  };

  // Disapprove

  _handleOnBackDisapproveReportButton = () => {
    this.setState(state => ({
      disapproveReportModal: {
        ...state.disapproveReportModal,
        visible: false
      }
    }));
  };

  _handleOnPressDisapproveReportButton = () => {
    this.setState(state => ({
      disapproveReportModal: {
        ...state.disapproveReportModal,
        visible: true
      }
    }));
  };

  _handleOnPressSendReportButton = async () => {
    const {
      viewReportModal: { report, sendingReport }
    } = this.state;

    if (!sendingReport) {
      this.setState(state => ({
        viewReportModal: {
          ...state.viewReportModal,
          sendingReport: true
        }
      }));

      try {
        const blob = await pdf(<PDFReportTemplate report={report} />).toBlob();
        const data = new FormData();
        data.append("file", blob);

        const response = await fetch(
          `${process.env.SEND_REPORT_ENDPOINT}/${report.id}`,
          {
            method: "POST",
            body: data,
            processData: false,
            contentType: false
          }
        );

        if (response.status !== 200) {
          alert("Hubo un problema al enviar el reporte al cliente.");
          bugsnagClient.notify(response);
        } else {
          alert("Se envió el reporte al cliente.");
        }
        this.setState(state => ({
          viewReportModal: {
            ...state.viewReportModal,
            sendingReport: false
          }
        }));
      } catch (error) {
        alert("Hubo un problema al enviar el reporte al cliente.");
        bugsnagClient.notify(error);
        this.setState(state => ({
          viewReportModal: {
            ...state.viewReportModal,
            sendingReport: false
          }
        }));
      }
    }
  };

  _handleOnDisapproveReportButton = reportId => {
    if (!!reportId) {
      this.setState(
        (state, _) => ({
          disapproveReportModal: {
            ...state.disapproveReportModal,
            addButtonDisabled: true
          }
        }),
        () =>
          disapproveReport(reportId)
            .then(() => {
              window.location = "/reportes";
              mixpanel.track("Desaprobar reporte");
            })
            .catch(err => {
              bugsnagClient.notify(err);
              this.setState(state => ({
                disapproveReportModal: {
                  visible: false
                }
              }));
            })
      );
    }
  };

  _handleOnPressAttachReport = () => {
    this.reportAttachArea.click();
    mixpanel.track("Adjuntar documento a reporte");
  };

  _handleOnReportAttach = evt => {
    const { viewReportModal } = this.state;
    const { files } = this.reportAttachArea;

    if (files.length > 0) {
      const data = new FormData();
      data.append("file", files[0], files[0].name);
      evt.target.value = "";

      this.setState(state => ({
        viewReportModal: { ...state.viewReportModal, uploadingReportFile: true }
      }));

      fetch(process.env.UPLOAD_ASSETS_URL, {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: data
      })
        .then(resp => resp.json())
        .then(result => {
          addAlarmsReportUrl(viewReportModal.id.toString(), result.imageUrl)
            .then(() => {
              this.setState(state => ({
                viewReportModal: {
                  ...state.viewReportModal,
                  uploadingReportFile: false,
                  report: {
                    ...state.viewReportModal.report,
                    alarmsReportUrl: result.imageUrl
                  }
                }
              }));
            })
            .catch(error => {
              bugsnagClient.notify(error);
              this.setState(state => ({
                viewReportModal: {
                  ...state.viewReportModal,
                  uploadingReportFile: false
                }
              }));
            });
        })
        .catch(error => {
          bugsnagClient.notify(error);
          this.setState(state => ({
            viewReportModal: {
              ...state.viewReportModal,
              uploadingReportFile: false
            }
          }));
        });
    }
  };

  _handleOnPressEditButton = () => {
    this.setState(state => ({
      viewReportModal: {
        ...state.viewReportModal,
        editMode: true,
        bak: state.viewReportModal.inspections
      }
    }));
  };

  _handleIntroMessageChange = newState => {
    this.setState(state => ({
      viewReportModal: {
        ...state.viewReportModal,
        introMessage: newState
      }
    }));
  };

  _handleCheckStateChange = (
    newState,
    inspectionId,
    checklistId,
    checklistItem
  ) => {
    this.setState(state => ({
      viewReportModal: {
        ...state.viewReportModal,
        inspections: {
          ...state.viewReportModal.inspections,
          [inspectionId]: {
            ...state.viewReportModal.inspections[inspectionId],
            [checklistId]: {
              ...state.viewReportModal.inspections[inspectionId][checklistId],
              [checklistItem]: {
                ...state.viewReportModal.inspections[inspectionId][checklistId][
                  checklistItem
                ],
                value: newState
              }
            }
          }
        }
      }
    }));
  };

  _handleCheckActionChange = (
    key,
    newState,
    inspectionId,
    checklistId,
    checklistItem
  ) => {
    const {
      viewReportModal: { inspections }
    } = this.state;

    const withKey = {
      ...inspections[inspectionId][checklistId][checklistItem],
      [key]: {
        ...inspections[inspectionId][checklistId][checklistItem][key],
        value: newState
      }
    };

    this.setState(state => ({
      viewReportModal: {
        ...state.viewReportModal,
        inspections: {
          ...state.viewReportModal.inspections,
          [inspectionId]: {
            ...state.viewReportModal.inspections[inspectionId],
            [checklistId]: {
              ...state.viewReportModal.inspections[inspectionId][checklistId],
              [checklistItem]: withKey
            }
          }
        }
      }
    }));
  };

  _handleObservationChange = (newState, inspectionId) =>
    this.setState(state => ({
      viewReportModal: {
        ...state.viewReportModal,
        inspections: {
          ...state.viewReportModal.inspections,
          [inspectionId]: {
            ...state.viewReportModal.inspections[inspectionId],
            observation: newState
          }
        }
      }
    }));

  _handleOnPressSaveButton = () => {
    this.setState(state => ({
      viewReportModal: { ...state.viewReportModal, saving: true }
    }));

    const { viewReportModal } = this.state;
    const areEqual = isEqual(viewReportModal.bak, viewReportModal.inspections);
    mixpanel.track("Guardar reporte editado");

    if (areEqual && viewReportModal.introMessage === undefined) {
      this.setState(state => ({
        viewReportModal: {
          ...state.viewReportModal,
          saving: false,
          editMode: false
        }
      }));
    } else {
      updateReport(
        viewReportModal.id,
        viewReportModal.introMessage ? viewReportModal.introMessage : null,
        JSON.stringify(viewReportModal.inspections)
      )
        .then(() =>
          this.setState(state => ({
            viewReportModal: {
              ...state.viewReportModal,
              saving: false,
              editMode: false
            }
          }))
        )
        .catch(err => {
          bugsnagClient.notify(err);
          this.setState(state => ({
            viewReportModal: { ...state.viewReportModal, saving: false }
          }));
        });
    }
  };

  _handleOnClickViewPDFReport = () =>
    mixpanel.track("Descargar Reporte PDF desde portal Administrador");
}

export default ReportsTemplate;
