import { gql } from "apollo-boost";

const getContractsByProject = gql`
  query getContractsByProject($facilityId: ID!) {
    contractsByProject(facilityId: $facilityId) {
      id
      annualCost
      monthlyCost
      baseBudget
      policyNumber
      status
      startDate
      endDate
      contractPolicyUrl
      SCINotesUrl
      commercialConditionsUrl
      facilityBlueprintsUrl
      deleted
      createdAt
      updatedAt

      facility {
        id
        name
        businessName {
          id
          name
          client {
            id
            avatarUrl
            name
          }
        }
      }

      contractDocuments {
        id
        url
        name
        type
      }

      inspectionChecklists {
        id
        checklistFormat
        quantity
        recurrence
        repeatOn
        status
        startOn
      }
    }
  }
`;

export default getContractsByProject;
