import React from "react";

import statesAndCities from "../../../utils/statesAndCities";
import {
  Button,
  Container,
  CloseIcon,
  Modal,
  Layout,
  LabeledInput,
  RemoveButton
} from "../../atoms";
import { LoadAsset, RemovableInput } from "../../molecules";

const AddClientModal = ({
  addButtonDisabled,
  selectedState,
  managerAccounts,
  managerAccountErrors,
  businessNameInputs,
  onClickCloseButton,
  onManagerAccountInputChange,
  onInputChange,
  onManagerAccountAddButton,
  onManagerAccountRemoveButton,
  onStateInputChange,
  onBusinessNameInputChange,
  onBusinessNameInputRemove,
  onBusinessNameAddButton,
  onCreateClientButton
}) => {
  return (
    <Container width={1100}>
      <div
        onClick={onClickCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>Creación de un nuevo cliente</Modal.Header>
      <Modal.Section
        style={{ display: "flex" }}
        paddingVertical={40}
        paddingHorizontal={100}
      >
        <LoadAsset
          buttonLabel="Adjuntar Logo"
          image="file_adjuntarlogo"
          centered={false}
        />
        <Layout.Grid>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={1}
                fullWidth
                label="Nombre del cliente"
                name="clientNameInput"
                onChange={onInputChange}
              />
            </div>
            <LabeledInput
              index={3}
              fullWidth
              label="Municipio"
              type="select"
              selectOptions={statesAndCities[selectedState]}
              name="clientCityInput"
              onChange={onInputChange}
            />
          </Layout.Column>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={2}
                fullWidth
                label="Estado"
                type="select"
                selectOptions={Object.keys(statesAndCities)}
                name="clientStateInput"
                onChange={onStateInputChange}
              />
            </div>
            <LabeledInput
              index={4}
              fullWidth
              label="Dirección"
              name="clientAddressInput"
              onChange={onInputChange}
            />
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Header>Cuenta del cliente</Modal.Header>
      <Modal.Section
        style={{ display: "flex" }}
        paddingVertical={80}
        paddingHorizontal={100}
      >
        <Layout.Grid style={{ margin: "0 auto" }}>
          <Layout.Column style={{ padding: "0 40px" }}>
            <LabeledInput
              index={5}
              fullWidth
              label="Correo electrónico"
              name="clientEmailInput"
              onChange={onInputChange}
            />
          </Layout.Column>
          <Layout.Column style={{ padding: "0 40px" }}>
            <LabeledInput
              index={6}
              fullWidth
              type="password"
              label="Contraseña"
              name="clientPasswordInput"
              onChange={onInputChange}
            />
          </Layout.Column>
          <Layout.Column style={{ padding: "0 40px" }}>
            <LabeledInput
              index={7}
              fullWidth
              type="password"
              label="Confirmar contraseña"
              name="clientPasswordConfirmInput"
              onChange={onInputChange}
            />
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Header>Cuentas de gerentes de planta</Modal.Header>
      {Object.keys(managerAccounts).length > 0 &&
        Object.keys(managerAccounts).map(account => {
          if (managerAccounts[account] !== undefined) {
            return (
              <>
                <Modal.Section
                  style={{
                    display: "flex",
                    marginTop: 80,
                    marginBottom: 0,
                    marginLeft: 100,
                    marginRight: 100
                  }}
                >
                  <Layout.Grid style={{ margin: "0 auto" }}>
                    <Layout.Column style={{ padding: "0 40px" }}>
                      <LabeledInput
                        fullWidth
                        label="Correo electrónico"
                        name="managerEmailInput"
                        onChange={evt =>
                          onManagerAccountInputChange(evt, account)
                        }
                      />
                    </Layout.Column>
                    <Layout.Column style={{ padding: "0 40px" }}>
                      <LabeledInput
                        fullWidth
                        type="password"
                        label="Contraseña"
                        name="managerPasswordInput"
                        onChange={evt =>
                          onManagerAccountInputChange(evt, account)
                        }
                      />
                    </Layout.Column>
                    <Layout.Column style={{ padding: "0 40px" }}>
                      <LabeledInput
                        fullWidth
                        type="password"
                        label="Confirmar contraseña"
                        name="managerPasswordConfirmInput"
                        onChange={evt =>
                          onManagerAccountInputChange(evt, account)
                        }
                      />
                    </Layout.Column>
                  </Layout.Grid>
                  <RemoveButton
                    onClick={() => onManagerAccountRemoveButton(account)}
                  />
                </Modal.Section>
                {managerAccountErrors[account] && (
                  <div
                    style={{
                      marginTop: 20,
                      marginRight: 100,
                      marginLeft: 100,
                      padding: 20,
                      borderRadius: 10,
                      textAlign: "right",
                      backgroundColor: "#F8DBDE"
                    }}
                  >
                    {managerAccountErrors[account].error}
                  </div>
                )}
              </>
            );
          }
        })}
      <div style={{ textAlign: "center", marginTop: 80 }}>
        <Button
          paddingVertical={10}
          onClick={onManagerAccountAddButton}
          style="outline"
        >
          {Object.keys(managerAccounts).length === 0
            ? "Añadir cuenta de gerente"
            : "Añadir otra cuenta de gerente"}
        </Button>
      </div>
      <Modal.Header>Añade las razones sociales</Modal.Header>
      <Modal.Section paddingVertical={40} paddingHorizontal={100}>
        {Object.keys(businessNameInputs).map(idx => {
          if (parseInt(idx) === 0) {
            return (
              <div
                key={idx}
                style={{
                  display: "inline-block",
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                <LabeledInput
                  name={`${idx}`}
                  label="Razón social"
                  onChange={onBusinessNameInputChange}
                />
              </div>
            );
          } else if (businessNameInputs[idx] !== undefined) {
            return (
              <div
                style={{
                  display: "inline-block",
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                <RemovableInput
                  name={`${idx}`}
                  label="Razón social"
                  onChange={onBusinessNameInputChange}
                  onRemove={onBusinessNameInputRemove}
                />
              </div>
            );
          }
        })}
        <div style={{ textAlign: "center" }}>
          <Button
            paddingVertical={10}
            onClick={onBusinessNameAddButton}
            style="outline"
          >
            Añadir una razón social
          </Button>
        </div>
      </Modal.Section>
      <Modal.Footer>
        <Button
          paddingVertical={10}
          paddingHorizontal={48}
          onClick={onCreateClientButton}
          disabled={addButtonDisabled}
        >
          Crear
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default AddClientModal;
