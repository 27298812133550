import React from "react";

const Download = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="22"
    viewBox="0 0 24 22"
  >
    <defs>
      <path
        id="4g0ca"
        d="M1182.312 109.097c-.382 0-.687.293-.687.658v5.982c0 1.627-1.386 2.948-3.082 2.948h-15.086c-1.701 0-3.082-1.325-3.082-2.948v-6.08c0-.365-.305-.657-.687-.657-.382 0-.688.292-.688.658v6.08c0 2.352 2.002 4.262 4.457 4.262h15.086c2.46 0 4.457-1.915 4.457-4.263v-5.982c0-.36-.306-.658-.688-.658z"
      />
      <path
        id="4g0cb"
        d="M1170.524 114.796a.66.66 0 0 0 .956 0l4.32-4.374a.692.692 0 0 0 0-.973.672.672 0 0 0-.962 0l-3.156 3.201V98.688a.681.681 0 0 0-.68-.688.681.681 0 0 0-.68.688v13.962l-3.16-3.201a.672.672 0 0 0-.962 0 .692.692 0 0 0 0 .973z"
      />
    </defs>
    <use fill="#fff" xlinkHref="#4g0ca" transform="translate(-1159 -98)" />
    <use fill="#fff" xlinkHref="#4g0cb" transform="translate(-1159 -98)" />
  </svg>
);

export default Download;
