import React, { Component } from "react";
import { Query } from "react-apollo";

import { getUsers } from "../../gql/queries";
import { UsersTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class UsersView extends Component {
  render() {
    return (
      <Query query={getUsers}>
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div testid="error-wrapper">error</div>;
          }

          return (
            <UsersTemplate
              loadingUsers={loading}
              users={data && data.adminUsers}
            />
          );
        }}
      </Query>
    );
  }
}

export default UsersView;
