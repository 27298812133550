import { gql } from "apollo-boost";
import { client } from "../apollo";

const disapproveReport = async reportId =>
  await client.mutate({
    mutation: gql`
      mutation {
        disapproveReport(
          reportId: "${reportId}"
        )
      }
  `
  });

export default disapproveReport;
