import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const Header = ({ children }) => {
  return <div className={styles.modalHeader}>{children}</div>;
};

const Section = ({ style, paddingVertical, paddingHorizontal, children }) => {
  const customStyle = {
    ...style,
    paddingTop: paddingVertical && `${paddingVertical}px`,
    paddingBottom: paddingVertical && `${paddingVertical}px`,
    paddingLeft: paddingHorizontal && `${paddingHorizontal}px`,
    paddingRight: paddingHorizontal && `${paddingHorizontal}px`
  };

  return (
    <div className={styles.modalSection} style={customStyle}>
      {children}
    </div>
  );
};

Section.propTypes = {
  style: PropTypes.object,
  paddingVertical: PropTypes.number,
  paddingHorizontal: PropTypes.number
};

const Footer = ({ children }) => {
  return <div className={styles.modalFooter}>{children}</div>;
};

export default {
  Header,
  Section,
  Footer
};
