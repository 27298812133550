import React from "react";

const Email = props => (
  <svg width={22} height={19} {...props}>
    <defs>
      <path
        id="Group_18_svg__a"
        d="M39.112 407.64c.278.207 1.117.825 2.516 1.851a621.15 621.15 0 0 1 3.217 2.371c.082.061.256.193.521.397a13.535 13.535 0 0 0 1.302.916c.25.156.485.273.706.35.22.079.425.117.614.117h.024c.189 0 .393-.038.614-.117.221-.077.457-.195.706-.35.25-.156.463-.297.639-.422.176-.126.396-.29.662-.494.266-.204.44-.336.522-.397l5.746-4.223a6.021 6.021 0 0 0 1.497-1.598c.402-.623.602-1.278.602-1.962a2.05 2.05 0 0 0-.583-1.468c-.39-.407-.85-.611-1.381-.611H38.964c-.63 0-1.115.225-1.455.676-.34.45-.509 1.013-.509 1.689 0 .546.225 1.137.675 1.774.45.636.93 1.137 1.437 1.5z"
      />
      <path
        id="Group_18_svg__b"
        d="M57.772 409.13c-2.684 1.923-4.722 3.418-6.113 4.483-.467.364-.845.648-1.136.851a6.87 6.87 0 0 1-1.16.624c-.483.212-.933.319-1.35.319h-.025c-.418 0-.868-.107-1.35-.319a6.877 6.877 0 0 1-1.16-.624c-.291-.203-.67-.487-1.136-.85-1.105-.858-3.14-2.353-6.102-4.484A6.628 6.628 0 0 1 37 408v10.317c0 .572.192 1.061.577 1.468.385.408.847.611 1.387.611h18.072c.54 0 1.002-.203 1.387-.61.385-.408.577-.897.577-1.469V408a6.905 6.905 0 0 1-1.228 1.13z"
      />
    </defs>
    <use
      fill="#c0c5d2"
      xlinkHref="#Group_18_svg__a"
      transform="translate(-37 -402)"
    />
    <use
      fill="#c0c5d2"
      xlinkHref="#Group_18_svg__b"
      transform="translate(-37 -402)"
    />
  </svg>
);

export default Email;
