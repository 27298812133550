import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { getInspectionsByProject } from "../../gql/queries";

// Local imports
import { InspectionsCalendarTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class InspectionView extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;

    return (
      <Query
        query={getInspectionsByProject}
        variables={{
          month: String(new Date().getUTCMonth()),
          year: String(new Date().getUTCFullYear()),
          facilityId: match.params.fid
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div>Error</div>;
          }

          if (data) {
            const actualData = {
              facility: data.facility,
              inspectionsByProject: data.inspectionsByProject
                ? data.inspectionsByProject
                : [],
              regularInspectionsByProject: data.regularInspectionsByProject
                ? data.regularInspectionsByProject
                : []
            };

            return (
              <InspectionsCalendarTemplate
                loadingData={loading}
                refetch={refetch}
                data={actualData}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default InspectionView;
