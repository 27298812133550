import { gql } from "apollo-boost";
import { client } from "../apollo";

const createTechnician = async (
  avatarUrl,
  firstName,
  lastName,
  email,
  mobilePhone,
  officePhone,
  address,
  facilityIds,
  password
) =>
  await client.mutate({
    mutation: gql`
      mutation createTechnician(
        $avatarUrl: String
        $firstName: String!
        $lastName: String!
        $email: String!
        $mobilePhone: String!
        $officePhone: String!
        $address: String!
        $facilityIds: [ID!]!
        $password: String!
      ) {
        createFacilityTechnician(
          avatarUrl: $avatarUrl
          firstName: $firstName
          lastName: $lastName
          email: $email
          mobilePhone: $mobilePhone
          officePhone: $officePhone
          address: $address
          facilityIds: $facilityIds
          password: $password
        ) {
          id
        }
      }
    `,
    variables: {
      avatarUrl,
      firstName,
      lastName,
      email,
      mobilePhone,
      officePhone,
      address,
      facilityIds,
      password
    }
  });

export default createTechnician;
