import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { SpinnerIcon, MiniSpinnerIcon } from "../../atoms/Icons";

const Spinner = ({ mini }) => (
  <div className={styles.spinner} style={mini ? { top: 0, left: 0 } : {}}>
    {mini ? <MiniSpinnerIcon /> : <SpinnerIcon />}
  </div>
);

Spinner.propTypes = {
  mini: PropTypes.bool
};

Spinner.defaultProps = {
  mini: false
};

export default Spinner;
