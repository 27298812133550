import React, { Component } from "react";
import PropTypes from "prop-types";
import fuzzy from "fuzzy";

import { currency } from "../../utils/formatter";
import {
  AvatarBubble,
  Button,
  Container,
  Heading,
  Table,
  TextInput
} from "../atoms";
import { Spinner, TableContainerHeader } from "../molecules";

const ADDITIONAL_SERVICE_STATUS = {
  null: "Pendiente",
  PURCHASE_ORDER: "Orden de Compra",
  JOB_FINISHED: "Trabajo Terminado",
  INVOICED: "Facturado"
};

const ADDITIONAL_SERVICE_STATUS_COLOR = {
  null: "#f5a623",
  PURCHASE_ORDER: "#d0021b",
  JOB_FINISHED: "#7ed321",
  INVOICED: "#7ed321"
};

class AdditionalServicesListTemplate extends Component {
  state = {
    filter: undefined,
    additionalServiceStatuses: {}
  };
  render() {
    const { filter } = this.state;
    const { additionalServices, loadingAdditionalServices } = this.props;

    const filteredAdditionalServices =
      additionalServices &&
      fuzzy.filter(filter, additionalServices, {
        extract: el => el.facility.businessName.client.name
      });

    const data = filter
      ? filteredAdditionalServices &&
        filteredAdditionalServices.map(result => result.original)
      : additionalServices;

    return (
      <>
        <Heading>Servicios Adicionales</Heading>
        <Container>
          {loadingAdditionalServices && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
          {additionalServices && (
            <Table.Main
              columns={[
                null,
                { facility: "Planta" },
                { client: "Cliente" },
                { businessName: "Razón Social" },
                { title: "Descripción" },
                { concept: "Concepto" },
                { zone: "Zona" },
                { budget: "Monto MXN" },
                { purchaseOrder: "OC" },
                { jobFinished: "TT" },
                { invoiced: "F" }
              ]}
              data={data}
              row={(additionalService, lastRow) =>
                this._renderAdditionalServiceRow(additionalService, lastRow)
              }
            />
          )}
        </Container>
      </>
    );
  }

  _filterAdditionalServices = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _renderAdditionalServiceRow = (additionalService, lastRow) => {
    const { additionalServiceStatuses } = this.state;
    const purchaseOrderStatus = additionalServiceStatuses[additionalService.id]
      ? additionalServiceStatuses[additionalService.id].purchaseOrder
      : additionalService.purchaseOrder;

    const jobFinishedStatus = additionalServiceStatuses[additionalService.id]
      ? additionalServiceStatuses[additionalService.id].jobFinished
      : additionalService.jobFinished;

    const invoicedStatus = additionalServiceStatuses[additionalService.id]
      ? additionalServiceStatuses[additionalService.id].invoiced
      : additionalService.invoiced;
    return (
      <tr
        testid="additionalServices-table-row"
        key={additionalService.id}
        style={{ borderBottom: !lastRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble
              url={
                additionalService.facility.businessName.client.avatarUrl &&
                additionalService.facility.businessName.client.avatarUrl
              }
            />
          </div>
        </Table.Cell>
        <Table.Cell>{additionalService.facility.name}</Table.Cell>
        <Table.Cell>
          {additionalService.facility.businessName.client.name}
        </Table.Cell>
        <Table.Cell>{additionalService.facility.businessName.name}</Table.Cell>
        <Table.Cell>{additionalService.details}</Table.Cell>
        <Table.Cell>{additionalService.title}</Table.Cell>
        <Table.Cell>{additionalService.facility.zone}</Table.Cell>
        <Table.Cell>{currency(additionalService.budget)}</Table.Cell>
        <Table.Cell>
          <input
            type="checkbox"
            name="purchaseOrder"
            checked={purchaseOrderStatus}
            disabled={!purchaseOrderStatus}
          />
        </Table.Cell>
        <Table.Cell>
          <input
            type="checkbox"
            name="jobFinished"
            checked={jobFinishedStatus}
            disabled={!jobFinishedStatus}
          />
        </Table.Cell>
        <Table.Cell>
          <input
            type="checkbox"
            name="invoiced"
            checked={invoicedStatus}
            disabled={!invoicedStatus}
          />
        </Table.Cell>
      </tr>
    );
  };
}

export default AdditionalServicesListTemplate;
