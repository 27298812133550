import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import classJoiner from "../../../utils/classJoiner";
import { NonSelectable } from "../";

const RoundButton = ({ onClick, disabled, children }) => (
  <NonSelectable
    style={{ display: "block" }}
    onClick={!disabled ? onClick : undefined}
  >
    <div
      className={
        disabled
          ? classJoiner([styles.roundButton, styles.disabledRoundButton])
          : styles.roundButton
      }
    >
      {children}
    </div>
  </NonSelectable>
);

RoundButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.string.isRequired
};

RoundButton.defaultProps = {
  disabled: false
};

export default RoundButton;
