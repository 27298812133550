import { gql } from "apollo-boost";
import { client } from "../apollo";

const deleteAdditionalService = async additionalServiceId =>
  await client.mutate({
    mutation: gql`
      mutation {
        deleteAdditionalService(
          additionalServiceId: "${additionalServiceId}"
        )
      }
  `
  });

export default deleteAdditionalService;
