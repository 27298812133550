import React, { Component } from "react";
import PropTypes from "prop-types";
import fuzzy from "fuzzy";
import { Query } from "react-apollo";

import Helmet from "react-helmet";
import DayPicker from "react-day-picker";
import { parseMilliseconds } from "../../utils/date";
import { CHECKLIST_FORMATS } from "../../utils/checklists";
import { getInspection } from "../../gql/queries";

import styles from "./InspectionsTemplateStyles.scss";

import {
  AvatarBubble,
  Button,
  Container,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  DownloadIcon,
  Dropdown,
  SignatureIcon,
  Heading,
  Table,
  Modal,
  Layout,
  LabeledInput,
  PlusIcon,
  FilterIcon,
  Switch,
  TextInput,
  BigRemoveButton
} from "../atoms";
import {
  FacilitySelector,
  TableContainerHeader,
  Spinner,
  ModalPortal,
  UploadButton
} from "../molecules";
import { tsToHumanReadableDate } from "../../utils/date";

import { getDoneInspectionsByDate } from "../../gql/queries";
import { bugsnagClient } from "../../bugsnag";

const ACTIVITIES = {
  Carretes: "Carretes de Manguera",
  Lamina: "Lámina de 1/2 pulgada"
};

const LOCATIONS = {
  "Zona Norte, Chihuahua": "Zona Norte, Chihuahua"
};

const ASIGNEDTECHNICIAN = {
  "Evan Andrews": "Evan Andrews"
};

const DROPDOWNYEARS = {
  "2019": "2019",
  "2020": "2020"
};

const DROPDOWNMONTHS = {
  Enero: "Enero",
  Febrero: "Febrero",
  Marzo: "Marzo",
  Abril: "Abril",
  Mayo: "Mayo",
  Junio: "Junio",
  Julio: "Julio",
  Agosto: "Agosto",
  Septiembre: "Septiembre",
  Octubre: "Octubre",
  Noviembre: "Noviembre",
  Diciembre: "Diciembre"
};

const VISITDAYS = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "10": "10",
  "11": "11",
  "12": "12",
  "13": "13",
  "14": "14",
  "15": "15",
  "16": "16",
  "17": "17",
  "18": "18",
  "19": "19",
  "20": "20",
  "21": "21",
  "22": "22",
  "23": "23",
  "24": "24",
  "25": "25",
  "26": "26",
  "27": "27",
  "28": "28",
  "29": "29",
  "30": "30",
  "31": "31"
};

const CHECKLIST_FORMAT_SCHEMAS = {
  DIESEL_PUMP: () => require("../../schemas/dieselPumpMaintenance"),
  ELECTRIC_PUMP: () => require("../../schemas/electricPumpMaintenance"),
  JOCKEY_PUMP: () => require("../../schemas/jockeyPumpMaintenance"),
  WATER_TANK: () => require("../../schemas/waterTankMaintenance"),
  DRY_SYSTEM: () => require("../../schemas/drySystemMaintenance"),
  HOSE_REELS: () => require("../../schemas/hoseReelsMaintenance"),
  ALARM_VALVES: () => require("../../schemas/alarmValvesMaintenance"),
  DRY_HYDRANT: () => require("../../schemas/dryHydrantMaintenance"),
  GATE_VALVES_INSPECTION: () => require("../../schemas/gateValvesInspection"),
  GATE_VALVES_TESTS: () => require("../../schemas/gateValvesTests"),
  DRY_HYDRANT_TESTS: () => require("../../schemas/dryHydrantTests"),
  FLOW_SENSORS_MAINTENANCE: () =>
    require("../../schemas/flowSensorsMaintenance"),
  WALL_HYDRANT_INSPECTION: () => require("../../schemas/wallHydrantInspection"),
  FIREFIGHTER_CONNECTIONS_INSPECTION: () =>
    require("../../schemas/firefighterConnectionsInspection"),
  EXTINGUISHER_INSPECTION: () => require("../../schemas/extinguisherInspection")
};

class DoneInspectionsTemplate extends Component {
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  onChange = date => this.setState({ date });

  state = {
    date: new Date(),
    filter: undefined,
    dateRangeFilter: {},
    addContractModal: {
      visible: false,
      addButtonDisabled: true
    },
    addInspectionChecklistModal: {
      visible: false,
      addButtonDisabled: false,
      formats: {}
    },
    viewReportModal: {
      visible: false
    }
  };

  static propTypes = {
    loadingInspections: PropTypes.bool.isRequired,
    inspections: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    loadingInspections: true,
    inspections: []
  };

  componentDidUpdate(_, prevState) {
    const { refetch } = this.props;
    const { dateRangeFilter: prevDateRangeFilter } = prevState;
    const { dateRangeFilter } = this.state;
    const { addContractModal: prevAddContractModal } = prevState;
    const { addContractModal: currAddContractModal } = this.state;

    const {
      addInspectionChecklistModal: prevAddInspectionChecklistModal
    } = prevState;

    const {
      addInspectionChecklistModal: currAddInspectionChecklistModal
    } = this.state;

    // Check for every required input field's presence
    // TODO: Turn this into a util
    // TODO: Advanced validation? (verify that endDate is greater
    // than startDate, or that the monthlyCost x 12 = annualCost, etc.)
    const requiredFields = [
      "selectedFacilityId",
      "startDate",
      "endDate",
      "monthlyCost",
      "annualCost",
      "policyNumber",
      "basePEP"
    ];

    const prevFields = requiredFields.map(rf => prevAddContractModal[rf]);
    const currFields = requiredFields.map(rf => currAddContractModal[rf]);

    const prevUnfilledFields = prevFields.filter(f => !f).length;
    const currUnfilledFields = currFields.filter(f => !f).length;

    if (prevUnfilledFields > 0 && currUnfilledFields === 0) {
      this.setState(state => ({
        addContractModal: {
          ...state.addContractModal,
          addButtonDisabled: false
        }
      }));
    }

    if (
      (!prevDateRangeFilter.startDate || !prevDateRangeFilter.endDate) &&
      dateRangeFilter.startDate &&
      dateRangeFilter.endDate
    ) {
      refetch({
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
    }

    if (prevDateRangeFilter.startDate && prevDateRangeFilter.endDate) {
      if (
        prevDateRangeFilter.startDate !== dateRangeFilter.startDate ||
        prevDateRangeFilter.endDate !== dateRangeFilter.endDate
      ) {
        refetch({
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
      }
    }

    if (
      prevDateRangeFilter.startDate &&
      prevDateRangeFilter.endDate &&
      (!dateRangeFilter.startDate && !dateRangeFilter.endDate)
    ) {
      refetch({ startDate: undefined, endDate: undefined });
    }
  }

  render() {
    const {
      filter,
      addContractModal,
      addInspectionChecklistModal,
      viewReportModal,
      dateRangeFilter
    } = this.state;
    const { loadingInspections, inspections } = this.props;

    const filteredInspections =
      inspections &&
      fuzzy.filter(filter, inspections, {
        extract: el => el.fTec.technician.firstName
      });

    const data = filter
      ? filteredInspections &&
        filteredInspections.map(result => result.original)
      : inspections;

    // Sort the data by created date
    // TODO: Extract to an util
    inspections.length > 0 &&
      inspections.sort((a, b) => {
        if (parseInt(a.createdAt) < parseInt(b.createdAt)) {
          return -1;
        }

        if (parseInt(a.createdAt) > parseInt(b.createdAt)) {
          return 1;
        }

        return 0;
      });

    return (
      <>
        <ModalPortal
          modal={this._renderViewReportModal}
          visible={viewReportModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <Heading>Inspecciones Realizadas</Heading>
        <Container noPadding>
          <div style={{ overflow: "hidden", margin: "30px" }}>
            <TextInput
              width={580}
              placeholder="Buscar por nombre de técnico"
              onChange={e => this._filterInspections(e)}
            />
            <div
              style={{
                display: "inline-block",
                textAlign: "center",
                marginLeft: "30px",
                position: "absolute"
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "20px",
                  fontWeight: "bold"
                }}
              >
                Filtrar por rango de fechas
              </div>
              <LabeledInput
                index={1}
                type="date-picker"
                name="startDate"
                defaultValue={dateRangeFilter.startDate}
                componentProps={{
                  dayPickerProps: {
                    disabledDays: {
                      after: new Date(dateRangeFilter.endDate)
                    }
                  }
                }}
                onChange={this._handleOnDateInputChange}
              />
              <span style={{ marginLeft: "15px" }}>
                <LabeledInput
                  index={2}
                  type="date-picker"
                  name="endDate"
                  defaultValue={dateRangeFilter.endDate}
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(dateRangeFilter.startDate)
                      }
                    }
                  }}
                  onChange={this._handleOnDateInputChange}
                />
              </span>
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "5px"
                }}
              >
                <BigRemoveButton onClick={this._handleOnResetDateRangeFilter} />
              </span>
            </div>
          </div>
          <Table.Main
            columns={[
              null,
              { "fTec.technician.firstName": "Técnico" },
              { "fTec.facility.name": "Planta" },
              {
                "inspectionChecklist.checklistFormat": "Checklist de Inspección"
              },
              { scheduledDate: "Fecha" },
              null
            ]}
            data={data}
            row={(inspection, lastInspectionRow) =>
              this._renderChecklistRow(inspection, lastInspectionRow)
            }
          />
        </Container>
      </>
    );
  }

  _filterInspections = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnResetDateRangeFilter = () => {
    this.setState({
      dateRangeFilter: {}
    });
  };

  _handleOnDateInputChange = e => {
    if (e.props) {
      return this.setState(state => ({
        dateRangeFilter: {
          ...state.dateRangeFilter,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _renderChecklistRow = (inspection, lastInspectionRow) => {
    const { firstName, lastName } = inspection.fTec.technician;
    const { checklistFormat } = inspection.inspectionChecklist;
    const inspectionTitle = CHECKLIST_FORMATS[checklistFormat];
    return (
      <tr
        testid="contracts-table-row"
        key={inspection.id}
        style={{ borderBottom: !lastInspectionRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble
              url={
                inspection.fTec.technician.avatarUrl &&
                inspection.fTec.technician.avatarUrl
              }
            />
          </div>
        </Table.Cell>
        <Table.Cell>
          {firstName} {lastName}
        </Table.Cell>
        <Table.Cell>{inspection.fTec.facility.name}</Table.Cell>
        <Table.Cell>{inspectionTitle}</Table.Cell>

        <Table.Cell>
          {tsToHumanReadableDate(
            new Date(
              new Date(inspection.completionDate).getTime() +
                new Date(inspection.completionDate).getTimezoneOffset() * 60000
            ).getTime()
          )}
        </Table.Cell>
        <Table.Cell>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              style="outline"
              onClick={() => this._handleOnClickViewReportModal(inspection.id)}
            >
              Ver
            </Button>
          </div>
        </Table.Cell>
      </tr>
    );
  };

  // TODO: Move elsewhere
  _renderAddChecklistModal = () => {
    const { addContractModal } = this.state;

    return (
      <Container width={507}>
        <div
          onClick={() => this._handleOnCloseButton()}
          style={{
            position: "absolute",
            cursor: "pointer",
            right: "120px",
            top: "90px"
          }}
        >
          <CloseIcon />
        </div>

        <div className={styles.modalTitle}>Filtrar Búsqueda</div>

        <Modal.Section
          style={{ margin: "0 50px" }}
          paddingVertical={30}
          paddingHorizontal={50}
        >
          <Layout.Grid>
            <Layout.Column>
              <div style={{ marginBottom: "15px" }}>
                <LabeledInput
                  fullWidth
                  name="activity"
                  label="Actividad"
                  type="select"
                  selectOptions={Object.keys(ACTIVITIES)}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <LabeledInput
                  fullWidth
                  name="locations"
                  label="Ubicación de la Planta"
                  type="select"
                  selectOptions={Object.keys(LOCATIONS)}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <div
                  style={{
                    width: "147px",
                    float: "left",
                    marginRight: "10px",
                    marginBottom: "15px"
                  }}
                >
                  <LabeledInput
                    fullWidth
                    name="year1"
                    label="Año de la visita"
                    type="select"
                    selectOptions={Object.keys(DROPDOWNYEARS)}
                  />
                </div>

                <div
                  style={{
                    width: "147px",
                    float: "left",
                    marginBottom: "15px"
                  }}
                >
                  <LabeledInput
                    fullWidth
                    name="year2"
                    label="(desde y hasta)"
                    type="select"
                    selectOptions={Object.keys(DROPDOWNYEARS)}
                  />
                </div>
              </div>

              <div style={{ marginBottom: "15px" }}>
                <div
                  style={{
                    width: "147px",
                    float: "left",
                    marginRight: "10px",
                    marginBottom: "15px"
                  }}
                >
                  <LabeledInput
                    fullWidth
                    name="month1"
                    label="Mes de la visita"
                    type="select"
                    selectOptions={Object.keys(DROPDOWNMONTHS)}
                  />
                </div>

                <div
                  style={{
                    width: "147px",
                    float: "left",
                    marginBottom: "15px"
                  }}
                >
                  <LabeledInput
                    style={{ borderColor: "red" }}
                    fullWidth
                    name="month2"
                    label="(desde y hasta)"
                    type="select"
                    selectOptions={Object.keys(DROPDOWNMONTHS)}
                  />
                </div>
              </div>

              <div style={{ marginBottom: "15px" }}>
                <LabeledInput
                  fullWidth
                  name="visitDays"
                  label="Días de la visita"
                  type="select"
                  selectOptions={Object.keys(VISITDAYS)}
                />
              </div>
              <div style={{ marginBottom: "15px" }}>
                <LabeledInput
                  fullWidth
                  name="technicians"
                  label="Técnico asignado"
                  type="select"
                  selectOptions={Object.keys(ASIGNEDTECHNICIAN)}
                />
              </div>
            </Layout.Column>
          </Layout.Grid>
        </Modal.Section>

        <div className={styles.modalFooter}>
          <div style={{ display: "inline-block", marginRight: "9px" }}>
            <Button
              style="outline"
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={() => this._handleOnCloseButton()}
            >
              REINICIAR
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={48}
            disabled={addContractModal.addButtonDisabled}
          >
            FILTRAR
          </Button>
        </div>
      </Container>
    );
  };

  _renderViewReportModal = () => {
    const { viewReportModal } = this.state;
    const { inspectionId } = this.state.viewReportModal;

    if (inspectionId) {
      return (
        <Query
          query={getInspection}
          variables={{
            inspectionId
          }}
        >
          {({ loading, error, data }) => {
            if (error) {
              console.log(error);
              return null;
            }

            if (loading) {
              return <Spinner />;
            }

            if (data.inspection) {
              const { inspection } = data;
              const { checklistFormat } = inspection.inspectionChecklist;
              const inspectionTitle = CHECKLIST_FORMATS[checklistFormat];
              const checklists = [];

              inspection.checklists.map(checklist =>
                checklists.push({
                  ...checklist,
                  format: inspection.inspectionChecklist.checklistFormat
                })
              );

              return (
                <Container width={1010}>
                  <div
                    onClick={() => this._handleOnCloseButton()}
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      right: 30
                    }}
                  >
                    <CloseIcon />
                  </div>
                  <div
                    style={{
                      color: "#4a4a4a",
                      fontFamily: "Open Sans",
                      fontSize: "30px",
                      fontWeight: "700",
                      marginLeft: "82px",
                      marginTop: "29px",
                      display: "inline-block"
                    }}
                  >
                    {inspectionTitle}
                  </div>
                  <div
                    style={{
                      color: "#686868",
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                      fontWeight: "400",
                      marginLeft: "85px",
                      display: "inline",
                      lineHeight: ".001px"
                    }}
                  >
                    {inspection.fTec.facility.businessName.client.name} -{" "}
                    {tsToHumanReadableDate(
                      new Date(
                        new Date(inspection.completionDate).getTime() +
                          new Date(
                            inspection.completionDate
                          ).getTimezoneOffset() *
                            60000
                      ).getTime()
                    )}
                  </div>
                  <Modal.Section paddingVertical={30} paddingHorizontal={165}>
                    <div
                      style={{
                        marginTop: "30px",
                        marginLeft: "250px",
                        display: "inline-block"
                      }}
                    >
                      <AvatarBubble
                        url={
                          inspection.fTec.technician.avatarUrl &&
                          inspection.fTec.technician.avatarUrl
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: "12px",
                        fontFamily: "Open Sans",
                        fontWeight: "400",
                        color: "#686868",
                        lineHeight: "20px",
                        marginLeft: "10px"
                      }}
                    >
                      Ingeniero
                      <div style={{ fontSize: "14px", fontWeight: "700" }}>
                        {inspection.fTec.technician.firstName}{" "}
                        {inspection.fTec.technician.lastName}
                      </div>
                    </div>
                    <div style={{ marginTop: "80px" }}>
                      <Layout.Grid>
                        <Layout.Column>
                          {checklists.map((checklist, idx) => {
                            const { format: checklistFormat } = checklist;
                            const checklistSchema = CHECKLIST_FORMAT_SCHEMAS[
                              checklistFormat
                            ]().default.checks;
                            const checklistTitle =
                              CHECKLIST_FORMATS[checklistFormat];
                            const checklistItems = JSON.parse(
                              checklist.checklistItems
                            );
                            const {
                              area,
                              key,
                              photoEvidence,
                              ...withOutAreaAndKey
                            } = checklistItems;

                            return (
                              <>
                                {idx > 0 ? (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "Open Sans",
                                      fontWeight: 700,
                                      color: "rgb(104, 104, 104)",
                                      paddingLeft: "120px",
                                      marginBottom: "20px",
                                      borderTop: "1px solid rgb(215, 215, 215)",
                                      marginTop: "40px",
                                      paddingTop: "40px"
                                    }}
                                  >
                                    {checklistTitle} #{checklist.number}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "Open Sans",
                                      fontWeight: 700,
                                      color: "rgb(104, 104, 104)",
                                      paddingLeft: "120px",
                                      marginBottom: "20px",
                                      marginTop: "40px"
                                    }}
                                  >
                                    {checklistTitle} #{checklist.number}
                                  </div>
                                )}
                                <div
                                  style={{
                                    lineHeight: "30px",
                                    fontSize: "14px",
                                    fontFamily: "Open Sans",
                                    fontWeight: "400",
                                    color: "#686868",
                                    marginLeft: "35px",
                                    display: "inline-block"
                                  }}
                                >
                                  <table style={{ borderSpacing: "30px" }}>
                                    <tbody>
                                      {Object.keys(withOutAreaAndKey).map(
                                        checklistItem => (
                                          <tr>
                                            <td
                                              style={{
                                                maxWidth: "225px",
                                                whiteSpace: "initial"
                                              }}
                                            >
                                              {
                                                checklistSchema[checklistItem]
                                                  .label
                                              }
                                            </td>
                                            <td>
                                              {checklistSchema[checklistItem]
                                                .type === "BOOL" && (
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      !checklistItems[
                                                        checklistItem
                                                      ].value && "#cacfd9",
                                                    backgroundImage:
                                                      checklistItems[
                                                        checklistItem
                                                      ].value &&
                                                      "linear-gradient(180deg, #8ee8a5 0%, #55cd6c 100%)",
                                                    width: "69px",
                                                    height: "26px",
                                                    marginTop: "5px",
                                                    borderRadius: "13px",
                                                    textAlign: "center",
                                                    color: "white",
                                                    fontFamily: "Open Sans",
                                                    fontSize: "12px"
                                                  }}
                                                >
                                                  {checklistItems[checklistItem]
                                                    .value
                                                    ? "SI"
                                                    : "NO"}
                                                </div>
                                              )}
                                              {checklistSchema[checklistItem]
                                                .type === "NUMBER" && (
                                                <div
                                                  style={{
                                                    justifyContent: "center",
                                                    width: 34.5 * 2,
                                                    height: 13 * 2,
                                                    borderRadius: 34.5
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      alignSelf: "center"
                                                    }}
                                                  >
                                                    {
                                                      checklistItems[
                                                        checklistItem
                                                      ].value
                                                    }{" "}
                                                    {
                                                      checklistSchema[
                                                        checklistItem
                                                      ].unit
                                                    }
                                                  </div>
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            );
                          })}
                        </Layout.Column>
                        <Layout.Column>
                          <>
                            <div
                              style={{
                                marginTop: "27px",
                                marginBottom: "21px",
                                fontSize: "16px",
                                fontFamily: "Open Sans",
                                fontWeight: "700",
                                color: "#686868",
                                lineHeight: "20px",
                                textAlign: "center"
                              }}
                            >
                              <div>{inspection.title}</div>
                            </div>
                            {inspection.checklists.map((checklist, idx) => {
                              const {
                                checklistFormat
                              } = inspection.inspectionChecklist;
                              const checklistTitle =
                                CHECKLIST_FORMATS[checklistFormat];
                              const checklistItems = JSON.parse(
                                checklist.checklistItems
                              );
                              const checkActions = Object.values(
                                checklistItems
                              ).filter(check => check.correctiveAction);

                              const checkObservations = Object.values(
                                checklistItems
                              ).filter(check => check.observation);

                              const checkPhotos = Object.values(
                                checklistItems
                              ).filter(check => check.photos);

                              const regularPhotos = (
                                checkPhotos.map(check => check.photos) || []
                              ).flat();

                              const regularPhotosURIs = regularPhotos.map(
                                photo => Object.values(photo)[0]
                              );

                              const correctiveActionPhotos = (
                                checkActions.map(check =>
                                  check.correctiveAction
                                    ? check.correctiveAction.photos
                                    : []
                                ) || []
                              ).flat();

                              const observationPhotos = (
                                checkObservations.map(check =>
                                  check.observation
                                    ? check.observation.photos
                                    : []
                                ) || []
                              ).flat();

                              const photos = [
                                ...regularPhotosURIs,
                                ...correctiveActionPhotos,
                                ...observationPhotos
                              ];

                              return (
                                <div key={idx}>
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      fontSize: "16px",
                                      fontFamily: "Open Sans",
                                      fontWeight: "700",
                                      color: "#686868",
                                      lineHeight: "20px",
                                      textAlign: "center"
                                    }}
                                  >
                                    {checklistTitle} #{idx + 1}
                                  </div>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginBottom: "30px",
                                      fontSize: "14px",
                                      fontFamily: "Open Sans",
                                      fontWeight: "400",
                                      color: "#686868",
                                      lineHeight: "20px"
                                    }}
                                  >
                                    <div
                                      style={{
                                        flex: 1,
                                        marginVertical: 22
                                      }}
                                    >
                                      <div
                                        size={12}
                                        style={{ fontWeight: "700" }}
                                      >
                                        Acciones correctivas
                                      </div>
                                    </div>
                                    {checkActions.length > 0 ? (
                                      checkActions.map((check, idx) => (
                                        <div key={idx} size={12}>
                                          - {check.correctiveAction.value}
                                        </div>
                                      ))
                                    ) : (
                                      <div size={12}>- Nada que reportar</div>
                                    )}
                                    <div
                                      style={{
                                        flex: 1,
                                        marginVertical: 22
                                      }}
                                    >
                                      <div
                                        size={12}
                                        style={{
                                          fontWeight: "700",
                                          marginTop: "10px"
                                        }}
                                      >
                                        Observaciones
                                      </div>
                                    </div>
                                    {checkObservations.length > 0 ? (
                                      checkObservations.map((check, idx) => (
                                        <div key={idx} size={12}>
                                          - {check.observation.value}
                                        </div>
                                      ))
                                    ) : (
                                      <div size={12}>- Nada que reportar</div>
                                    )}
                                  </div>
                                  {photos.length > 0 && (
                                    <div
                                      style={{
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                        marginTop: 22
                                      }}
                                    >
                                      {photos.length > 0 &&
                                        photos.map((photo, idx) => (
                                          <img
                                            key={idx}
                                            style={{
                                              width: "200px",
                                              height: "200px",
                                              borderRadius: 10,
                                              marginBottom: 20,
                                              transform: "rotate(90deg)"
                                            }}
                                            src={photo}
                                          />
                                        ))}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        </Layout.Column>
                      </Layout.Grid>
                    </div>
                  </Modal.Section>
                </Container>
              );
            }
          }}
        </Query>
      );
    }
  };

  _handleOnClickViewReportModal = inspectionId => {
    this.setState(state => ({
      viewReportModal: { ...state.viewReportModal, visible: true, inspectionId }
    }));
  };

  _handleOnClickAddButton = () => {
    this.setState(state => ({
      addContractModal: { ...state.addContractModal, visible: true }
    }));
  };

  _handleOnCloseButton = () => {
    this.setState({
      addContractModal: { visible: false },
      addInspectionChecklistModal: { visible: false },
      viewReportModal: { visible: false }
    });
  };

  _handleOnInputChange = e => {
    // Handle input change of the date picker component
    if (e.props) {
      return this.setState(state => ({
        addContractModal: {
          ...state.addContractModal,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      addContractModal: {
        ...state.addContractModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };
}

export default DoneInspectionsTemplate;
