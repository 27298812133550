import { gql } from "apollo-boost";

const getClientsBusinessNames = gql`
  query getClientsBusinessNames {
    clients {
      id
      name
      avatarUrl
      state
      city
      address
      updatedAt
      createdAt

      facilityManagers {
        id
        user {
          id
          emailAddress
        }
        facility {
          id
          name
          color
          zone
        }
      }

      businessNames {
        id
        name
      }
    }
  }
`;

export default getClientsBusinessNames;
