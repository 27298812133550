import React from "react";
import format from "date-fns/format";

import {
  Button,
  Container,
  CloseIcon,
  Modal,
  Layout,
  LabeledInput,
  TextArea,
  CustomDropdown,
  AvatarBubble
} from "../../atoms";
import { FacilitySelector } from "../../molecules";

const CORRECTIVE_ACTION_STATUS = {
  null: "Pendiente",
  PURCHASE_ORDER: "Orden de compra",
  JOB_FINISHED: "Trabajo terminado",
  INVOICED: "Facturado"
};

const CORRECTIVE_ACTION_STATUSES_DICT = {
  null: [],
  PURCHASE_ORDER: ["null"],
  JOB_FINISHED: ["null", "PURCHASE_ORDER"],
  INVOICED: ["null", "PURCHASE_ORDER", "JOB_FINISHED"]
};

const EditCorrectiveActionModal = ({
  editCorrectiveActionModalState: editCorrectiveActionModal,
  correctiveAction,
  editCorrectiveActionId,
  editLabeledTechnicians,
  editLabeledContracts,
  handleOnEditStatusChange,
  onEditCloseButton,
  handleOnEditFacilitySelectorChange,
  handleOnEditFacilitySelect,
  handleOnEditInputChange,
  onEditCorrectiveActionButton
}) => {
  const addButtonEnabled =
    editCorrectiveActionModal.nameAndLastNameInput &&
    editCorrectiveActionModal.title &&
    editCorrectiveActionModal.startDate &&
    editCorrectiveActionModal.budgetMXN &&
    editCorrectiveActionModal.budgetUSD &&
    editCorrectiveActionModal.details;

  const dropdownButtonDisabled =
    !editCorrectiveActionModal.budgetMXN ||
    !editCorrectiveActionModal.budgetUSD ||
    !editCorrectiveActionModal.nameAndLastNameInput ||
    !editCorrectiveActionModal.details ||
    !editCorrectiveActionModal.selectedFacilityId ||
    !editCorrectiveActionModal.title ||
    !editCorrectiveActionModal.startDate;

  const nameAndLastNameInput = editCorrectiveActionModal.nameAndLastNameInput;
  const { facility } = editCorrectiveActionModal;

  const Field = ({ label, value }) => (
    <div style={{ width: "100%", margin: "0 auto", marginBottom: 40 }}>
      <div style={{ color: "#5A5E61", marginBottom: 10 }}>{label}</div>
      <div style={{ color: "#5A5E61", fontWeight: "bold" }}>{value}</div>
    </div>
  );

  const Facility = ({ avatarUrl, clientName, facilityName }) => (
    <div style={{ marginBottom: 40 }}>
      <div style={{ float: "left" }}>
        <AvatarBubble url={avatarUrl} />
      </div>
      <div style={{ display: "inline-block", marginLeft: 20 }}>
        <div style={{ marginBottom: 10, color: "#5A5E61" }}>{clientName}</div>
        <div style={{ color: "#5A5E61", fontWeight: "bold" }}>
          {facilityName}
        </div>
      </div>
    </div>
  );
  return (
    <Container width={1118}>
      <div
        onClick={onEditCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>Acción correctiva</Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        {String(editCorrectiveActionModal.newStatus) === "null" ? (
          <FacilitySelector
            selected={[
              facility.businessName.client.name,
              facility.businessName.name,
              facility.id
            ]}
            onChange={(key, value) =>
              handleOnEditFacilitySelectorChange(key, value)
            }
            onFacilitySelect={facilityId =>
              handleOnEditFacilitySelect(facilityId)
            }
          />
        ) : (
            <div style={{ marginTop: "20px" }}>
              <span style={{ display: "inline-block", marginLeft: "70" }}>
                <Facility
                  avatarUrl={
                    facility.businessName.client.avatarUrl &&
                    facility.businessName.client.avatarUrl
                  }
                  clientName="Cliente"
                  facilityName={facility.businessName.client.name}
                />
              </span>
              <span style={{ display: "inline-block", marginLeft: "70" }}>
                <Field label="Razon Social" value={facility.businessName.name} />
              </span>
              <span style={{ display: "inline-block", marginLeft: "70" }}>
                <Field label="Planta" value={facility.name} />
              </span>
            </div>
          )}
      </Modal.Section>
      <Modal.Section
        style={{ margin: "0 50px", borderTop: "1px solid #d7d7d7" }}
        paddingVertical={30}
        paddingHorizontal={100}
      >
        <Layout.Grid>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              {editLabeledTechnicians(
                editCorrectiveActionModal.selectedFacilityId,
                nameAndLastNameInput
              )}
            </div>
            <div style={{ paddingRight: "70px" }}>
              {String(editCorrectiveActionModal.newStatus) === "null" ? (
                <LabeledInput
                  index={4}
                  type="currency"
                  name="budgetMXN"
                  label="Monto MXN"
                  defaultValue={editCorrectiveActionModal.budgetMXN}
                  onChange={handleOnEditInputChange}
                />
              ) : (
                  <Field
                    label="Monto MXN"
                    value={editCorrectiveActionModal.budgetMXN}
                  />
                )}
            </div>
          </Layout.Column>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={2}
                name="title"
                label="Concepto"
                defaultValue={editCorrectiveActionModal.title}
                onChange={handleOnEditInputChange}
              />
            </div>
            {String(editCorrectiveActionModal.newStatus) === "null" ? (
              <LabeledInput
                index={5}
                type="currency"
                name="budgetUSD"
                label="Monto USD"
                defaultValue={editCorrectiveActionModal.budgetUSD}
                onChange={handleOnEditInputChange}
              />
            ) : (
                <Field
                  label="Monto USD"
                  value={editCorrectiveActionModal.budgetUSD}
                />
              )}
          </Layout.Column>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={3}
                type="date-picker"
                name="startDate"
                label="Comienza"
                defaultValue={format(
                  editCorrectiveActionModal.startDate,
                  "MM/DD/YYYY"
                )}
                onChange={handleOnEditInputChange}
              />
            </div>
            {String(editCorrectiveActionModal.newStatus) === "null" ? (
              <div>{editLabeledContracts(editCorrectiveActionModal)}</div>
            ) : (
                <Field
                  label="PEP base"
                  value={
                    editCorrectiveActionModal.contract &&
                      editCorrectiveActionModal.contract.name
                      ? editCorrectiveActionModal.contract.name
                      : editCorrectiveActionModal.contract.baseBudget
                  }
                />
              )}
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Header>Información Adicional</Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <Layout.Grid>
          <Layout.Column>
            <div
              style={{
                marginLeft: "1px",
                marginBottom: "8px",
                color: "rgba(43, 48, 52, 0.4)",
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "14px"
              }}
            >
              Detalles
            </div>
            <TextArea
              name="details"
              value={editCorrectiveActionModal.details}
              width={524}
              height={134}
              maxLength={1000}
              onChange={handleOnEditInputChange}
            />
          </Layout.Column>
          <Layout.Column style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }}
            >
              <CustomDropdown
                parentId={editCorrectiveActionModal.id}
                options={CORRECTIVE_ACTION_STATUS}
                disabledOptions={CORRECTIVE_ACTION_STATUSES_DICT[editCorrectiveActionModal.status]}
                selected={editCorrectiveActionModal.newStatus}
                defaultValue={null}
                onChange={handleOnEditStatusChange}
                disabled={dropdownButtonDisabled}
              />
            </div>
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            style="outline"
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={onEditCloseButton}
          >
            Volver
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={48}
          onClick={onEditCorrectiveActionButton}
          disabled={
            !addButtonEnabled ||
            editCorrectiveActionModal.editingCorrectiveAction
          }
        >
          Añadir
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default EditCorrectiveActionModal;
