import React, { Component } from "react";
import { Query } from "react-apollo";

import { getUnreadNotificationsForClient } from "../../gql/queries";
import { NotificationsTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class NotificationsView extends Component {
  render() {
    const { userId } = this.props;

    return (
      <Query query={getUnreadNotificationsForClient} variables={{ userId }}>
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div testid="error-wrapper">error</div>;
          }
          return (
            <div style={{ marginLeft: -90 }}>
              <NotificationsTemplate
                loading={loading}
                notifications={data && data.unreadNotificationsForClient}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default NotificationsView;
