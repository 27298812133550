export default {
  name: "dry_system_maintenance",
  title: "Mantenimiento de Bomba Diesel",
  checks: {
    manometerValve: {
      section: "Sistema Seco",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Valvula de manometros abiertas",
      unit: null
    },
    acceleratorValve: {
      section: "Sistema Seco",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Válvula de acelerador abierta",
      unit: null
    },
    airManometerSystem: {
      section: "Sistema Seco",
      required: true,
      warning: false,
      type: "NUMBER",
      label:
        "Manometros de aire de sistema y acelerador, indicando igual valor de presion",
      unit: "P.S.I."
    },
    waterManometers: {
      section: "Sistema Seco",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Manometros de agua indicando la presión en la red de suministro",
      unit: null
    },
    pressureSwitch: {
      section: "Sistema Seco",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Presostatos Conectados",
      unit: null
    },
    testingValves: {
      section: "Sistema Seco",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Valvulas de prueba y drenaje cerradas",
      unit: null
    },
    bypassValves: {
      section: "Sistema Seco",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Valvulas de by pass del sistema de regulacion del aire cerrada",
      unit: null
    }
  }
};
