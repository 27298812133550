import { gql } from "apollo-boost";
import { client } from "../apollo";

const deleteContractDocument = async (contractId, id) =>
  await client.mutate({
    mutation: gql`
      mutation {
        createContractDocument(
          contractId: "${contractId}"
          id:"${id}"
        ) {
          id
        }
      }
  `
  });

export default deleteContractDocument;