import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { NonSelectable, Button } from "../../atoms";

// TODO: Move most of the bar chart logic to a
// BarChart component (molecule?)
const IncomeChart = ({ height, title, data, onClickSort }) => {
  const highestValue = Math.max(...data.map(d => d.value));
  return (
    <div className={styles.chartHolder}>
      <h2 className={styles.chartTitle}>{title}</h2>
      {onClickSort && (
        <span style={{ float: "right" }}>
          <Button radius={5} paddingVertical={1} onClick={onClickSort}>
            Ordenar
          </Button>
        </span>
      )}
      <NonSelectable
        style={{
          paddingRight: 40,
          width: "calc(100% - 40px)",
          maxHeight: height,
          minHeight: height,
          overflowY: "scroll"
        }}
      >
        {data.map((datum, i) => {
          const barWidth = (100 / highestValue) * datum.value;
          const valueInThousands = Math.round(datum.value / 1000);

          return (
            <div key={i} className={styles.barHolder}>
              <div className={styles.barLabel}>{datum.label}</div>
              <div className={styles.barAndValue}>
                <div
                  style={{ width: `calc(${barWidth}% - 61px)` }}
                  className={styles.chartBar}
                />
                <div className={styles.barValue}>${valueInThousands}K</div>
              </div>
            </div>
          );
        })}
      </NonSelectable>
    </div>
  );
};

IncomeChart.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  onClickSort: PropTypes.function
};

export default IncomeChart;
