import React, { Component } from "react";
import { Query } from "react-apollo";

// Local imports
import { getClientUsers } from "../../gql/queries";
import { TechnicianListTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class TechnicianView extends Component {
  render() {
    return (
      <Query query={getClientUsers}>
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div>Error</div>;
          }

          if (data) {
            return (
              <TechnicianListTemplate
                loadingUsers={loading}
                users={data && data.clientUsers}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default TechnicianView;
