import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import classJoiner from "../../../utils/classJoiner";
import { SelectDropdownIcon } from "../";

class LabeledSelect extends Component {
  state = {
    active: false
  };

  static propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
    keyedOptions: PropTypes.arrayOf(PropTypes.object),
    disabledOptions: PropTypes.arrayOf(PropTypes.string),
    defaultValue: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    disabled: false
  };

  render() {
    const { active } = this.state;
    const {
      label,
      options: unkeyedOptions,
      keyedOptions,
      disabledOptions,
      defaultValue,
      onSelect,
      disabled,
      ...props
    } = this.props;

    const selectWrapperStyle = active
      ? styles.selectWrapperActive
      : styles.selectWrapper;

    const selectWrapperClassName = disabled
      ? classJoiner([selectWrapperStyle, styles.selectWrapperDisabled])
      : selectWrapperStyle;

    // TODO: Clean up this and look for a better way to do...
    // ...keyed options 🤔
    const uOptions = unkeyedOptions;
    const kOptions = {};

    if (keyedOptions) {
      keyedOptions.map(ko => {
        kOptions[ko.id] = ko.name;
      });
    }

    return (
      <div className={selectWrapperClassName} style={props.style}>
        <div className={styles.selectLabel}>{label}</div>
        <select
          className={styles.select}
          value={defaultValue ? defaultValue : ""}
          onChange={onSelect}
          onFocus={this._setActive}
          onBlur={this._setInactive}
          disabled={disabled}
        >
          <option disabled hidden />
          {uOptions &&
            uOptions.map((option, i) => <option key={i}>{option}</option>)}
          {kOptions &&
            Object.keys(kOptions).map((optionKey, i) => (
              <option
                key={i}
                value={optionKey}
                disabled={
                  disabledOptions &&
                  disabledOptions.find(option => option === optionKey)
                }
              >
                {kOptions[optionKey]}
              </option>
            ))}
        </select>
        <div className={styles.selectDropdownIconWrapper}>
          <SelectDropdownIcon />
        </div>
      </div>
    );
  }

  _setActive = () => this.setState({ active: true });

  _setInactive = () => this.setState({ active: false });
}

export default LabeledSelect;
