import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import "./global.scss";

import * as userSelectors from "./store/selectors/user";
import AdminApp from "./AdminApp";
import ClientApp from "./ClientApp";
import PDFReportView from "./views/PDFReportView";
import { LoginView, LogoutView } from "./views/auth";

class App extends Component {
  state = {
    Portal: undefined
  };

  componentDidMount() {
    const { user } = this.props;

    if (user.kind === "ADMIN") {
      this.setState({ Portal: AdminApp });
    } else if (user.kind === "CLIENT" || user.kind === "FACILITY_MANAGER") {
      this.setState({ Portal: ClientApp });
    } else if (!user.loggedIn) {
      if (window.location.pathname !== "/iniciar-sesion") {
        window.location.href = "/iniciar-sesion";
      }
    }
  }

  render() {
    const { Portal } = this.state;
    const { user } = this.props;

    return (
      <Router>
        <>
          <Switch>
            <Route
              path="/iniciar-sesion"
              component={() => <LoginView user={user} />}
            />
            <Route path="/cerrar-sesion" component={() => <LogoutView />} />
            {Portal !== undefined && (
              <Switch>
                <Route
                  path="/report/:rid"
                  component={({ match }) => (
                    <PDFReportView reportId={match.params.rid} />
                  )}
                />
                <Route path="/" component={() => <Portal user={user} />} />
              </Switch>
            )}
          </Switch>
        </>
      </Router>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: userSelectors.selectUserState(state)
});

export default connect(mapStateToProps)(App);
