import { gql } from "apollo-boost";
import { client } from "../apollo";

const deleteContract = async contractId =>
  await client.mutate({
    mutation: gql`
      mutation {
        deleteContract(
          contractId: "${contractId}"
        )
      }
  `
  });

export default deleteContract;