import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Modal, TextArea, LabeledInput } from "../../atoms";

const ReAssignRescheduleInspectionModal = ({
  addButtonDisabled,
  inspectionRescheduleId,
  onReAssignRescheduleInspectionButton,
  handleOnInputChange
}) => {
  return (
    <Container width={540}>
      <Modal.Section paddingVertical={80} paddingHorizontal={80}>
        <div style={{ fontSize: "20px", textAlign: "center" }}>
          <span style={{ fontWeight: "bold" }}>Reagendar</span> inspección
        </div>
        <TextArea
          name="details"
          width={524}
          height={134}
          maxLength={1000}
          onChange={handleOnInputChange}
        />
        <LabeledInput
          type="date-picker"
          name="newDate"
          label="Asignar nueva fecha"
          componentProps={{
            dayPickerProps: {
              disabledDays: {
                before: new Date()
              }
            }
          }}
          onChange={handleOnInputChange}
        />
      </Modal.Section>
      <div style={{ marginBottom: 40, textAlign: "center" }}>
        <Button
          paddingVertical={8}
          radius={7}
          paddingHorizontal={30}
          onClick={() =>
            onReAssignRescheduleInspectionButton(inspectionRescheduleId)
          }
          disabled={addButtonDisabled}
        >
          ASIGNAR
        </Button>
      </div>
    </Container>
  );
};

ReAssignRescheduleInspectionModal.PropTypes = {
  addButtonDisabled: PropTypes.bool.isRequired,
  inspectionRescheduleId: PropTypes.string.isRequired,
  inspectionReschedule: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  onReAssignRescheduleInspectionButton: PropTypes.func.isRequired,
  handleOnInputChange: PropTypes.func.isRequired
};

export default ReAssignRescheduleInspectionModal;
