import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styles from "./styles.scss";
import { Button } from "../../atoms";

class AssetsButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };
  }

  _handleOnClickDocsButton = () => {
    const inputField = this.refs.fileField;
    inputField.click();
  };

  _uploadFile = event => {
    const text = this.props.label
      .replace(/ /g, "")
      .replace("ó", "o")
      .replace("ñ", "n")
      .toLowerCase();
    const fileId = "file_" + text;
    const data = new FormData();
    data.append("file", event.target.files[0], event.target.files[0].name);

    fetch(process.env.UPLOAD_ASSETS_URL, {
      method: "POST",
      headers: {
        Accept: "application/json"
      },
      body: data
    })
      .then(resp => resp.json())
      .then(result => {
        this.setState(state => ({ active: true }));
        localStorage.setItem(fileId, result.imageUrl);

        if (typeof this.props.updateParent === "function") {
          this.props.updateParent();
        }
      });
  };
  render() {
    const { icon, label, updateParent } = this.props;
    return (
      <Button
        style={this.state.active ? "uploaded" : "outline"}
        className={this.state.active ? "uploaded" : "outline"}
        paddingVertical={8}
        paddingHorizontal={10}
        radius={7}
        onClick={this._handleOnClickDocsButton}
      >
        <div className={styles.buttonIconWrapper}>{icon()}</div>
        {label}
        <input
          ref="fileField"
          type="file"
          className={styles.hide}
          onChange={this._uploadFile}
        />
      </Button>
    );
  }
}

AssetsButton.defaultProps = {
  previewContainer: false,
  label: "Examinar"
};

AssetsButton.propTypes = {
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default AssetsButton;
