import React from "react";
import TagsInput from "react-tag-autocomplete";

import tagStyles from "../../../tagStyles.scss";

import statesAndCities from "../../../utils/statesAndCities";
import {
  Button,
  Container,
  CloseIcon,
  Modal,
  Layout,
  LabeledInput
} from "../../atoms";
import { LoadAsset, RemovableInput, ClientSelector } from "../../molecules";

const EditFacilityModal = ({
  editFacilityModalState: editFacilityModal,
  facility,
  editFacilityId,
  addButtonDisabled,
  onClientSelectorChange,
  onFacilityNameChange,
  onBusinessNameSelect,
  onEditFacilityButton,
  onClickCloseButton,
  handleDeleteTag,
  handleAddTag,
  onDataLoaded
}) => {
  const photoURL = facility.avatarUrl == null ? "" : facility.avatarUrl;

  return (
    <Container width={916}>
      <div
        onClick={onClickCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>
        Editar datos de la planta{" "}
        <span style={{ color: "#5A5E61", fontSize: "13px" }}>
          {facility.name}
        </span>
      </Modal.Header>
      <Modal.Section paddingVertical={100} paddingHorizontal={280}>
        <LoadAsset
          buttonLabel="Adjuntar Foto de planta"
          image="file_adjuntarfotodeplanta"
          centered={true}
          defaultImage={photoURL}
        />
      </Modal.Section>
      <Modal.Section paddingVertical={100} paddingHorizontal={280}>
        <ClientSelector
          selected={[
            facility.businessName.client.name.trim(),
            facility.businessName.id,
            facility.zone
          ]}
          facility={facility}
          facilityName={facility.name}
          onChange={(key, value) => onClientSelectorChange(key, value)}
          onBusinessNameSelect={facilityId => onBusinessNameSelect(facilityId)}
          onFacilityNameChange={facilityName =>
            onFacilityNameChange(facilityName)
          }
          onDataLoaded={onDataLoaded}
        />
        <div>
          <div style={{ marginBottom: 20 }}>Gerentes de planta</div>
          <TagsInput
            classNames={{
              root: tagStyles.reactTags,
              rootFocused: tagStyles.reactTagsIsFocused,
              selected: tagStyles.reactTags__selected,
              selectedTag: tagStyles.reactTags__selectedTag,
              search: tagStyles.reactTags__search,
              suggestions: tagStyles.reactTags__suggestions
            }}
            placeholder="Añadir gerente de planta"
            tags={editFacilityModal.tags || []}
            suggestions={editFacilityModal.suggestions || []}
            handleDelete={handleDeleteTag}
            handleAddition={handleAddTag}
          />
        </div>
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <Button
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={onEditFacilityButton}
            disabled={addButtonDisabled}
          >
            Guardar Cambios
          </Button>
        </div>
      </Modal.Section>
    </Container>
  );
};

export default EditFacilityModal;
