import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Query } from "react-apollo";
import format from "date-fns/format";
import mixpanel from "mixpanel-browser";

import { readNotification } from "../../../gql/mutations";
import { BellIcon, Button } from "../../atoms";
import { Spinner } from "../../molecules";
import { bugsnagClient } from "../../../bugsnag";

import styles from "./styles.scss";
import { parseToDDMMAAAA } from "../../../utils/date";

class NotificationsIconModal extends Component {
  static propTypes = {
    notificationsFor: PropTypes.string.isRequired,
    notificationsQuery: PropTypes.object.isRequired
  };

  render() {
    const { notificationsFor, notificationsQuery } = this.props;

    return (
      <Query
        query={notificationsQuery}
        variables={{ userId: notificationsFor }}
        pollInterval={10000}
      >
        {({ loading, error, data }) => {
          // Until we fix it
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            // return <div testid="error-wrapper">error</div>;
            return <div />;
          }

          return (
            <>
              <div className={styles.quickViewTriggerArea}>
                <Link to="/notificaciones">
                  <div className={styles.notificationsContainer}>
                    <div className={styles.bellContainer}>
                      <BellIcon />
                    </div>
                    {this._renderNotificationCounter(data, loading)}
                  </div>
                </Link>
              </div>
              {this._renderNotificationsQuickView(data, 4)}
            </>
          );
        }}
      </Query>
    );
  }

  _renderNotificationCounter = (data, loading) => {
    if (loading) {
      return (
        <div className={styles.notificationCounter}>
          <Spinner mini />
        </div>
      );
    }

    const notifications = data.unreadNotificationsForAdmin
      ? data.unreadNotificationsForAdmin
      : data.unreadNotificationsForClient;

    if (notifications && notifications.length > 0) {
      return (
        <div className={styles.notificationCounter}>{notifications.length}</div>
      );
    }

    return null;
  };

  _renderNotificationsQuickView = (data, howMany) => {
    const unreadNotifications =
      data && data.unreadNotificationsForAdmin
        ? data.unreadNotificationsForAdmin
        : data.unreadNotificationsForClient;

    if (unreadNotifications) {
      const sortedNotifications = [...(unreadNotifications || [])].sort(
        (a, b) => {
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        }
      );

      const notifications = sortedNotifications.slice(0, howMany);

      return (
        <div className={styles.notificationsQuickViewArea}>
          <div className={styles.notificationsQuickViewContainer}>
            <div className={styles.notificationsHeader}>Notificaciones</div>
            <div className={styles.notificationDetailsContainer}>
              {notifications.map((notification, idx) => (
                <div key={idx} className={styles.notificationContainer}>
                  <div className={styles.notificationDetailsContainer}>
                    <div
                      className={styles.notificationFacilityName}
                      title={this._getFacility(notification).name}
                    >
                      {this._getFacility(notification).name}
                    </div>
                    <div className={styles.notificationDate}>
                      {format(
                        new Date(parseInt(notification.createdAt)),
                        "DD/M/YYYY"
                      )}
                    </div>
                    <div className={styles.notificationBody}>
                      {this._getNotificationDetails(notification)}
                    </div>
                  </div>
                  <div className={styles.notificationViewButtonContainer}>
                    <Button
                      paddingVertical={5}
                      paddingHorizontal={20}
                      radius={8}
                      style="outline"
                      onClick={() =>
                        this._handleOnPressViewNotificationButton(
                          notification,
                          !!data.unreadNotificationsForAdmin
                        )
                      }
                    >
                      Ver
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.viewAllContainer}>
              <Link to="/notificaciones">Ver todas</Link>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  _handleOnPressViewNotificationButton = (notification, forAdmin) => {
    if (notification.type === "REPORT_FINISHED") {
      readNotification(notification.id, this.props.notificationsFor).then(
        () => {
          const { initiator } = notification;
          if (forAdmin) {
            window.location = `/reportes/${initiator.id}`;
            mixpanel.track("Ver notificacion reporte administrador");
          } else {
            window.location = `/${initiator.fTec.facility.id}/reportes/${
              initiator.id
              }`;
            mixpanel.track("Ver notificacion reporte cliente");
          }
        }
      );
    }
    if (notification.type === "ADDITIONAL_SERVICE_REQUEST") {
      readNotification(notification.id, this.props.notificationsFor).then(
        () => {
          window.location = "/contratos/servicios-adicionales";
          mixpanel.track("Ver notificacion servicio adicional");
        }
      );
    }
    if (notification.type === "CORRECTIVE_ACTION_REQUEST") {
      readNotification(notification.id, this.props.notificationsFor).then(
        () => {
          const location = `/contratos/acciones-correctivas/${
            notification.initiator.id
            }`;
          window.location = location;
          mixpanel.track("Ver notificacion accion correctiva");
        }
      );
    }
    if (notification.type === "INSPECTION_RESCHEDULE") {
      readNotification(notification.id, this.props.notificationsFor).then(
        () => {
          window.location = `/agenda/reagendadas/${notification.initiator.id}`;
          mixpanel.track("Ver notificacion inspeccion reagendada");
        }
      );
    }
  };

  _getFacility = notification => {
    if (notification.type === "ADDITIONAL_SERVICE_REQUEST") {
      return notification.initiator.facility;
    }

    if (
      notification.type === "REPORT_FINISHED" ||
      notification.type === "CORRECTIVE_ACTION_REQUEST"
    ) {
      return notification.initiator.fTec.facility;
    }

    if (notification.type === "INSPECTION_RESCHEDULE") {
      return notification.initiator.inspection.fTec.facility;
    }
  };

  _getNotificationDetails = notification => {
    if (
      notification.type === "ADDITIONAL_SERVICE_REQUEST" ||
      notification.type === "CORRECTIVE_ACTION_REQUEST"
    ) {
      return notification.initiator.details;
    }

    if (notification.type === "REPORT_FINISHED") {
      return "Reporte terminado";
    }

    if (notification.type === "INSPECTION_RESCHEDULE") {
      return "Inspección reagendada";
    }
  };
}

export default NotificationsIconModal;
