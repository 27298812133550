import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { RedDownloadIcon } from "../../atoms";

const Header = ({ children, onClick, filePath }) => {
  return (
    <div className={styles.modalHeader}>
      <div className={styles.topBar}>
        <div className={styles.fileName}>{children}</div>
        <div className={styles.topBarMenuContainer}>
          <div className={styles.topBarMenu}>
            <RedDownloadIcon />
            <a href={filePath}>Descargar</a>
          </div>
          <div className={styles.topBarMenu} onClick={onClick}>
            X Cerrar
          </div>
        </div>
      </div>
    </div>
  );
};

const Section = ({
  downloadPath,
  streamPath,
  style,
  paddingVertical,
  paddingHorizontal,
  children
}) => {
  const customStyle = {
    ...style,
    paddingTop: paddingVertical && `${paddingVertical}px`,
    paddingBottom: paddingVertical && `${paddingVertical}px`,
    paddingLeft: paddingHorizontal && `${paddingHorizontal}px`,
    paddingRight: paddingHorizontal && `${paddingHorizontal}px`
  };

  return (
    <div className={styles.previewContainer} style={customStyle}>
      {downloadPath.indexOf(".pdf") > 0 && (
        <object
          data={streamPath}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      )}
      {downloadPath.indexOf(".pdf") == -1 && (
        <img src={downloadPath} className={styles.image} />
      )}
      {children}
    </div>
  );
};

Section.propTypes = {
  style: PropTypes.object,
  paddingVertical: PropTypes.number,
  paddingHorizontal: PropTypes.number
};

const Footer = ({ children }) => {
  return <div className={styles.modalFooter}>{children}</div>;
};

export default {
  Header,
  Section,
  Footer
};
