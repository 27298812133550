import React, { Component } from "react";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import { getRescheduledInspections } from "../../gql/queries";
import { RescheduledInspectionsTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class RescheduledInspectionsView extends Component {
  static propTypes = {
    inspectionRescheduleId: PropTypes.string
  };

  render() {
    const { inspectionRescheduleId } = this.props;
    return (
      <Query query={getRescheduledInspections}>
        {({ loading, error, data, refetch }) => {
          // Until error is fixed
          // if (error) {
          //   bugsnagClient.notify(error);
          //   console.error(error);
          //   return <div testid="error-wrapper">error</div>;
          // }

          return (
            <RescheduledInspectionsTemplate
              loadingInspections={loading}
              inspectionReschedules={data && data.inspectionReschedules}
              inspectionRescheduleId={inspectionRescheduleId}
              refetch={refetch}
            />
          );
        }}
      </Query>
    );
  }
}

export default RescheduledInspectionsView;
