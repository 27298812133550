import React, { Component } from "react";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

// Local imports
import { getFacilitiesByClient, getFacilityManager } from "../../gql/queries";
import { ProjectsTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class ProjectsView extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired
  };

  render() {
    const { userId, userKind } = this.props;

    const query =
      userKind === "CLIENT" ? getFacilitiesByClient : getFacilityManager;

    return (
      <Query query={query} variables={{ userId }}>
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div>Error</div>;
          }

          if (data) {
            return <ProjectsTemplate loadingData={loading} data={data} />;
          }
        }}
      </Query>
    );
  }
}

export default ProjectsView;
