import { gql } from "apollo-boost";

const getFacilities = gql`
  query getFacilities {
    facilities {
      id
      facilityManagers {
        id
        user {
          id
          emailAddress
        }
      }
      businessName {
        id
        client {
          id
          name
          avatarUrl
          facilityManagers {
            id
            user {
              id
              emailAddress
            }
          }
        }
        name
      }
      name
      zone
      createdAt
      updatedAt
      avatarUrl
    }
  }
`;

export default getFacilities;
