import React from "react";
import PropTypes from "prop-types";

import {
  CloseIcon,
  Container,
  Modal,
  Layout,
  LabeledInput,
  Button
} from "../../atoms";
import { LoadAsset } from "../../molecules";

const EditTechnicianModal = ({
  editTechnicianModalState: editTechnicianModal,
  editTechnicianId,
  onClickCloseButton,
  onInputChange,
  onCancelButton,
  onEditTechnician,
  addButtonDisabled,
  technician
}) => {
  const photoURL = technician.avatarUrl == null ? "" : technician.avatarUrl;

  return (
    <Container style={{ maxWidth: "80%" }} width={1300}>
      <div
        onClick={onClickCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>Editar datos del técnico</Modal.Header>
      <Modal.Section paddingVertical={100} paddingHorizontal={250}>
        <LoadAsset
          buttonLabel="Editar Foto"
          defaultImage={photoURL}
          image="file_editarfoto"
          centered={true}
        />
        <Layout.Grid style={{ marginTop: "180px", flexWrap: "nowrap" }}>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={1}
                fullWidth
                label="Nombre"
                name="technicianFirstNameInput"
                onChange={onInputChange}
                defaultValue={technician.firstName}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={3}
                fullWidth
                label="Email"
                name="technicianEmailInput"
                onChange={onInputChange}
                defaultValue={technician.email}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={5}
                fullWidth
                label="Teléfono Oficina"
                name="technicianOfficePhoneInput"
                onChange={onInputChange}
                defaultValue={technician.officePhone}
              />
            </div>
          </Layout.Column>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={2}
                fullWidth
                label="Apellido"
                name="technicianLastNameInput"
                onChange={onInputChange}
                defaultValue={technician.lastName}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={4}
                fullWidth
                label="Teléfono Móvil"
                name="technicianMobilePhoneInput"
                onChange={onInputChange}
                defaultValue={technician.mobilePhone}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={6}
                fullWidth
                label="Dirección"
                name="technicianAddressInput"
                onChange={onInputChange}
                defaultValue={technician.address}
              />
            </div>
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={10}
            paddingHorizontal={55}
            onClick={onCancelButton}
            style="outline"
          >
            Cancelar
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={30}
          onClick={onEditTechnician}
          radius={10}
          disabled={addButtonDisabled}
        >
          Guardar Cambios
        </Button>
      </Modal.Footer>
    </Container>
  );
};

EditTechnicianModal.propTypes = {
  editTechnicianModalState: PropTypes.shape({
    technicianFirstNameInput: PropTypes.string,
    technicianEmailInput: PropTypes.string,
    technicianOfficePhoneInput: PropTypes.string,
    technicianLastNameInput: PropTypes.string,
    technicianMobilePhoneInput: PropTypes.string,
    technicianAddressInput: PropTypes.string
  }),
  onClickCloseButton: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCancelButton: PropTypes.func.isRequired,
  onEditTechnician: PropTypes.func.isRequired,
  editTechnicianId: PropTypes.string.isRequired,
  addButtonDisabled: PropTypes.bool.isRequired,
  technician: PropTypes.object.isRequired
};

export default EditTechnicianModal;
