import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import classJoiner from "../../../utils/classJoiner";
import { NonSelectable } from "../";

class Switch extends Component {
  state = {
    active: false
  };

  static propTypes = {
    active: PropTypes.bool,
    labels: PropTypes.shape({
      active: PropTypes.string.isRequired,
      inactive: PropTypes.string.isRequired
    }),
    onToggle: PropTypes.func,
    disabled: PropTypes.bool,
    falseColor: PropTypes.string
  };

  static defaultProps = {
    labels: {
      active: "Sí",
      inactive: "No"
    },
    disabled: false
  };

  constructor(props) {
    super(props);
    const { active } = this.props;
    this.state = { active };
  }

  render() {
    const { active } = this.state;
    const {
      active: activeFromProps,
      labels,
      disabled,
      falseColor
    } = this.props;

    const classNames = active
      ? classJoiner([styles.switchTrack, styles.switchTrackActive])
      : classJoiner([styles.switchTrack, styles.switchTrackInactive]);

    const style =
      active && falseColor
        ? {}
        : {
          backgroundColor: falseColor,
          backgroundImage: falseColor && "none"
        };

    return (
      <NonSelectable>
        <div
          className={classNames}
          style={style}
          onClick={!disabled ? this._handleOnClick : undefined}
        >
          {!disabled && <div className={styles.switchThumb} />}
          <div className={styles.switchLabel}>
            {active ? labels.active : labels.inactive}
          </div>
        </div>
      </NonSelectable>
    );
  }

  _handleOnClick = () => {
    const { active } = this.state;
    const { onToggle } = this.props;

    this.setState(
      state => ({
        active: !state.active
      }),
      onToggle(!active)
    );
  };
}

export default Switch;
