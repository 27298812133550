export default {
  name: "firefighter_connections_inspection",
  title: "Inspección de conexiones de bomberos",
  inputs: {
    area: {
      label: "Área"
    },
    key: {
      label: "Toma siamesa"
    }
  },
  checks: {
    accessible: {
      warning: false,
      type: "BOOL",
      label: "Accesible y visible",
      unit: null
    },
    capsAndThreadsInGoodCondition: {
      warning: false,
      type: "BOOL",
      label: "Tapas y roscas en buen estado",
      unit: null
    },
    waterLeaks: {
      warning: false,
      type: "BOOL",
      label: "Presencia de fugas de agua en válvula check",
      unit: null
    },
    packagesInGoodState: {
      warning: false,
      type: "BOOL",
      label: "Empaques en buen estado",
      unit: null
    },
    cracks: {
      warning: false,
      type: "BOOL",
      label: "Presencia de grietas",
      unit: null
    },
    identificationTags: {
      warning: false,
      type: "BOOL",
      label: "Etiqueta de identificación",
      unit: null
    },
    requiresAdjustment: {
      type: "BOOL",
      label: "Requiere ajuste",
      unit: null
    },
    requiresRepair: {
      warning: false,
      type: "BOOL",
      label: "Requiere reparación",
      unit: null
    },
    requiresReplacement: {
      warning: false,
      type: "BOOL",
      label: "Requiere reemplazo",
      unit: null
    }
  }
};
