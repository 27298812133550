import React, { Component } from "react";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { getClientsFacilities } from "../../../gql/queries";
import { AvatarBubble, LabeledSelect, RemoveButton } from "../../atoms";
import { bugsnagClient } from "../../../bugsnag";

class FacilitySelector extends Component {
  state = {
    selectedClient: undefined,
    selectedBusinessName: undefined,
    selectedFacility: undefined
  };

  static propTypes = {
    onClientChange: PropTypes.func.isRequired,
    onBusinessNameChange: PropTypes.func.isRequired,
    onFacilitySelect: PropTypes.func.isRequired,
    disabled: PropTypes.arrayOf(PropTypes.string),
    selected: PropTypes.arrayOf(PropTypes.string),
    onRemove: PropTypes.func
  };

  componentDidMount() {
    const { selected } = this.props;

    if (selected && selected.length === 3) {
      this.setState({
        selectedClient: selected[0],
        selectedBusinessName: selected[1],
        selectedFacility: selected[2]
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    const { selected: prevSelected } = prevProps;

    if (selected[2] !== prevSelected[2]) {
      this.setState({
        selectedFacility: selected[2]
      });
    }
  }

  render() {
    const {
      selectedClient,
      selectedBusinessName,
      selectedFacility
    } = this.state;
    const { disabled, onRemove } = this.props;

    return (
      <Query query={getClientsFacilities}>
        {({ loading, error, data }) => {
          if (loading) {
            return <div>loading</div>;
          }

          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div>error</div>;
          }

          if (data) {
            // Filter out necessary data
            // TODO: Move to class methods
            const clients = data.clientFacilities.map(client => ({
              id: client.id,
              name: client.name,
              avatarUrl: client.avatarUrl
            }));

            const clientNames = clients.map(client => client.name);

            const client =
              selectedClient &&
              data.clientFacilities.find(
                client =>
                  client.name.replace(/ /g, "") ===
                  selectedClient.replace(/ /g, "")
              );

            const clientBusinessNames = client && client.businessNames;
            const businessNames =
              clientBusinessNames && clientBusinessNames.map(bn => bn.name);

            const facilities =
              selectedBusinessName &&
              clientBusinessNames.find(
                bn =>
                  bn.name.replace(/ /g, "") ===
                  selectedBusinessName.replace(/ /g, "")
              ).facilities;

            return (
              <div className={styles.facilitySelectorWrapper}>
                <AvatarBubble url={client && client.avatarUrl} />
                <div className={styles.selectWrapper}>
                  <LabeledSelect
                    label="Seleccionar Cliente"
                    options={clientNames}
                    defaultValue={selectedClient && selectedClient}
                    onSelect={e =>
                      this._handleOnSelectClient(e.currentTarget.value)
                    }
                  />
                </div>
                <div className={styles.selectWrapper}>
                  <LabeledSelect
                    label="Razón Social"
                    options={businessNames}
                    defaultValue={selectedBusinessName && selectedBusinessName}
                    onSelect={e =>
                      this._handleOnSelectBusinessName(e.currentTarget.value)
                    }
                    disabled={!selectedClient}
                  />
                </div>
                <div className={styles.selectWrapper}>
                  <LabeledSelect
                    label="Planta"
                    keyedOptions={facilities}
                    disabledOptions={disabled}
                    defaultValue={selectedFacility && selectedFacility}
                    onSelect={e => this._handleOnSelectFacility(e)}
                    disabled={!selectedBusinessName}
                  />
                </div>
                {onRemove && (
                  <div className={styles.removeButtonContainer}>
                    <RemoveButton onClick={onRemove} />
                  </div>
                )}
              </div>
            );
          }
        }}
      </Query>
    );
  }

  _handleOnSelectClient = value => {
    const { onChange } = this.props;

    this.setState(
      {
        selectedClient: value,
        selectedBusinessName: undefined,
        selectedFacility: undefined
      },
      onChange("selectedClient", value)
    );
  };

  _handleOnSelectBusinessName = value => {
    const { onChange } = this.props;

    this.setState(
      {
        selectedBusinessName: value,
        selectedFacility: undefined
      },
      onChange("selectedBusinessName", value)
    );
  };

  _handleOnSelectFacility = e => {
    const { onFacilitySelect } = this.props;

    this.setState(
      {
        selectedFacility: e.currentTarget.value
      },
      onFacilitySelect(e.currentTarget.value)
    );
  };
}

export default FacilitySelector;
