import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import NonSelectable from "../NonSelectable";

class TextArea extends Component {
  state = {
    value: ""
  };

  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    maxLength: PropTypes.number
  };

  render() {
    const { value } = this.state;
    const {
      name,
      value: valueFromProps,
      width,
      height,
      placeholder,
      maxLength
    } = this.props;

    return (
      <NonSelectable>
        {maxLength ? (
          <div className={styles.lengthIndicator}>
            {value.length}/{maxLength}
          </div>
        ) : (
            <div className={styles.lengthIndicator}>{value.length}</div>
          )}
        <textarea
          name={name}
          style={{ width, height }}
          className={styles.textArea}
          type="text"
          placeholder={placeholder}
          maxLength={maxLength}
          defaultValue={valueFromProps}
          onChange={e => this._handleOnChange(e)}
        />
      </NonSelectable>
    );
  }

  _handleOnChange = evt => {
    const { onChange } = this.props;
    this.setState(
      { value: evt.currentTarget.value },
      onChange && onChange(evt)
    );
  };
}

export default TextArea;
