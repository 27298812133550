import React, { Component } from "react";
import DayPicker from "react-day-picker";
import Helmet from "react-helmet";
import styles from "./InspectionsCalendarTemplateStyles.scss";
import PropTypes from "prop-types";
import { Spinner } from "../molecules";
import moment from "moment";
import { Heading } from "../atoms";

import { CHECKLIST_FORMATS } from "../../utils/checklists";

const HelmetStyles = `
  .DayPicker{
    width:788px;
    height:755px;
    box-shadow: 0 4px 32px rgba(204, 204, 204, 0.24);
    border-radius: 14px;
    background-color: #ffffff;
  }
  .DayPicker-wrapper {
      margin-top: 21px;
  }
  .DayPicker-WeekdaysRow {
      height: 49px;
  }
  .DayPicker-Day.DayPicker-Day--outside {
    opacity: 0.4;
    color: #686868;
  }
  .DayPicker-Weekday {
      display: table-cell;
      color: #333333;
      text-align: center;
      font-size: 25px;
      width: 56px;
      height: 19px;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;                               
  }
  .DayPicker-Day{
    margin:0;
    padding:1px;
    width:27px;
    height: 17px;
    font-size:16px;
    color: #212b36;
    line-height: 14px;
    font-weight: 400;
    text-align: center;
  }
  .DayPicker-Day--date1 {
    background-color: #00ff00;
    color: white;
  }
  .DayPicker-Day--date2 {
    background-color: #00ff00;
    color: white;
  }
  .DayPicker-Day--date3 {
    background-color: #00ff00;
    color: white;
  }                                                        
  .DayPicker-Day--date4 {
    background-color: #dd4a58;
    color: white;
  }     
  .DayPicker-NavButton--prev{
    margin-right:536px;
  }   
 .DayPicker-NavButton--next{
    margin-right:180px;
  }   
  .DayPicker-Day--today {
    color: rgba(0,0,0,.87);
    font-weight:normal;
  }                            
  .DayPicker-Caption{    
    font-family: "Open Sans";
    height: 22px;
    margin-bottom: 51px;
    text-align: center;
    color: #dd4a58;
    font-size: 30px !important;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
  }  
  .DayPicker-Day--highlighted {
    color: #dd4a58;
    font-size: 26px;
    font-weight: 700;
    line-height: 14px;
  }
  `;

const WEEKDAYS_SHORT = {
  es: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"]
};
const MONTHS = {
  es: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ]
};
const WEEKDAYS_LONG = {
  es: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
};

const FIRST_DAY_OF_WEEK = {
  es: 1
};

const LABELS = {
  es: { nextMonth: ">", previousMonth: "<" }
};

const WEEKDAYS = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};

function getWeekdays(weekday, month, year, everyWeekday = true) {
  let found = false;
  let currentDay = 1;
  const dates = [];
  const date = new Date(year, month, currentDay);

  const weekdayIdx = Object.keys(WEEKDAYS).findIndex(wd => wd === weekday);
  if (weekdayIdx === -1) {
    return undefined;
  }

  if (everyWeekday) {
    while (date.getMonth() === month) {
      date.setDate(currentDay);

      if (date.getDay() !== WEEKDAYS[weekday]) {
        currentDay++;
      } else {
        currentDay++;
        if (date.getMonth() === month) {
          dates.push(new Date(date.getTime()));
        }
      }
    }

    return dates;
  }

  while (!found) {
    date.setDate(currentDay);

    if (date.getDay() !== WEEKDAYS[weekday]) {
      currentDay++;
    } else {
      found = true;
    }
  }

  return date;
}

class InspectionsCalendarTemplate extends Component {
  static propTypes = {
    loadingData: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  static defaultProps = {
    loadingData: true,
    data: {
      regularInspectionsByProject: [],
      inspectionsByProject: []
    }
  };

  state = {
    refetching: false,
    selectedDay: null,
    date: {
      month: new Date().getUTCMonth(),
      year: new Date().getUTCFullYear()
    }
  };

  render() {
    const { refetching, selectedDay } = this.state;
    const { loadingData, data } = this.props;
    const { regularInspectionsByProject, inspectionsByProject } = data;

    const inspections = this._getInspections([
      ...regularInspectionsByProject,
      ...inspectionsByProject
    ]);

    return (
      <React.Fragment>
        <Heading>Inspecciones Por Realizar</Heading>
        <div className={styles.container}>
          <Helmet>
            <style>{HelmetStyles}</style>
          </Helmet>
          {/* <div style={{ position: "relative" }}> */}
          {(loadingData || refetching) && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255,255,255,0.8)",
                borderRadius: 14,
                zIndex: 2
              }}
            >
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Spinner />
              </div>
            </div>
          )}
          {/* </div> */}
          <DayPicker
            month={new Date()}
            renderDay={day => this._renderDay(day, inspections)}
            locale={"es"}
            months={MONTHS["es"]}
            weekdaysLong={WEEKDAYS_LONG["es"]}
            weekdaysShort={WEEKDAYS_SHORT["es"]}
            firstDayOfWeek={FIRST_DAY_OF_WEEK["es"]}
            labels={LABELS["es"]}
            onMonthChange={this._onMonthChange}
            showOutsideDays
          />
        </div>
      </React.Fragment>
    );
  }

  _getInspections = inspections => {
    const { date: calendarDate } = this.state;
    const dates = {};

    inspections.map(inspection => {
      if (
        inspection.__typename === "Inspection" ||
        inspection.__typename === "CorrectiveActionInspection" ||
        inspection.__typename === "AdditionalServiceInspection"
      ) {
        const { scheduledDate: date } = inspection;
        const day = moment(date, "YYYY-MM-DD")
          .utc()
          .format("DD-MM-YYYY");

        if (dates[day]) {
          dates[day] = [...dates[day], inspection];
        } else {
          dates[day] = [inspection];
        }
      } else if (inspection.__typename === "RegularInspection") {
        const { scheduledDate } = inspection;
        const day = moment(new Date(Number(scheduledDate)), "YYYY-MM-DD")
          .utc()
          .format("DD-MM-YYYY");
        if (dates[day]) {
          dates[day] = [...dates[day], inspection];
        } else {
          dates[day] = [inspection];
        }
      }
    });

    return dates;
  };

  _onMonthChange = date => {
    const { refetch } = this.props;

    const month = new Date(date).getUTCMonth();
    const year = new Date(date).getUTCFullYear();

    this.setState({
      refetching: true,
      date: {
        month,
        year
      }
    });

    refetch({ month: String(month), year: String(year) }).then(() =>
      this.setState({ refetching: false })
    );
  };

  _renderDay = (day, inspections) => {
    const { facility } = this.props.data;
    const facilityName = facility && facility.name;
    const dateFormat = moment(day, "YYYY-MM-DD").format("DD-MM-YYYY");
    const date = day.getDate();

    const whiteCell = {
      float: "left",
      border: "1px solid #ffffff",
      width: "100px",
      borderRadius: "29px",
      height: "100px",
      display: "flex",
      flexDirection: "column"
    };
    const dateStyle = {
      height: "29px",
      position: "relative",
      top: "2px",
      color: "#212b36",
      fontSize: "26px",
      fontWeight: "400",
      lineHeight: "14px"
    };
    const infoInspection = {
      float: "left",
      fontSize: "15px",
      color: "#4a4a4a",
      left: "10px",
      top: "-35px",
      fontWeight: "400",
      lineHeight: "14px"
    };
    const technicianName = {
      float: "left",
      fontSize: "15px",
      color: "#42c4c8",
      left: "10px",
      top: "-35px",
      fontWeight: "700",
      lineHeight: "14px"
    };

    const renderInfo = info => {
      if (info !== undefined) {
        if (
          info[0].__typename === "Inspection" ||
          info[0].__typename === "RegularInspection"
        ) {
          return (
            <>
              <div key={`tec-${info[0].id}`} style={technicianName}>
                {facilityName}
              </div>
            </>
          );
        } else if (info[0].__typename === "CorrectiveActionInspection") {
          return (
            <>
              <div key={`tec-${info[0].id}`} style={technicianName}>
                {facilityName}
              </div>
              <div key={`type-${info[0].id}`} style={infoInspection}>
                {info[0].correctiveAction.title}
              </div>
            </>
          );
        } else if (info[0].__typename === "AdditionalServiceInspection") {
          return (
            <>
              <div key={`tec-${info[0].id}`} style={technicianName}>
                {facilityName}
              </div>
              <div key={`type-${info[0].id}`} style={infoInspection}>
                {info[0].additionalService.title}
              </div>
            </>
          );
        }
      }
    };

    return (
      <div style={whiteCell}>
        <div style={dateStyle}>{date}</div>
        {renderInfo(inspections[dateFormat])}
      </div>
    );
  };
}

export default InspectionsCalendarTemplate;
