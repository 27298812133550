import React, { Component } from "react";
import { Query } from "react-apollo";
import { adopt } from "react-adopt";

import {
  getInspectionsTodo,
  getRescheduledInspections
} from "../../gql/queries";
import { InspectionsTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class InspectionsView extends Component {
  render() {
    const Composed = adopt({
      inspectionsQuery: ({ render }) => (
        <Query query={getInspectionsTodo}>{render}</Query>
      ),
      rescheduleQuery: ({ render }) => (
        <Query query={getRescheduledInspections}>{render}</Query>
      )
    });
    return (
      <Composed>
        {({ inspectionsQuery, rescheduleQuery }) => {
          if (inspectionsQuery.error) {
            bugsnagClient.notify(inspectionsQuery.error);
            console.error(inspectionsQuery.error);
          }
          if (rescheduleQuery.error) {
            bugsnagClient.notify(rescheduleQuery.error);
            console.error(rescheduleQuery.error);
          }
          return (
            <InspectionsTemplate
              inspections={inspectionsQuery.data.inspectionsTodo}
              inspectionReschedules={rescheduleQuery.data.inspectionReschedules}
              refetch={inspectionsQuery.refetch}
              refetchReschedule={rescheduleQuery.refetch}
              loadingInspcetions={inspectionsQuery.loading}
            />
          );
        }}
      </Composed>
    );
  }
}

export default InspectionsView;
