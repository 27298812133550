import { gql } from "apollo-boost";
import { client } from "../apollo";

const createUser = async (
  firstName,
  lastName,
  jobTitle,
  zone,
  avatarUrl,
  emailAddress,
  userPassword,
  mobilePhone,
  clientPortalDisplay
) =>
  await client.mutate({
    mutation: gql`
      mutation createUser(
        $firstName: String!
        $lastName: String!
        $jobTitle: String!
        $zone: String!
        $avatarUrl: String
        $emailAddress: String!
        $userPassword: String!
        $mobilePhone: String
        $clientPortalDisplay: Boolean!
      ) {
        createUser(
          firstName: $firstName
          lastName: $lastName
          jobTitle: $jobTitle
          zone: $zone
          avatarUrl: $avatarUrl
          emailAddress: $emailAddress
          userPassword: $userPassword
          mobilePhone: $mobilePhone
          clientPortalDisplay: $clientPortalDisplay
        ) {
          id
        }
      }
    `,
    variables: {
      firstName,
      lastName,
      jobTitle,
      zone,
      avatarUrl,
      emailAddress,
      userPassword,
      mobilePhone,
      clientPortalDisplay
    }
  });

export default createUser;
