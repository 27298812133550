import { USER_ACTIONS } from "../actions/user";

const initialState = {
  loggedIn: false,
  authState: undefined,
  tokens: {
    authToken: undefined,
    refreshToken: undefined
  },
  uid: undefined,
  kind: undefined
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.LOGIN_REQUEST:
      return { ...state, authState: "ATTEMPTING_LOGIN" };
    case USER_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        authState: undefined,
        tokens: {
          authToken: action.payload.authToken,
          refreshToken: action.payload.refreshToken
        },
        uid: action.payload.uid,
        kind: action.payload.kind
      };
    case USER_ACTIONS.LOGIN_ERROR:
      return { ...state, authState: action.payload.error };
    case USER_ACTIONS.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
