import React from "react";

const Contact = props => (
  <svg width={24} height={21} {...props}>
    <path
      d="M22,2 L14,2 L14,5 L10,5 L10,2 L2,2 C0.8954305,2 1.3527075e-16,2.8954305 0,4 L0,18 C1.3527075e-16,19.1045695 0.8954305,20 2,20 L22,20 C23.1045695,20 24,19.1045695 24,18 L24,4 C24,2.8954305 23.1045695,2 22,2 L22,2 Z M8,7 C9.1045695,7 10,7.8954305 10,9 C10,10.1045695 9.1045695,11 8,11 C6.8954305,11 6,10.1045695 6,9 C6,7.8954305 6.8954305,7 8,7 L8,7 Z M12,15 L4,15 L4,14 C4,12.67 6.67,12 8,12 C9.33,12 12,12.67 12,14 L12,15 L12,15 Z M20,16 L14,16 L14,14 L20,14 L20,16 L20,16 Z M20,12 L14,12 L14,10 L20,10 L20,12 L20,12 Z M20,8 L14,8 L14,6 L20,6 L20,8 L20,8 Z M13,4 L11,4 L11,0 L13,0 L13,4 Z"
      fill="#CACFD9"
      fillRule="nonzero"
    />
  </svg>
);

export default Contact;
