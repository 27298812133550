import React, { Component } from "react";
import PropTypes from "prop-types";
import { bugsnagClient } from "../../bugsnag";
import format from "date-fns/format";
import esLocale from "date-fns/locale/es";
import mixpanel from "mixpanel-browser";
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  AvatarBubble,
  AttachmentIcon,
  Button,
  Container,
  Heading,
  Table,
  SignatureIcon,
  CloseIcon,
  DownloadIcon,
  Layout,
  Modal,
  Dropdown,
  Switch
} from "../atoms";
import { ModalPortal, Spinner } from "../molecules";
import {
  CHECKLIST_FORMATS,
  CHECKLIST_FORMAT_SCHEMAS
} from "../../utils/checklists";
import { client } from "../../gql/apollo";
import { getReport } from "../../gql/queries";
import { parseMilliseconds } from "../../utils/date";
import { PDFReportTemplate } from "../templates";

export const reportsShape = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  mobilePhone: PropTypes.string,
  zone: PropTypes.string,
  plant: PropTypes.string,
  client: PropTypes.string,
  requestType: PropTypes.string
};

class ReportsListTemplate extends Component {
  static propTypes = {
    loadingReports: PropTypes.bool.isRequired,
    reports: PropTypes.arrayOf(PropTypes.shape(reportsShape)).isRequired
  };

  static defaultProps = {
    loadingReports: true
  };

  state = {
    viewReportModal: {
      visible: false
    },
    viewChecklistModal: {
      visible: false
    }
  };

  componentDidUpdate(prevProps) {
    const { loadingReports, reportId } = this.props;
    if (prevProps.loadingReports && !loadingReports) {
      if (reportId) {
        this._handleOnClickViewReportModal(reportId);
      }
    }
  }

  render() {
    const { loadingReports, reports } = this.props;
    const { viewReportModal, viewChecklistModal } = this.state;
    return (
      <>
        <ModalPortal
          modal={this._renderViewReportModal}
          visible={viewReportModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderViewChecklistModal}
          visible={viewChecklistModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <Heading>Reportes</Heading>
        <Container no Padding>
          <div style={{ marginTop: "70px" }}>
            {loadingReports && <Spinner />}
            {reports && (
              <Table.Main
                columns={[
                  { zone: "Zona" },
                  { plant: "Planta" },
                  { client: "Cliente" },
                  { requestType: "Tipo de solicitud" },
                  { requestType: "Fecha" },
                  {
                    sticky: {
                      style: {
                        position: "absolute",
                        whiteSpace: "nowrap",
                        right: 0,
                        marginTop: "-4px",
                        padding: "24px 14px",
                        background: "#ffffff",
                        borderLeft: "1px solid rgb(234, 234, 234)",
                        width: "103px"
                      }
                    }
                  }
                ]}
                data={reports}
                row={(report, lastReportRow) =>
                  this._renderReportRow(report, lastReportRow)
                }
              />
            )}
          </div>
        </Container>
      </>
    );
  }

  _renderReportRow = (data, lastReportRow) => {
    const date = format(data.date, "D [de] MMMM [de] YYYY", {
      locale: esLocale
    });
    return (
      <tr
        testid="reports-table-row"
        key={data.id}
        style={{ borderBottom: !lastReportRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>{data.fTec.facility.zone}</Table.Cell>
        <Table.Cell>{data.fTec.facility.name}</Table.Cell>
        <Table.Cell>{data.fTec.facility.businessName.client.name}</Table.Cell>
        <Table.Cell>Reporte</Table.Cell>
        <Table.Cell>{date}</Table.Cell>
        <Table.Cell style={{ position: "static" }}>
          <Table.Cell fixed>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                style="outline"
                onClick={() => this._handleOnClickViewReportModal(data.id)}
              >
                Ver
              </Button>
            </div>
          </Table.Cell>
          <div style={{ opacity: 0, zIndex: -1000 }}>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                style="outline"
                onClick={() => this._handleOnClickViewReportModal(data.id)}
              >
                Ver
              </Button>
            </div>
          </div>
        </Table.Cell>
      </tr>
    );
  };

  _handleOnClickViewReportModal = reportId => {
    this.setState(state => ({
      viewReportModal: {
        ...state.viewReportModal,
        id: reportId,
        visible: true,
        loading: true
      }
    }));

    client
      .query({
        query: getReport,
        variables: { reportId },
        fetchPolicy: "network-only"
      })
      .then(result => {
        const inspections = {};
        result.data.report &&
          result.data.report.inspections.map(inspection => {
            if (inspection.__typename === "Inspection") {
              inspections[inspection.id] = inspections[inspection.id] || {};

              inspection.checklists.map(checklist => {
                inspections[inspection.id] = {
                  ...inspections[inspection.id],
                  [checklist.id]: JSON.parse(checklist.checklistItems)
                };
              });
            }
          });

        this.setState(state => ({
          viewReportModal: {
            ...state.viewReportModal,
            visible: true,
            loading: false,
            report: result.data.report,
            inspections
          }
        }));
      })
      .catch(err => bugsnagClient.notify(err));
  };

  _renderViewReportModal = () => {
    const {
      viewReportModal: { report, loading }
    } = this.state;

    if (loading) {
      return <Spinner />;
    }

    const hasCorrectiveActionInspections = report.inspections.some(
      inspection => inspection.__typename === "CorrectiveActionInspection"
    );

    const hasAdditionalServiceInspections = report.inspections.some(
      inspection => inspection.__typename === "AdditionalServiceInspection"
    );

    const reportDate = format(report.date, "D [de] MMMM [de] YYYY", {
      locale: esLocale
    });

    return (
      <Container width={1010}>
        <div
          onClick={() => this._handleOnCloseButton()}
          style={{ position: "absolute", cursor: "pointer", right: 30 }}
        >
          <CloseIcon />
        </div>
        <div
          style={{
            color: "#4a4a4a",
            fontFamily: "Open Sans",
            fontSize: "30px",
            fontWeight: "700",
            marginLeft: "82px",
            marginTop: "29px",
            display: "inline-block"
          }}
        >
          Reporte de inspección
        </div>
        <div
          style={{
            position: "relative",
            marginLeft: "70px",
            display: "inline-block"
          }}
          onClick={this._handleOnClickViewChecklistModal}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1
            }}
          />
          <Dropdown disabled label="Ver checklist" />
        </div>
        <div style={{ display: "inline-block", marginLeft: "80px" }}>
          <PDFDownloadLink
            document={<PDFReportTemplate report={report} />}
            fileName={`${report.date}_${report.fTec.facility.name}`}
          >
            {({ blob, url, loading, error }) => {
              if (error) {
                bugsnagClient.notify(error);
                return <Button>Error...</Button>;
              }

              if (loading) {
                return <Button>Generando PDF...</Button>;
              } else {
                return (
                  <Button onClick={this._handleOnClickViewPDFReport}>
                    Descargar PDF
                    <div style={{ display: "inline-block", marginLeft: "9px" }}>
                      <DownloadIcon />
                    </div>
                  </Button>
                );
              }
            }}
          </PDFDownloadLink>
        </div>
        <div
          style={{
            color: "#686868",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontWeight: "400",
            marginLeft: "85px",
            display: "inline",
            lineHeight: ".001px"
          }}
        >
          {reportDate}
        </div>
        <Modal.Section paddingVertical={30} paddingHorizontal={188}>
          <div
            style={{
              marginTop: "27px",
              fontSize: "14px",
              fontFamily: "Open Sans",
              fontWeight: "400",
              color: "#686868",
              lineHeight: "20px",
              textAlign: "justify"
            }}
          >
            <div
              style={{ fontWeight: "bold", fontSize: "16", marginBottom: 20 }}
            >
              {report.fTec.facility.businessName.client.name}
            </div>
            {report.introMessage
              ? report.introMessage
              : `Por medio de la presente, le informo que el día ${reportDate}, se realizó la revisión de las condiciones en que se encuentra el sistema contra incendios de ${
              report.fTec.facility.name
              }. Nuestro compromiso es brindarle un excelente servicio de seguridad contra incendios es por eso que buscamos revisar cada uno de los equipos dentro de nuestro alcance.`}
          </div>
          <div
            style={{
              width: "100%",
              marginTop: 20,
              marginBottom: 20,
              lineHeight: "20px",
              fontSize: "14px",
              fontFamily: "Open Sans",
              fontWeight: "400",
              color: "#686868"
            }}
          >
            A continuación se describen las actividades realizadas,
            observaciones, acciones correctivas y recomendaciones para el
            funcionamiento adecuado del sistema.
          </div>
          <div
            style={{
              marginTop: "27px",
              marginBottom: "21px",
              fontSize: "16px",
              fontFamily: "Open Sans",
              fontWeight: "700",
              color: "#686868",
              lineHeight: "20px",
              textAlign: "center"
            }}
          >
            Reporte de trabajo Planta {report.fTec.facility.name}
          </div>
          <div
            style={{
              lineHeight: "20px",
              fontSize: "14px",
              fontFamily: "Open Sans",
              fontWeight: "400",
              color: "#686868"
            }}
          >
            <ul>
              {report.inspections.map(inspection => {
                if (inspection.__typename === "Inspection") {
                  const { checklistFormat } = inspection.inspectionChecklist;

                  return <li>• {CHECKLIST_FORMATS[checklistFormat]}</li>;
                }
              })}
              {hasAdditionalServiceInspections && (
                <ul>- Servicios adicionales</ul>
              )}
              {hasCorrectiveActionInspections && (
                <ul>- Acciones correctivas</ul>
              )}
            </ul>
          </div>
          {report.inspections.map(inspection => {
            if (inspection.__typename === "Inspection") {
              const { checklistFormat } = inspection.inspectionChecklist;
              const checklistTitle = CHECKLIST_FORMATS[checklistFormat];

              return (
                <>
                  <div
                    style={{
                      marginTop: "27px",
                      marginBottom: "21px",
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                      fontWeight: "700",
                      color: "#686868",
                      lineHeight: "20px",
                      textAlign: "center"
                    }}
                  >
                    <div>{inspection.title}</div>
                  </div>
                  {inspection.checklists.map((checklist, idx) => {
                    const checklistItems = JSON.parse(checklist.checklistItems);
                    const checkActions = Object.values(checklistItems).filter(
                      check => check.correctiveAction
                    );

                    const checkObservations = Object.values(
                      checklistItems
                    ).filter(check => check.observation);

                    const checkPhotos = Object.values(checklistItems).filter(
                      check => check.photos
                    );

                    const regularPhotos = (
                      checkPhotos.map(check => check.photos) || []
                    ).flat();

                    const regularPhotosURIs = regularPhotos.map(
                      photo => Object.values(photo)[0]
                    );

                    const correctiveActionPhotos = (
                      checkActions.map(check =>
                        check.correctiveAction
                          ? check.correctiveAction.photos
                          : []
                      ) || []
                    ).flat();

                    const observationPhotos = (
                      checkObservations.map(check =>
                        check.observation ? check.observation.photos : []
                      ) || []
                    ).flat();

                    const photos = [
                      ...regularPhotosURIs,
                      ...correctiveActionPhotos,
                      ...observationPhotos
                    ];

                    return (
                      <div key={idx}>
                        <div
                          style={{
                            marginTop: "15px",
                            fontSize: "16px",
                            fontFamily: "Open Sans",
                            fontWeight: "700",
                            color: "#686868",
                            lineHeight: "20px",
                            textAlign: "center"
                          }}
                        >
                          {checklistTitle} #{idx + 1}
                        </div>
                        {checklist.unavailable && (
                          <div
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                          >
                            - No disponible
                          </div>
                        )}
                        {!checklist.unavailable && (
                          <>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "30px",
                                fontSize: "14px",
                                fontFamily: "Open Sans",
                                fontWeight: "400",
                                color: "#686868",
                                lineHeight: "20px"
                              }}
                            >
                              <div style={{ flex: 1, marginVertical: 22 }}>
                                <div size={12} style={{ fontWeight: "700" }}>
                                  Acciones correctivas
                                </div>
                              </div>
                              {checkActions.length > 0 ? (
                                checkActions.map((check, idx) => (
                                  <div key={idx} size={12}>
                                    - {check.correctiveAction.value}
                                  </div>
                                ))
                              ) : (
                                  <div size={12}>- Nada que reportar</div>
                                )}
                              <div style={{ flex: 1, marginVertical: 22 }}>
                                <div
                                  size={12}
                                  style={{
                                    fontWeight: "700",
                                    marginTop: "10px"
                                  }}
                                >
                                  Observaciones
                                </div>
                              </div>
                              {checkObservations.length > 0 ? (
                                checkObservations.map((check, idx) => (
                                  <div key={idx} size={12}>
                                    - {check.observation.value}
                                  </div>
                                ))
                              ) : (
                                  <div size={12}>- Nada que reportar</div>
                                )}
                            </div>
                            {photos.length > 0 && (
                              <div
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  justifyContent: "space-between",
                                  marginTop: 22
                                }}
                              >
                                {photos.length > 0 &&
                                  photos.map((photo, idx) => (
                                    <img
                                      key={idx}
                                      style={{
                                        width: "28%",
                                        height: 180,
                                        borderRadius: 10,
                                        marginBottom: 25,
                                        marginRight: 3,
                                        marginLeft: 30
                                      }}
                                      src={photo}
                                    />
                                  ))}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              );
            } else if (
              inspection.__typename === "AdditionalServiceInspection"
            ) {
              const photos = JSON.parse(inspection.photos) || [];
              const observation = inspection.observation;

              return (
                <div style={{ marginTop: "40px" }}>
                  <div
                    style={{
                      marginBottom: "20px",
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                      fontWeight: "700",
                      color: "#686868",
                      lineHeight: "20px",
                      textAlign: "center",
                      marginBottom: 20
                    }}
                  >
                    Servicio adicional – {inspection.additionalService.details}
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "30px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                      color: "#686868",
                      lineHeight: "20px"
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: "0 10px",
                          borderRadius: 8
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: 10
                          }}
                        >
                          Evidencia fotográfica
                        </div>
                        {photos.length === 0 && "Sin evidencia fotográfica"}
                        {photos.length > 0 && (
                          <div>
                            {photos.map(photo => (
                              <div
                                style={{
                                  overflow: "hidden",
                                  position: "relative",
                                  marginRight: 10,
                                  width: "calc(33% - 10px)",
                                  float: "left",
                                  borderRadius: 8
                                }}
                              >
                                <img
                                  style={{ width: "100%" }}
                                  src={Object.values(photo)[0]}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: "0 10px"
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: 10
                          }}
                        >
                          Observación
                        </div>
                        {observation}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (inspection.__typename === "CorrectiveActionInspection") {
              const photos = JSON.parse(inspection.photos) || [];
              const observation = inspection.observation;

              return (
                <div style={{ marginTop: "40px" }}>
                  <div
                    style={{
                      marginBottom: "20px",
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                      fontWeight: "700",
                      color: "#686868",
                      lineHeight: "20px",
                      textAlign: "center",
                      marginBottom: 20
                    }}
                  >
                    Acción correctiva – {inspection.correctiveAction.details}
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "30px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                      color: "#686868",
                      lineHeight: "20px"
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: "0 10px",
                          borderRadius: 8
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: 10
                          }}
                        >
                          Evidencia fotográfica
                        </div>
                        {photos.length === 0 && "Sin evidencia fotográfica"}
                        {photos.length > 0 && (
                          <div>
                            {photos.map(photo => (
                              <div
                                style={{
                                  overflow: "hidden",
                                  position: "relative",
                                  marginRight: 10,
                                  width: "calc(33% - 10px)",
                                  float: "left",
                                  borderRadius: 8
                                }}
                              >
                                <img
                                  style={{ width: "100%" }}
                                  src={Object.values(photo)[0]}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          padding: 10,
                          margin: "0 10px"
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: 10
                          }}
                        >
                          Observación
                        </div>
                        {observation}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
          {report.alarmsReportUrl &&
            this._renderComplementaryReport(report.alarmsReportUrl)}
          <Layout.Grid>
            <Layout.Column>
              <div
                style={{
                  marginLeft: "90px",
                  display: "table",
                  marginBottom: "10px"
                }}
              >
                <SignatureIcon />
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontFamily: "Open Sans",
                  color: "#686868",
                  lineHeight: "20px",
                  borderTopStyle: "solid",
                  borderTopColor: "#979797",
                  borderTopWidth: "1px",
                  marginRight: "40px"
                }}
              >
                Ing. Francisco Javier Yañez Moreno
                <div style={{ fontWeight: "700" }}>
                  Gerente de mantenimiento
                </div>
                <div>Francisco.yanez@demek.com</div>
              </div>
            </Layout.Column>
            <Layout.Column>
              <div
                style={{
                  marginLeft: "125px",
                  display: "table",
                  marginBottom: "10px"
                }}
              >
                <img
                  width={101}
                  height={77}
                  src={`data:image/jpg;base64,${decodeURI(
                    report.clientSignatureUri
                  )}`}
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontFamily: "Open Sans",
                  fontWeight: "700",
                  color: "#686868",
                  borderTopStyle: "solid",
                  borderTopColor: "#979797",
                  borderTopWidth: "1px",
                  lineHeight: "20px",
                  marginLeft: "40px"
                }}
              >
                Firma del cliente
              </div>
            </Layout.Column>
          </Layout.Grid>
        </Modal.Section>
      </Container>
    );
  };

  _renderComplementaryReport = report => {
    const pathFragments = report.split("/");
    const fileName = pathFragments[pathFragments.length - 1].replace(
      /[0-9]{13}-/g,
      ""
    );

    return (
      <div style={{ marginBottom: 40 }}>
        <div
          style={{
            marginTop: "27px",
            marginBottom: "21px",
            fontSize: "16px",
            fontFamily: "Open Sans",
            fontWeight: "700",
            color: "#686868",
            lineHeight: "20px"
          }}
        >
          Reporte complementario
        </div>
        <a
          href={report}
          style={{
            textDecoration: "none"
          }}
        >
          <div
            style={{
              display: "inline-block",
              color: "#8ABEFF",
              cursor: "pointer"
            }}
          >
            <div style={{ display: "inline-block", marginRight: 10 }}>
              {fileName}
            </div>
            <AttachmentIcon />
          </div>
        </a>
      </div>
    );
  };

  _renderViewChecklistModal = () => {
    const {
      viewReportModal: { report }
    } = this.state;

    const checklists = [];
    report.inspections.map(inspection => {
      if (inspection.__typename === "Inspection") {
        return inspection.checklists.map(checklist =>
          checklists.push({
            ...checklist,
            format: inspection.inspectionChecklist.checklistFormat
          })
        );
      }
    });

    const sortedChecklists = checklists.sort((a, b) => {
      if (a.format < b.format) {
        return -1;
      } else if (a.format === b.format) {
        if (a.number < b.number) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    });

    const halfWayThrough = Math.floor(sortedChecklists.length / 2);
    const inspectionsHalfOne = sortedChecklists.slice(0, halfWayThrough);
    const inspectionsHalfTwo = sortedChecklists.slice(
      halfWayThrough,
      sortedChecklists.length
    );

    const reportDate = format(report.date, "D [de] MMMM [de] YYYY", {
      locale: esLocale
    });

    return (
      <Container width={1010}>
        <div
          onClick={() => this._handleOnCloseButton()}
          style={{ position: "absolute", cursor: "pointer", right: 30 }}
        >
          <CloseIcon />
        </div>
        <div
          style={{
            color: "#4a4a4a",
            fontFamily: "Open Sans",
            fontSize: "30px",
            fontWeight: "700",
            marginLeft: "82px",
            marginTop: "29px",
            display: "inline-block"
          }}
        >
          Reporte de inspección
        </div>
        <div
          style={{
            position: "relative",
            marginLeft: "70px",
            display: "inline-block"
          }}
          onClick={this._handleOnClickReViewModal}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1
            }}
          />
          <Dropdown disabled label="Ver informe escrito" />
        </div>
        <div style={{ display: "inline-block", marginLeft: "80px" }}>
          <Button onClick={() => (window.location = `/report/${report.id}`)}>
            Descargar PDF
            <div style={{ display: "inline-block", marginLeft: "9px" }}>
              <DownloadIcon />
            </div>
          </Button>
        </div>
        <div
          style={{
            color: "#686868",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontWeight: "400",
            marginLeft: "85px",
            display: "inline",
            lineHeight: ".001px"
          }}
        >
          {reportDate}
        </div>
        <Modal.Section paddingVertical={30} paddingHorizontal={90}>
          <Layout.Grid>
            <Layout.Column>
              {inspectionsHalfOne.map((checklist, idx) => {
                const { format: checklistFormat } = checklist;
                const checklistSchema = CHECKLIST_FORMAT_SCHEMAS[
                  checklistFormat
                ]().default.checks;
                const checklistTitle = CHECKLIST_FORMATS[checklistFormat];
                const checklistItems = JSON.parse(checklist.checklistItems);
                const photoEvidence = checklistItems.photoEvidence;

                return (
                  <>
                    {idx > 0 ? (
                      <div
                        style={{
                          fontSize: "14px",
                          fontFamily: "Open Sans",
                          fontWeight: 700,
                          color: "rgb(104, 104, 104)",
                          paddingLeft: "120px",
                          marginBottom: "20px",
                          borderTop: "1px solid rgb(215, 215, 215)",
                          marginTop: "40px",
                          paddingTop: "40px"
                        }}
                      >
                        {checklistTitle} #{checklist.number}
                      </div>
                    ) : (
                        <div
                          style={{
                            fontSize: "14px",
                            fontFamily: "Open Sans",
                            fontWeight: 700,
                            color: "rgb(104, 104, 104)",
                            paddingLeft: "120px",
                            marginBottom: "20px",
                            marginTop: "40px"
                          }}
                        >
                          {checklistTitle} #{checklist.number}
                        </div>
                      )}
                    {photoEvidence !== undefined && (
                      <div style={{ marginBottom: 40 }}>
                        <div
                          style={{
                            marginTop: "40px",
                            marginBottom: "20px",
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#686868",
                            lineHeight: "20px"
                          }}
                        >
                          Evidencia fotográfica
                        </div>
                        <div
                          style={{
                            lineHeight: "20px",
                            fontSize: "14px",
                            fontFamily: "Open Sans",
                            fontWeight: "400",
                            color: "#686868"
                          }}
                        >
                          {checklistItems.photoEvidence !== undefined && (
                            <img
                              style={{ width: 240, borderRadius: 10 }}
                              src={checklistItems.photoEvidence}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {checklist.unavailable && (
                      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                        - No disponible
                      </div>
                    )}
                    {!checklist.unavailable && (
                      <>
                        <div
                          style={{
                            lineHeight: "30px",
                            fontSize: "14px",
                            fontFamily: "Open Sans",
                            fontWeight: "400",
                            color: "#686868",
                            marginLeft: "35px",
                            display: "inline-block"
                          }}
                        >
                          <table style={{ borderSpacing: "30px" }}>
                            <tbody>
                              {Object.keys(checklistItems).map(
                                checklistItem => {
                                  if (
                                    checklistItem !== "area" &&
                                    checklistItem !== "key" &&
                                    checklistItem !== "photoEvidence"
                                  ) {
                                    return (
                                      <tr>
                                        <td
                                          style={{
                                            maxWidth: "225px",
                                            whiteSpace: "initial"
                                          }}
                                        >
                                          {checklistSchema[checklistItem].label}
                                        </td>
                                        <td>
                                          {checklistSchema[checklistItem]
                                            .type === "BOOL" && (
                                              <Switch
                                                disabled
                                                falseColor="#cacfd9"
                                                active={
                                                  checklistItems[checklistItem].value
                                                }
                                              />
                                            )}
                                          {checklistSchema[checklistItem]
                                            .type === "NUMBER" && (
                                              <div
                                                style={{
                                                  justifyContent: "center",
                                                  width: 34.5 * 2,
                                                  height: 13 * 2,
                                                  borderRadius: 34.5
                                                }}
                                              >
                                                <div style={{ alignSelf: "center" }}>
                                                {
                                                    checklistItems[checklistItem]
                                                      .value
                                                  }{" "}
                                                  {
                                                    checklistSchema[checklistItem]
                                                      .unit
                                                  }
                                                </div>
                                              </div>
                                            )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </Layout.Column>
            <div
              style={{
                borderLeftStyle: "solid",
                borderLeftColor: "#d7d7d7",
                borderLeftWidth: "1px",
                paddingLeft: "30px"
              }}
            >
              <Layout.Column>
                {inspectionsHalfTwo.map((checklist, idx) => {
                  const { format: checklistFormat } = checklist;
                  const checklistSchema = CHECKLIST_FORMAT_SCHEMAS[
                    checklistFormat
                  ]().default.checks;
                  const checklistTitle = CHECKLIST_FORMATS[checklistFormat];
                  const checklistItems = JSON.parse(checklist.checklistItems);
                  const photoEvidence = checklistItems.photoEvidence;

                  return (
                    <>
                      {idx > 0 ? (
                        <div
                          style={{
                            fontSize: "14px",
                            fontFamily: "Open Sans",
                            fontWeight: 700,
                            color: "rgb(104, 104, 104)",
                            paddingLeft: "120px",
                            marginBottom: "20px",
                            borderTop: "1px solid rgb(215, 215, 215)",
                            marginTop: "40px",
                            paddingTop: "40px"
                          }}
                        >
                          {checklistTitle} #{checklist.number}
                        </div>
                      ) : (
                          <div
                            style={{
                              fontSize: "14px",
                              fontFamily: "Open Sans",
                              fontWeight: 700,
                              color: "rgb(104, 104, 104)",
                              paddingLeft: "120px",
                              marginBottom: "20px",
                              marginTop: "40px"
                            }}
                          >
                            {checklistTitle} #{checklist.number}
                          </div>
                        )}
                      {photoEvidence !== undefined && (
                        <div style={{ marginBottom: 40 }}>
                          <div
                            style={{
                              marginTop: "40px",
                              marginBottom: "20px",
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#686868",
                              lineHeight: "20px"
                            }}
                          >
                            Evidencia fotográfica
                          </div>
                          <div
                            style={{
                              lineHeight: "20px",
                              fontSize: "14px",
                              fontFamily: "Open Sans",
                              fontWeight: "400",
                              color: "#686868"
                            }}
                          >
                            {checklistItems.photoEvidence !== undefined && (
                              <img
                                style={{ width: 240, borderRadius: 10 }}
                                src={checklistItems.photoEvidence}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {checklist.unavailable && (
                        <div
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          - No disponible
                        </div>
                      )}
                      {!checklist.unavailable && (
                        <>
                          <div
                            style={{
                              lineHeight: "30px",
                              fontSize: "14px",
                              fontFamily: "Open Sans",
                              fontWeight: "400",
                              color: "#686868",
                              marginLeft: "35px",
                              display: "inline-block"
                            }}
                          >
                            <table style={{ borderSpacing: "30px" }}>
                              <tbody>
                                {Object.keys(checklistItems).map(
                                  checklistItem => {
                                    if (
                                      checklistItem !== "area" &&
                                      checklistItem !== "key" &&
                                      checklistItem !== "photoEvidence"
                                    ) {
                                      return (
                                        <tr>
                                          <td
                                            style={{
                                              maxWidth: "225px",
                                              whiteSpace: "initial"
                                            }}
                                          >
                                            {checklistSchema[checklistItem].label}
                                          </td>
                                          <td>
                                            {checklistSchema[checklistItem]
                                              .type === "BOOL" && (
                                                <Switch
                                                  disabled
                                                  falseColor="#cacfd9"
                                                  active={
                                                    checklistItems[checklistItem].value
                                                  }
                                                />
                                              )}
                                            {checklistSchema[checklistItem]
                                              .type === "NUMBER" && (
                                                <div
                                                  style={{
                                                    justifyContent: "center",
                                                    width: 34.5 * 2,
                                                    height: 13 * 2,
                                                    borderRadius: 34.5
                                                  }}
                                                >
                                                  <div style={{ alignSelf: "center" }}>
                                                    {
                                                      checklistItems[checklistItem]
                                                        .value
                                                    }{" "}
                                                    {
                                                      checklistSchema[checklistItem]
                                                        .unit
                                                    }
                                                  </div>
                                                </div>
                                              )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
              </Layout.Column>
            </div>
          </Layout.Grid>
        </Modal.Section>
      </Container>
    );
  };

  _handleOnClickViewChecklistModal = () => {
    this.setState(state => ({
      viewReportModal: { ...state.viewReportModal, visible: false },
      viewChecklistModal: {
        ...state.viewChecklistModal,
        visible: true
      }
    }));
  };

  _handleOnClickViewPDFReport = () => mixpanel.track("Descargar Reporte PDF");

  _handleOnClickReViewModal = () => {
    this.setState(state => ({
      viewReportModal: { ...state.viewReportModal, visible: true },
      viewChecklistModal: { ...state.viewChecklistModal, visible: false }
    }));
  };

  _handleOnCloseButton = () => {
    this.setState({
      viewReportModal: { visible: false },
      viewChecklistModal: { visible: false }
    });
  };
}

export default ReportsListTemplate;
