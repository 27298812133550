import React from "react";

import styles from "./styles.scss";

const NonSelectable = ({ children, ...props }) => {
  return (
    <div className={styles.nonSelectable} {...props}>
      {children}
    </div>
  );
};

export default NonSelectable;
