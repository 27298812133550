import React from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";

import {
  CloseIcon,
  Container,
  Modal,
  Layout,
  LabeledInput,
  Button
} from "../../atoms";
import { LoadAsset } from "../../molecules";

const EditUserModal = ({
  editUserModalState: editUserModal,
  editUserId,
  onClickCloseButton,
  onInputChange,
  onCancelButton,
  onEditUser,
  addButtonDisabled,
  user
}) => {
  const photoURL = user.avatarUrl == null ? "" : user.avatarUrl;

  return (
    <Container style={{ maxWidth: "80%" }} width={1300}>
      <div
        onClick={onClickCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>Editar datos del administrador</Modal.Header>
      <Modal.Section paddingVertical={100} paddingHorizontal={250}>
        <LoadAsset
          buttonLabel="Editar Foto"
          defaultImage={photoURL}
          image="file_editarfoto"
          centered={true}
        />
        <Layout.Grid style={{ marginTop: "180px", flexWrap: "nowrap" }}>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={1}
                fullWidth
                label="Nombre"
                name="userFirstNameInput"
                onChange={onInputChange}
                defaultValue={user.firstName}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={3}
                fullWidth
                label="Correo electronico"
                name="userEmailInput"
                onChange={onInputChange}
                defaultValue={user.emailAddress}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={5}
                fullWidth
                label="Zona"
                name="userZoneInput"
                onChange={onInputChange}
                defaultValue={user.zone}
              />
            </div>
            <label>
              <Switch
                height={20}
                width={48}
                checked={
                  editUserModal.clientPortalDisplay === undefined
                    ? user.clientPortalDisplay
                    : editUserModal.clientPortalDisplay
                }
                onChange={checked =>
                  onInputChange({
                    target: {
                      name: "clientPortalDisplay",
                      value: checked
                    }
                  })
                }
              />
              <span
                style={{
                  marginLeft: "10px",
                  fontSize: "12px",
                  position: "absolute",
                  marginTop: "3.7px"
                }}
              >
                Mostrar en los contactos del cliente
              </span>
            </label>
          </Layout.Column>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={2}
                fullWidth
                label="Apellido"
                name="userLastNameInput"
                onChange={onInputChange}
                defaultValue={user.lastName}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={4}
                fullWidth
                label="Teléfono Móvil"
                name="userMobilePhoneInput"
                onChange={onInputChange}
                defaultValue={user.mobilePhone}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={6}
                fullWidth
                label="Cargo"
                name="userJobTitleInput"
                onChange={onInputChange}
                defaultValue={user.jobTitle}
              />
            </div>
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={10}
            paddingHorizontal={55}
            onClick={onCancelButton}
            style="outline"
          >
            Cancelar
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={30}
          onClick={onEditUser}
          radius={10}
          disabled={addButtonDisabled}
        >
          Guardar Cambios
        </Button>
      </Modal.Footer>
    </Container>
  );
};

EditUserModal.propTypes = {
  editUserModalState: PropTypes.shape({
    userFirstNameInput: PropTypes.string,
    userEmailInput: PropTypes.string,
    userZoneInput: PropTypes.string,
    userLastNameInput: PropTypes.string,
    userMobilePhoneInput: PropTypes.string,
    userJobTitleInput: PropTypes.string
  }),
  onClickCloseButton: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCancelButton: PropTypes.func.isRequired,
  onEditUser: PropTypes.func.isRequired,
  editUserId: PropTypes.string.isRequired,
  addButtonDisabled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

export default EditUserModal;
