export default {
  name: "jockey_pump_maintenance",
  title: "Mantenimiento de Bomba Jockey",
  checks: {
    suctionValve: {
      section: "Bomba Jockey",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Válvula de succión abierta",
      unit: null
    },
    dischargeValve: {
      section: "Bomba Jockey",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Válvula de descarga abierta",
      unit: null
    },
    fireNetworkPressure: {
      section: "Bomba Jockey",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Presión de la red de incendio",
      unit: "P.S.I."
    },
    automaticOp: {
      section: "Bomba Jockey",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Funcion en Operación Automatica",
      unit: null
    },
    manualOp: {
      section: "Bomba Jockey",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Funcionamiento en Operación Manual Arranque / Paro",
      unit: null
    },
    startPressure: {
      section: "Bomba Jockey",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Presión de Arranque",
      unit: "P.S.I."
    },
    stopPressure: {
      section: "Bomba Jockey",
      type: "NUMBER",
      label: "Presión de Paro",
      unit: "P.S.I."
    },
    enginePower: {
      section: "Bomba Jockey",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Potencia del motor",
      unit: "H.P."
    },
    bimetallicPumpAdjustment: {
      section: "Bomba Jockey",
      required: false,
      warning: false,
      type: "NUMBER",
      label: "Ajuste de Bimetalico Bomba",
      unit: "Ampers"
    },
    operationVoltage: {
      section: "Bomba Jockey",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Voltaje de Operación",
      unit: "Volts"
    },
    operationCurrent: {
      section: "Bomba Jockey",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente de Operación",
      unit: "Ampers"
    },
    abnormalities: {
      section: "Bomba Jockey",
      required: false,
      warning: false,
      type: "STRING",
      label: "Registrar cualquier anormalidad",
      unit: null
    }
  }
};
