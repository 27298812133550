import React from "react";

const Check = props => (
  <svg width={24} height={24} {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
  </svg>
);

export const Check14px = props => (
  <svg width={14} height={14} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        d="M5.25 9.432L2.817 7l-.828.822 3.261 3.261 7-7-.822-.822z"
      />
    </g>
  </svg>
);

export default Check;
