export default {
  name: "water_tank_maintenance",
  title: "Mantenimiento de Tanque de Agua",
  checks: {
    waterLevel: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Nivel de Agua",
      unit: "%"
    },
    temperature: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Temperatura no menor a 4.4°C",
      unit: null
    },
    corrosionFree: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Libre de corrosión",
      unit: null
    },
    supportCondition: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Soportería en buen estado",
      unit: null
    },
    vents: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Ventilas",
      unit: null
    },
    stairsCondition: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Escaleras en buen estado",
      unit: null
    },
    leakageFree: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Libre de fugas",
      unit: null
    },
    riskAssessment: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label:
        "Área cercana al tanque libre de almacenamiento de combustibles, basura o material que represente riesgo",
      unit: null
    },
    iceFree: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Libre de acumulación de hielo",
      unit: null
    },
    floaterValveCondition: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Válvula flotador en buen estado",
      unit: null
    },
    fillingPipeCondition: {
      section: "Tanque de Agua",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Tubería de llenado en buen estado",
      unit: null
    }
  }
};
