import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./styles.scss";
import { Button, ViewIcon, PlusIcon } from "../../atoms";

class UploadButton extends Component {
  state = { uploadingFile: false };

  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    onSuccessfulUpload: PropTypes.func.isRequired
  };

  _handleOnClickDocsButton = () => {
    const inputField = this.refs.fileField;
    inputField.click();
  };

  _uploadFile = event => {
    const { onSuccessfulUpload } = this.props;
    const fileName = event.target.files[0].name;

    const data = new FormData();
    data.append("file", event.target.files[0], event.target.files[0].name);
    event.target.value = "";

    this.setState({ uploadingFile: true });

    fetch(process.env.UPLOAD_ASSETS_URL, {
      method: "POST",
      headers: {
        Accept: "application/json"
      },
      body: data
    })
      .then(resp => resp.json())
      .then(result => {
        this.setState({ uploadingFile: false }, () =>
          onSuccessfulUpload(fileName, result.imageUrl)
        );
      });
  };

  render() {
    const { uploadingFile } = this.state;
    const { label } = this.props;

    return (
      <Button
        style="outline"
        paddingVertical={8}
        paddingHorizontal={10}
        radius={7}
        onClick={this._handleOnClickDocsButton}
        disabled={uploadingFile}
      >
        <div className={styles.buttonIconWrapper}>
          <PlusIcon tint="#dd4a58" />
        </div>
        {label}
        <input
          ref="fileField"
          type="file"
          className={styles.hide}
          onChange={this._uploadFile}
        />
      </Button>
    );
  }
}

export default UploadButton;
