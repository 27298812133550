import React, { Component } from "react";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import { getCorrectiveActions } from "../../gql/queries";
import { CorrectiveActionsTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class CorrectiveActionsView extends Component {
  static propTypes = {
    correctiveActionId: PropTypes.string
  };

  render() {
    const { correctiveActionId } = this.props;
    return (
      <Query query={getCorrectiveActions}>
        {({ loading, error, data, refetch }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div testid="error-wrapper">error</div>;
          }

          return (
            <CorrectiveActionsTemplate
              loadingCorrectiveActions={loading}
              correctiveActions={data && data.correctiveActions}
              correctiveActionId={correctiveActionId}
              refetch={refetch}
            />
          );
        }}
      </Query>
    );
  }
}

export default CorrectiveActionsView;
