import { gql } from "apollo-boost";
import { client } from "../apollo";

const deleteCorrectiveAction = async correctiveActionId =>
  await client.mutate({
    mutation: gql`
      mutation {
        deleteCorrectiveAction(
          correctiveActionId: "${correctiveActionId}"
        )
      }
  `
  });

export default deleteCorrectiveAction;
