import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";

import { CloseIcon, Container, Modal, LabeledInput, Button } from "../../atoms";
import styles from "../../templates/InspectionsTemplateStyles.scss";
import { FacilitySelector } from "../../molecules";

const EditInspectionModal = ({
  editInspectionModalState: editInspectionModal,
  facilitySelectors,
  facilitySelector,
  editInspectionId,
  scheduledDate,
  onCloseButton,
  onInputChange,
  onFacilitySelectorChange,
  onViewChecklistButton,
  onFacilitySelect,
  inspection,
  renderLabeledTechnicians,
  editButtonDisabled
}) => {
  return (
    <Container width={1100}>
      <div
        onClick={onCloseButton}
        style={{
          position: "absolute",
          cursor: "pointer",
          right: "20px",
          top: "20px"
        }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>Editar Inspección</Modal.Header>
      <Modal.Header>Editar Plantas Asignadas</Modal.Header>
      <Modal.Section>
        <div style={{ marginLeft: 100, marginRight: 100, marginTop: 40 }}>
          <FacilitySelector
            selected={[
              inspection.fTec.facility.businessName.client.name,
              inspection.fTec.facility.businessName.name,
              inspection.fTec.facility.id
            ]}
            onChange={(key, value) => onFacilitySelectorChange(key, value)}
            onFacilitySelect={facilityId => onFacilitySelect(facilityId)}
          />
        </div>
      </Modal.Section>
      <Modal.Header>Fecha</Modal.Header>
      <Modal.Section>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionItem}>
            <LabeledInput
              index={1}
              fullWidth
              label="Fecha"
              type="date-picker"
              name="dateInput"
              defaultValue={format(inspection.scheduledDate, "MM/DD/YYYY")}
              onChange={onInputChange}
            />
          </div>
        </div>
      </Modal.Section>
      <Modal.Header>Editar Tecnico Asignado</Modal.Header>
      <Modal.Section style={{ padding: 40 }}>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionItem}>
            {renderLabeledTechnicians(editInspectionModal)}
          </div>
        </div>
      </Modal.Section>
      <div style={{ marginTop: 40 }}>
        <Modal.Footer>
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <Button
              style="outline"
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={onCloseButton}
            >
              Cancelar
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={() =>
              onViewChecklistButton(inspection.inspectionChecklist)
            }
            disabled={editButtonDisabled}
          >
            Editar checklist
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
};

EditInspectionModal.propTypes = {
  onCloseButton: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onViewChecklistButton: PropTypes.func.isRequired,
  editInspectionId: PropTypes.string.isRequired,
  inspection: PropTypes.object.isRequired
};

export default EditInspectionModal;
