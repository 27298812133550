import React, { Component } from "react";
import { Query } from "react-apollo";

import { getFacilities } from "../../gql/queries";
import { FacilitiesTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class FacilitiesView extends Component {
  render() {
    return (
      <Query query={getFacilities}>
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div testid="error-wrapper">error</div>;
          }

          return (
            <FacilitiesTemplate
              loadingFacilities={loading}
              facilities={data.facilities}
            />
          );
        }}
      </Query>
    );
  }
}

export default FacilitiesView;
