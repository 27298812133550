import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { NonSelectable, SelectDropdownIcon } from "../";

const Dropdown = ({ label, selected, options, disabled }) => {
  return (
    <NonSelectable>
      <div className={styles.dropdownIcon}>
        <SelectDropdownIcon />
      </div>
      <select
        disabled={disabled}
        className={styles.dropdown}
        defaultValue={selected ? selected : label}
      >
        {label && (
          <option disabled hidden>
            {label}
          </option>
        )}
        {options &&
          options.map((option, i) => <option key={i}>{option}</option>)}
      </select>
    </NonSelectable>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool
};

Dropdown.defaultProps = {
  disabled: false
};

export default Dropdown;
