import React from "react";

const Engineer = props => (
  <svg width={22} height={23} {...props}>
    <defs>
      <path
        id="engineer_svg__a"
        d="M57.873 327H38.036a1.037 1.037 0 0 0 0 2.074h19.837a1.036 1.036 0 1 0 0-2.074z"
      />
      <path
        id="engineer_svg__b"
        d="M57.092 326.003A9.046 9.046 0 0 0 48.934 317c1.197.604 3.378 2.083 3.88 5.044a.31.31 0 0 1-.255.358h-.052a.311.311 0 0 1-.315-.255c-.556-3.33-3.568-4.639-4.146-4.861-.578.222-3.59 1.532-4.146 4.861a.312.312 0 0 1-.306.26h-.052a.31.31 0 0 1-.263-.363c.494-2.961 2.675-4.44 3.874-5.044A9.046 9.046 0 0 0 39 326.003z"
      />
      <path
        id="engineer_svg__c"
        d="M48.025 335.048a5.05 5.05 0 0 1-5.05-5.048H38v1.084l2.734.89c.169.63.422 1.238.751 1.803l-1.267 2.59 1.484 1.485 2.56-1.302a7.406 7.406 0 0 0 1.81.744l.937 2.728h2.1l.89-2.734c.633-.17 1.24-.42 1.806-.748l2.591 1.262 1.485-1.482-1.304-2.562a7.465 7.465 0 0 0 .744-1.807l2.728-.933V330h-4.975a5.048 5.048 0 0 1-5.047 5.048h-.002z"
      />
    </defs>
    <use
      fill="#cacfd9"
      xlinkHref="#engineer_svg__a"
      transform="translate(-37 -317)"
    />
    <use
      fill="#cacfd9"
      xlinkHref="#engineer_svg__b"
      transform="translate(-37 -317)"
    />
    <use
      fill="#cacfd9"
      xlinkHref="#engineer_svg__c"
      transform="translate(-37 -317)"
    />
  </svg>
);

export default Engineer;
