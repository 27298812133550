import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const RoundInput = ({ name, type, onChange, placeholder }) => (
  <input
    name={name}
    onChange={onChange}
    className={styles.roundInput}
    type={type}
    placeholder={placeholder}
  />
);

RoundInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(["text", "email", "password"]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

RoundInput.defaultProps = {
  type: "text"
};

export default RoundInput;
