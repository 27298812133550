import React, { Component } from "react";
import Query from "react-apollo";
import isEqual from "lodash/isEqual";

import {
  Card,
  Layout,
  Heading,
  PolicyIcon,
  LabeledInput,
  BigRemoveButton
} from "../atoms";
import { AnalysisCard, Spinner } from "../molecules";
import {
  IncomeChart,
  DashboardFilters,
  ProductivityChart,
  StatusGraph
} from "../organisms";
import styles from "./DashboardTemplateStyles.scss";
import getContractsByDate from "../../gql/queries";

const SORT_TYPE = {
  ASC: "ASC",
  DESC: "DESC"
};

class DashboardTemplate extends Component {
  state = {
    dateRangeFilter: {},
    data: [],
    sortKey: undefined,
    sortType: SORT_TYPE.DESC,
    sorted: false
  };

  componentDidUpdate(prevProps, prevState) {
    const { refetch } = this.props;
    const { dateRangeFilter: prevDateRangeFilter } = prevState;
    const { dateRangeFilter } = this.state;

    if (!isEqual(prevProps.additionalServices, this.props.additionalServices)) {
      this.setState({ additionalServices: this.props.additionalServices });
    }

    if (
      (!prevDateRangeFilter.startDate || !prevDateRangeFilter.endDate) &&
      dateRangeFilter.startDate &&
      dateRangeFilter.endDate
    ) {
      refetch({
        status: true,
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
    }

    if (prevDateRangeFilter.startDate && prevDateRangeFilter.endDate) {
      if (
        prevDateRangeFilter.startDate !== dateRangeFilter.startDate ||
        prevDateRangeFilter.endDate !== dateRangeFilter.endDate
      ) {
        refetch({
          status: true,
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
      }
    }

    if (
      prevDateRangeFilter.startDate &&
      prevDateRangeFilter.endDate &&
      (!dateRangeFilter.startDate && !dateRangeFilter.endDate)
    ) {
      refetch({ status: true, startDate: undefined, endDate: undefined });
    }
  }

  componentDidMount() {
    const { additionalServices } = this.props;
    this.setState({ additionalServices });
  }

  render() {
    const { dateRangeFilter, sortedFromState } = this.state;
    const {
      loading,
      contracts,
      previousYearContracts,
      additionalServices
    } = this.props;

    return (
      <>
        <div style={{ marginBottom: "20px", fontWeight: "bold" }}>
          <div
            style={{
              display: "inline-block",
              marginRight: "20px",
              fontWeight: "700",
              marginLeft: "22px",
              fontFamily: "Exo, sans-serif",
              color: "#1a173b"
            }}
          >
            Filtrar por rango de fechas
          </div>
          <span style={{ marginRight: "10px", color: "#1a173b" }}>De</span>
          <LabeledInput
            index={1}
            type="date-picker"
            name="startDate"
            defaultValue={dateRangeFilter.startDate}
            componentProps={{
              dayPickerProps: {
                disabledDays: {
                  after: new Date(dateRangeFilter.endDate)
                }
              }
            }}
            onChange={this._handleOnDateInputChange}
          />
          <span
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              color: "#1a173b"
            }}
          >
            Hasta
          </span>
          <LabeledInput
            index={2}
            type="date-picker"
            name="endDate"
            defaultValue={dateRangeFilter.endDate}
            componentProps={{
              dayPickerProps: {
                disabledDays: {
                  before: new Date(dateRangeFilter.startDate)
                }
              }
            }}
            onChange={this._handleOnDateInputChange}
          />
          <span
            style={{
              display: "inline-block",
              marginLeft: "5px"
            }}
          >
            <BigRemoveButton onClick={this._handleOnResetDateRangeFilter} />
          </span>
        </div>
        {loading && <Spinner />}
        {!loading &&
          (() => {
            const incomePerFacility = contracts.map(contract => ({
              label: contract.facility.name,
              value: contract.annualCost
            }));

            const assignedAdditionalServices = additionalServices.filter(
              additionalService => additionalService.facility
            );

            const facilityNames = assignedAdditionalServices.map(
              additionalService => additionalService.facility.name
            );

            const additionalServicesPerFacility = {};

            facilityNames.map(facilityName => {
              const groupedAdditionalServices = assignedAdditionalServices.filter(
                additionalService =>
                  additionalService.facility.name === facilityName
              );

              additionalServicesPerFacility[facilityName] = {
                label: facilityName,
                value: groupedAdditionalServices
                  .map(e => e.budget)
                  .reduce((acc, curr) => acc + curr, 0)
              };
            });

            const annualCosts = contracts.map(contract => contract.annualCost);

            const prevAnnualCosts = previousYearContracts.map(
              contract => contract.annualCost
            );

            const totalAnnualSales = new Intl.NumberFormat("es-MX").format(
              annualCosts.reduce((prev, curr) => prev + curr, 0)
            );

            const prevTotalAnnualSales = new Intl.NumberFormat("es-MX").format(
              prevAnnualCosts.reduce((prev, curr) => prev + curr, 0)
            );

            const additionalServicesCosts = additionalServices.map(
              additionalService =>
                additionalService.exchangeRate
                  ? additionalService.budget +
                    additionalService.budgetUSD * additionalService.exchangeRate
                  : additionalService.budget
            );

            const totalAdditionalServicesCosts = new Intl.NumberFormat(
              "es-MX"
            ).format(
              additionalServicesCosts.reduce((prev, curr) => prev + curr, 0)
            );

            const salesTotals = new Intl.NumberFormat("es-MX").format(
              parseFloat(totalAdditionalServicesCosts.replace(/,/g, "")) +
              parseFloat(totalAnnualSales.replace(/,/g, ""))
            );

            const prevSales = parseFloat(
              prevTotalAnnualSales.replace(/,/g, "")
            );
            const sales = parseFloat(totalAnnualSales.replace(/,/g, ""));
            const growthPercentage = Math.round(
              ((sales - prevSales) / prevSales) * 100
            );

            const sortedAdditionalServices = Object.values(
              additionalServicesPerFacility
            ).sort((a, b) => b.value - a.value);

            return (
              <>
                <Heading>Análisis</Heading>
                <Layout.Grid>
                  <Layout.Column stackable marginHorizontal={20}>
                    <AnalysisCard
                      heading={contracts.length}
                      label="Total de Pólizas Activas"
                      icon={<PolicyIcon />}
                    />
                  </Layout.Column>
                  <Layout.Column stackable marginHorizontal={20}>
                    <AnalysisCard
                      heading={`$${totalAnnualSales}`}
                      label="Total acumulado Pólizas"
                    />
                  </Layout.Column>
                  <Layout.Column stackable marginHorizontal={20}>
                    <AnalysisCard
                      heading={`$${totalAdditionalServicesCosts}`}
                      label="Servicios Adicionales"
                    />
                  </Layout.Column>
                  <Layout.Column stackable marginHorizontal={20}>
                    <AnalysisCard
                      heading={`$${salesTotals}`}
                      label="Monto total de ventas"
                    />
                  </Layout.Column>
                </Layout.Grid>
                <Heading>Ingresos</Heading>
                <Layout.Grid>
                  <Layout.Column marginHorizontal={20}>
                    <Card>
                      <IncomeChart
                        height={220}
                        title="Ingresos por pólizas"
                        data={incomePerFacility}
                      />
                    </Card>
                  </Layout.Column>
                  <Layout.Column marginHorizontal={20}>
                    <Card>
                      <IncomeChart
                        height={220}
                        title="Ingresos por servicios adicionales"
                        data={Object.values(
                          sortedFromState || sortedAdditionalServices
                        )}
                        onClickSort={() =>
                          this._handleOnClickSort(
                            sortedFromState || sortedAdditionalServices
                          )
                        }
                      />
                    </Card>
                  </Layout.Column>
                </Layout.Grid>
                <div style={{ marginTop: "60px" }}>
                  <Heading>Crecimiento</Heading>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    marginLeft: 20
                  }}
                >
                  <StatusGraph
                    title="Estado de crecimiento"
                    name="Con respecto al año pasado"
                    percentage={
                      growthPercentage < 0
                        ? 0
                        : isFinite(growthPercentage)
                          ? growthPercentage
                          : 0
                    }
                  />
                </div>
              </>
            );
          })()}
      </>
    );
  }

  _handleOnClickSort = data => {
    const { sortType } = this.state;
    const dataValues = Object.values(data);
    let type = sortType;
    let sortedData = [];

    if (sortType === SORT_TYPE.DESC) {
      sortedData = dataValues.sort((a, b) => a.value - b.value);
      type = SORT_TYPE.ASC;
    } else if (sortType === SORT_TYPE.ASC) {
      sortedData = dataValues.sort((a, b) => b.value - a.value);
      type = SORT_TYPE.DESC;
    }

    this.setState({
      sortedFromState: sortedData,
      sortType: type
    });
  };

  _handleOnDateInputChange = e => {
    if (e.props) {
      return this.setState(state => ({
        dateRangeFilter: {
          ...state.dateRangeFilter,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _handleOnResetDateRangeFilter = () => {
    const startDate = new Date(new Date().getFullYear(), 11, 1);
    const endDate = new Date(new Date().getFullYear(), 11, 31);
    this.setState({
      dateRangeFilter: {
        startDate,
        endDate
      }
    });
  };
}

export default DashboardTemplate;
