import React from "react";

const RedDownload = props => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="27.000000pt"
    height="22.000000pt"
    viewBox="0 0 27.000000 22.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <metadata>
      Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g
      transform="translate(0.000000,22.000000) scale(0.100000,-0.100000)"
      fill="#dd4a58"
      stroke="none"
    >
      <path
        d="M120 154 c0 -21 -4 -33 -10 -29 -16 10 -12 -11 5 -25 12 -10 18 -10
  30 0 17 14 21 35 5 25 -6 -4 -10 8 -10 29 0 20 -4 36 -10 36 -5 0 -10 -16 -10
  -36z"
      />
      <path
        d="M62 73 c3 -15 14 -18 68 -18 54 0 65 3 68 18 2 9 -1 17 -7 17 -6 0
  -11 -7 -11 -15 0 -11 -12 -15 -50 -15 -38 0 -50 4 -50 15 0 8 -5 15 -11 15 -6
  0 -9 -8 -7 -17z"
      />
    </g>
  </svg>
);

export default RedDownload;
