import { gql } from "apollo-boost";
import { client } from "../apollo";

const addAlarmsReportUrl = async (reportId, reportUrl) =>
  await client.mutate({
    mutation: gql`
      mutation addAlarmsReportUrl($reportId: ID!, $reportUrl: String!) {
        addAlarmsReportUrl(reportId: $reportId, reportUrl: $reportUrl)
      }
    `,
    variables: {
      reportId,
      reportUrl
    }
  });

export default addAlarmsReportUrl;
