export default {
  name: "wall_hydrant_inspection",
  title: "Inspección de hidrantes de muro",
  inputs: {
    area: {
      label: "Área"
    },
    key: {
      label: "Clave hidrante muro"
    }
  },
  checks: {
    accessible: {
      warning: false,
      type: "BOOL",
      label: "Accesible",
      unit: null
    },
    waterOrIce: {
      warning: false,
      type: "BOOL",
      label: "Presencia de agua o hielo",
      unit: null
    },
    waterLeaks: {
      warning: false,
      type: "BOOL",
      label: "Fugas de agua",
      unit: null
    },
    cracks: {
      warning: false,
      type: "BOOL",
      label: "Grietas",
      unit: null
    },
    hydrantCaps: {
      warning: false,
      type: "BOOL",
      label: "Tapas del hidrante",
      unit: null
    },
    capWear: {
      warning: false,
      type: "BOOL",
      label: "Desgaste de roscas",
      unit: null
    },
    keyAvailability: {
      type: "BOOL",
      label: "Disponibilidad de llave",
      unit: null
    },
    tagsCondition: {
      warning: false,
      type: "BOOL",
      label: "Etiquetas en buen estado",
      unit: null
    },
    adjustment: {
      warning: false,
      type: "BOOL",
      label: "Ajuste",
      unit: null
    },
    repair: {
      warning: false,
      type: "BOOL",
      label: "Reparacion",
      unit: null
    },
    replace: {
      warning: false,
      type: "BOOL",
      label: "Reemplazo",
      unit: null
    },
    visibleChainAndLock: {
      warning: false,
      type: "BOOL",
      label: "Cadena y candado visible",
      unit: null
    }
  }
};
