import React, { Component } from "react";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import { getAdditionalServicesByProject } from "../../gql/queries";
import { AdditionalServicesListTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class AdditionalServicesView extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };
  render() {
    const { match } = this.props;
    return (
      <Query
        query={getAdditionalServicesByProject}
        variables={{ facilityId: match.params.fid }}
      >
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div testid="error-wrapper">error</div>;
          }
          return (
            <AdditionalServicesListTemplate
              loadingAdditionalServices={loading}
              additionalServices={data && data.additionalServicesByProject}
            />
          );
        }}
      </Query>
    );
  }
}

export default AdditionalServicesView;
