export default {
  name: "gate_valves_tests",
  title: "Inspección y pruebas de válvulas compuerta",
  inputs: {
    area: {
      label: "Área"
    },
    key: {
      label: "Clave válvula PIV"
    }
  },
  checks: {
    openAndClose: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Apertura y cierre de válvula dejándola en su posición original",
      unit: null
    },
    idTag: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Etiqueta de identificacion",
      unit: null
    },
    leaksOrCracks: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Presencia de fuga o grietas",
      unit: null
    },
    avoidBlockage: {
      required: true,
      warning: false,
      type: "BOOL",
      label:
        "Evitar bloqueo de válvula volviéndola un cuarto después de abrirla de nuevo",
      unit: null
    },
    specialKey: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Llave especial en caso de ser requerida",
      unit: null
    },
    valveLubrication: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Lubricación válvula PIV",
      unit: null
    },
    requiresRepair: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Requiere reparación",
      unit: null,
      correctiveAction: {
        on: true,
        message: "Reparar o reemplazar"
      }
    },
    requiresReplacement: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Requiere reemplazo",
      unit: null,
      correctiveAction: {
        on: true,
        message: "Reparar o reemplazar"
      }
    },
    requiresAdjustment: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Requiere ajuste",
      unit: null
    },
    visibleLock: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Candado visible",
      unit: null
    }
  }
};
