import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

import styles from "./styles.scss";
import objectPath from "../../../utils/objectPath";
import { NonSelectable } from "../";

const Head = ({ children, onClick, style }) => (
  <th onClick={onClick} className={styles.tableHead} style={style}>
    {children}
  </th>
);

const TableCell = ({ style, fixed, children }) => (
  <td
    style={style}
    className={fixed ? styles.fixedTableCell : styles.tableCell}
  >
    {children}
  </td>
);

TableCell.propTypes = {
  style: PropTypes.object,
  fixed: PropTypes.bool
};

TableCell.defaultProps = {
  fixed: false
};

const SORT_TYPE = {
  ASC: "ASC",
  DESC: "DESC"
};

class Table extends Component {
  state = {
    data: [],
    sortKey: undefined,
    sortType: SORT_TYPE.DESC,
    sorted: false
  };

  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ data });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data });
    }
  }

  render() {
    const { data, sortKey, sortType, sorted } = this.state;
    const { columns, row, style } = this.props;

    return (
      <div className={styles.tableContainer} style={style}>
        <table className={styles.table}>
          <colgroup>
            <col width="76" />
          </colgroup>
          <tbody>
            <tr className={styles.headerRow}>
              {columns.map((col, idx) => {
                if (col === null) {
                  return <Head key={idx} />;
                } else if (col.sticky) {
                  return (
                    <Head key={idx}>
                      <div style={col.sticky.style} />
                    </Head>
                  );
                }

                // We don't care about extra elements
                const key = Object.keys(col)[0];
                const value = Object.values(col)[0];

                return (
                  <Head
                    key={idx}
                    onClick={() => this._handleOnColumnHeadClick(key)}
                  >
                    <span style={{ marginRight: 10 }}>{value}</span>
                    {sorted &&
                      sortKey === key &&
                      (sortType === SORT_TYPE.ASC ? "^" : "v")}
                  </Head>
                );
              })}
            </tr>
            {data.map((datum, i) => {
              let lastRow = false;
              if (i + 1 === data.length) lastRow = true;
              return row(datum, lastRow);
            })}
          </tbody>
        </table>
      </div>
    );
  }

  _handleOnColumnHeadClick = columnKey => {
    const { data, sortKey, sortType } = this.state;

    const sortedData = [...data].sort((a, b) => {
      if (objectPath(a, columnKey) > objectPath(b, columnKey)) {
        if (sortType === SORT_TYPE.ASC) {
          return 1;
        } else if (sortType === SORT_TYPE.DESC) {
          return -1;
        }
      }

      if (objectPath(a, columnKey) < objectPath(b, columnKey)) {
        if (sortType === SORT_TYPE.ASC) {
          return -11;
        } else if (sortType === SORT_TYPE.DESC) {
          return 1;
        }
      }

      return 0;
    });

    this.setState(_ => {
      let actualSortType = SORT_TYPE.ASC;

      if (sortKey === columnKey) {
        actualSortType =
          sortType === SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
      }

      return {
        data: sortedData,
        sortKey: columnKey,
        sortType: actualSortType,
        sorted: true
      };
    });
  };
}

export default {
  Main: Table,
  Cell: TableCell
};
