import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { NavLink } from "react-router-dom";
import HighlightedButton from "../../atoms/HighlightedButton";
import stylesHigh from "../../atoms/HighlightedButton/styles.scss";

const Sidebar = ({ buttons, hideFor }) => {
  let hidden = false;

  if (hideFor.length > 0) {
    hidden = !!hideFor.find(e => e === window.location.pathname);
  }

  if (!hidden)
    return (
      <>
        <div className={styles.sidebar}>
          <ul>
            {buttons.map((button, idx) => (
              <li key={idx} className={styles.sidebarLi}>
                <NavLink
                  key={idx}
                  exact={button.exact}
                  to={button.linkTo}
                  activeClassName={stylesHigh.verticalHighlight}
                >
                  <HighlightedButton
                    style={{ height: "56px", padding: "20px 0px" }}
                    orientation="vertical"
                    content={() => button.icon()}
                  />
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </>
    );

  return null;
};

Sidebar.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func.isRequired,
      linkTo: PropTypes.string.isRequired,
      exact: PropTypes.bool
    })
  ).isRequired,
  hideFor: PropTypes.arrayOf(PropTypes.string)
};

Sidebar.defaultProps = {
  hideFor: []
};

export default Sidebar;
