import React, { Component } from "react";
import PropTypes from "prop-types";
import { readNotification } from "../../gql/mutations";
import { Container, Heading, Table, Button, Modal } from "../atoms";
import mixpanel from "mixpanel-browser";

import { Spinner } from "../molecules";
import { parseToDDMMAAAA } from "../../utils/date";

export const notificationShape = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  mobilePhone: PropTypes.string,
  zone: PropTypes.string,
  plant: PropTypes.string,
  client: PropTypes.string,
  requestType: PropTypes.string
};

const NOTIFICATION_TYPES = {
  ADDITIONAL_SERVICE_REQUEST: "Servicio adicional",
  CORRECTIVE_ACTION_REQUEST: "Acción correctiva",
  REPORT_FINISHED: "Reporte finalizado",
  INSPECTION_RESCHEDULE: "Reagendación de inspección"
};

class NotificationsTemplate extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.shape(notificationShape))
      .isRequired
  };

  static defaultProps = {
    loading: true
  };

  render() {
    const { loading, notifications = [] } = this.props;

    const last50 = notifications.slice(0, 50);
    const notifs = last50.map(notif => ({
      ...notif,
      facility: this._getFacility(notif),
      description: this._getNotificationDetails(notif)
    }));

    return (
      <>
        <Heading>Notificaciones</Heading>
        <Container noPadding>
          <div style={{ marginTop: 100 }}>
            {loading && <Spinner />}
            {notifications && (
              <Table.Main
                columns={[
                  { "facility.name": "Planta" },
                  { "facility.businessName.client.name": "Cliente" },
                  { "facility.businessName.client.city": "Localidad" },
                  { description: "Descripción" },
                  { createdAt: "Fecha" },
                  { type: "Tipo" },
                  null
                ]}
                data={notifs}
                row={(notification, lastRow) =>
                  this._renderNotificationRow(notification, lastRow)
                }
              />
            )}
          </div>
        </Container>
      </>
    );
  }

  _renderNotificationRow = (notification, lastRow) => {
    const { forAdmin } = this.props;
    const { client } = notification.facility.businessName;

    return (
      <tr
        testid="notifications-table-row"
        key={notification.id}
        style={{ borderBottom: !lastRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>{notification.facility.name}</Table.Cell>
        <Table.Cell>{client.name}</Table.Cell>
        <Table.Cell>{`${client.city}, ${client.state}`}</Table.Cell>
        <Table.Cell>{notification.description}</Table.Cell>
        <Table.Cell>{parseToDDMMAAAA(notification.createdAt)}</Table.Cell>
        <Table.Cell>{NOTIFICATION_TYPES[notification.type]}</Table.Cell>
        <Table.Cell>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              style="outline"
              onClick={() =>
                this._handleOnPressViewNotificationButton(
                  notification,
                  forAdmin
                )
              }
            >
              Ver
            </Button>
          </div>
        </Table.Cell>
      </tr>
    );
  };

  _getFacility = notification => {
    if (
      notification.type === "ADDITIONAL_SERVICE_REQUEST" ||
      notification.type === "CORRECTIVE_ACTION_REQUEST"
    ) {
      return notification.initiator.fTec.facility;
    }

    if (notification.type === "INSPECTION_RESCHEDULE") {
      return notification.initiator.inspection.fTec.facility;
    }

    if (notification.type === "REPORT_FINISHED") {
      return notification.initiator.fTec.facility;
    }
  };

  _getNotificationDetails = notification => {
    if (
      notification.type === "ADDITIONAL_SERVICE_REQUEST" ||
      notification.type === "CORRECTIVE_ACTION_REQUEST"
    ) {
      return notification.initiator.details;
    }

    if (notification.type === "REPORT_FINISHED") {
      return "Reporte terminado";
    }

    if (notification.type === "INSPECTION_RESCHEDULE") {
      return "Inspección reagendada";
    }
  };

  _handleOnPressViewNotificationButton = (notification, forAdmin) => {
    if (notification.type === "REPORT_FINISHED") {
      readNotification(notification.id, this.props.notificationsFor).then(
        () => {
          const { initiator } = notification;
          if (forAdmin) {
            window.location = `/reportes/${initiator.id}`;
            mixpanel.track("Ver notificacion reporte administrador");
          } else {
            window.location = `/${initiator.fTec.facility.id}/reportes/${initiator.id}`;
            mixpanel.track("Ver notificacion reporte cliente");
          }
        }
      );
    }
    if (notification.type === "ADDITIONAL_SERVICE_REQUEST") {
      readNotification(notification.id, this.props.userId).then(() => {
        window.location = "/contratos/servicios-adicionales";
        mixpanel.track("Ver notificacion servicio adicional");
      });
    }
    if (notification.type === "CORRECTIVE_ACTION_REQUEST") {
      readNotification(notification.id, this.props.userId).then(() => {
        window.location = "/contratos/acciones-correctivas";
        mixpanel.track("Ver notificacion accion correctiva");
      });
    }
    if (notification.type === "INSPECTION_RESCHEDULE") {
      readNotification(notification.id, this.props.userId).then(() => {
        window.location = `/inspecciones/reagendadas/${
          notification.initiator.id
        }`;
        mixpanel.track("Ver notificacion inspeccion reagendada");
      });
    }
  };
}

export default NotificationsTemplate;
