import { gql } from "apollo-boost";
import { client } from "../apollo";

const disapproveInspectionReschedule = async (
  inspectionRescheduleId,
  newDate
) =>
  await client.mutate({
    mutation: gql`
      mutation {
        disapproveInspectionReschedule(
          inspectionRescheduleId: "${inspectionRescheduleId}"
          newDate: "${newDate}"
        )
      }
  `
  });

export default disapproveInspectionReschedule;
