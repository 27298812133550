import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Modal } from "../../atoms";

const DeleteTechnicianModal = ({
  deleteTechnicianId,
  deleteButtonDisabled,
  technician,
  onBackDeleteButton,
  onDeleteTechnicianButton
}) => {
  return (
    <Container width={540}>
      <Modal.Section paddingVertical={80} paddingHorizontal={80}>
        <div style={{ fontSize: "20px", color: "#5A5E61" }}>
          ¿Estas seguro que deseas eliminar al técnico{" "}
          <div
            style={{
              fontWeight: "bold",
              display: "inline-block",
              color: "#5A5E61 "
            }}
          >
            {technician.firstName + " " + technician.lastName}
          </div>{" "}
          de tu lista?
        </div>
      </Modal.Section>
      <div style={{ marginBottom: "37px", marginLeft: "95px" }}>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={8}
            radius={7}
            paddingHorizontal={35}
            onClick={onBackDeleteButton}
            style="outline"
          >
            No, volver
          </Button>
        </div>
        <Button
          paddingVertical={8}
          radius={7}
          paddingHorizontal={30}
          onClick={() => onDeleteTechnicianButton(deleteTechnicianId)}
          disabled={deleteButtonDisabled}
        >
          Sí, continuar
        </Button>
      </div>
    </Container>
  );
};

DeleteTechnicianModal.PropTypes = {
  deleteTechnicianId: PropTypes.string.isRequired,
  deleteButtonDisabled: PropTypes.bool.isRequired,
  technician: PropTypes.object.isRequired,
  onBackDeleteButton: PropTypes.func.isRequired,
  onDeleteTechnicianButton: PropTypes.func.isRequired
};

export default DeleteTechnicianModal;
