import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { Card } from "../../atoms";

const AnalysisCard = ({ heading, label, icon }) => {
  return (
    <Card height={60} margins={{ bottom: 40 }}>
      <>
        <div className={styles.cardBody}>
          <h1 className={styles.cardHeading}>{heading}</h1>
          <p>{label}</p>
        </div>
        {icon && <div className={styles.cardIcon}>{icon}</div>}
      </>
    </Card>
  );
};

AnalysisCard.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element
};

export default AnalysisCard;
