import { gql } from "apollo-boost";

const getClientUsers = gql`
  query getClientUsers {
    clientUsers {
      id
      firstName
      lastName
      avatarUrl
      jobTitle
      emailAddress
      mobilePhone
      zone
      clientPortalDisplay
    }
  }
`;

export default getClientUsers;
