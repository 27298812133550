import { gql } from "apollo-boost";

const getContracts = gql`
  query getContracts($startDate: String, $endDate: String) {
    contracts(startDate: $startDate, endDate: $endDate) {
      id
      facility {
        id
        name
        avatarUrl
        businessName {
          id
          name
          client {
            id
            name
            avatarUrl
          }
        }
      }
      contractDocuments {
        id
        url
        name
        type
      }
      annualCost
      monthlyCost
      baseBudget
      policyNumber
      status
      startDate
      endDate
      deleted
      updatedAt
      createdAt
      contractPolicyUrl
      SCINotesUrl
      commercialConditionsUrl
      facilityBlueprintsUrl
      deleted
      historic
      updatedAt
      createdAt

      inspectionChecklists {
        id
        checklistFormat
        quantity
        recurrence
        repeatOn
        status
        startOn
      }
    }
  }
`;

export default getContracts;
