import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Container,
  Modal,
  CloseIcon,
  Layout,
  AvatarBubble
} from "../../atoms";
import { FacilitySelector } from "../../molecules";

const EditFacilitiesModal = ({
  editTechnicianId,
  facilitySelectors,
  addButtonDisabled,
  selectedFacilityIds,
  onCloseButton,
  onFacilitySelectorChange,
  onFacilitySelect,
  onFacilityAddButton,
  onCancelButton,
  onEditTechnicianFacilitiesButton,
  onRemoveFacilityInput,
  technician
}) => {
  return (
    <>
      <Container width={1300}>
        <div
          onClick={onCloseButton}
          style={{ position: "absolute", cursor: "pointer", right: 30 }}
        >
          <CloseIcon />
        </div>
        <Modal.Header>
          <span style={{ bottom: "12px", position: "relative" }}>
            Editar Plantas Asignadas{" "}
          </span>
          <div style={{ marginLeft: "20px", display: "inline-block" }}>
            <AvatarBubble avatarUrl={technician.avatarUrl} />
          </div>
          <span
            style={{
              color: "#5A5E61",
              fontWeight: "bold",
              fontSize: "13px",
              marginLeft: "10px",
              position: "relative",
              bottom: "12px"
            }}
          >
            {technician.firstName + " " + technician.lastName}
          </span>
        </Modal.Header>
        <Modal.Section paddingVertical={100} paddingHorizontal={100}>
          <Layout.Grid>
            <Layout.Column style={{ padding: "0 40px" }}>
              {Object.keys(facilitySelectors).map((selector, idx) => (
                <div style={{ marginBottom: "40px" }}>
                  <FacilitySelector
                    disabled={Object.values(selectedFacilityIds)}
                    selected={[
                      facilitySelectors[selector].selectedClient,
                      facilitySelectors[selector].selectedBusinessName,
                      facilitySelectors[selector].selectedFacilityId
                    ]}
                    onRemove={
                      Object.keys(facilitySelectors).length !== 1 &&
                      (() => onRemoveFacilityInput(selector))
                    }
                    onChange={(key, value) =>
                      onFacilitySelectorChange(selector, key, value)
                    }
                    onFacilitySelect={facilityId =>
                      onFacilitySelect(selector, facilityId)
                    }
                  />
                </div>
              ))}
            </Layout.Column>
          </Layout.Grid>
          <div style={{ width: "15.5%", margin: "0 auto" }}>
            <Button
              paddingVertical={10}
              paddingHorizontal={55}
              onClick={onFacilityAddButton}
              style="outline"
            >
              Añadir
            </Button>
          </div>
        </Modal.Section>
        <Modal.Footer>
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <Button
              paddingVertical={10}
              paddingHorizontal={55}
              onClick={onCancelButton}
              style="outline"
            >
              Cancelar
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={60}
            onClick={onEditTechnicianFacilitiesButton}
            radius={10}
            disabled={addButtonDisabled}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Container>
    </>
  );
};

EditFacilitiesModal.PropTypes = {
  editTechnicianId: PropTypes.string.isRequired,
  addButtonDisabled: PropTypes.bool.isRequired,
  technician: PropTypes.object.isRequired,
  onCloseButton: PropTypes.func.isRequired,
  onCancelButton: PropTypes.func.isRequired,
  onDeleteTechnicianButton: PropTypes.func.isRequired,
  onFacilitySelectorChange: PropTypes.func.isRequired,
  onFacilitySelect: PropTypes.func.isRequired,
  onFacilityAddButton: PropTypes.func.isRequired,
  onEditTechnicianFacilitiesButton: PropTypes.func.isRequired,
  selectedFacilityIds: PropTypes.func.isRequired
};

export default EditFacilitiesModal;
