import React from "react";

const Home = props => (
  <svg width={19} height={22} {...props}>
    <defs>
      <path
        id="home_svg__a"
        d="M39 148.8a1 1 0 0 1 1-1h16.88a1 1 0 0 1 1 1V169a1 1 0 0 1-1 1H40a1 1 0 0 1-1-1zm6.77 12.65a2.5 2.5 0 0 0 2.5 2.5h.34a2.5 2.5 0 0 0 2.5-2.5v-.35a2.5 2.5 0 0 0-2.5-2.5h-.34a2.5 2.5 0 0 0-2.5 2.5z"
      />
    </defs>
    <use
      fill="#cacfd9"
      xlinkHref="#home_svg__a"
      transform="translate(-39 -148)"
    />
  </svg>
);

export default Home;
