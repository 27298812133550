import React from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";

import {
  CloseIcon,
  Container,
  Modal,
  Layout,
  LabeledInput,
  Button
} from "../../atoms";
import { LoadAsset } from "../../molecules";

const AddUserModal = ({
  addUserModalState: addUserModal,
  onClickCloseButton,
  onInputChange,
  onBackButton,
  onCreateUser
}) => {
  return (
    <Container style={{ maxWidth: "80%" }} width={1300}>
      <div
        onClick={onClickCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>Agregar administrador</Modal.Header>
      <Modal.Section paddingVertical={100} paddingHorizontal={250}>
        <LoadAsset
          buttonLabel="Adjuntar foto"
          image="file_adjuntarfoto"
          centered={true}
        />
        <Layout.Grid style={{ marginTop: "180px", flexWrap: "nowrap" }}>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={1}
                fullWidth
                label="Nombre"
                name="userFirstNameInput"
                onChange={onInputChange}
                defaultValue={addUserModal.userFirstNameInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={3}
                fullWidth
                label="Correo electronico"
                name="userEmailInput"
                onChange={onInputChange}
                defaultValue={addUserModal.userEmailInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={5}
                fullWidth
                label="Zona"
                name="userZoneInput"
                onChange={onInputChange}
                defaultValue={addUserModal.userZoneInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={7}
                fullWidth
                label="Contraseña"
                type="password"
                name="userPasswordInput"
                onChange={onInputChange}
                defaultValue={addUserModal.userPasswordInput}
              />
            </div>
            <label>
              <Switch
                height={20}
                width={48}
                checked={addUserModal.clientPortalDisplay}
                onChange={checked =>
                  onInputChange({
                    target: {
                      name: "clientPortalDisplay",
                      value: checked
                    }
                  })
                }
              />
              <span
                style={{
                  marginLeft: "10px",
                  fontSize: "12px",
                  position: "absolute",
                  marginTop: "3.7px"
                }}
              >
                Mostrar en los contactos del cliente
              </span>
            </label>
          </Layout.Column>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={2}
                fullWidth
                label="Apellido"
                name="userLastNameInput"
                onChange={onInputChange}
                defaultValue={addUserModal.userLastNameInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={4}
                fullWidth
                label="Teléfono Móvil"
                name="userMobilePhoneInput"
                onChange={onInputChange}
                defaultValue={addUserModal.userMobilePhoneInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={6}
                fullWidth
                label="Cargo"
                name="userJobTitleInput"
                onChange={onInputChange}
                defaultValue={addUserModal.userJobTitleInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={8}
                fullWidth
                label="Repetir Contraseña"
                type="password"
                name="userConfirmPasswordInput"
                onChange={onInputChange}
                defaultValue={addUserModal.userConfirmPasswordInput}
              />
            </div>
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={10}
            paddingHorizontal={55}
            onClick={() => onBackButton(0)}
            style="outline"
          >
            Volver
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={30}
          onClick={onCreateUser}
          radius={10}
          disabled={addUserModal.addButtonDisabled}
        >
          Crear administrador
        </Button>
      </Modal.Footer>
    </Container>
  );
};

AddUserModal.propTypes = {
  addUserModalState: PropTypes.shape({
    userFirstNameInput: PropTypes.string,
    userEmailInput: PropTypes.string,
    userZoneInput: PropTypes.string,
    userPasswordInput: PropTypes.string,
    userLastNameInput: PropTypes.string,
    userMobilePhoneInput: PropTypes.string,
    userJobTitleInput: PropTypes.string,
    userConfirmPasswordInput: PropTypes.string
  }).isRequired,
  onClickCloseButton: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onBackButton: PropTypes.func.isRequired,
  onCreateUser: PropTypes.func.isRequired
};

export default AddUserModal;
