import { gql } from "apollo-boost";

const getAdditionalServices = gql`
  query getAdditionalServices($startDate: String, $endDate: String) {
    additionalServices(startDate: $startDate, endDate: $endDate) {
      id
      facility {
        id
        name
        businessName {
          id
          name
          client {
            id
            name
            avatarUrl
          }
        }
      }
      contract {
        id
        baseBudget
        facility {
          id
          name
          businessName {
            id
            name
            client {
              id
              name
              avatarUrl
            }
          }
        }
      }
      fTec {
        id
        facility {
          id
          name
          businessName {
            id
            name
            client {
              id
              name
              avatarUrl
            }
          }
        }
      }
      fTec {
        id
        technician {
          id
          firstName
          lastName
        }
      }
      additionalServiceInspection {
        id
        scheduledDate
        fTec {
          id
          technician {
            id
            firstName
            lastName
          }
        }
      }
      title
      budget
      budgetUSD
      exchangeRate
      details
      purchaseOrder
      jobFinished
      invoiced
      createdAt
      updatedAt
    }
  }
`;

export default getAdditionalServices;
