import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const RemoveButton = ({ onClick }) => (
  <div onClick={onClick} className={styles.removeButton} />
);

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default RemoveButton;
