export default {
  name: "gate_valves_inspection",
  title: "Inspección de válvulas compuerta",
  inputs: {
    area: {
      label: "Área"
    },
    key: {
      label: "Clave válvula PIV"
    }
  },
  checks: {
    open: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Abierta",
      unit: null
    },
    accessible: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Accesible",
      unit: null
    },
    specialKey: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Llave especial si la requiere",
      unit: null
    },
    waterLeaks: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Presencia fugas de agua",
      unit: null
    },
    cracks: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Presencia de grietas",
      unit: null
    },
    idTag: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Etiqueta de Identificación",
      unit: null
    },
    requiresRepair: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Requiere reparación",
      unit: null
    },
    requiresReplacement: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Requiere reemplazo",
      unit: null
    },
    requiresAdjustment: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Requiere ajuste",
      unit: null
    },
    visibleLock: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Candado visible",
      unit: null
    }
  }
};
