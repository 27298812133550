import React, { Component } from "react";
import DayPicker from "react-day-picker";
import Helmet from "react-helmet";
import styles from "./ScheduleTemplateStyles.scss";
import PropTypes from "prop-types";
import { Spinner, ModalPortal } from "../molecules";
import moment from "moment";
import { Query } from "react-apollo";

import {
  Heading,
  Button,
  FilterIcon,
  Container,
  CloseIcon,
  Modal,
  Layout,
  LabeledInput,
  Table,
  AvatarBubble
} from "../atoms";
import { getInspectionsForAdmin } from "../../gql/queries";
import { CHECKLIST_FORMATS } from "../../utils/checklists";
import { parseMilliseconds } from "../../utils/date";

const RECURRENCES = {
  WEEKLY: "Semanal",
  MONTHLY: "Mensual",
  BIMONTHLY: "Bimestral",
  TRIMONTHLY: "Trimestral",
  HALFYEAR: "Semestral",
  YEARLY: "Anual"
};

const ACTIVITIES = {
  Carretes: "Carretes de Manguera",
  Lamina: "Lámina de 1/2 pulgada"
};

const LOCATIONS = {
  "Zona Norte, Chihuahua": "Zona Norte, Chihuahua"
};

const ASIGNEDTECHNICIAN = {
  "Evan Andrews": "Evan Andrews"
};

const DROPDOWNYEARS = {
  "2019": "2019",
  "2020": "2020"
};

const DROPDOWNMONTHS = {
  Enero: "Enero",
  Febrero: "Febrero",
  Marzo: "Marzo",
  Abril: "Abril",
  Mayo: "Mayo",
  Junio: "Junio",
  Julio: "Julio",
  Agosto: "Agosto",
  Septiembre: "Septiembre",
  Octubre: "Octubre",
  Noviembre: "Noviembre",
  Diciembre: "Diciembre"
};

const VISITDAYS = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "10": "10",
  "11": "11",
  "12": "12",
  "13": "13",
  "14": "14",
  "15": "15",
  "16": "16",
  "17": "17",
  "18": "18",
  "19": "19",
  "20": "20",
  "21": "21",
  "22": "22",
  "23": "23",
  "24": "24",
  "25": "25",
  "26": "26",
  "27": "27",
  "28": "28",
  "29": "29",
  "30": "30",
  "31": "31"
};

const HelmetStyles = `
  .DayPicker{
    box-shadow: 0 4px 32px rgba(204, 204, 204, 0.24);
    border-radius: 14px;
    background-color: #ffffff;
  }
  .DayPicker-wrapper {
      margin-top: 21px;
  }
  .DayPicker-WeekdaysRow {
      height: 49px;
  }
  .DayPicker-Day.DayPicker-Day--selected {
    color: red;
    background-color: red;
  }
  .DayPicker-Day.DayPicker-Day--outside {
    opacity: 0.4;
    color: #686868;
  }
  .DayPicker-Weekday {
      display: table-cell;
      color: #333333;
      text-align: center;
      font-size: 16px;
      width: 56px;
      height: 19px;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;                               
  }
  .DayPicker-Day{
    margin:0;
    font-size: 16px;
    color: #212b36;
    line-height: 14px;
    font-weight: 400;
    text-align: center;
  }
  .DayPicker-Day--date1 {
    background-color: #00ff00;
    color: white;
  }
  .DayPicker-Day--date2 {
    background-color: #00ff00;
    color: white;
  }
  .DayPicker-Day--date3 {
    background-color: #00ff00;
    color: white;
  }                                                        
  .DayPicker-Day--date4 {
    background-color: #dd4a58;
    color: white;
  }     
  .DayPicker-Day--today {
    color: rgba(0,0,0,.87);
    font-weight:normal;
  }                            
  .DayPicker-Caption{    
    font-family: "Open Sans";
    height: 22px;
    margin-bottom: 51px;
    text-align: center;
    color: #dd4a58;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
  }  
  .DayPicker-Day--highlighted {
    color: #dd4a58;
    font-size: 16px;
    font-weight: 700;
    line-height: 14px;
  }

  .DayPicker-Day--today {
    background-color: pink !important;
  }
  `;

const WEEKDAYS_SHORT = {
  es: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"]
};
const MONTHS = {
  es: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ]
};
const WEEKDAYS_LONG = {
  es: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
};

const FIRST_DAY_OF_WEEK = {
  es: 1
};

const LABELS = {
  es: { nextMonth: ">", previousMonth: "<" }
};

const WEEKDAYS = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};

function getWeekdays(weekday, month, year, everyWeekday = true) {
  let found = false;
  let currentDay = 1;
  const dates = [];
  const date = new Date(year, month, currentDay);

  const weekdayIdx = Object.keys(WEEKDAYS).findIndex(wd => wd === weekday);
  if (weekdayIdx === -1) {
    return undefined;
  }

  if (everyWeekday) {
    while (date.getMonth() === month) {
      date.setDate(currentDay);

      if (date.getDay() !== WEEKDAYS[weekday]) {
        currentDay++;
      } else {
        currentDay++;
        if (date.getMonth() === month) {
          dates.push(new Date(date.getTime()));
        }
      }
    }

    return dates;
  }

  while (!found) {
    date.setDate(currentDay);

    if (date.getDay() !== WEEKDAYS[weekday]) {
      currentDay++;
    } else {
      found = true;
    }
  }

  return date;
}

class ScheduleTemplate extends Component {
  static propTypes = {
    loadingData: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired
  };

  static defaultProps = {
    loadingData: true,
    data: {
      regularInspectionsForAdmin: [],
      inspectionsForAdmin: []
    }
  };

  state = {
    refetching: false,
    selectedDay: null,
    date: {
      month: new Date().getUTCMonth(),
      year: new Date().getUTCFullYear()
    },
    addContractModal: {
      visible: false,
      addButtonDisabled: true
    },
    addInspectionChecklistModal: {
      visible: false,
      addButtonDisabled: false,
      formats: {}
    }
  };

  render() {
    const { addContractModal, refetching, selectedDay } = this.state;
    const { loadingData, data } = this.props;
    const { regularInspectionsForAdmin, inspectionsForAdmin } = data;

    const groupedInspections = this._getInspections([
      ...regularInspectionsForAdmin,
      ...inspectionsForAdmin
    ]);

    const inspections = Object.values(groupedInspections).flat();
    const inspectionsOnDay = inspections.filter((e, idx) => {
      const date = e.scheduledDate;
      const selected = new Date(selectedDay).toISOString().split("T")[0];
      const scheduledDate = new Date(isNaN(date) ? date : Number(date))
        .toISOString()
        .split("T")[0];

      return selected === scheduledDate;
    });

    inspectionsOnDay.sort((a, b) =>
      a.fTec.technician.firstName > b.fTec.technician.firstName ? 1 : -1
    );

    const inspectionsPerFacility = {};
    inspectionsOnDay.map(inspection => {
      inspectionsPerFacility[inspection.fTec.id] = inspection.fTec;
    });

    return (
      <React.Fragment>
        {/* Rename to filter modal */}
        <ModalPortal
          modal={this._renderAddChecklistModal}
          visible={addContractModal.visible}
        />
        <Heading>Agenda de Inspecciones</Heading>
        {/* TODO: To implement inspection filtering */}
        <div style={{ margin: "40px 40px 0 40px", display: "none" }}>
          <Button onClick={() => this._handleOnClickAddButton()}>
            Aplicar un filtro
            <div className={styles.plusIconWrapper}>
              <FilterIcon tint="#ffffff" />
            </div>
          </Button>
        </div>
        <div className={styles.container}>
          <Helmet>
            <style>{HelmetStyles}</style>
          </Helmet>
          <div style={{ position: "relative" }}>
            {(loadingData || refetching) && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  borderRadius: 14,
                  zIndex: 2
                }}
              >
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <Spinner />
                </div>
              </div>
            )}
            <DayPicker
              month={new Date()}
              renderDay={day => this._renderDay(day, groupedInspections)}
              selectedDays={new Date()}
              locale={"es"}
              months={MONTHS["es"]}
              weekdaysLong={WEEKDAYS_LONG["es"]}
              weekdaysShort={WEEKDAYS_SHORT["es"]}
              firstDayOfWeek={FIRST_DAY_OF_WEEK["es"]}
              labels={LABELS["es"]}
              onMonthChange={this._onMonthChange}
              showOutsideDays
              onDayClick={day => this._handleDayClick(day)}
            />
          </div>
          <Table.Main
            style={{ width: "100%" }}
            columns={[null, { name: "Técnico" }, { facility: "Planta" }]}
            data={Object.values(inspectionsPerFacility)}
            row={(inspection, lastRow) =>
              this._renderInspectionRow(inspection, lastRow)
            }
          />
        </div>
      </React.Fragment>
    );
  }

  _renderInspectionRow = (inspection, lastRow) => (
    <tr
      testid="inspections-table-row"
      key={inspection.id}
      style={{ borderBottom: !lastRow && "solid 1px #eaeaea" }}
    >
      <Table.Cell>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          {inspection && (
            <AvatarBubble
              url={
                inspection.technician.avatarUrl &&
                inspection.technician.avatarUrl
              }
            />
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        {inspection
          ? `${inspection.technician.firstName} ${
          inspection.technician.lastName
          }`
          : "N/A"}
      </Table.Cell>
      <Table.Cell>
        <div
          style={{
            height: "10px",
            width: "10px",
            borderRadius: "100%",
            display: "inline-block",
            margin: "0 auto",
            marginRight: 10,
            backgroundColor: inspection.facility.color
          }}
        />
        {inspection.facility.name}
      </Table.Cell>
    </tr>
  );

  _handleDayClick = day => this.setState({ selectedDay: day });

  _onMonthChange = date => {
    const { refetch } = this.props;

    const month = new Date(date).getUTCMonth();
    const year = new Date(date).getUTCFullYear();

    this.setState({
      refetching: true,
      date: {
        month,
        year
      }
    });

    refetch({ month: String(month), year: String(year) }).then(() =>
      this.setState({ refetching: false })
    );
  };

  _getInspections = inspections => {
    const { date: calendarDate } = this.state;
    const dates = {};

    inspections.map(inspection => {
      if (
        inspection.__typename === "Inspection" ||
        inspection.__typename === "CorrectiveActionInspection" ||
        inspection.__typename === "AdditionalServiceInspection"
      ) {
        const { scheduledDate: date } = inspection;
        const day = moment(date, "YYYY-MM-DD")
          .utc()
          .format("DD-MM-YYYY");

        if (dates[day]) {
          dates[day] = [...dates[day], inspection];
        } else {
          dates[day] = [inspection];
        }
      } else if (inspection.__typename === "RegularInspection") {
        const { scheduledDate } = inspection;
        const day = moment(new Date(Number(scheduledDate)), "YYYY-MM-DD")
          .utc()
          .format("DD-MM-YYYY");
        if (dates[day]) {
          dates[day] = [...dates[day], inspection];
        } else {
          dates[day] = [inspection];
        }
      }
    });

    return dates;
  };

  _renderAddChecklistModal = () => {
    const { addContractModal } = this.state;

    return (
      <Container width={507}>
        <div
          onClick={() => this._handleOnCloseButton()}
          style={{
            position: "absolute",
            cursor: "pointer",
            right: "120px",
            top: "90px"
          }}
        >
          <CloseIcon />
        </div>

        <div className={styles.modalTitle}>Filtrar Búsqueda</div>

        <Modal.Section
          style={{ margin: "0 50px" }}
          paddingVertical={30}
          paddingHorizontal={50}
        >
          <Layout.Grid>
            <Layout.Column>
              <div style={{ marginBottom: "15px" }}>
                <LabeledInput
                  fullWidth
                  name="activity"
                  label="Actividad"
                  type="select"
                  selectOptions={Object.keys(ACTIVITIES)}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <LabeledInput
                  fullWidth
                  name="locations"
                  label="Ubicación de la Planta"
                  type="select"
                  selectOptions={Object.keys(LOCATIONS)}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <div
                  style={{
                    width: "147px",
                    float: "left",
                    marginRight: "10px",
                    marginBottom: "15px"
                  }}
                >
                  <LabeledInput
                    fullWidth
                    name="year1"
                    label="Año de la visita"
                    type="select"
                    selectOptions={Object.keys(DROPDOWNYEARS)}
                  />
                </div>

                <div
                  style={{
                    width: "147px",
                    float: "left",
                    marginBottom: "15px"
                  }}
                >
                  <LabeledInput
                    fullWidth
                    name="year2"
                    label="(desde y hasta)"
                    type="select"
                    selectOptions={Object.keys(DROPDOWNYEARS)}
                  />
                </div>
              </div>

              <div style={{ marginBottom: "15px" }}>
                <div
                  style={{
                    width: "147px",
                    float: "left",
                    marginRight: "10px",
                    marginBottom: "15px"
                  }}
                >
                  <LabeledInput
                    fullWidth
                    name="month1"
                    label="Mes de la visita"
                    type="select"
                    selectOptions={Object.keys(DROPDOWNMONTHS)}
                  />
                </div>

                <div
                  style={{
                    width: "147px",
                    float: "left",
                    marginBottom: "15px"
                  }}
                >
                  <LabeledInput
                    style={{ borderColor: "red" }}
                    fullWidth
                    name="month2"
                    label="(desde y hasta)"
                    type="select"
                    selectOptions={Object.keys(DROPDOWNMONTHS)}
                  />
                </div>
              </div>

              <div style={{ marginBottom: "15px" }}>
                <LabeledInput
                  fullWidth
                  name="visitDays"
                  label="Días de la visita"
                  type="select"
                  selectOptions={Object.keys(VISITDAYS)}
                />
              </div>
              <div style={{ marginBottom: "15px" }}>
                <LabeledInput
                  fullWidth
                  name="technicians"
                  label="Técnico asignado"
                  type="select"
                  selectOptions={Object.keys(ASIGNEDTECHNICIAN)}
                />
              </div>
            </Layout.Column>
          </Layout.Grid>
        </Modal.Section>

        <div className={styles.modalFooter}>
          <div style={{ display: "inline-block", marginRight: "9px" }}>
            <Button
              style="outline"
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={() => this._handleOnCloseButton()}
            >
              REINICIAR
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={48}
            disabled={addContractModal.addButtonDisabled}
          >
            FILTRAR
          </Button>
        </div>
      </Container>
    );
  };

  _handleOnClickAddButton = () => {
    this.setState(state => ({
      addContractModal: { ...state.addContractModal, visible: true }
    }));
  };

  _handleOnCloseButton = () => {
    this.setState({
      addContractModal: { visible: false },
      addInspectionChecklistModal: { visible: false }
    });
  };

  _handleOnInputChange = e => {
    // Handle input change of the date picker component
    if (e.props) {
      return this.setState(state => ({
        addContractModal: {
          ...state.addContractModal,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      addContractModal: {
        ...state.addContractModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _renderDay = (day, inspections) => {
    const { selectedDay } = this.state;
    const dateFormat = moment(day, "YYYY-MM-DD")
      .utc()
      .format("DD-MM-YYYY");
    const date = day.getUTCDate();
    const dateId = day.toDateString();
    const selectedDateId = selectedDay && selectedDay.toDateString();

    const whiteCell = {
      position: "relative",
      float: "left",
      width: "60px",
      height: "60px",
      display: "flex",
      flexDirection: "column"
    };

    const whiteCellSelected = {
      position: "relative",
      float: "left",
      width: "60px",
      height: "60px",
      display: "flex",
      flexDirection: "column",
      boxShadow: "",
      borderRadius: "100%",
      boxShadow: "0px 0px 0px 6px white, 0px 0px 0px 9px rgba(221, 74, 88, 1)"
    };

    const dateStyle = {
      position: "relative",
      color: "#212b36",
      fontSize: "16px",
      fontWeight: "400",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1
    };

    const infoInspection = {
      float: "left",
      fontSize: "15px",
      color: "#4a4a4a",
      left: "10px",
      top: "-35px",
      fontWeight: "400",
      lineHeight: "14px"
    };

    const inspectionDot = {
      height: "10px",
      width: "10px",
      borderRadius: "100%",
      display: "inline-block",
      margin: "0 auto"
    };

    const renderInfo = info => {
      const facilities = {};
      const facilityColors = {};

      if (info !== undefined) {
        info.map(inspection => {
          facilityColors[inspection.fTec.facility.id] =
            inspection.fTec.facility.color;

          facilities[inspection.fTec.facility.id] = [
            ...(facilities[inspection.fTec.facility.id] || [])
          ];

          facilities[inspection.fTec.facility.id].push(inspection);
        });

        return (
          <>
            <div style={infoInspection} />
            <div style={{ marginTop: -10 }}>
              {Object.keys(facilities).map(facility => (
                <div
                  style={{
                    ...inspectionDot,
                    backgroundColor: facilityColors[facility]
                  }}
                />
              ))}
            </div>
          </>
        );
      }
    };

    return (
      <div style={dateId === selectedDateId ? whiteCellSelected : whiteCell}>
        <div style={dateStyle}>{date}</div>
        {renderInfo(inspections[dateFormat])}
      </div>
    );
  };
}

export default ScheduleTemplate;
