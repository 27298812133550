import React from "react";

const Attach = props => (
  <svg width={27} height={14} {...props}>
    <path
      d="M7.425 14C3.321 14 0 10.863 0 7s3.321-7 7.425-7H21.6C24.583 0 27 2.278 27 5.09c0 2.814-2.416 5.092-5.4 5.092H10.125C8.262 10.182 6.75 8.756 6.75 7s1.512-3.182 3.375-3.182H20.25v1.91H10.125c-.742 0-1.35.566-1.35 1.272 0 .706.607 1.273 1.35 1.273H21.6c1.863 0 3.375-1.426 3.375-3.182S23.463 1.909 21.6 1.909H7.425c-2.984 0-5.4 2.278-5.4 5.091s2.416 5.09 5.4 5.09H20.25V14H7.425z"
      fill={props.color || "#444"}
      fillRule="nonzero"
    />
  </svg>
);

export default Attach;
