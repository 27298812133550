import React from "react";

import { francisco } from "../../../../assets/images/signatures";

const Signature = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="101"
    height="77"
    viewBox="0 0 101 77"
  >
    <image width="101" height="77" xlinkHref={francisco} />
  </svg>
);

export default Signature;
