import React, { Component } from "react";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { getClientsBusinessNames } from "../../../gql/queries";
import { AvatarBubble, LabeledSelect, LabeledInput } from "../../atoms";

class ClientSelector extends Component {
  state = {
    selectedClient: undefined,
    selectedBusinessName: undefined,
    selectedZone: undefined,
    facilityName: undefined
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onBusinessNameSelect: PropTypes.func.isRequired,
    onFacilityNameChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    facilityName: PropTypes.string.isRequired
  };

  componentDidMount() {
    const { selected, facility, facilityName, onDataLoaded } = this.props;

    if (facilityName) {
      this.setState({
        facilityName
      });
    }

    if (selected && selected.length === 3) {
      const facilityManagers =
        facility &&
        facility.businessName.client.facilityManagers
          .map(manager => manager)
          .flat();

      const suggestions = [];

      facilityManagers &&
        facilityManagers.map(manager => {
          suggestions.push({
            id: manager.id,
            name: manager.user.emailAddress
          });
        });

      onDataLoaded(suggestions);

      this.setState({
        selectedClient: selected[0],
        selectedBusinessName: selected[1],
        selectedZone: selected[2]
      });
    }
  }

  render() {
    const {
      selectedClient,
      selectedBusinessName,
      selectedZone,
      facilityName
    } = this.state;

    return (
      <Query query={getClientsBusinessNames}>
        {({ loading, error, data }) => {
          if (loading) {
            return <div>loading</div>;
          }

          if (error) {
            return <div>error</div>;
          }

          if (data) {
            // Filter out necessary data
            // TODO: Move to class methods
            const clients = data.clients.map(client => ({
              id: client.id,
              name: client.name,
              avatarUrl: client.avatarUrl
            }));

            const clientNames = clients.map(client => ({
              id: client.name,
              name: client.name
            }));

            const client =
              selectedClient &&
              data.clients.find(
                client => client.name.trim() === selectedClient.trim()
              );

            const clientBusinessNames = client && client.businessNames;

            return (
              <>
                <div className={styles.avatarBubbleWrapper}>
                  <AvatarBubble url={client && client.avatarUrl} />
                </div>
                <div className={styles.selectWrapper}>
                  <LabeledSelect
                    label="Seleccionar Cliente"
                    keyedOptions={clientNames}
                    defaultValue={selectedClient && selectedClient}
                    onSelect={e =>
                      this._handleOnSelectClient(data, e.currentTarget.value)
                    }
                  />
                </div>
                <div className={styles.selectWrapper}>
                  <LabeledSelect
                    label="Razón Social"
                    keyedOptions={clientBusinessNames}
                    defaultValue={selectedBusinessName && selectedBusinessName}
                    onSelect={e => this._handleOnSelectBusinessName(e)}
                    disabled={!selectedClient}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <LabeledInput
                    fullWidth
                    label="Nombre de la planta"
                    defaultValue={facilityName && facilityName}
                    onChange={e => this._handleOnFacilityNameChange(e)}
                  />
                </div>
                <div className={styles.selectWrapper}>
                  <LabeledSelect
                    label="Zona"
                    options={["NORTE", "CENTRO", "BAJIO"]}
                    defaultValue={selectedZone && selectedZone}
                    onSelect={e =>
                      this._handleOnSelectZone(e.currentTarget.value)
                    }
                  />
                </div>
              </>
            );
          }
        }}
      </Query>
    );
  }

  _handleOnSelectClient = (data, value) => {
    const { onChange, onDataLoaded } = this.props;

    const client = data.clients.filter(client => client.name === value);
    const facilityManagers = client.map(c => c.facilityManagers).flat();
    const formattedFacilityManagers = [];

    facilityManagers.map(manager => {
      formattedFacilityManagers.push({
        id: manager.id,
        name: manager.user.emailAddress
      });
    });

    onDataLoaded(formattedFacilityManagers);

    this.setState(
      {
        selectedClient: value,
        selectedBusinessName: undefined
      },
      onChange("selectedClient", value)
    );
  };

  _handleOnSelectBusinessName = e => {
    const { onBusinessNameSelect } = this.props;

    const fragments = e.currentTarget.value.split(":");
    const businessNameId = fragments[0];

    this.setState(
      {
        selectedBusinessName: businessNameId
      },
      onBusinessNameSelect(businessNameId)
    );
  };

  _handleOnFacilityNameChange = e => {
    const { onChange } = this.props;
    const { value } = e.currentTarget;

    this.setState(
      {
        facilityName: value
      },
      onChange("facilityName", value)
    );
  };

  _handleOnSelectZone = value => {
    const { onChange } = this.props;

    this.setState(
      {
        selectedZone: value
      },
      onChange("selectedZone", value)
    );
  };
}

export default ClientSelector;
