import React, { Component } from "react";
import PropTypes from "prop-types";
import fuzzy from "fuzzy";
import uuidv4 from "uuid/v4";
import mixpanel from "mixpanel-browser";

import { createClient, updateClient, deleteClient } from "../../gql/mutations";
import {
  AvatarBubble,
  Button,
  Container,
  Heading,
  EditIcon,
  DeleteIcon,
  Modal,
  Table
} from "../atoms";
import { TableContainerHeader, ModalPortal, Spinner } from "../molecules";
import {
  AddClientModal,
  DeleteClientModal,
  EditClientModal,
  ViewClientModal
} from "../organisms";
import { bugsnagClient } from "../../bugsnag";

// WARN: Let's be careful with this, as we're using it to reset
// state whenever we close a modal using this const.
// This could cause unintended effects if we have state that doesn't belong
// to the modals.
const initialState = {
  filter: undefined,
  addClientModal: {
    managerAccounts: {},
    managerAccountErrors: {},
    businessNameInputs: {
      0: ""
    },
    addClientModalVisible: false,
    addButtonDisabled: true
  },
  deleteClientModal: {
    deleteClientModalVisible: false,
    deleteButtonDisabled: false
  },
  editClientModal: {
    businessNameInputs: [
      {
        0: ""
      }
    ],
    visible: false,
    saveButtonDisabled: false
  },
  viewClientModal: {
    businessNameInputs: [
      {
        0: ""
      }
    ],
    visible: false
  }
};

class ClientsTemplate extends Component {
  state = initialState;

  static propTypes = {
    loadingClients: PropTypes.bool.isRequired,
    // TODO: Define object shape
    clients: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    loadingClients: true
  };

  componentDidUpdate(_, prevState) {
    const { addClientModal: prevAddClientModal } = prevState;
    const { addClientModal: currAddClientModal } = this.state;

    const requiredFields = [
      "clientNameInput",
      "selectedState",
      "clientCityInput",
      "clientAddressInput"
    ];

    const prevFields = requiredFields.map(rf => prevAddClientModal[rf]);
    const currFields = requiredFields.map(rf => currAddClientModal[rf]);

    const prevUnfilledFields = prevFields.filter(f => !f).length;
    const currUnfilledFields = currFields.filter(f => !f).length;
    if (prevUnfilledFields > 0 && currUnfilledFields === 0) {
      this.setState(state => ({
        addClientModal: {
          ...state.addClientModal,
          addButtonDisabled: false
        }
      }));
    } else if (currUnfilledFields > 0 && prevUnfilledFields === 0) {
      this.setState(state => ({
        addClientModal: {
          ...state.addClientModal,
          addButtonDisabled: true
        }
      }));
    }
  }

  render() {
    const {
      filter,
      addClientModal: { addClientModalVisible },
      deleteClientModal: { deleteClientModalVisible },
      editClientModal,
      viewClientModal
    } = this.state;

    const { loadingClients, clients } = this.props;

    const filteredClients =
      clients &&
      fuzzy.filter(filter, clients, {
        extract: el => el.name
      });

    const data = filter
      ? filteredClients && filteredClients.map(result => result.original)
      : clients;

    return (
      <>
        <ModalPortal
          modal={this._renderAddClientModal}
          visible={addClientModalVisible}
          onBackdropClick={this._handleOnClickCloseButton}
        />
        <ModalPortal
          modal={this._renderDeleteClientModal}
          visible={deleteClientModalVisible}
          onBackdropClick={this._handleOnBackButton}
        />
        <ModalPortal
          modal={this._renderEditClientModal}
          visible={editClientModal.visible}
          onBackdropClick={this._handleOnClickCloseButton}
        />
        <ModalPortal
          modal={this._renderViewClientModal}
          visible={viewClientModal.visible}
          onBackdropClick={this._handleOnClickCloseButton}
        />
        <Heading>Clientes</Heading>
        <Container noPadding>
          <TableContainerHeader
            placeHolder="Buscar por nombre de cliente"
            onInputChange={e => this._filterClients(e)}
            onClickAddButton={this._handleOnClickAddButton}
          />
          {loadingClients && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
          {clients && (
            <Table.Main
              columns={[
                null,
                { name: "Cliente" },
                { "businessNames.length": "Razones sociales" },
                null
              ]}
              data={data}
              row={(client, lastClientRow) =>
                this._renderClientRow(client, lastClientRow)
              }
            />
          )}
        </Container>
      </>
    );
  }

  _renderAddClientModal = () => {
    const {
      addButtonDisabled,
      selectedState,
      managerAccounts,
      managerAccountErrors,
      businessNameInputs,
      clientAddressInput,
      clientCityInput,
      clientEmailInput,
      clientNameInput,
      clientPasswordConfirmInput,
      clientPasswordInput
    } = this.state.addClientModal;

    const requiredFields = {
      clientAddressInput,
      clientCityInput,
      clientEmailInput,
      clientNameInput,
      clientPasswordConfirmInput,
      clientPasswordInput,
      selectedState
    };

    const validRequiredFields = Object.values(requiredFields).every(
      field => field
    );

    const businessNames = Object.values(businessNameInputs).filter(
      e => e != undefined
    );

    const validBusinessNameInputs = businessNames.every(e => e);

    return (
      <AddClientModal
        addButtonDisabled={
          !(
            validRequiredFields &&
            validBusinessNameInputs &&
            !addButtonDisabled
          )
        }
        selectedState={selectedState}
        managerAccounts={managerAccounts}
        managerAccountErrors={managerAccountErrors}
        businessNameInputs={businessNameInputs}
        onClickCloseButton={this._handleOnClickCloseButton}
        onManagerAccountInputChange={this._handleOnManagerAccountInputChange}
        onInputChange={this._handleOnInputChange}
        onManagerAccountAddButton={this._handleOnManagerAccountAddButton}
        onManagerAccountRemoveButton={this._handleOnManagerAccountRemoveButton}
        onStateInputChange={this._handleOnStateInputChange}
        onBusinessNameInputChange={this._handleOnBusinessNameInputChange}
        onBusinessNameInputRemove={this._handleOnBusinessNameInputRemove}
        onBusinessNameAddButton={this._handleOnBusinessNameAddButton}
        onCreateClientButton={this._handleOnCreateClientButton}
      />
    );
  };

  _renderDeleteClientModal = () => {
    const {
      deleteClientModal: { deleteButtonDisabled, deleteClientId }
    } = this.state;

    const { clients } = this.props;
    const client = clients.find(client => client.userId === deleteClientId);

    return (
      <DeleteClientModal
        deleteButtonDisabled={deleteButtonDisabled}
        deleteClientId={deleteClientId}
        client={client}
        onBackButton={this._handleOnBackButton}
        onDeleteClientButton={this._handleOnDeleteClientButton}
      />
    );
  };

  _renderEditClientModal = () => {
    const {
      saveButtonDisabled: saveButtonDisabledFromState,
      businessNameInputs,
      editClientId,

      clientAddressInput,
      clientCityInput,
      clientNameInput,
      clientStateInput
    } = this.state.editClientModal;
    const { clients } = this.props;
    const client = clients.find(client => client.id === editClientId);
    const businessNames = businessNameInputs.filter(input => {
      const businessNameId = Object.keys(input)[0];
      return input[businessNameId] !== undefined;
    });

    const inputsToValidate = [
      clientAddressInput,
      clientCityInput,
      clientNameInput,
      clientStateInput
    ];

    const validBusinessNameInputs = businessNames.every(businessName => {
      const businessNameId = Object.keys(businessName)[0];
      return !!businessName[businessNameId];
    });

    const validInputs = inputsToValidate.every(e => e);
    const saveButtonDisabled =
      !validBusinessNameInputs || !validInputs || saveButtonDisabledFromState;

    !localStorage.getItem("file_editarlogo") &&
      localStorage.setItem("file_editarlogo", client.avatarUrl);

    return (
      <EditClientModal
        client={client}
        editClientId={editClientId}
        userId={client.userId}
        saveButtonDisabled={saveButtonDisabled}
        selectedCity={clientCityInput}
        selectedState={clientStateInput}
        businessNameInputs={businessNameInputs}
        onClickCloseButton={this._handleOnClickCloseButton}
        onInputChange={this._handleOnEditInputChange}
        onStateInputChange={this._handleOnEditStateInputChange}
        onBusinessNameInputChange={this._handleOnEditBusinessNameInputChange}
        onBusinessNameInputRemove={this._handleOnEditBusinessNameInputRemove}
        onBusinessNameAddButton={this._handleOnEditBusinessNameAddButton}
        onEditClientButton={this._handleOnEditClientButton}
      />
    );
  };

  _renderViewClientModal = () => {
    const { businessNameInputs, viewClientId } = this.state.viewClientModal;
    const { clients } = this.props;
    const client = clients.find(client => client.id === viewClientId);

    return (
      <ViewClientModal
        client={client}
        viewClientId={viewClientId}
        businessNameInputs={businessNameInputs}
        onClickCloseButton={this._handleOnClickCloseButton}
      />
    );
  };

  _renderClientRow = (client, lastClientRow) => {
    const businessNamesCount = client.businessNames
      ? client.businessNames.length
      : 0;

    return (
      <tr
        testid="clients-table-row"
        key={client.id}
        style={{ borderBottom: !lastClientRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble url={client.avatarUrl && client.avatarUrl} />
          </div>
        </Table.Cell>
        <Table.Cell>{client.name}</Table.Cell>
        <Table.Cell>{businessNamesCount}</Table.Cell>
        <Table.Cell style={{ textAlign: "right" }}>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={16}
              style="outline"
              onClick={() => this._handleOnPressViewClientButton(client.id)}
            >
              Ver cliente
            </Button>
          </div>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
              onClick={() =>
                this._handleOnPressDeleteClientButton(client.userId)
              }
            >
              <DeleteIcon />
            </Button>
          </div>
          <Button
            radius={7}
            paddingVertical={8}
            paddingHorizontal={8}
            style="silver"
            iconOnly
            onClick={() => this._handleOnPressEditClientButton(client.id)}
          >
            <EditIcon />
          </Button>
        </Table.Cell>
      </tr>
    );
  };

  _handleOnBackButton = () => {
    this.setState(state => ({
      deleteClientModal: {
        ...state.deleteClientModal,
        deleteClientModalVisible: false
      }
    }));
  };

  _handleOnPressDeleteClientButton = userId => {
    this.setState(state => ({
      deleteClientModal: {
        ...state.deleteClientModal,
        deleteClientModalVisible: true,
        deleteClientId: userId
      }
    }));
  };

  _filterClients = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnClickAddButton = () => {
    this.setState(state => ({
      addClientModal: { ...state.addClientModal, addClientModalVisible: true }
    }));
  };

  _handleOnClickCloseButton = () => {
    localStorage.removeItem("file_editarlogo");
    this.setState(initialState);
  };

  _handleOnManagerAccountInputChange = (event, idx) => {
    event.persist();
    this.setState(state => ({
      addClientModal: {
        ...state.addClientModal,
        managerAccounts: {
          ...state.addClientModal.managerAccounts,
          [idx]: {
            ...state.addClientModal.managerAccounts[idx],
            [event.target.name]: event.target.value
          }
        }
      }
    }));
  };

  _handleOnInputChange = event => {
    this.setState({
      addClientModal: {
        ...this.state.addClientModal,
        [event.target.name]: event.target.value
      }
    });
  };

  _handleOnEditInputChange = event => {
    this.setState({
      editClientModal: {
        ...this.state.editClientModal,
        [event.target.name]: event.target.value
      }
    });
  };

  _handleOnStateInputChange = event => {
    const selectedState = event.target.value;
    this.setState((state, _) => ({
      addClientModal: {
        ...state.addClientModal,
        selectedState
      }
    }));
  };
  _handleOnEditStateInputChange = event => {
    const selectedState = event.target.value;
    this.setState((state, _) => ({
      editClientModal: {
        ...state.editClientModal,
        clientStateInput: selectedState,
        clientCityInput: undefined
      }
    }));
  };

  _handleOnManagerAccountAddButton = () => {
    const { managerAccounts } = this.state.addClientModal;

    if (Object.keys(managerAccounts).length === 0) {
      this.setState(state => ({
        addClientModal: {
          ...state.addClientModal,
          managerAccounts: {
            [uuidv4()]: {
              emailInput: undefined,
              passwordInput: undefined,
              passwordConfirmInput: undefined
            }
          }
        }
      }));
    } else {
      this.setState(state => ({
        addClientModal: {
          ...state.addClientModal,
          managerAccounts: {
            ...state.addClientModal.managerAccounts,
            [uuidv4()]: {
              emailInput: undefined,
              passwordInput: undefined,
              passwordConfirmInput: undefined
            }
          }
        }
      }));
    }
  };

  _handleOnManagerAccountRemoveButton = accountIdx => {
    const { managerAccounts } = this.state.addClientModal;

    this.setState(state => ({
      addClientModal: {
        ...state.addClientModal,
        managerAccounts: {
          ...state.addClientModal.managerAccounts,
          [accountIdx]: undefined
        }
      }
    }));
  };

  _handleOnBusinessNameAddButton = () => {
    let isValid = false;
    const { businessNameInputs } = this.state.addClientModal;

    Object.keys(businessNameInputs).map(input => {
      if (businessNameInputs[input] !== "") {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      this.setState(state => ({
        addClientModal: {
          ...state.addClientModal,
          businessNameInputs: {
            ...state.addClientModal.businessNameInputs,
            [Object.keys(businessNameInputs).length + 1]: ""
          }
        }
      }));
    } else {
      alert("Escribe el nombre de la razón social");
    }
  };

  _handleOnEditBusinessNameAddButton = () => {
    let isValid = false;
    const { businessNameInputs } = this.state.editClientModal;

    businessNameInputs.map(input => {
      if (Object.values(input)[0] !== "") {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      this.setState(state => ({
        editClientModal: {
          ...state.editClientModal,
          businessNameInputs: [
            ...state.editClientModal.businessNameInputs,
            { [uuidv4()]: "" }
          ]
        }
      }));
    } else {
      alert("Escribe el nombre de la razón social");
    }
  };

  _handleOnBusinessNameInputChange = event => {
    event.persist();

    const { name, value } = event.nativeEvent.target;
    this.setState(state => ({
      addClientModal: {
        ...state.addClientModal,
        businessNameInputs: {
          ...state.addClientModal.businessNameInputs,
          [parseInt(name)]: value
        }
      }
    }));
  };

  _handleOnEditBusinessNameInputChange = event => {
    const { businessNameInputs } = this.state.editClientModal;

    event.persist();
    const { name, value } = event.nativeEvent.target;
    const inputId = parseInt(name);

    const newBusinessNameInputs = [...businessNameInputs];
    newBusinessNameInputs[inputId] = {
      [Object.keys(newBusinessNameInputs[inputId])[0]]: value
    };

    this.setState(state => ({
      editClientModal: {
        ...state.editClientModal,
        businessNameInputs: newBusinessNameInputs
      }
    }));
  };

  _handleOnBusinessNameInputRemove = inputName => {
    this.setState(state => ({
      addClientModal: {
        ...state.addClientModal,
        businessNameInputs: {
          ...state.addClientModal.businessNameInputs,
          [parseInt(inputName)]: undefined
        }
      }
    }));
  };

  _handleOnEditBusinessNameInputRemove = inputName => {
    const { businessNameInputs } = this.state.editClientModal;
    const newBusinessNameInputs = businessNameInputs.filter(
      (_, idx) => idx !== parseInt(inputName)
    );

    this.setState(state => ({
      editClientModal: {
        ...state.editClientModal,
        businessNameInputs: newBusinessNameInputs
      }
    }));
  };

  _handleOnDeleteClientButton = userId => {
    if (!!userId) {
      this.setState(
        (state, _) => ({
          deleteClientModal: {
            ...state.deleteClientModal,
            deleteButtonDisabled: true
          }
        }),
        () =>
          deleteClient(userId)
            .then(() => {
              window.location = "/clientes";
            })
            .catch(err => {
              bugsnagClient.notify(err);
              this.setState(state => ({
                deleteClientModal: {
                  deleteClientModal: false
                }
              }));
            })
      );
    }
  };

  _handleOnCreateClientButton = () => {
    const {
      addClientModal: {
        managerAccounts,
        clientNameInput,
        selectedState,
        clientCityInput,
        clientAddressInput,
        businessNameInputs,
        clientEmailInput,
        clientPasswordConfirmInput,
        clientPasswordInput
      }
    } = this.state;

    const businessNameValues = Object.values(businessNameInputs);
    const businessNames = businessNameValues.filter(e => e !== undefined);
    const hasEmptyBusinessNames = businessNames.find(e => e === "") === "";

    if (clientPasswordInput !== clientPasswordConfirmInput) {
      return alert("Las contraseñas no coinciden");
    }

    if (clientPasswordInput.length < 5) {
      return alert("La contraseña debe de tener al menos 6 caracteres");
    }

    const avatarUrlFromLocalStorage = localStorage.getItem("file_adjuntarlogo");

    const managerAccountErrors = {};
    Object.keys(managerAccounts).map(accountIdx => {
      const account = managerAccounts[accountIdx];

      if (account) {
        if (
          !account.managerEmailInput ||
          !account.managerPasswordInput ||
          !account.managerPasswordConfirmInput
        ) {
          managerAccountErrors[accountIdx] = {
            error: "Rellena todos los campos"
          };
        } else if (
          account.managerPasswordInput !== account.managerPasswordConfirmInput
        ) {
          managerAccountErrors[accountIdx] = {
            error: "Las contraseñas no coinciden"
          };
        } else if (account.managerPasswordInput.length < 5) {
          managerAccountErrors[accountIdx] = {
            error: "La contraseña debe de tener al menos 6 caracteres"
          };
        }
      }
    });

    if (Object.keys(managerAccountErrors).length > 0) {
      return this.setState(state => ({
        addClientModal: {
          ...state.addClientModal,
          managerAccountErrors
        }
      }));
    }

    if (
      !!clientNameInput &&
      !!selectedState &&
      !!clientCityInput &&
      !!clientAddressInput &&
      !hasEmptyBusinessNames
    ) {
      this.setState(
        (state, _) => ({
          addClientModal: {
            ...state.addClientModal,
            addButtonDisabled: true
          }
        }),
        () =>
          createClient(
            clientNameInput,
            selectedState,
            clientCityInput,
            clientAddressInput,
            businessNames,
            avatarUrlFromLocalStorage,
            clientEmailInput,
            clientPasswordInput,
            JSON.stringify(managerAccounts)
          )
            .then(() => {
              localStorage.removeItem("file_adjuntarlogo");
              window.location = "/clientes";
              mixpanel.track("Nuevo cliente creado");
            })
            .catch(err => {
              console.log(err);
              if (err.message.indexOf("not_unique: emailAddress") > -1) {
                const matches = err.message.match(/\[(.*?)\]/);
                alert(`El correo ${matches[1]} ya existe`);
              }

              this.setState(state => ({
                addClientModal: {
                  ...state.addClientModal,
                  addButtonDisabled: false
                }
              }));
            })
      );
    } else {
      alert("Missing data");
    }
  };

  _handleOnEditClientButton = () => {
    const {
      editClientModal: {
        editClientId,
        userId,
        clientAvatarUrl,
        clientNameInput,
        clientStateInput,
        clientCityInput,
        clientAddressInput,
        businessNameInputs,
        emailAddressInput
      }
    } = this.state;

    const avatarUrl = localStorage.getItem("file_editarlogo");

    this.setState(
      (state, _) => ({
        editClientModal: {
          ...state.editClientModal,
          saveButtonDisabled: true
        }
      }),
      () =>
        updateClient(
          editClientId,
          userId,
          avatarUrl,
          clientNameInput,
          clientStateInput,
          clientCityInput,
          clientAddressInput,
          emailAddressInput,
          JSON.stringify(businessNameInputs)
        )
          .then(() => {
            localStorage.removeItem("file_editarlogo");
            window.location = "/clientes";
          })
          .catch(err => {
            // TODO: Report to bugsnag
            console.log(err);
            this.setState(state => ({
              editClientModal: {
                ...state.editClientModal,
                saveButtonDisabled: false
              }
            }));
          })
    );
  };

  _handleOnPressEditClientButton = clientId => {
    const { clients } = this.props;
    const client = clients.find(client => client.id === clientId);

    const businessNameInputs = [];
    client.businessNames.map(businessName =>
      businessNameInputs.push({ [businessName.id]: businessName.name })
    );

    this.setState(state => ({
      editClientModal: {
        ...state.editClientModal,
        visible: true,
        editClientId: clientId,
        userId: client.userId,
        clientNameInput: client.name,
        avatarUrl: client.avatarUrl,
        clientStateInput: client.state,
        clientCityInput: client.city,
        clientAddressInput: client.address,
        businessNameInputs
      }
    }));
  };

  _handleOnPressViewClientButton = clientId => {
    const { clients } = this.props;
    const client = clients.find(client => client.id === clientId);

    const businessNameInputs = [];
    client.businessNames.map(businessName =>
      businessNameInputs.push({ [businessName.id]: businessName.name })
    );

    this.setState(state => ({
      viewClientModal: {
        ...state.viewClientModal,
        visible: true,
        viewClientId: clientId,
        businessNameInputs
      }
    }));
  };
}

export default ClientsTemplate;
