import { gql } from "apollo-boost";

const getInspectionsByProject = gql`
  query inspectionsByProject(
    $month: String!
    $year: String!
    $facilityId: ID!
  ) {
    facility(id: $facilityId) {
      name
    }
    regularInspectionsByProject(
      month: $month
      year: $year
      facilityId: $facilityId
    ) {
      __typename
      id
      scheduledDate
      completionDate
      reportId
      inspectionChecklist {
        id
        type
        checklistFormat
        quantity
        recurrence
        repeatOn
      }
      inspectionReschedule {
        id
        requestedDate
        note
        status
        assignedDate
        createdAt
        updatedAt
      }
    }
    inspectionsByProject(facilityId: $facilityId) {
      __typename
      ... on Inspection {
        id
        scheduledDate
        completionDate
        reportId
        inspectionChecklist {
          id
          type
          checklistFormat
          quantity
          recurrence
          repeatOn
        }
        inspectionReschedule {
          id
          requestedDate
          note
          status
          assignedDate
          createdAt
          updatedAt
        }
      }
      ... on CorrectiveActionInspection {
        id
        scheduledDate
        completionDate
        reportId
        correctiveAction {
          id
          title
          details
        }
        inspectionReschedule {
          id
          requestedDate
          note
          status
          assignedDate
          createdAt
          updatedAt
        }
      }
      ... on AdditionalServiceInspection {
        id
        scheduledDate
        completionDate
        reportId
        additionalService {
          id
          title
          details
        }
        inspectionReschedule {
          id
          requestedDate
          note
          status
          assignedDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export default getInspectionsByProject;
