import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import styles from "./app.scss";

import * as Admin from "./views/admin";
import { ProtectedApp } from "./wrappers";
import {
  HomeIcon,
  ClientsIcon,
  EngineerIcon,
  EmailIcon,
  CalendarIcon,
  FacilityIcon,
  ReportIcon,
  ContactIcon
} from "./components/atoms";
import { Sidebar, Topbar } from "./components/organisms";
import { getUnreadNotificationsForAdmin } from "./gql/queries";

class AdminApp extends Component {
  render() {
    const { user } = this.props;

    return (
      <ProtectedApp>
        <Topbar
          notificationsFor={user.uid}
          notificationsQuery={getUnreadNotificationsForAdmin}
          navigation={[
            {
              basePath: "/contratos",
              links: [
                {
                  linkTo: "/contratos",
                  label: "Contratos"
                },
                {
                  linkTo: "/contratos/servicios-adicionales",
                  label: "Servicios adicionales"
                },
                {
                  linkTo: "/contratos/acciones-correctivas",
                  label: "Acciones correctivas"
                }
              ]
            },
            {
              basePath: "/agenda",
              links: [
                {
                  linkTo: "/agenda",
                  label: "Agenda"
                },
                {
                  linkTo: "/agenda/inspecciones",
                  label: "Por Realizar"
                },
                {
                  linkTo: "/agenda/realizadas",
                  label: "Realizadas"
                },
                {
                  linkTo: "/agenda/retrasadas",
                  label: "Retrasadas"
                },
                {
                  linkTo: "/agenda/reagendadas",
                  label: "Reagendadas"
                }
              ]
            }
          ]}
          userType="admin"
        />
        <Sidebar
          buttons={[
            { icon: () => <HomeIcon />, linkTo: "/", exact: true },
            { icon: () => <ClientsIcon />, linkTo: "/clientes" },
            { icon: () => <FacilityIcon />, linkTo: "/plantas" },
            { icon: () => <EngineerIcon />, linkTo: "/tecnicos" },
            { icon: () => <EmailIcon />, linkTo: "/contratos" },
            { icon: () => <CalendarIcon />, linkTo: "/agenda" },
            { icon: () => <ReportIcon />, linkTo: "/reportes" },
            { icon: () => <ContactIcon />, linkTo: "/usuarios" }
          ]}
        />
        <div className={styles.container}>
          <Switch>
            <Route exact path="/" component={() => <Admin.DashboardView />} />
            <Route path="/clientes" component={() => <Admin.ClientsView />} />
            <Route
              path="/tecnicos"
              component={() => <Admin.TechniciansView />}
            />
            <Route
              path="/contratos/servicios-adicionales"
              component={() => <Admin.AdditionalServicesView />}
            />
            <Route
              path="/contratos/acciones-correctivas/:caid"
              component={routeProps => (
                <Admin.CorrectiveActionsView
                  CorrectiveActionId={routeProps.match.params.caid}
                />
              )}
            />
            <Route
              path="/contratos/acciones-correctivas"
              component={() => <Admin.CorrectiveActionsView />}
            />
            <Route
              path="/contratos"
              component={() => <Admin.ContractsView />}
            />
            <Route path="/plantas" component={() => <Admin.FacilitiesView />} />
            <Route
              exact
              path="/agenda/inspecciones"
              component={() => <Admin.InspectionsView />}
            />
            <Route
              exact
              path="/agenda"
              component={() => <Admin.ScheduleView />}
            />
            <Route
              path="/agenda/realizadas"
              component={() => <Admin.DoneInspectionsView />}
            />
            <Route
              path="/agenda/retrasadas"
              component={() => <Admin.DelayedInspectionsView />}
            />
            <Route
              path="/agenda/reagendadas/:rid"
              component={routeProps => (
                <Admin.RescheduledInspectionsView
                  inspectionRescheduleId={routeProps.match.params.rid}
                />
              )}
            />
            <Route
              path="/agenda/reagendadas"
              component={() => <Admin.RescheduledInspectionsView />}
            />
            <Route
              path="/reportes/:rid"
              component={routeProps => (
                <Admin.ReportsView reportId={routeProps.match.params.rid} />
              )}
            />
            <Route path="/reportes" component={() => <Admin.ReportsView />} />
            <Route
              path="/notificaciones"
              component={() => (
                <Admin.NotificationsView userId={user.uid} forAdmin />
              )}
            />
            <Route path="/usuarios" component={() => <Admin.UsersView />} />
          </Switch>
        </div>
      </ProtectedApp>
    );
  }
}

export default AdminApp;
