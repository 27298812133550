import { gql } from "apollo-boost";
import { client } from "../apollo";

const editTechnicianFacilities = async (technicianId, facilityIds) =>
  await client.mutate({
    mutation: gql`
      mutation {
        editTechnicianFacilities(
          technicianId: "${technicianId}"
          facilityIds: ${JSON.stringify(facilityIds)}
        ) {
            id
        }
      }
  `
  });

export default editTechnicianFacilities;
