import React from "react";

const MiniSpinner = props => (
  <svg width={18} height={18} {...props}>
    <path
      d="M4 9h2a3 3 0 0 0 6 0h2A5 5 0 0 1 4 9z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);

export default MiniSpinner;
