import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { NonSelectable } from "../../atoms";

// TODO: Move most of the bar chart logic to a
// BarChart component (molecule?)
const ProductivityChart = ({ height, title, data }) => {
  const highestValue = Math.max(...data.map(d => d.value));

  return (
    <div className={styles.chartHolder}>
      <h2 className={styles.chartTitle}>{title}</h2>
      <NonSelectable
        style={{
          paddingRight: 30,
          height: "calc(100% - 40px)",
          width: 452,
          maxHeight: height,
          minHeight: height,
          overflowY: "hidden",
          overflowX: "scroll",
          display: "flex"
        }}
      >
        {data.map((datum, i) => {
          const barHeight = (100 / highestValue) * datum.value;

          return (
            <div key={i} className={styles.barHolder}>
              <div className={styles.barTrack}>
                <div className={styles.barAndValue}>
                  <div className={styles.point} />
                  <div
                    style={{ height: `calc(${barHeight}% - 20px)` }}
                    className={styles.chartBar}
                  />
                </div>
              </div>
              <div className={styles.barLabel}>{datum.label}</div>
            </div>
          );
        })}
      </NonSelectable>
    </div>
  );
};

ProductivityChart.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired
};

export default ProductivityChart;
