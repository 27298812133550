import React from "react";

const Clients = props => (
  <svg width={20} height={22} {...props}>
    <defs>
      <path
        id="Combined_Shape_svg__a"
        d="M43.36 233.58a5.58 5.58 0 1 1 11.16 0 5.58 5.58 0 0 1-11.16 0zM39 245.66c0-2.397 4.441-4.34 9.92-4.34s9.92 1.943 9.92 4.34-4.441 4.34-9.92 4.34-9.92-1.943-9.92-4.34z"
      />
    </defs>
    <use
      fill="#cacfd9"
      xlinkHref="#Combined_Shape_svg__a"
      transform="translate(-39 -228)"
    />
  </svg>
);

export default Clients;
