import React from "react";

const Spinner = props => (
  <svg width={60} height={60} {...props}>
    <path
      d="M0 30h5c0 13.807 11.193 25 25 25s25-11.193 25-25h5c0 16.569-13.431 30-30 30C13.431 60 0 46.569 0 30z"
      fill="#DD4A58"
      fillRule="evenodd"
    />
  </svg>
);

export default Spinner;
