import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";

// Local imports
import { getReportsByProject } from "../../gql/queries";
import { ReportsListTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class ReportsView extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;

    return (
      <Query
        query={getReportsByProject}
        variables={{ facilityId: match.params.fid }}
      >
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div>Error</div>;
          }

          if (data) {
            return (
              <ReportsListTemplate
                loadingReports={loading}
                reports={data && data.inspectionReportsByFacility}
                reportId={match.params.rid}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default ReportsView;
