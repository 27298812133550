import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { LabeledInput, RemoveButton } from "../../atoms";

const RemovableInput = ({ onRemove, ...props }) => {
  return (
    <div className={styles.removableInputContainer}>
      <LabeledInput {...props} />
      <div className={styles.removeButtonContainer}>
        <RemoveButton onClick={() => onRemove(props.name)} />
      </div>
    </div>
  );
};

RemovableInput.propTypes = {
  onRemove: PropTypes.func.isRequired
};

export default RemovableInput;
