import React from "react";

const View = props => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24.000000pt"
    height="16.000000pt"
    viewBox="0 0 24.000000 16.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <metadata>
      Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g
      transform="translate(0.000000,16.000000) scale(0.100000,-0.100000)"
      fill="#dd4a58"
      stroke="none"
    >
      <path
        d="M85 131 c-11 -5 -28 -18 -38 -29 -17 -19 -17 -20 13 -46 40 -33 80
  -33 120 0 34 29 30 43 -21 70 -31 16 -45 17 -74 5z m79 -22 c14 -11 26 -24 26
  -28 0 -4 -16 -16 -35 -27 -34 -18 -36 -18 -70 0 -19 11 -35 23 -35 27 0 13 52
  49 70 49 10 0 29 -9 44 -21z"
      />
      <path
        d="M96 104 c-20 -19 -5 -54 24 -54 29 0 44 35 24 54 -19 20 -29 20 -48
  0z m41 -10 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z"
      />
    </g>
  </svg>
);

export default View;
