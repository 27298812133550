import { gql } from "apollo-boost";

const getAdditionalServicesByProject = gql`
  query getAdditionalServicesByProject($facilityId: ID!) {
    additionalServicesByProject(facilityId: $facilityId) {
      id
      facility {
        id
        name
        zone
        businessName {
          id
          name
          client {
            id
            name
            avatarUrl
          }
        }
      }
      title
      details
      purchaseOrder
      jobFinished
      invoiced
      budget
    }
  }
`;

export default getAdditionalServicesByProject;
