import React from "react";

const ImageUpload = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <path
        id="Duotone_svg__a"
        d="M700.644 393.253l-4.442-5.349-6.232 8h24.93l-8.023-10.674z"
      />
      <path
        id="Duotone_svg__b"
        d="M716.032 370.966h-6.799V368.7H686.57v31.729h31.729v-22.664h-2.267zm-1.133 24.94h-24.93l6.233-8.008 4.442 5.354 6.232-8.03z"
      />
      <path
        id="Duotone_svg__c"
        d="M722.832 364.169v-6.799h-4.533v6.799H711.5v4.533h6.799v6.776h4.533v-6.776h6.799v-4.533z"
      />
      <path
        id="Duotone_svg__d"
        d="M718.292 400.432h-31.73v-31.73h22.664v-4.532h-22.663a4.546 4.546 0 0 0-4.533 4.533v31.729a4.546 4.546 0 0 0 4.533 4.532h31.729a4.546 4.546 0 0 0 4.532-4.532v-22.664h-4.532z"
      />
    </defs>
    <use
      fill="#dd4a58"
      xlinkHref="#Duotone_svg__a"
      transform="translate(-682 -357)"
    />
    <use
      fill="#fff"
      xlinkHref="#Duotone_svg__b"
      transform="translate(-682 -357)"
    />
    <use
      fill="#dd4a58"
      xlinkHref="#Duotone_svg__c"
      transform="translate(-682 -357)"
    />
    <use
      fill="#74849a"
      xlinkHref="#Duotone_svg__d"
      transform="translate(-682 -357)"
    />
  </svg>
);

export default ImageUpload;
