import React, { Component } from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";

if (process.env.NODE_ENV !== "test") {
  require("react-day-picker/lib/style.css");
}

import styles from "./styles.scss";
import { SelectDropdownIcon } from "../Icons";

const LABELED_INPUT_TYPES = ["text", "select", "password", "date-picker"];

class RedLabeledInput extends Component {
  state = {
    active: false
  };

  static propTypes = {
    index: PropTypes.number,
    width: PropTypes.number,
    fullWidth: PropTypes.bool,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(LABELED_INPUT_TYPES),
    selectOptions: PropTypes.arrayOf(PropTypes.string),
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    type: "text",
    disabled: false
  };

  render() {
    const { active } = this.state;
    const {
      index,
      width,
      fullWidth,
      label,
      type,
      selectOptions,
      defaultValue,
      name,
      onChange,
      disabled
    } = this.props;

    const style = { width: width && `${width}px` };

    if (fullWidth) style.width = "100%";

    return (
      <div
        className={
          active
            ? styles.labeledInputContainerActive
            : styles.labeledInputContainer
        }
        style={{ opacity: disabled && 0.4, width: fullWidth && "100%" }}
      >
        <div className={styles.inputLabel} style={style}>
          {label}
        </div>
        <div className={styles.inputContainer} style={style}>
          {(() => {
            switch (type) {
              case "text":
                return (
                  <input
                    tabIndex={index}
                    onFocus={this._setActive}
                    onBlur={this._setInactive}
                    onChange={onChange}
                    name={name}
                    type="text"
                    style={style}
                    defaultValue={defaultValue}
                  />
                );
              case "select":
                return (
                  <div className={styles.selectInputContainer}>
                    <select className={styles.selectInput}
                      tabIndex={index}
                      onFocus={this._setActive}
                      onBlur={this._setInactive}
                      onChange={onChange}
                      name={name && name}
                      defaultValue={defaultValue}
                      style={style}
                      disabled={disabled}
                    >
                      <option hidden />
                      {selectOptions &&
                        selectOptions.map((option, i) => (
                          <option key={i}>{option}</option>
                        ))}
                    </select>
                    <div className={styles.selectInputIcon}>
                      <SelectDropdownIcon />
                    </div>
                  </div>
                );
              case "password":
                return (
                  <input
                    tabIndex={index}
                    onFocus={this._setActive}
                    onBlur={this._setInactive}
                    onChange={onChange}
                    name={name && name}
                    type="password"
                    style={style}
                    defaultValue={defaultValue}
                  />
                );
              case "date-picker":
                return (
                  <DayPickerInput
                    inputProps={{ name: name }}
                    value={defaultValue}
                    onDayChange={(day, modifiers, input) => onChange(input)}
                  />
                );
            }
          })()}
        </div>
      </div>
    );
  }

  _setActive = () => this.setState({ active: true });

  _setInactive = () => this.setState({ active: false });
}

export default RedLabeledInput;
