import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card } from "../../atoms";
import CircularProgressBar from "../../molecules/CircularProgressBar";
import styles from "./styles.scss";

const StatusGraph = ({ title, name, percentage }) => {
  return (
    <div className={styles.container}>
      <Card>
        <div>
          <h2 className={styles.graphTitle}>{title}</h2>
          <CircularProgressBar percentage={percentage} />
          <h2 className={styles.graphName}>{name}</h2>
        </div>
      </Card>
    </div>
  );
};

StatusGraph.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  percentage: PropTypes.number
};

export default StatusGraph;
