import { gql } from "apollo-boost";
import { client } from "../apollo";

const deleteInspection = async inspectionChecklistId =>
  await client.mutate({
    mutation: gql`
      mutation {
        deleteInspection(
          inspectionChecklistId: "${inspectionChecklistId}"
        )
      }
  `
  });

export default deleteInspection;
