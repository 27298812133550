import { gql } from "apollo-boost";

const getInspectionsForAdmin = gql`
  query inspectionsForAdmin($month: String!, $year: String!) {
    regularInspectionsForAdmin(month: $month, year: $year) {
      __typename
      id
      scheduledDate
      completionDate
      reportId
      fTec {
        id
        technician {
          id
          avatarUrl
          firstName
          lastName
        }
        facility {
          id
          name
          color
          businessName {
            id
            client {
              id
              name
            }
          }
        }
      }
      inspectionChecklist {
        id
        type
        checklistFormat
        quantity
        recurrence
        repeatOn
      }
      inspectionReschedule {
        id
        requestedDate
        note
        status
        assignedDate
        createdAt
        updatedAt
      }
    }
    inspectionsForAdmin(month: $month, year: $year) {
      __typename
      ... on Inspection {
        id
        scheduledDate
        completionDate
        reportId
        fTec {
          id
          technician {
            id
            avatarUrl
            firstName
            lastName
          }
          facility {
            id
            name
            color
            businessName {
              id
              client {
                id
                name
              }
            }
          }
        }
        inspectionChecklist {
          id
          type
          checklistFormat
          quantity
          recurrence
          repeatOn
        }
        inspectionReschedule {
          id
          requestedDate
          note
          status
          assignedDate
          createdAt
          updatedAt
        }
      }
      ... on CorrectiveActionInspection {
        id
        scheduledDate
        completionDate
        reportId
        fTec {
          id
          technician {
            id
            avatarUrl
            firstName
            lastName
          }
          facility {
            id
            color
            name
            businessName {
              id
              client {
                id
                name
              }
            }
          }
        }
        correctiveAction {
          id
          title
          details
        }
        inspectionReschedule {
          id
          requestedDate
          note
          status
          assignedDate
          createdAt
          updatedAt
        }
      }
      ... on AdditionalServiceInspection {
        id
        scheduledDate
        completionDate
        reportId
        fTec {
          id
          technician {
            id
            avatarUrl
            firstName
            lastName
          }
          facility {
            id
            color
            name
            businessName {
              id
              client {
                id
                name
              }
            }
          }
        }
        additionalService {
          id
          title
          details
        }
        inspectionReschedule {
          id
          requestedDate
          note
          status
          assignedDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export default getInspectionsForAdmin;
