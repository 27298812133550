import { gql } from "apollo-boost";
import { client } from "../apollo";

const deleteClient = async userId =>
  await client.mutate({
    mutation: gql`
      mutation {
        deleteClient(
          userId: "${userId}"
        )
      }
  `
  });

export default deleteClient;
