import React, { Component } from "react";
import style from "./style.scss";
import PropTypes from "prop-types";

class CircularProgressBar extends Component {
  render() {
    const sqSize = 150;
    const strokeWidth = 8;

    const { percentage } = this.props;
    const realPercentage = percentage > 100 ? 100 : percentage;

    // Size of the enclosing square
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = "-10 -10 170 170";
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * realPercentage) / 100;

    return (
      <svg width={210} height={210} viewBox={viewBox}>
        <defs>
          <filter id="sofGlow" height="300%" width="300%" x="-75%" y="-75%">
            <feMorphology
              operator="dilate"
              radius="2"
              in="SourceAlpha"
              result="thicken"
            />

            <feGaussianBlur in="thicken" stdDeviation="3" result="blurred" />

            <feFlood floodColor="rgba(221, 74, 88, 0.44)" result="glowColor" />
            <feComposite
              in="glowColor"
              in2="blurred"
              operator="in"
              result="softGlow_colored"
            />

            <feMerge>
              <feMergeNode in="softGlow_colored" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <circle
          className={style.circleBackground}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className={style.circleProgress}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth + 2}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
          filter="url(#sofGlow)"
        />
        <text
          className={style.circleText}
          x="45%"
          y="45%"
          dy=".3em"
          textAnchor="middle"
        >
          {`${percentage}%`}
        </text>
      </svg>
    );
  }
}

CircularProgressBar.propTypes = {
  percentage: PropTypes.number
};

export default CircularProgressBar;
