import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import NonSelectable from "../NonSelectable";

const TextInput = ({ width, placeholder, onChange }) => {
  return (
    <NonSelectable>
      <input
        style={{ maxWidth: width && width }}
        className={styles.textInput}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
      />
    </NonSelectable>
  );
};

TextInput.propTypes = {
  width: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default TextInput;
