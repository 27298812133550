import { gql } from "apollo-boost";
import { client } from "../apollo";

const approveReport = async reportId =>
  await client.mutate({
    mutation: gql`
      mutation {
        approveReport(
          reportId: "${reportId}"
        )
      }
  `
  });

export default approveReport;
