export { default as ClientsView } from "./ClientsView";
export { default as DashboardView } from "./DashboardView";
export { default as TechniciansView } from "./TechniciansView";
export { default as ContractsView } from "./ContractsView";
export { default as FacilitiesView } from "./FacilitiesView";
export { default as InspectionsView } from "./InspectionsView";
export { default as DoneInspectionsView } from "./DoneInspectionsView";
export { default as AdditionalServicesView } from "./AdditionalServicesView";
export { default as CorrectiveActionsView } from "./CorrectiveActionsView";
export { default as DelayedInspectionsView } from "./DelayedInspectionsView";
export { default as NotificationsView } from "./NotificationsView";
export {
  default as RescheduledInspectionsView
} from "./RescheduledInspectionsView";
export { default as ReportsView } from "./ReportsView";
export { default as ScheduleView } from "./ScheduleView";
export { default as UsersView } from "./UsersView";
