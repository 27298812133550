import { gql } from "apollo-boost";

const getReports = gql`
  query getReports($startDate: String, $endDate: String) {
    reports(startDate: $startDate, endDate: $endDate) {
      id
      number
      introMessage
      date
      approved
      fTec {
        id
        technician {
          id
          firstName
          lastName
        }
        facility {
          id
          name
        }
      }
    }
  }
`;

export default getReports;
