export default {
  name: "hose_reels_maintenance",
  title: "Inspección de carretes de mangueras",
  checks: {
    nozzlePlacement: {
      warning: false,
      type: "BOOL",
      label: "Boquillas en su lugar",
      unit: null,
    },
    manualValve: {
      warning: false,
      type: "BOOL",
      label: "Válvula manual completa",
      unit: null,
    },
    leaksFree: {
      warning: false,
      type: "BOOL",
      label: "Sin fugas",
      unit: null
    },
    obstructionFree: {
      warning: false,
      type: "BOOL",
      label: "Sin obstrucciones",
      unit: null
    },
    pipelineCondition: {
      warning: false,
      type: "BOOL",
      label: "Tuberia en buenas condiciones",
      unit: null
    },
    valveOperation: {
      warning: false,
      type: "BOOL",
      label: "Sin dificultad para operar válvula",
      unit: null,
    },
    reelOperation: {
      type: "BOOL",
      label: "Sin dificultad para operar carrete",
      unit: null
    },
    hoseTangle: {
      warning: false,
      type: "BOOL",
      label: "Manguera bien acomodada o enrollada",
      unit: null
    }
  }
};
