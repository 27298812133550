import { gql } from "apollo-boost";
import { client } from "../apollo";

const createContractAndChecklists = async (
  facilityId,
  annualCost,
  monthlyCost,
  baseBudget,
  policyNumber,
  startDate,
  endDate,
  documents,
  inspectionChecklists
) =>
  await client.mutate({
    mutation: gql`
      mutation createContractAndChecklists(
        $facilityId: ID!
        $annualCost: String!
        $monthlyCost: String!
        $baseBudget: String!
        $policyNumber: String!
        $startDate: String!
        $endDate: String!
        $documents: String
        $inspectionChecklists: String!
      ) {
        createContractAndChecklists(
          facilityId: $facilityId
          annualCost: $annualCost
          monthlyCost: $monthlyCost
          baseBudget: $baseBudget
          policyNumber: $policyNumber
          startDate: $startDate
          endDate: $endDate
          documents: $documents
          inspectionChecklists: $inspectionChecklists
        ) {
          id
        }
      }
    `,
    variables: {
      facilityId,
      annualCost,
      monthlyCost,
      baseBudget,
      policyNumber,
      startDate,
      endDate,
      documents,
      inspectionChecklists
    }
  });

export default createContractAndChecklists;
