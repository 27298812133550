import React from "react";

import statesAndCities from "../../../utils/statesAndCities";
import {
  Button,
  Container,
  CloseIcon,
  Modal,
  Layout,
  LabeledInput
} from "../../atoms";
import { LoadAsset, RemovableInput } from "../../molecules";

const EditClientModal = ({
  editClientModalState: editClientModal,
  client,
  editClientId,
  userId,
  saveButtonDisabled,
  selectedCity,
  selectedState,
  businessNameInputs,
  onClickCloseButton,
  onInputChange,
  onStateInputChange,
  onBusinessNameInputChange,
  onBusinessNameInputRemove,
  onBusinessNameAddButton,
  onEditClientButton
}) => {
  const photoURL = client.avatarUrl == null ? "" : client.avatarUrl;

  return (
    <Container width={1100}>
      <div
        onClick={onClickCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>
        Editar cliente{" "}
        <span style={{ color: "#5A5E61", fontSize: "13px" }}>
          {client.name}
        </span>
      </Modal.Header>{" "}
      <Modal.Section
        style={{ display: "flex" }}
        paddingVertical={40}
        paddingHorizontal={100}
      >
        <LoadAsset
          buttonLabel="Editar Logo"
          defaultImage={photoURL}
          image="file_editarlogo"
          centered={false}
        />
        <Layout.Grid>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={1}
                fullWidth
                label="Nombre del cliente"
                name="clientNameInput"
                onChange={onInputChange}
                defaultValue={client.name}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={4}
                fullWidth
                label="Municipio"
                type="select"
                selectOptions={statesAndCities[selectedState]}
                name="clientCityInput"
                onChange={onInputChange}
                defaultValue={selectedCity}
              />
            </div>
            <LabeledInput
              index={2}
              fullWidth
              label="Correo Electrónico"
              name="emailAddressInput"
              onChange={onInputChange}
              defaultValue={client.user.emailAddress}
            />
          </Layout.Column>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={3}
                fullWidth
                label="Estado"
                type="select"
                selectOptions={Object.keys(statesAndCities)}
                name="clientStateInput"
                onChange={onStateInputChange}
                defaultValue={selectedState}
              />
            </div>
            <LabeledInput
              index={5}
              fullWidth
              label="Dirección"
              name="clientAddressInput"
              onChange={onInputChange}
              defaultValue={client.address}
            />
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Header>Editar razones sociales</Modal.Header>
      <Modal.Section paddingVertical={40} paddingHorizontal={100}>
        {businessNameInputs.map((businessName, idx) => {
          if (idx === 0) {
            return (
              <div
                key={Object.keys(businessName)[0]}
                style={{
                  display: "inline-block",
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                <LabeledInput
                  name={`${idx}`}
                  label="Razón social"
                  onChange={onBusinessNameInputChange}
                  defaultValue={Object.values(businessName)[0]}
                />
              </div>
            );
          } else {
            return (
              <div
                key={Object.keys(businessName)[0]}
                style={{
                  display: "inline-block",
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                <RemovableInput
                  name={`${idx}`}
                  label="Razón social"
                  onChange={onBusinessNameInputChange}
                  onRemove={onBusinessNameInputRemove}
                  defaultValue={Object.values(businessName)[0]}
                />
              </div>
            );
          }
        })}
        <div style={{ textAlign: "center" }}>
          <Button
            paddingVertical={10}
            onClick={onBusinessNameAddButton}
            style="outline"
          >
            Añadir una razón social
          </Button>
        </div>
      </Modal.Section>
      <Modal.Footer>
        <Button
          paddingVertical={10}
          paddingHorizontal={48}
          onClick={onEditClientButton}
          disabled={saveButtonDisabled}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default EditClientModal;
