import { gql } from "apollo-boost";

const getInspection = gql`
  query inspection($inspectionId: ID!) {
    inspection(id: $inspectionId) {
      id
      inspectionChecklist {
        id
        checklistFormat
        recurrence
        repeatOn
      }
      fTec {
        id
        technician {
          id
          avatarUrl
          firstName
          lastName
        }
        facility {
          id
          businessName {
            id
            client {
              id
              name
            }
          }
        }
      }
      checklists {
        id
        checklistItems
        zone
        number
        signedBy {
          id
          firstName
          lastName
        }
        signatureUri
      }
      scheduledDate
      completionDate
    }
  }
`;

export default getInspection;
