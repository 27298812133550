import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./styles.scss";

const ProjectCard = ({
  thumbnailUrl,
  facilityId,
  facilityName,
  facilityAddress
}) => {
  return (
    <Link to={`/${facilityId}/contratos`} className={styles.card}>
      <div className={styles.cardThumbnailContainer}>
        <div
          className={styles.cardThumbnail}
          style={{ backgroundImage: `url(${thumbnailUrl})` }}
        />
      </div>
      <div className={styles.cardDetailsContainer}>
        <span className={styles.cardFacilityName}>{facilityName}</span>
        <span className={styles.cardFacilityAddress}>{facilityAddress}</span>
        <div className={styles.cardDetailsDiv} />
        <div className={styles.cardViewButton}>Ver</div>
      </div>
    </Link>
  );
};

ProjectCard.propTypes = {
  thumbnailUrl: PropTypes.string,
  facilityId: PropTypes.string.isRequired,
  facilityName: PropTypes.string.isRequired,
  facilityAddress: PropTypes.string.isRequired
};

export default ProjectCard;
