import React from "react";
import PropTypes from "prop-types";

import {
  CloseIcon,
  Container,
  Modal,
  Layout,
  Button,
  AvatarBubble,
  FileButton
} from "../../atoms";
import { parseMilliseconds } from "../../../utils/date";

const Field = ({ label, value }) => (
  <div style={{ width: "100%", margin: "0 auto", marginBottom: 40 }}>
    <div style={{ color: "#5A5E61", marginBottom: 10 }}>{label}</div>
    <div style={{ color: "#5A5E61", fontWeight: "bold" }}>{value}</div>
  </div>
);

const Facility = ({ avatarUrl, clientName, facilityName }) => (
  <div style={{ marginBottom: 40 }}>
    <div style={{ float: "left" }}>
      <AvatarBubble url={avatarUrl} />
    </div>
    <div style={{ display: "inline-block", marginLeft: 20 }}>
      <div style={{ marginBottom: 10, color: "#5A5E61" }}>{clientName}</div>
      <div style={{ color: "#5A5E61", fontWeight: "bold" }}>{facilityName}</div>
    </div>
  </div>
);

const ViewContractModal = ({
  viewContractModalState: viewContractModal,
  viewContractId,
  onViewChecklistButton,
  onBackButton,
  onPreviewButton,
  contract
}) => {
  const contractPolicies = contract.contractDocuments.filter(
    e => e.type === "POLICIES"
  );

  const SCINotes = contract.contractDocuments.filter(
    e => e.type === "SCI_NOTES"
  );

  const commercialConditions = contract.contractDocuments.filter(
    e => e.type === "COMMERCIAL_CONDITIONS"
  );

  const blueprints = contract.contractDocuments.filter(
    e => e.type === "FACILITY_BLUEPRINTS"
  );

  const contractPolicyUrlFragments =
    contract.contractPolicyUrl && contract.contractPolicyUrl.split("/");

  const SCINotesUrlFragments =
    contract.SCINotesUrl && contract.SCINotesUrl.split("/");

  const conditionsUrlFragments =
    contract.commercialConditionsUrl &&
    contract.commercialConditionsUrl.split("/");

  const blueprintsUrlFragments =
    contract.facilityBlueprintsUrl && contract.facilityBlueprintsUrl.split("/");

  contract.contractPolicyUrl &&
    contractPolicies.push({
      type: "POLICIES",
      legacy: true,
      name: contractPolicyUrlFragments[contractPolicyUrlFragments.length - 1],
      url: contract.contractPolicyUrl
    });

  contract.SCINotesUrl &&
    SCINotes.push({
      type: "SCI_NOTES",
      legacy: true,
      name: SCINotesUrlFragments[SCINotesUrlFragments.length - 1],
      url: contract.SCINotesUrl
    });

  contract.commercialConditionsUrl &&
    commercialConditions.push({
      type: "COMMERCIAL_CONDITIONS",
      legacy: true,
      name: conditionsUrlFragments[conditionsUrlFragments.length - 1],
      url: contract.commercialConditionsUrl
    });

  contract.facilityBlueprintsUrl &&
    blueprints.push({
      type: "FACILITY_BLUEPRINTS",
      legacy: true,
      name: blueprintsUrlFragments[blueprintsUrlFragments.length - 1],
      url: contract.facilityBlueprintsUrl
    });

  const startDate = new Date(`${contract.startDate}T00:00`).getTime();

  const endDate = new Date(`${contract.endDate}T00:00`).getTime();

  return (
    <Container width={1118}>
      <div
        onClick={onBackButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>
        Ver datos del contrato{" "}
        <span style={{ color: "rgba(43, 48, 52, 0.4)", fontSize: "13px" }}>
          {contract.policyNumber}
        </span>
      </Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <div style={{ marginTop: "20px" }}>
          <span style={{ display: "inline-block", marginLeft: "70" }}>
            <Facility
              avatarUrl={
                contract.facility.businessName.client.avatarUrl &&
                contract.facility.businessName.client.avatarUrl
              }
              clientName="Seleccionar cliente"
              facilityName={contract.facility.businessName.client.name}
            />
          </span>
          <span style={{ display: "inline-block", marginLeft: "70" }}>
            <Field
              label="Razon Social"
              value={contract.facility.businessName.name}
            />
          </span>
          <span style={{ display: "inline-block", marginLeft: "70" }}>
            <Field label="Planta" value={contract.facility.name} />
          </span>
        </div>
      </Modal.Section>
      <Modal.Section
        style={{ margin: "0 50px", borderTop: "1px solid #d7d7d7" }}
        paddingVertical={30}
        paddingHorizontal={100}
      >
        <Layout.Grid style={{ marginLeft: "70px" }}>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <Field
                label="Fecha de inicio de contrato"
                value={parseMilliseconds(startDate)}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <Field label="Costo total mensual" value={contract.monthlyCost} />
            </div>
          </Layout.Column>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <Field
                label="Fecha de finalización de contrato"
                value={parseMilliseconds(endDate)}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <Field label="PEP base" value={contract.baseBudget} />
            </div>
          </Layout.Column>
          <Layout.Column>
            <Field label="Costo total anual" value={contract.annualCost} />
            <Field label="Nombre de póliza" value={contract.policyNumber} />
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Header>Documentos</Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <div>
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                marginBottom: 20,
                fontSize: 16,
                fontWeight: 700,
                color: "#5A5E61"
              }}
            >
              Póliza del contrato
            </div>
            {contractPolicies.length === 0 && (
              <div>Sin pólizas de contrato</div>
            )}
            <div
              style={{
                marginLeft: -20,
                marginRight: -20
              }}
            >
              {contractPolicies.map(doc => (
                <div
                  style={{
                    display: "inline-block",
                    width: "calc(33% - 40px)",
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: 20
                  }}
                  key={doc.url}
                >
                  <FileButton
                    label={doc.name}
                    onClick={() => onPreviewButton(doc.url)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                marginBottom: 20,
                fontSize: 16,
                fontWeight: 700,
                color: "#5A5E61"
              }}
            >
              Información del SCI
            </div>
            {SCINotes.length === 0 && (
              <div>Sin documentos de información del SCI</div>
            )}
            <div
              style={{
                marginLeft: -20,
                marginRight: -20
              }}
            >
              {SCINotes.map(doc => (
                <div
                  style={{
                    display: "inline-block",
                    width: "calc(33% - 40px)",
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: 20
                  }}
                  key={doc.url}
                >
                  <FileButton
                    label={doc.name}
                    onClick={() => onPreviewButton(doc.url)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                marginBottom: 20,
                fontSize: 16,
                fontWeight: 700,
                color: "#5A5E61"
              }}
            >
              Condiciones comerciales
            </div>
            {commercialConditions.length === 0 && (
              <div>Sin condiciones comerciales</div>
            )}
            <div
              style={{
                marginLeft: -20,
                marginRight: -20
              }}
            >
              {commercialConditions.map(doc => (
                <div
                  style={{
                    display: "inline-block",
                    width: "calc(33% - 40px)",
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: 20
                  }}
                  key={doc.url}
                >
                  <FileButton
                    label={doc.name}
                    onClick={() => onPreviewButton(doc.url)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <div
              style={{
                marginBottom: 20,
                fontSize: 16,
                fontWeight: 700,
                color: "#5A5E61"
              }}
            >
              Planos de planta
            </div>
            {blueprints.length === 0 && <div>Sin planos de planta</div>}
            <div
              style={{
                marginLeft: -20,
                marginRight: -20
              }}
            >
              {blueprints.map(doc => (
                <div
                  style={{
                    display: "inline-block",
                    width: "calc(33% - 40px)",
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: 20
                  }}
                  key={doc.url}
                >
                  <FileButton
                    label={doc.name}
                    onClick={() => onPreviewButton(doc.url)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={10}
            paddingHorizontal={55}
            style="outline"
            onClick={onBackButton}
          >
            Cerrar
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={30}
          radius={10}
          onClick={onViewChecklistButton}
        >
          Ver Checklist
        </Button>
      </Modal.Footer>
    </Container>
  );
};

ViewContractModal.propTypes = {
  onBackButton: PropTypes.func.isRequired,
  viewContractId: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired
};

export default ViewContractModal;
