import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import styles from "./app.scss";

import * as Client from "./views/client";
import {
  HomeIcon,
  ClientsIcon,
  EmailIcon,
  CalendarIcon
} from "./components/atoms";
import { Sidebar, Topbar } from "./components/organisms";
import { getUnreadNotificationsForClient } from "./gql/queries";

class ClientApp extends Component {
  render() {
    const { user, location } = this.props;
    const routeParam = location.pathname.split("/")[1];

    return (
      <>
        <Topbar
          notificationsFor={user.uid}
          notificationsQuery={getUnreadNotificationsForClient}
          navigation={[
            {
              basePath: "/:fid/contratos",
              links: [
                {
                  linkTo: `/${routeParam}/contratos`,
                  label: "Contratos"
                },
                {
                  linkTo: `/${routeParam}/contratos/servicios-adicionales`,
                  label: "Servicios adicionales"
                }
              ]
            }
          ]}
          userType="client"
        />
        <Sidebar
          hideFor={["/", "/notificaciones"]}
          buttons={[
            {
              icon: () => <HomeIcon />,
              linkTo: `/${routeParam}/contratos`
            },
            { icon: () => <ClientsIcon />, linkTo: `/${routeParam}/contactos` },
            {
              icon: () => <CalendarIcon />,
              linkTo: `/${routeParam}/inspecciones`
            },
            { icon: () => <EmailIcon />, linkTo: `/${routeParam}/reportes` }
          ]}
        />
        <div className={styles.container}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <Client.ProjectsView userId={user.uid} userKind={user.kind} />
              )}
            />
            <Route
              path="/:fid/contactos"
              component={({ match }) => <Client.TechnicianView match={match} />}
            />
            <Route
              exact
              path="/:fid/contratos"
              component={({ match }) => (
                <Client.ContractsListView match={match} />
              )}
            />
            <Route
              path="/:fid/contratos/servicios-adicionales"
              component={({ match }) => (
                <Client.AdditionalServicesView match={match} />
              )}
            />
            <Route
              path="/:fid/reportes/:rid"
              component={({ match }) => <Client.ReportsView match={match} />}
            />
            <Route
              path="/:fid/reportes"
              component={({ match }) => <Client.ReportsView match={match} />}
            />
            <Route
              path="/:fid/inspecciones"
              component={({ match }) => (
                <Client.InspectionsView match={match} />
              )}
            />
            <Route
              path="/notificaciones"
              component={() => <Client.NotificationsView userId={user.uid} />}
            />
          </Switch>
        </div>
      </>
    );
  }
}

export default withRouter(ClientApp);
