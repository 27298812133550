import React from "react";
import PropTypes from "prop-types";

import { Container, CloseIcon, Modal, Layout, Button } from "../../atoms";
import { FacilitySelector } from "../../molecules";

const AddFacilitiesModal = ({
  facilitySelectors,
  addButtonDisabled,
  selectedFacilityIds,
  onCloseButton,
  onFacilitySelectorChange,
  onFacilitySelect,
  onFacilityAddButton,
  onBackButton,
  onCreateTechnicianButton,
  onRemoveFacilityInput
}) => {
  return (
    <>
      <Container width={1300}>
        <div
          onClick={onCloseButton}
          style={{ position: "absolute", cursor: "pointer", right: 30 }}
        >
          <CloseIcon />
        </div>
        <Modal.Header>Asignar Plantas</Modal.Header>
        <Modal.Section paddingVertical={100} paddingHorizontal={100}>
          <Layout.Grid>
            <Layout.Column style={{ padding: "0 40px" }}>
              {Object.keys(facilitySelectors).map((selector, idx) => (
                <div style={{ marginBottom: "40px" }}>
                  <FacilitySelector
                    disabled={Object.values(selectedFacilityIds)}
                    selected={[
                      facilitySelectors[selector].selectedClient,
                      facilitySelectors[selector].selectedBusinessName,
                      facilitySelectors[selector].selectedFacilityId
                    ]}
                    onRemove={
                      idx !== 0 && (() => onRemoveFacilityInput(selector))
                    }
                    onChange={(key, value) =>
                      onFacilitySelectorChange(selector, key, value)
                    }
                    onFacilitySelect={facilityId =>
                      onFacilitySelect(selector, facilityId)
                    }
                  />
                </div>
              ))}
            </Layout.Column>
          </Layout.Grid>
          <div style={{ width: "15.5%", margin: "0 auto" }}>
            <Button
              paddingVertical={10}
              paddingHorizontal={55}
              onClick={onFacilityAddButton}
              style="outline"
            >
              Añadir
            </Button>
          </div>
        </Modal.Section>
        <Modal.Footer>
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <Button
              paddingVertical={10}
              paddingHorizontal={55}
              onClick={() => onBackButton(1)}
              style="outline"
            >
              Volver
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={30}
            onClick={onCreateTechnicianButton}
            radius={10}
            disabled={addButtonDisabled}
          >
            Crear Técnico
          </Button>
        </Modal.Footer>
      </Container>
    </>
  );
};

AddFacilitiesModal.propTypes = {
  facilitySelectors: PropTypes.object.isRequired,
  addButtonDisabled: PropTypes.bool.isRequired,
  selectedFacilityIds: PropTypes.object.isRequired,
  onCloseButton: PropTypes.func.isRequired,
  onFacilitySelectorChange: PropTypes.func.isRequired,
  onFacilitySelect: PropTypes.func.isRequired,
  onFacilityAddButton: PropTypes.func.isRequired,
  onBackButton: PropTypes.func.isRequired,
  onCreateTechnicianButton: PropTypes.func.isRequired
};

export default AddFacilitiesModal;
