import React from "react";
import PropTypes from "prop-types";

const Plus = ({ tint }) => (
  <svg width={24} height={24}>
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill={tint} />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

Plus.propTypes = {
  tint: PropTypes.string
};

export default Plus;
