import { gql } from "apollo-boost";
import { client } from "../apollo";

const deleteUser = async userId =>
  await client.mutate({
    mutation: gql`
      mutation {
        deleteUser(
          userId: "${userId}"
        )
      }
  `
  });

export default deleteUser;
