import { gql } from "apollo-boost";

const getCorrectiveActions = gql`
  query getCorrectiveActions($startDate: String, $endDate: String) {
    correctiveActions(startDate: $startDate, endDate: $endDate) {
      id
      facility {
        id
        name
        businessName {
          id
          name
          client {
            id
            name
            avatarUrl
          }
        }
      }
      contract {
        id
        baseBudget
        facility {
          id
          name
          businessName {
            id
            name
            client {
              id
              name
              avatarUrl
            }
          }
        }
      }
      fTec {
        id
        facility {
          id
          name
          businessName {
            id
            name
            client {
              id
              name
              avatarUrl
            }
          }
        }
      }
      fTec {
        id
        technician {
          id
          firstName
          lastName
        }
      }
      correctiveActionInspection {
        id
        scheduledDate
        fTec {
          id
          technician {
            id
            firstName
            lastName
          }
        }
      }
      title
      budget
      budgetUSD
      details
      status
      createdAt
      updatedAt
    }
  }
`;

export default getCorrectiveActions;
