import React, { Component } from "react";
import PropTypes from "prop-types";
import fuzzy from "fuzzy";
import TagsInput from "react-tag-autocomplete";
import mixpanel from "mixpanel-browser";

import tagStyles from "../../tagStyles.scss";

import {
  createFacility,
  deleteFacility,
  editFacility
} from "../../gql/mutations";
import {
  AvatarBubble,
  Button,
  Container,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  Table,
  Heading,
  Modal
} from "../atoms";
import {
  TableContainerHeader,
  Spinner,
  ClientSelector,
  ModalPortal,
  LoadAsset
} from "../molecules";
import { DeleteFacilityModal, EditFacilityModal } from "../organisms";

class FacilitiesTemplate extends Component {
  state = {
    filter: undefined,
    addFacilityModal: {
      visible: false,
      savingFacility: false,
      tags: [],
      suggestions: []
    },
    deleteFacilityModal: {
      deleteFacilityModalVisible: false,
      deleteButtonDisabled: false
    },
    editFacilityModal: {
      visible: false,
      savingFacility: false
    }
  };

  static propTypes = {
    loadingFacilities: PropTypes.bool.isRequired,
    facilities: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  static defaultProps = {
    loadingFacilities: true,
    facilities: []
  };

  render() {
    const {
      filter,
      addFacilityModal,
      deleteFacilityModal: { deleteFacilityModalVisible },
      editFacilityModal
    } = this.state;
    const { loadingFacilities, facilities } = this.props;

    const filteredFacilities =
      facilities &&
      fuzzy.filter(filter, facilities, {
        extract: el => el.name
      });

    const data = filter
      ? filteredFacilities && filteredFacilities.map(result => result.original)
      : facilities;

    return (
      <>
        <ModalPortal
          modal={this._renderAddFacilityModal}
          visible={addFacilityModal.visible}
        />
        <ModalPortal
          modal={this._renderDeleteFacilityModal}
          visible={deleteFacilityModalVisible}
          onBackdropClick={this._handleOnBackButton}
        />
        <ModalPortal
          modal={this._renderEditFacilityModal}
          visible={editFacilityModal.visible}
          onBackdropClick={this._handleOnEditBackButton}
        />
        <Heading>Plantas</Heading>
        <Container noPadding>
          <TableContainerHeader
            placeHolder="Buscar por nombre de planta"
            onInputChange={e => this._filterFacilities(e)}
            onClickAddButton={this._handleOnClickAddButton}
          />
          {loadingFacilities && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
          {facilities.length > 0 && (
            <Table.Main
              columns={[
                null,
                { "businessName.client.name": "Cliente" },
                { "businessName.name": "Razón social" },
                { name: "Nombre de la planta" },
                { zone: "Zona" },
                null
              ]}
              data={data}
              row={(facility, lastFacilityRow) =>
                this._renderFacilityRow(facility, lastFacilityRow)
              }
            />
          )}
        </Container>
      </>
    );
  }

  _renderFacilityRow = (facility, lastFacilityRow) => {
    return (
      <tr
        testid="facility-table-row"
        key={facility.id}
        style={{ borderBottom: !lastFacilityRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble url={facility.avatarUrl && facility.avatarUrl} />
          </div>
        </Table.Cell>
        <Table.Cell>{facility.businessName.client.name}</Table.Cell>
        <Table.Cell>{facility.businessName.name}</Table.Cell>
        <Table.Cell>{facility.name}</Table.Cell>
        <Table.Cell>{facility.zone}</Table.Cell>
        <Table.Cell>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
              onClick={() =>
                this._handleOnPressDeleteFacilityButton(facility.id)
              }
            >
              <DeleteIcon />
            </Button>
          </div>
          <Button
            radius={7}
            paddingVertical={8}
            paddingHorizontal={8}
            style="silver"
            iconOnly
            onClick={() => this._handleOnPressEditFacilityButton(facility.id)}
          >
            <EditIcon />
          </Button>
        </Table.Cell>
      </tr>
    );
  };

  _filterFacilities = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnClickAddButton = () => {
    this.setState(state => ({
      addFacilityModal: { ...state.addFacilityModal, visible: true }
    }));
  };

  _handleOnClickCloseButton = () => {
    this.setState({
      addFacilityModal: {
        visible: false
      }
    });
  };

  _renderAddFacilityModal = () => {
    const {
      addFacilityModal,
      addFacilityModal: { savingFacility }
    } = this.state;

    const buttonDisabled =
      addFacilityModal.selectedBusinessNameId &&
      addFacilityModal.selectedBusinessNameId.length > 0 &&
      (addFacilityModal.facilityName &&
        addFacilityModal.facilityName.length > 0) &&
      (addFacilityModal.selectedZone &&
        addFacilityModal.selectedZone.length > 0);

    return (
      <Container width={916}>
        <div
          onClick={() => this._handleOnClickCloseButton()}
          style={{ position: "absolute", cursor: "pointer", right: 30 }}
        >
          <CloseIcon />
        </div>
        <Modal.Header>Creación de nueva planta</Modal.Header>
        <Modal.Section paddingVertical={100} paddingHorizontal={280}>
          <LoadAsset
            buttonLabel="Adjuntar Foto de planta"
            image="file_adjuntarfotodeplanta"
            centered={true}
          />
        </Modal.Section>
        <Modal.Section paddingVertical={100} paddingHorizontal={280}>
          <ClientSelector
            selected={[
              addFacilityModal.selectedClient,
              addFacilityModal.selectedBusinessNameId,
              addFacilityModal.selectedZone
            ]}
            onChange={(key, value) =>
              this._handleOnClientSelectorChange(key, value)
            }
            onBusinessNameSelect={facilityId =>
              this._handleOnBusinessNameSelect(facilityId)
            }
            onFacilityNameChange={facilityName =>
              this._handleOnFacilityNameChange(facilityName)
            }
            onDataLoaded={data =>
              this.setState(state => ({
                addFacilityModal: {
                  ...state.addFacilityModal,
                  tags: [],
                  suggestions: data
                }
              }))
            }
          />
          <div>
            <div style={{ marginBottom: 20 }}>Gerentes de planta</div>
            <TagsInput
              classNames={{
                root: tagStyles.reactTags,
                rootFocused: tagStyles.reactTagsIsFocused,
                selected: tagStyles.reactTags__selected,
                selectedTag: tagStyles.reactTags__selectedTag,
                search: tagStyles.reactTags__search,
                suggestions: tagStyles.reactTags__suggestions
              }}
              placeholder="Añadir gerente de planta"
              tags={addFacilityModal.tags}
              suggestions={addFacilityModal.suggestions}
              handleDelete={this._handleDeleteTag}
              handleAddition={this._handleAddTag}
            />
          </div>
          <div style={{ textAlign: "center", marginTop: 50 }}>
            <Button
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={() => this._handleOnCreateFacilityButton()}
              disabled={savingFacility || !buttonDisabled}
            >
              Crear
            </Button>
          </div>
        </Modal.Section>
      </Container>
    );
  };

  _handleDeleteTag = i => {
    const { addFacilityModal } = this.state;
    const tags = addFacilityModal.tags.slice(0);
    tags.splice(i, 1);
    this.setState(state => ({
      addFacilityModal: {
        ...state.addFacilityModal,
        tags
      }
    }));
  };

  _handleAddTag = tag => {
    const { addFacilityModal } = this.state;
    const tags = [].concat(addFacilityModal.tags, tag);
    this.setState(state => ({
      addFacilityModal: {
        ...state.addFacilityModal,
        tags
      }
    }));
  };

  _renderDeleteFacilityModal = () => {
    const {
      deleteFacilityModal: { deleteButtonDisabled, deleteFacilityId }
    } = this.state;

    const { facilities } = this.props;
    const facility = facilities.find(
      facility => facility.id === deleteFacilityId
    );

    return (
      <DeleteFacilityModal
        deleteButtonDisabled={deleteButtonDisabled}
        deleteFacilityId={deleteFacilityId}
        facility={facility}
        onBackButton={this._handleOnBackButton}
        onDeleteFacilityButton={this._handleOnDeleteFacilityButton}
      />
    );
  };

  // TODO: Dry these handlers

  _handleOnBackButton = () => {
    this.setState(state => ({
      deleteFacilityModal: {
        ...state.deleteFacilityModal,
        deleteFacilityModalVisible: false
      }
    }));
  };

  _handleOnPressDeleteFacilityButton = facilityId => {
    this.setState(state => ({
      deleteFacilityModal: {
        ...state.deleteFacilityModal,
        deleteFacilityModalVisible: true,
        deleteFacilityId: facilityId
      }
    }));
  };

  _handleOnDeleteFacilityButton = facilityId => {
    if (!!facilityId) {
      this.setState(
        (state, _) => ({
          deleteFacilityModal: {
            ...state.deleteFacilityModal,
            deleteButtonDisabled: true
          }
        }),
        () =>
          deleteFacility(facilityId)
            .then(() => {
              window.location = "/plantas";
            })
            .catch(err => {
              console.log(err);
              this.setState(state => ({
                deleteFacilityModal: {
                  deleteFacilityModal: false
                }
              }));
            })
      );
    }
  };

  _handleOnClientSelectorChange = (key, value) => {
    this.setState(state => ({
      addFacilityModal: {
        ...state.addFacilityModal,
        [key]: value
      }
    }));
  };

  _handleOnBusinessNameSelect = businessNameId => {
    this.setState(state => ({
      addFacilityModal: {
        ...state.addFacilityModal,
        selectedBusinessNameId: businessNameId
      }
    }));
  };

  _handleOnFacilityNameChange = facilityName => {
    this.setState(state => ({
      addFacilityModal: {
        ...state.addFacilityModal,
        facilityName
      }
    }));
  };

  _handleOnCreateFacilityButton = () => {
    const {
      addFacilityModal: {
        selectedBusinessNameId,
        facilityName,
        selectedZone,
        tags
      }
    } = this.state;

    if (!!selectedBusinessNameId && !!facilityName && !!selectedZone) {
      this.setState(
        (state, _) => ({
          addFacilityModal: {
            ...state.addFacilityModal,
            addButtonDisabled: true
          }
        }),
        () => {
          let zone = selectedZone.toUpperCase();

          // TODO: Find a better way to do this?
          if (selectedZone === "Bajío") {
            zone = "BAJIO";
          }

          const avatarUrlFromLocalStorage = localStorage.getItem("file_adjuntarfotodeplanta");

          const facilityManagers = tags
            .map(e => e.id)
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => tags[e])
            .map(e => tags[e]);

          return createFacility(
            selectedBusinessNameId,
            avatarUrlFromLocalStorage,
            facilityName,
            zone,
            JSON.stringify(facilityManagers)
          )
            .then(() => {
              localStorage.removeItem("file_adjuntarfotodeplanta");
              window.location = "/plantas";
              mixpanel.track("Nueva planta creada");
            })
            .catch(err => {
              console.log(err);
              this.setState(state => ({
                addFacilityModal: {
                  ...state.addFacilityModal,
                  addButtonDisabled: false
                }
              }));
            });
        }
      );
    } else {
      // TODO:
      alert("Missing data");
    }
  };

  _handleOnEditFacility = () => {
    const {
      editFacilityModal: {
        editFacilityId,
        selectedBusinessNameId,
        facilityName,
        selectedZone,
        tags
      }
    } = this.state;

    this.setState(
      (state, _) => ({
        editFacilityModal: {
          ...state.editFacilityModal,
          savingFacility: true
        }
      }),
      () => {
        let zone = selectedZone.toUpperCase();

        // TODO: Find a better way to do this?
        if (selectedZone === "Bajío") {
          zone = "BAJIO";
        }

        const facilityAvatarUrl = localStorage.getItem("file_adjuntarfotodeplanta");

        const facilityManagers = tags
          .map(e => e.id)
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter(e => tags[e])
          .map(e => tags[e]);

        return editFacility(
          editFacilityId,
          selectedBusinessNameId,
          facilityAvatarUrl,
          facilityName,
          zone,
          JSON.stringify(facilityManagers)
        )
          .then(() => {
            localStorage.removeItem("file_adjuntarfotodeplanta");
            window.location = "/plantas";
          })
          .catch(err => {
            console.log(err);
            this.setState(state => ({
              editFacilityModal: {
                ...state.editFacilityModal,
                savingFacility: false
              }
            }));
          });
      }
    );
  };

  _renderEditFacilityModal = () => {
    const {
      editFacilityModal,
      editFacilityModal: { savingFacility, editFacilityId }
    } = this.state;
    const { facilities } = this.props;
    const facility = facilities.find(
      facility => facility.id === editFacilityId
    );

    const buttonDisabled =
      editFacilityModal.selectedBusinessNameId &&
      editFacilityModal.selectedBusinessNameId.length > 0 &&
      (editFacilityModal.facilityName &&
        editFacilityModal.facilityName.length > 0) &&
      (editFacilityModal.selectedZone &&
        editFacilityModal.selectedZone.length > 0);

    !localStorage.getItem("file_adjuntarfotodeplanta") &&
      localStorage.setItem("file_adjuntarfotodeplanta", facility.avatarUrl);

    return (
      <EditFacilityModal
        facility={facility}
        editFacilityModalState={editFacilityModal}
        editFacilityId={editFacilityId}
        addButtonDisabled={savingFacility || !buttonDisabled}
        onClickCloseButton={this._handleOnCloseButton}
        onEditFacilityButton={() => this._handleOnEditFacility(editFacilityId)}
        onClientSelectorChange={this._handleOnEditClientSelectorChange}
        onFacilityNameChange={this._handleOnEditFacilityNameChange}
        onBusinessNameSelect={this._handleOnEditBusinessNameSelect}
        handleDeleteTag={this._handleEditDeleteTag}
        handleAddTag={this._handleEditAddTag}
        onDataLoaded={data =>
          this.setState(state => ({
            editFacilityModal: {
              ...state.editFacilityModal,
              suggestions: data
            }
          }))
        }
      />
    );
  };

  _handleEditDeleteTag = i => {
    const { editFacilityModal } = this.state;
    const tags = editFacilityModal.tags.slice(0);
    tags.splice(i, 1);
    this.setState(state => ({
      editFacilityModal: {
        ...state.editFacilityModal,
        tags
      }
    }));
  };

  _handleEditAddTag = tag => {
    const { editFacilityModal } = this.state;
    const tags = [].concat(editFacilityModal.tags, tag);
    this.setState(state => ({
      editFacilityModal: {
        ...state.editFacilityModal,
        tags
      }
    }));
  };

  _handleOnPressEditFacilityButton = facilityId => {
    const { facilities } = this.props;

    const facility =
      facilities && facilities.find(facility => facility.id === facilityId);

    const tags = [];
    facility.facilityManagers.map(manager => {
      tags.push({
        id: manager.id,
        name: manager.user.emailAddress
      });
    });

    this.setState(state => ({
      editFacilityModal: {
        ...state.editFacilityModal,
        visible: true,
        editFacilityId: facilityId,
        selectedClient: facility.businessName.client.id,
        selectedBusinessNameId: facility.businessName.id,
        facilityName: facility.name,
        selectedZone: facility.zone,
        tags
      }
    }));
  };

  _handleOnCloseButton = () => {
    localStorage.removeItem("file_adjuntarfotodeplanta");
    this.setState({
      editFacilityModal: { visible: false }
    });
  };

  _handleOnEditClientSelectorChange = (key, value) => {
    const { selectedBusinessNameId } = this.state.editFacilityModal;

    this.setState(state => ({
      editFacilityModal: {
        ...state.editFacilityModal,
        [key]: value,
        selectedBusinessNameId:
          key === "selectedClient" ? undefined : selectedBusinessNameId
      }
    }));
  };

  _handleOnEditBusinessNameSelect = businessNameId => {
    this.setState(state => ({
      editFacilityModal: {
        ...state.editFacilityModal,
        selectedBusinessNameId: businessNameId
      }
    }));
  };

  _handleOnEditFacilityNameChange = facilityName => {
    this.setState(state => ({
      editFacilityModal: {
        ...state.editFacilityModal,
        facilityName
      }
    }));
  };
}

export default FacilitiesTemplate;
