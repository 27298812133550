import React from "react";

const EditFacilities = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23"
    height="23"
    viewBox="0 0 31 31"
  >
    <defs>
      <path
        id="so9fa"
        d="M1416.044 352.06h-1.679a.24.24 0 0 1-.24-.24v-1.68a.24.24 0 0 1 .24-.24h1.679a.24.24 0 0 1 .24.24v1.68a.24.24 0 0 1-.24.24zm-6.035-.24a.24.24 0 0 1-.241.24h-1.678a.24.24 0 0 1-.241-.24v-1.68a.24.24 0 0 1 .24-.24h1.679a.24.24 0 0 1 .24.24zm8.055-6.794a.912.912 0 0 0-.941.046l-4.954 3.306v-2.538a.926.926 0 0 0-1.44-.767l-5.093 3.395v-7.112c0-.188-.145-.356-.333-.356h-5.961c-.189 0-.342.168-.342.356v14.376c0 .189.153.34.342.34h18.872a.334.334 0 0 0 .334-.34v-9.893a.917.917 0 0 0-.484-.813z"
      />
      <path
        id="so9fb"
        d="M1399.341 339.96h5.961c.189 0 .334-.129.334-.317v-2.722c0-.51-.405-.921-.916-.921h-4.797a.921.921 0 0 0-.923.92v2.723c0 .188.153.318.341.318z"
      />
      <path id="so9fc" d="M1415 357a7 7 0 1 1 14 0 7 7 0 0 1-14 0z" />
      <path
        id="so9fd"
        d="M1425.241 355.598l-1.839-1.84c.234-.192.525-.298.832-.298a1.302 1.302 0 0 1 1.306 1.306c0 .308-.106.598-.299.832zm-4.498 4.507l-.752-.752 4.178-4.178.752.752zm2.322-6.025l.767.77-4.17 4.178-.767-.77zm2.417-.562a1.764 1.764 0 0 0-2.497 0l-4.579 4.577a.229.229 0 0 0-.065.131l-.339 2.513a.228.228 0 0 0 .065.193.233.233 0 0 0 .162.068c.01 0 .02 0 .03-.002l1.514-.204a.23.23 0 0 0-.061-.457l-1.214.164.237-1.753 1.845 1.845a.226.226 0 0 0 .324 0l4.578-4.578c.334-.334.518-.777.518-1.25 0-.472-.184-.915-.518-1.247z"
      />
      <linearGradient
        id="so9fe"
        x1="1423.5"
        x2="1419.09"
        y1="354.59"
        y2="360.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#dd4a58" />
        <stop offset="1" stopColor="#f36875" />
      </linearGradient>
    </defs>
    <use
      fill="#fff"
      fillOpacity="0"
      stroke="#dd4a58"
      strokeMiterlimit="50"
      xlinkHref="#so9fa"
      transform="translate(-1398 -335)"
    />
    <use
      fill="#fff"
      fillOpacity="0"
      stroke="#dd4a58"
      strokeMiterlimit="50"
      xlinkHref="#so9fb"
      transform="translate(-1398 -335)"
    />
    <g>
      <g transform="translate(-1398 -335)">
        <use fill="#fafafa" xlinkHref="#so9fc" />
        <use
          fill="#fff"
          fillOpacity="0"
          stroke="#dd4a58"
          strokeMiterlimit="50"
          xlinkHref="#so9fc"
        />
      </g>
      <g transform="translate(-1398 -335)">
        <use fill="url(#so9fe)" xlinkHref="#so9fd" />
        <use
          fill="#fff"
          fillOpacity="0"
          stroke="#dd4a58"
          strokeMiterlimit="50"
          strokeWidth="0.2"
          xlinkHref="#so9fd"
        />
      </g>
    </g>
  </svg>
);

export default EditFacilities;
