import React, { Component } from "react";
import {
  PDFDownloadLink,
  PDFViewer,
  Page,
  Image,
  Text,
  View,
  Document,
  StyleSheet
} from "@react-pdf/renderer";
import format from "date-fns/format";
import ESLocale from "date-fns/locale/es";

import { francisco } from "../../../assets/images/signatures";

import { tsToHumanReadableDate } from "../../utils/date";
import {
  CHECKLIST_FORMATS,
  CHECKLIST_FORMAT_SCHEMAS
} from "../../utils/checklists";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    paddingBottom: 80,
    textAlign: "justify"
  },
  logo: {
    height: 70,
    margin: 20,
    marginBottom: 20,
    paddingBottom: 20,
    borderBottomWidth: 2,
    borderBottomColor: "#2E4182"
  },
  section: {
    margin: 20,
    marginBottom: 60,
    padding: 20,
    flexGrow: 1
  },
  paragraph: {
    marginTop: 20
  },
  indented: {
    marginLeft: 20
  },
  metadata: {
    textAlign: "right"
  },
  regular: {
    fontSize: 11,
    lineHeight: 1.5
  },
  bold: {
    fontSize: 11,
    lineHeight: 1.5,
    fontFamily: "Helvetica-Bold"
  },
  centered: {
    textAlign: "center"
  },
  signature: {
    width: 60,
    margin: 20
  },
  topSign: {
    marginTop: 18
  }
});

const logoUrl = `${process.env.DOWNLOAD_ASSETS_URL}dkfire_report_header.png`;

const technicianSignature = report =>
  report.inspections[0].checklists
    ? report.inspections.find(inspection =>
      inspection.checklists.find(checklist => checklist.signatureUri)
    ).checklists[0].signatureUri
    : report.inspections[0].signatureUri;

const introductoryMessage = (date, facilityName) =>
  `Por medio de la presente, le informamos que el día ${format(
    date,
    "DD [de] MMMM [de] YYYY",
    { locale: ESLocale }
  )} se realizó la revisión de las condiciones en que se encuentra el sistema contra incendios de ${facilityName}. Nuestro compromiso es brindarle un excelente servicio de seguridad contra incendios es por eso que buscamos revisar cada uno de los equipos dentro de nuestro alcance.`;

const formatInspectionNames = inspectionNames => inspectionNames.join(", ");

const PDFReportTemplate = ({ report }) => {
  const inspections = report.inspections.filter(
    e => e.__typename === "Inspection"
  );

  const specialInspections = report.inspections.filter(
    e =>
      e.__typename === "AdditionalServiceInspection" ||
      e.__typename === "CorrectiveActionInspection"
  );

  const inspectionNames = {};
  inspections.map(
    e =>
      (inspectionNames[e.id] =
        CHECKLIST_FORMATS[e.inspectionChecklist.checklistFormat])
  );

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.section}>
          <View fixed>
            <Image style={styles.logo} src={logoUrl} fixed />
          </View>
          <View style={styles.metadata}>
            <Text style={styles.regular}>Chihuahua, Chihuahua.</Text>
            <Text style={styles.regular}>
              {format(report.date, "DD [de] MMMM [de] YYYY", {
                locale: ESLocale
              })}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.bold}>
              {report.fTec.facility.businessName.client.name}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.regular}>
              {report.introMessage
                ? report.introMessage
                : introductoryMessage(report.date, report.fTec.facility.name)}
            </Text>
          </View>
          <View style={styles.paragraph}>
            <Text style={styles.regular}>
              La revisión constó de la inspección de{" "}
              {formatInspectionNames(Object.values(inspectionNames))}.
            </Text>
          </View>
          <View>
            <View style={styles.paragraph}>
              <Text style={[styles.bold, styles.centered]}>
                Contenido del reporte de trabajo de {report.fTec.facility.name}
              </Text>
            </View>
            <View style={styles.paragraph}>
              {Object.keys(inspectionNames).map(inspection => (
                <Text style={styles.regular}>
                  Inspección de {inspectionNames[inspection]}
                </Text>
              ))}
              <Text style={styles.regular}>Acciones correctivas</Text>
              <Text style={styles.regular}>Observaciones</Text>
              <Text style={styles.regular}>Evidencia fotográfica</Text>
            </View>
          </View>
          <View>
            <View style={styles.paragraph}>
              <Text style={[styles.bold, styles.centered]}>
                Resultados del reporte de trabajo de {report.fTec.facility.name}
              </Text>
            </View>
            <View style={styles.paragraph}>
              <Text style={styles.regular}>
                A continuación se describen las actividades realizadas,
                observaciones, acciones correctivas y recomendaciones para el
                funcionamiento adecuado del sistema contra incendio.
              </Text>
            </View>
            {Object.keys(inspectionNames).map(inspection => {
              const inspectionName = inspectionNames[inspection];
              const actualInspection = report.inspections.find(
                e => e.id === inspection
              );
              const checklistFormat =
                actualInspection &&
                actualInspection.inspectionChecklist.checklistFormat;

              const checklists = actualInspection.checklists.sort((a, b) => {
                if (a.number < b.number) return -1;
                if (a.number > b.number) return 1;
                return 0;
              });

              return checklists.map(checklist => {
                const checklistSchema = CHECKLIST_FORMAT_SCHEMAS[
                  checklistFormat
                ]().default;
                const checklistItems = JSON.parse(checklist.checklistItems);
                const checklistPhotos =
                  Object.values(checklistItems).filter(e => e.photos) || [];
                const checklistObservations =
                  Object.values(checklistItems).filter(e => e.observation) ||
                  [];
                const checklistActions =
                  Object.values(checklistItems).filter(
                    e => e.correctiveAction
                  ) || [];

                const regularPhotosURIs = checklistPhotos
                  .map(check =>
                    check.photos.map(photos => Object.values(photos)[0])
                  )
                  .flat();

                const photoEvidence = checklistItems.photoEvidence;

                photoEvidence !== undefined &&
                  regularPhotosURIs.push(photoEvidence);

                const correctiveActionPhotos = Object.keys(checklistItems)
                  .map(
                    item =>
                      (checklistItems[item] &&
                        checklistItems[item].correctiveAction &&
                        checklistItems[item].correctiveAction.photos) ||
                      []
                  )
                  .flat();

                const observationPhotos = Object.keys(checklistItems)
                  .map(
                    item =>
                      (checklistItems[item] &&
                        checklistItems[item].observation &&
                        checklistItems[item].observation.photos) ||
                      []
                  )
                  .flat();

                return (
                  <View key={checklist.id} break>
                    <View style={styles.paragraph}>
                      <Text style={[styles.bold, styles.centered]}>
                        Inspección de {inspectionName} #{checklist.number}
                      </Text>
                    </View>
                    <View style={[styles.paragraph, styles.indented]}>
                      {Object.keys(checklistItems).map(item => {
                        if (
                          item !== "area" &&
                          item !== "key" &&
                          item !== "photoEvidence"
                        ) {
                          let label =
                            checklistSchema.checks[item] &&
                            checklistSchema.checks[item].label &&
                            checklistSchema.checks[item].label.replace(
                              /(.{1,80})(?:\n|$| )/g,
                              "$1\n"
                            );

                          if (
                            item === "turnOnTime" &&
                            typeof checklistItems[item].value === "boolean"
                          ) {
                            label = "Tiempo de encendido de la bomba 10min";
                            checklistSchema.checks[item].type = "BOOL";
                          }

                          return (
                            <View
                              style={{
                                flexDirection: "row",
                                marginBottom: 20
                              }}
                              wrap={false}
                            >
                              <View style={{ flex: 1, fontWeight: "700" }}>
                                <Text style={styles.regular}>{label}</Text>
                              </View>
                              <View style={{ marginBottom: 10 }}>
                                {checklistSchema.checks[item] &&
                                  checklistSchema.checks[item].type ===
                                    "BOOL" && (
                                  <View
                                    style={{
                                      width: 50,
                                      textAlign: "center",
                                      backgroundColor: checklistItems[item]
                                        .value
                                        ? "#55cd6c"
                                        : "#dd4a58",
                                      borderTopLeftRadius: 10,
                                      borderTopRightRadius: 10,
                                      borderBottomRightRadius: 10,
                                      borderBottomLeftRadius: 10
                                    }}
                                  >
                                    <Text
                                      style={{
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        fontSize: 12,
                                        color: "#ffffff"
                                      }}
                                    >
                                      {checklistItems[item].value
                                        ? "Si"
                                        : "No"}
                                    </Text>
                                  </View>
                                )}
                                {checklistSchema.checks[item] &&
                                  checklistSchema.checks[item].type ===
                                    "NUMBER" && (
                                  <View
                                    style={{
                                      width: 120,
                                      textAlign: "center"
                                    }}
                                  >
                                    <Text
                                      style={{
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        fontSize: 12,
                                        textAlign: "right"
                                      }}
                                    >
                                      {checklistItems[item].value}{" "}
                                      {checklistSchema.checks[item].unit}
                                    </Text>
                                  </View>
                                )}
                              </View>
                            </View>
                          );
                        }
                      })}
                      <View style={styles.paragraph}>
                        <Text style={styles.bold}>Observaciones</Text>
                        {checklistObservations.length > 0 && (
                          <View>
                            {checklistObservations.map(check => (
                              <Text style={styles.regular}>
                                - {check.observation.value}
                              </Text>
                            ))}
                            {observationPhotos.length > 0 && (
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  flexWrap: "wrap",
                                  marginTop: 20
                                }}
                              >
                                {observationPhotos.map(photo => (
                                  <Image
                                    style={{
                                      height: 200,
                                      marginLeft: 20,
                                      marginBottom: 20
                                    }}
                                    src={`${photo}?x-request=pdf`}
                                  />
                                ))}
                              </View>
                            )}
                          </View>
                        )}
                        {checklistObservations.length === 0 && (
                          <View>
                            <Text style={styles.regular}>
                              Sin observaciones
                            </Text>
                          </View>
                        )}
                      </View>
                      <View style={styles.paragraph}>
                        <Text style={styles.bold}>Acciones correctivas</Text>
                        {checklistActions.length > 0 && (
                          <View>
                            {checklistActions.map(check => (
                              <Text style={styles.regular}>
                                - {check.correctiveAction.value}
                              </Text>
                            ))}
                            {correctiveActionPhotos.length > 0 && (
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  flexWrap: "wrap",
                                  marginTop: 20
                                }}
                              >
                                {correctiveActionPhotos.map(photo => (
                                  <Image
                                    style={{
                                      height: 200,
                                      marginLeft: 20,
                                      marginBottom: 20
                                    }}
                                    src={`${photo}?x-request=pdf`}
                                  />
                                ))}
                              </View>
                            )}
                          </View>
                        )}
                        {checklistActions.length === 0 && (
                          <View>
                            <Text style={styles.regular}>
                              Sin acciones correctivas
                            </Text>
                          </View>
                        )}
                      </View>
                      <View break>
                        <View style={styles.paragraph}>
                          <Text style={styles.bold}>Fotografías</Text>
                          {regularPhotosURIs.length > 0 && (
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                marginTop: 20
                              }}
                            >
                              {regularPhotosURIs.map(photo => (
                                <Image
                                  style={{
                                    height: 200,
                                    marginLeft: 20,
                                    marginBottom: 20
                                  }}
                                  src={`${photo}?x-request=pdf`}
                                />
                              ))}
                            </View>
                          )}
                          {regularPhotosURIs.length === 0 && (
                            <View style={{ flexDirection: "row" }}>
                              <Text style={styles.regular}>
                                Sin evidencia fotográfica
                              </Text>
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                );
              });
            })}
            {specialInspections.length > 0 &&
              specialInspections.map(inspection => {
                const photos = JSON.parse(inspection.photos) || [];
                const observation = inspection.observation;

                const title =
                  inspection.__typename === "AdditionalServiceInspection"
                    ? "Servicio adicional"
                    : "Acción correctiva";

                const details =
                  inspection.__typename === "AdditionalServiceInspection"
                    ? inspection.additionalService.details
                    : inspection.correctiveAction.details;

                return (
                  <View key={inspection.id}>
                    <View style={styles.paragraph}>
                      <Text style={styles.regular}>
                        {title} – {details}
                      </Text>
                    </View>
                    <View style={[styles.paragraph, styles.indented]}>
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1, paddingRight: 200 }}>
                          <Text style={styles.regular}>{observation}</Text>
                        </View>
                      </View>
                      <View style={styles.paragraph}>
                        <Text style={styles.bold}>Fotografías</Text>
                        {photos.length > 0 && (
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              marginTop: 20
                            }}
                          >
                            {photos.map(photo => (
                              <Image
                                style={{
                                  height: 200,
                                  marginLeft: 20,
                                  marginBottom: 20
                                }}
                                src={`${Object.values(photo)[0]}?x-request=pdf`}
                              />
                            ))}
                          </View>
                        )}
                        {photos.length === 0 && (
                          <View style={{ flexDirection: "row" }}>
                            <Text style={styles.regular}>
                              Sin evidencia fotográfica
                            </Text>
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                );
              })}
          </View>
          <View break>
            <View style={styles.paragraph}>
              <Text style={[styles.bold, styles.centered]}>
                Conclusiones del reporte de trabajo de{" "}
                {report.fTec.facility.name}
              </Text>
            </View>
            <View style={styles.paragraph}>
              <Text style={styles.regular}>
                De acuerdo a los resultados obtenidos de las inspecciones
                realizadas de{" "}
                {formatInspectionNames(Object.values(inspectionNames))}. Se
                procederá a atender las observaciones y las acciones correctivas
                encontradas. Se seguirán realizando las inspecciones programadas
                para asegurar el funcionamiento óptimo de los equipos
                involucrados en la planta {report.fTec.facility.name}.
              </Text>
            </View>
          </View>
          <View style={[styles.paragraph, { flexDirection: "row" }]}>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 10,
                  margin: 20,
                  marginBottom: 0,
                  borderBottomWidth: 1
                }}
              >
                <Image
                  style={[styles.signature, { width: 148, height: 84 }]}
                  src={francisco}
                  fixed
                />
              </View>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View
                  style={{
                    width: 218,
                    height: 64,
                    textAlign: "center",
                    fontSize: 10
                  }}
                >
                  <Text style={[styles.regular, styles.topSign]}>
                    Ing. Francisco Javier Yañez Moreno
                  </Text>
                  <Text style={[styles.bold]}>Gerente de Mantenimiento</Text>
                  <Text style={[styles.regular]}>
                    Francisco.yanez@demek.com
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 135,
                  paddingTop: 10,
                  margin: 20,
                  marginBottom: 0,
                  borderBottomWidth: 1
                }}
              >
                <Image
                  style={styles.signature}
                  src={`data:image/png;base64,${decodeURI(
                    report.clientSignatureUri
                  )}`}
                  fixed
                />
              </View>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Image
                  style={{ width: 218, height: 64 }}
                  src="https://dkfire-prod.s3.us-east-2.amazonaws.com/1561654305383-client_signature_footer.png"
                />
              </View>
            </View>
          </View>
          <View style={{ position: "absolute", bottom: -60 }} fixed>
            <Text style={[styles.regular, { fontSize: 9, textAlign: "right" }]}>
              Centro Ejecutivo No. 5700 Complejo Industrial El Saucito C.P.
              31125 Chihuahua, Chih.
            </Text>
            <Text style={[styles.regular, { fontSize: 9, textAlign: "right" }]}>
              Tel. +52 (614) 439 3960
            </Text>
            <Text style={[styles.regular, { fontSize: 9, textAlign: "right" }]}>
              www.dkfire.mx
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFReportTemplate;
