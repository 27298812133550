export { default as ClientsTemplate } from "./ClientsTemplate";
export { default as TechniciansTemplate } from "./TechniciansTemplate";
export { default as ContractsTemplate } from "./ContractsTemplate";
export { default as DashboardTemplate } from "./DashboardTemplate";
export { default as FacilitiesTemplate } from "./FacilitiesTemplate";
export { default as InspectionsTemplate } from "./InspectionsTemplate";
export { default as DoneInspectionsTemplate } from "./DoneInspectionsTemplate";
export { default as ProjectsTemplate } from "./ProjectsTemplate";
export {
  default as AdditionalServicesTemplate
} from "./AdditionalServicesTemplate";
export {
  default as CorrectiveActionsTemplate
} from "./CorrectiveActionsTemplate";
export { default as TechnicianListTemplate } from "./TechnicianListTemplate";
export { default as ContractsListTemplate } from "./ContractsListTemplate";
export { default as ReportsListTemplate } from "./ReportsListTemplate";
export {
  default as InspectionsCalendarTemplate
} from "./InspectionsCalendarTemplate";
export {
  default as DelayedInspectionsTemplate
} from "./DelayedInspectionsTemplate";
export {
  default as RescheduledInspectionsTemplate
} from "./RescheduledInspectionsTemplate";
export {
  default as AdditionalServicesListTemplate
} from "./AdditionalServicesListTemplate";
export { default as NotificationsTemplate } from "./NotificationsTemplate";
export { default as LoginTemplate } from "./LoginTemplate";
export { default as ReportsTemplate } from "./ReportsTemplate";
export { default as ScheduleTemplate } from "./ScheduleTemplate";
export { default as PDFReportTemplate } from "./PDFReportTemplate";
export { default as UsersTemplate } from "./UsersTemplate";
