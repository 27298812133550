import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import fuzzy from "fuzzy";
import format from "date-fns/format";

import { bugsnagClient } from "../../bugsnag";
import { currency } from "../../utils/formatter";
import {
  getTechniciansByFacility,
  getContractsByProject
} from "../../gql/queries";
import {
  createAdditionalService,
  setAdditionalServiceStatus,
  deleteAdditionalService,
  editAdditionalService
} from "../../gql/mutations";

import {
  Button,
  Container,
  CloseIcon,
  CustomDropdown,
  LabeledSelect,
  DeleteIcon,
  EditIcon,
  Heading,
  Table,
  Modal,
  Layout,
  LabeledInput,
  TextArea,
  TextInput,
  BigRemoveButton,
  PlusIcon
} from "../atoms";
import {
  FacilitySelector,
  TableContainerHeader,
  Spinner,
  ModalPortal
} from "../molecules";
import {
  DeleteAdditionalServiceModal,
  EditAdditionalServiceModal,
  ViewAdditionalServiceModal
} from "../organisms";
import styles from "./InspectionsTemplateStyles.scss";

const ADDITIONAL_SERVICE_STATUS = {
  null: "Pendiente",
  PURCHASE_ORDER: "Orden de compra",
  JOB_FINISHED: "Trabajo terminado",
  INVOICED: "Facturado"
};

const ADDITIONAL_SERVICE_STATUSES_DICT = {
  null: [],
  PURCHASE_ORDER: ["null"],
  JOB_FINISHED: ["null", "PURCHASE_ORDER"],
  INVOICED: ["null", "PURCHASE_ORDER", "JOB_FINISHED"]
};

class AdditionalServicesTemplate extends Component {
  state = {
    filter: undefined,
    dateRangeFilter: {},
    addAdditionalServiceModal: {
      visible: false,
      creatingAdditionalService: false,
      status: "null"
    },
    additionalServiceStatuses: {},
    deleteAdditionalServiceModal: {
      deleteAdditionalServiceModalVisible: false,
      deleteButtonDisabled: false
    },
    editAdditionalServiceModal: {
      visible: false,
      editingAdditionalService: false,
      status: "null",
      newStatus: "null"
    },
    viewAdditionalServiceModal: {
      visible: false
    }
  };

  static propTypes = {
    loadingAdditionalServices: PropTypes.bool.isRequired,
    additionalServices: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    loadingAdditionalServices: true,
    additionalServices: []
  };

  componentDidUpdate(_, prevState) {
    const { refetch } = this.props;
    const { dateRangeFilter: prevDateRangeFilter } = prevState;
    const { dateRangeFilter } = this.state;

    if (
      (!prevDateRangeFilter.startDate || !prevDateRangeFilter.endDate) &&
      dateRangeFilter.startDate &&
      dateRangeFilter.endDate
    ) {
      refetch({
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
    }

    if (prevDateRangeFilter.startDate && prevDateRangeFilter.endDate) {
      if (
        prevDateRangeFilter.startDate !== dateRangeFilter.startDate ||
        prevDateRangeFilter.endDate !== dateRangeFilter.endDate
      ) {
        refetch({
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
      }
    }

    if (
      prevDateRangeFilter.startDate &&
      prevDateRangeFilter.endDate &&
      (!dateRangeFilter.startDate && !dateRangeFilter.endDate)
    ) {
      refetch({ startDate: undefined, endDate: undefined });
    }
  }

  render() {
    const {
      filter,
      addAdditionalServiceModal,
      deleteAdditionalServiceModal: { deleteAdditionalServiceModalVisible },
      editAdditionalServiceModal,
      viewAdditionalServiceModal,
      dateRangeFilter
    } = this.state;
    const { loadingAdditionalServices, additionalServices } = this.props;

    const filteredAdditionalServices =
      additionalServices &&
      fuzzy.filter(filter, additionalServices, {
        extract: el => (el.facility || {}).name || el.fTec.facility.name
      });

    const data = filter
      ? filteredAdditionalServices &&
      filteredAdditionalServices.map(result => result.original)
      : additionalServices;

    const formattedData = data
      .map(d => {
        if (d.additionalServiceInspection) {
          const scheduledDate = d.additionalServiceInspection.scheduledDate;
          d.additionalServiceInspection.timestamp = new Date(
            scheduledDate
          ).getTime();
        }

        return d;
      })
      .sort((a, b) => {
        if (a.additionalServiceInspection && !b.additionalServiceInspection) {
          return -1;
        } else if (
          a.additionalServiceInspection &&
          b.additionalServiceInspection
        ) {
          const aScheduledDate = new Date(
            a.additionalServiceInspection.timestamp
          ).getTime();
          const bScheduledDate = new Date(
            b.additionalServiceInspection.timestamp
          ).getTime();
          if (aScheduledDate > bScheduledDate) {
            return -1;
          } else if (aScheduledDate < bScheduledDate) {
            return 1;
          }
        }

        return 0;
      });

    return (
      <>
        <ModalPortal
          modal={this._renderAddAdditionalServiceModal}
          visible={addAdditionalServiceModal.visible}
          onBackdropClick={this._closeAddAdditionalServiceModal}
        />
        <ModalPortal
          modal={this._renderDeleteAdditionalServiceModal}
          visible={deleteAdditionalServiceModalVisible}
          onBackdropClick={this._handleOnDeleteBackButton}
        />
        <ModalPortal
          modal={this._renderEditAdditionalServiceModal}
          visible={editAdditionalServiceModal.visible}
          onBackdropClick={this._closeEditAdditionalServiceModal}
        />
        <ModalPortal
          modal={this._renderViewAdditionalServiceModal}
          visible={viewAdditionalServiceModal.visible}
          onBackdropClick={this._closeViewAdditionalServiceModal}
        />
        <Heading>Servicios adicionales</Heading>
        <Container noPadding>
          <div style={{ overflow: "hidden", margin: "30px" }}>
            <TextInput
              width={580}
              placeholder="Buscar por nombre de planta"
              onChange={e => this._filterAdditionalServices(e)}
            />
            <div
              style={{
                display: "inline-block",
                textAlign: "center",
                marginLeft: "30px",
                position: "absolute"
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "20px",
                  fontWeight: "bold"
                }}
              >
                Filtrar por rango de fechas
              </div>
              <LabeledInput
                index={1}
                type="date-picker"
                name="startDate"
                defaultValue={dateRangeFilter.startDate}
                componentProps={{
                  dayPickerProps: {
                    disabledDays: {
                      after: new Date(dateRangeFilter.endDate)
                    }
                  }
                }}
                onChange={this._handleOnDateInputChange}
              />
              <span style={{ marginLeft: "15px" }}>
                <LabeledInput
                  index={2}
                  type="date-picker"
                  name="endDate"
                  defaultValue={dateRangeFilter.endDate}
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(dateRangeFilter.startDate)
                      }
                    }
                  }}
                  onChange={this._handleOnDateInputChange}
                />
              </span>
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "5px"
                }}
              >
                <BigRemoveButton onClick={this._handleOnResetDateRangeFilter} />
              </span>
            </div>
            <div style={{ float: "right", marginLeft: "10px" }}>
              <Button onClick={this._handleOnClickAddButton}>
                Agregar
                <div className={styles.plusIconWrapper}>
                  <PlusIcon tint="#ffffff" />
                </div>
              </Button>
            </div>
          </div>
          {loadingAdditionalServices && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
          {additionalServices.length > 0 && (
            <Table.Main
              columns={[
                { "contract.facility.name": "Planta" },
                { "contract.facility.businessName.client.name": "Cliente" },
                { title: "Descripción" },
                { "additionalServiceInspection.scheduledDate": "Fecha" },
                { budget: "Monto MXN" },
                { budgetUSD: "Monto USD" },
                { exchangeRate: "Tipo de cambio" },
                { purchaseOrder: "OC" },
                { jobFinished: "TT" },
                { invoiced: "F" },
                {
                  sticky: {
                    style: {
                      position: "absolute",
                      whiteSpace: "nowrap",
                      right: 0,
                      marginTop: "-4px",
                      padding: "24px 14px",
                      background: "#ffffff",
                      borderLeft: "1px solid rgb(234, 234, 234)",
                      width: "195px"
                    }
                  }
                }
              ]}
              data={formattedData}
              row={(additionalService, lastAdditionalServiceRow) =>
                this._renderAdditionalServiceRow(
                  additionalService,
                  lastAdditionalServiceRow
                )
              }
            />
          )}
        </Container>
      </>
    );
  }

  _handleOnResetDateRangeFilter = () => {
    this.setState({
      dateRangeFilter: {}
    });
  };

  _filterAdditionalServices = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnDateInputChange = e => {
    if (e.props) {
      return this.setState(state => ({
        dateRangeFilter: {
          ...state.dateRangeFilter,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _renderAdditionalServiceRow = (additionalService, lastContactRow) => {
    const { additionalServiceStatuses } = this.state;
    const isUpdatingStatus =
      additionalServiceStatuses[additionalService.id] &&
      additionalServiceStatuses[additionalService.id].updating;

    const scheduledDate = additionalService.additionalServiceInspection
      ? format(
        additionalService.additionalServiceInspection.scheduledDate,
        "DD/MM/YYYY"
      )
      : "N/A";

    const purchaseOrderStatus = additionalServiceStatuses[additionalService.id]
      ? additionalServiceStatuses[additionalService.id].purchaseOrder
      : additionalService.purchaseOrder;

    const jobFinishedStatus = additionalServiceStatuses[additionalService.id]
      ? additionalServiceStatuses[additionalService.id].jobFinished
      : additionalService.jobFinished;

    const invoicedStatus = additionalServiceStatuses[additionalService.id]
      ? additionalServiceStatuses[additionalService.id].invoiced
      : additionalService.invoiced;

    const facility =
      additionalService.fTec === null
        ? additionalService.facility
        : additionalService.fTec.facility;

    return (
      <tr
        testid="additionalServices-table-row"
        key={additionalService.id}
        style={{ borderBottom: !lastContactRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>{facility.name}</Table.Cell>
        <Table.Cell>{facility.businessName.client.name}</Table.Cell>
        <Table.Cell>{additionalService.details}</Table.Cell>
        <Table.Cell>{scheduledDate}</Table.Cell>
        <Table.Cell>{currency(additionalService.budget)}</Table.Cell>
        <Table.Cell>{currency(additionalService.budgetUSD)}</Table.Cell>
        <Table.Cell>{currency(additionalService.exchangeRate || 0)}</Table.Cell>
        <Table.Cell>
          <input
            type="checkbox"
            name="purchaseOrder"
            checked={purchaseOrderStatus}
            disabled={isUpdatingStatus}
            onChange={() =>
              this._handleOnAdditionalServiceStatusChange(
                additionalService.id,
                "purchaseOrder",
                purchaseOrderStatus
              )
            }
          />
        </Table.Cell>
        <Table.Cell>
          <input
            type="checkbox"
            name="jobFinished"
            checked={jobFinishedStatus}
            disabled={isUpdatingStatus}
            onChange={() =>
              this._handleOnAdditionalServiceStatusChange(
                additionalService.id,
                "jobFinished",
                jobFinishedStatus
              )
            }
          />
        </Table.Cell>
        <Table.Cell>
          <input
            type="checkbox"
            name="invoiced"
            checked={invoicedStatus}
            disabled={isUpdatingStatus}
            onChange={() =>
              this._handleOnAdditionalServiceStatusChange(
                additionalService.id,
                "invoiced",
                invoicedStatus
              )
            }
          />
        </Table.Cell>
        <Table.Cell style={{ position: "static" }}>
          <Table.Cell fixed>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                style="outline"
                onClick={() =>
                  this._handleOnPressViewAdditionalServiceButton(
                    additionalService.id
                  )
                }
              >
                Ver
              </Button>
            </div>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                paddingHorizontal={8}
                style="silver"
                iconOnly
                onClick={() =>
                  this._handleOnPressDeleteAdditionalServiceButton(
                    additionalService.id
                  )
                }
              >
                <DeleteIcon />
              </Button>
            </div>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
              disabled={
                additionalService.status === "JOB_FINISHED" ||
                additionalService.status === "INVOICED"
              }
              onClick={() =>
                this._handleOnPressEditAdditionalServiceButton(
                  additionalService.id
                )
              }
            >
              <EditIcon />
            </Button>
          </Table.Cell>
          <div style={{ opacity: 0, zIndex: -1000 }}>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button radius={7} paddingVertical={8} style="outline">
                Ver
              </Button>
            </div>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                paddingHorizontal={8}
                style="silver"
                iconOnly
              >
                <DeleteIcon />
              </Button>
            </div>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
            >
              <EditIcon />
            </Button>
          </div>
        </Table.Cell>
      </tr>
    );
  };

  _handleOnAdditionalServiceStatusChange = (
    additionalServiceId,
    status,
    statusState
  ) => {
    this.setState(state => ({
      ...state,
      additionalServiceStatuses: {
        ...state.additionalServiceStatuses,
        [additionalServiceId]: {
          ...(state.additionalServiceStatuses[additionalServiceId] || {}),
          updating: true
        }
      }
    }));

    setAdditionalServiceStatus(additionalServiceId, status, !statusState)
      .then(() =>
        this.setState(state => ({
          ...state,
          additionalServiceStatuses: {
            ...state.additionalServiceStatuses,
            [additionalServiceId]: {
              ...(state.additionalServiceStatuses[additionalServiceId] || {}),
              [status]: !statusState,
              updating: false
            }
          }
        }))
      )
      .catch(() =>
        this.setState(state => ({
          ...state,
          additionalServiceStatuses: {
            ...state.additionalServiceStatuses,
            [additionalServiceId]: {
              ...(state.additionalServiceStatuses[additionalServiceId] || {}),
              updating: false
            }
          }
        }))
      );
  };

  // TODO: Move elsewhere
  _renderAddAdditionalServiceModal = () => {
    const { addAdditionalServiceModal } = this.state;

    const addButtonEnabled =
      (addAdditionalServiceModal.nameAndLastNameInput &&
        addAdditionalServiceModal.title &&
        addAdditionalServiceModal.startDate &&
        addAdditionalServiceModal.details &&
        addAdditionalServiceModal.budgetMXN) ||
      (addAdditionalServiceModal.nameAndLastNameInput &&
        addAdditionalServiceModal.title &&
        addAdditionalServiceModal.startDate &&
        addAdditionalServiceModal.details &&
        addAdditionalServiceModal.budgetUSD);

    const purchaseOrderStatus = addAdditionalServiceModal.purchaseOrder;
    const jobFinishedStatus = addAdditionalServiceModal.jobFinished;
    const invoicedStatus = addAdditionalServiceModal.invoiced;

    return (
      <Container width={1118}>
        <div
          onClick={() => this._handleOnCloseButton()}
          style={{ position: "absolute", cursor: "pointer", right: 30 }}
        >
          <CloseIcon />
        </div>
        <Modal.Header>Servicio Adicional</Modal.Header>
        <Modal.Section paddingVertical={30} paddingHorizontal={100}>
          <FacilitySelector
            selected={[
              addAdditionalServiceModal.selectedClient,
              addAdditionalServiceModal.selectedBusinessName,
              addAdditionalServiceModal.selectedFacilityId
            ]}
            onChange={(key, value) =>
              this._handleOnFacilitySelectorChange(key, value)
            }
            onFacilitySelect={facilityId =>
              this._handleOnFacilitySelect(facilityId)
            }
          />
        </Modal.Section>
        <Modal.Section
          style={{ margin: "0 50px", borderTop: "1px solid #d7d7d7" }}
          paddingVertical={30}
          paddingHorizontal={100}
        >
          <Layout.Grid>
            <Layout.Column>
              <div style={{ marginBottom: "40px" }}>
                {this._renderLabeledTechnicians(addAdditionalServiceModal)}
              </div>
              <div style={{ paddingRight: "70px", marginBottom: "40px" }}>
                <LabeledInput
                  index={4}
                  type="currency"
                  name="budgetMXN"
                  label="Monto MXN"
                  defaultValue={addAdditionalServiceModal.budgetMXN}
                  onChange={this._handleOnInputChange}
                />
              </div>
              {this._renderLabeledContracts(addAdditionalServiceModal)}
            </Layout.Column>
            <Layout.Column>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={2}
                  name="title"
                  label="Concepto"
                  defaultValue={addAdditionalServiceModal.title}
                  onChange={this._handleOnInputChange}
                />
              </div>
              <LabeledInput
                index={5}
                type="currency"
                name="budgetUSD"
                label="Monto USD"
                defaultValue={addAdditionalServiceModal.budgetUSD}
                onChange={this._handleOnInputChange}
              />
            </Layout.Column>
            <Layout.Column>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={3}
                  type="date-picker"
                  name="startDate"
                  label="Comienza"
                  defaultValue={addAdditionalServiceModal.startDate}
                  onChange={this._handleOnInputChange}
                />
              </div>
              <LabeledInput
                index={5}
                type="currency"
                name="exchangeRate"
                label="Tipo de cambio"
                defaultValue={addAdditionalServiceModal.exchangeRate}
                onChange={this._handleOnInputChange}
              />
            </Layout.Column>
          </Layout.Grid>
        </Modal.Section>
        <Modal.Header>Información Adicional</Modal.Header>
        <Modal.Section paddingVertical={30} paddingHorizontal={100}>
          <Layout.Grid>
            <Layout.Column>
              <div
                style={{
                  marginLeft: "1px",
                  marginBottom: "8px",
                  color: "#5A5E61",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "14px"
                }}
              >
                Detalles
              </div>
              <TextArea
                name="details"
                width={524}
                height={134}
                maxLength={1000}
                onChange={this._handleOnInputChange}
              />
            </Layout.Column>
            <Layout.Column style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: 100,
                  transform: "translate(-50%, -50%)"
                }}
              >
                <div>
                  <div style={{ marginBottom: 10, fontWeight: "700" }}>OC</div>
                  <div>
                    <input
                      type="checkbox"
                      checked={purchaseOrderStatus}
                      onChange={() =>
                        this._handleOnStatusChange(
                          "purchaseOrder",
                          purchaseOrderStatus
                        )
                      }
                    />
                  </div>
                </div>
                <div>
                  <div style={{ marginBottom: 10, fontWeight: "700" }}>TT</div>
                  <div>
                    <input
                      type="checkbox"
                      checked={jobFinishedStatus}
                      onChange={() =>
                        this._handleOnStatusChange(
                          "jobFinished",
                          jobFinishedStatus
                        )
                      }
                    />
                  </div>
                </div>
                <div>
                  <div style={{ marginBottom: 10, fontWeight: "700" }}>F</div>
                  <div>
                    <input
                      type="checkbox"
                      checked={invoicedStatus}
                      onChange={() =>
                        this._handleOnStatusChange("invoiced", invoicedStatus)
                      }
                    />
                  </div>
                </div>
              </div>
            </Layout.Column>
          </Layout.Grid>
        </Modal.Section>
        <Modal.Footer>
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <Button
              style="outline"
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={() => this._handleOnCloseButton()}
            >
              Volver
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={this._handleOnCreateAdditionalServiceButton}
            disabled={
              !addButtonEnabled ||
              addAdditionalServiceModal.creatingAdditionalService
            }
          >
            Añadir
          </Button>
        </Modal.Footer>
      </Container>
    );
  };

  _renderDeleteAdditionalServiceModal = () => {
    const {
      deleteAdditionalServiceModal: {
        deleteButtonDisabled,
        deleteAdditionalServiceId
      }
    } = this.state;

    const { additionalServices } = this.props;
    const additionalService = additionalServices.find(
      additionalService => additionalService.id === deleteAdditionalServiceId
    );

    return (
      <DeleteAdditionalServiceModal
        deleteButtonDisabled={deleteButtonDisabled}
        deleteAdditionalServiceId={deleteAdditionalServiceId}
        additionalService={additionalService}
        onBackButton={this._handleOnDeleteBackButton}
        onDeleteAdditionalServiceButton={
          this._handleOnDeleteAdditionalServiceButton
        }
      />
    );
  };

  _renderEditAdditionalServiceModal = () => {
    const { editAdditionalServiceModal } = this.state;

    return (
      <EditAdditionalServiceModal
        editAdditionalServiceModalState={editAdditionalServiceModal}
        editAdditionalServiceId={editAdditionalServiceModal.id}
        editLabeledTechnicians={this._renderEditLabeledTechnicians}
        handleOnEditFacilitySelectorChange={
          this._handleOnEditFacilitySelectorChange
        }
        handleOnEditInputChange={this._handleOnEditInputChange}
        editLabeledContracts={this._renderEditLabeledContracts}
        handleOnEditStatusChange={this._handleOnEditStatusChange}
        onEditCloseButton={this._handleOnEditCloseButton}
        handleOnEditFacilitySelect={this._handleOnEditFacilitySelect}
        onEditAdditionalServiceButton={
          this._handleOnEditAdditionalServiceButton
        }
      />
    );
  };

  _renderViewAdditionalServiceModal = () => {
    const {
      viewAdditionalServiceModal: { viewAdditionalServiceId }
    } = this.state;

    const { additionalServices } = this.props;
    const additionalService = additionalServices.find(
      additionalService => additionalService.id === viewAdditionalServiceId
    );

    return (
      <ViewAdditionalServiceModal
        additionalService={additionalService}
        viewAdditionalServiceId={viewAdditionalServiceId}
        onViewCloseButton={this._handleOnViewCloseButton}
      />
    );
  };

  _renderLabeledTechnicians = addAdditionalServiceModal => {
    const {
      selectedFacilityId,
      nameAndLastNameInput
    } = addAdditionalServiceModal;

    if (selectedFacilityId) {
      return (
        <Query
          query={getTechniciansByFacility}
          variables={{
            facilityId: selectedFacilityId
          }}
        >
          {({ loading, error, data }) => {
            if (error) {
              bugsnagClient.notify(error);
              console.error(error);
              return null;
            }

            if (data) {
              let technicians = [];
              if (
                data.techniciansByFacility !== undefined &&
                data.techniciansByFacility.length > 0
              ) {
                technicians = data.techniciansByFacility.map(technician => ({
                  id: technician.facilityTechnicianId,
                  name: `${technician.lastName}, ${technician.firstName}`
                }));
              }

              return (
                <LabeledSelect
                  style={{ width: 188, margin: 0 }}
                  label="Asignado a"
                  name="nameAndLastNameInput"
                  keyedOptions={technicians}
                  defaultValue={nameAndLastNameInput && nameAndLastNameInput}
                  onSelect={e =>
                    this._handleOnInputChangeTechnician(e.currentTarget.value)
                  }
                />
              );
            }

            return (
              <LabeledSelect
                style={{ width: 188, margin: 0 }}
                label="Nombre y Apellido"
                name="nameAndLastNameInput"
                keyedOptions={[]}
                disabled
              />
            );
          }}
        </Query>
      );
    }

    return (
      <LabeledSelect
        style={{ width: 188, margin: 0 }}
        label="Asignado a"
        name="nameAndLastNameInput"
        keyedOptions={[]}
        disabled
      />
    );
  };

  _renderEditLabeledTechnicians = editAdditionalServiceModal => {
    const {
      selectedFacilityId,
      nameAndLastNameInput
    } = editAdditionalServiceModal;

    if (selectedFacilityId) {
      return (
        <Query
          query={getTechniciansByFacility}
          variables={{
            facilityId: selectedFacilityId
          }}
        >
          {({ loading, error, data }) => {
            if (error) {
              bugsnagClient.notify(error);
              console.error(error);
              return null;
            }

            if (data) {
              let technicians = [];
              if (
                data.techniciansByFacility !== undefined &&
                data.techniciansByFacility.length > 0
              ) {
                technicians = data.techniciansByFacility.map(technician => ({
                  id: technician.facilityTechnicianId,
                  name: `${technician.lastName}, ${technician.firstName}`
                }));
              }

              return (
                <LabeledSelect
                  style={{ width: 188, margin: 0 }}
                  label="Asignado a"
                  name="nameAndLastNameInput"
                  keyedOptions={technicians}
                  defaultValue={nameAndLastNameInput && nameAndLastNameInput}
                  onSelect={e =>
                    this._handleOnEditInputChangeTechnician(
                      e.currentTarget.value
                    )
                  }
                />
              );
            }

            return (
              <LabeledSelect
                style={{ width: 188, margin: 0 }}
                label="Nombre y Apellido"
                name="nameAndLastNameInput"
                keyedOptions={[]}
                disabled
              />
            );
          }}
        </Query>
      );
    }

    return (
      <LabeledSelect
        style={{ width: 188, margin: 0 }}
        label="Asignado a"
        name="nameAndLastNameInput"
        keyedOptions={[]}
        disabled
      />
    );
  };

  _handleOnDeleteBackButton = () => {
    this.setState(state => ({
      deleteAdditionalServiceModal: {
        ...state.deleteAdditionalServiceModal,
        deleteAdditionalServiceModalVisible: false
      }
    }));
  };

  _handleOnInputChangeTechnician = value => {
    return this.setState(state => ({
      addAdditionalServiceModal: {
        ...state.addAdditionalServiceModal,
        nameAndLastNameInput: value
      }
    }));
  };

  _handleOnEditInputChangeTechnician = value => {
    return this.setState(state => ({
      editAdditionalServiceModal: {
        ...state.editAdditionalServiceModal,
        nameAndLastNameInput: value
      }
    }));
  };

  _handleOnPressDeleteAdditionalServiceButton = additionalServiceId => {
    this.setState(state => ({
      deleteAdditionalServiceModal: {
        ...state.deleteAdditionalServiceModal,
        deleteAdditionalServiceModalVisible: true,
        deleteAdditionalServiceId: additionalServiceId
      }
    }));
  };

  _renderLabeledContracts = addAdditionalServiceModal => {
    const { selectedFacilityId, contractId } = addAdditionalServiceModal;

    if (selectedFacilityId) {
      return (
        <Query
          query={getContractsByProject}
          variables={{
            facilityId: selectedFacilityId
          }}
        >
          {({ loading, error, data }) => {
            if (error) {
              bugsnagClient.notify(error);
              console.error(error);
              return null;
            }

            if (data) {
              let contracts = [];
              if (
                data.contractsByProject !== undefined &&
                data.contractsByProject.length > 0
              ) {
                contracts = data.contractsByProject.map(contract => ({
                  id: contract.id,
                  name: contract.baseBudget
                }));
              }
              const nullOption = "N/A";
              contracts.push({ id: nullOption, name: nullOption });

              return (
                <LabeledSelect
                  style={{ width: 188, margin: 0 }}
                  label="PEP base"
                  name="baseBudget"
                  keyedOptions={contracts}
                  defaultValue={contractId == null ? nullOption : contractId}
                  onSelect={({ currentTarget: { value } }) => {
                    value = value !== nullOption ? value : "N/A";
                    this._handleOnInputChangeContract(value);
                  }}
                />
              );
            }

            return (
              <LabeledSelect
                style={{ width: 188, margin: 0 }}
                label="PEP base"
                name="baseBudget"
                keyedOptions={[]}
                disabled
              />
            );
          }}
        </Query>
      );
    }

    return (
      <LabeledSelect
        style={{ width: 188, margin: 0 }}
        label="PEP base"
        name="baseBudget"
        keyedOptions={[]}
        disabled
      />
    );
  };

  _renderEditLabeledContracts = editAdditionalServiceModal => {
    const { selectedFacilityId, contractId } = editAdditionalServiceModal;

    if (selectedFacilityId) {
      return (
        <Query
          query={getContractsByProject}
          variables={{
            facilityId: selectedFacilityId
          }}
        >
          {({ loading, error, data }) => {
            if (error) {
              bugsnagClient.notify(error);
              console.error(error);
              return null;
            }

            if (data) {
              let contracts = [];
              if (
                data.contractsByProject !== undefined &&
                data.contractsByProject.length > 0
              ) {
                contracts = data.contractsByProject.map(contract => ({
                  id: contract.id,
                  name: contract.baseBudget
                }));
              }
              const nullOption = "N/A";
              contracts.push({ id: nullOption, name: nullOption });

              return (
                <LabeledSelect
                  style={{ width: 188, margin: 0 }}
                  label="PEP base"
                  name="baseBudget"
                  keyedOptions={contracts}
                  defaultValue={contractId == null ? nullOption : contractId}
                  onSelect={({ currentTarget: { value } }) => {
                    value = value !== nullOption ? value : "N/A";
                    this._handleOnEditInputChangeContract(contracts, value);
                  }}
                />
              );
            }

            return (
              <LabeledSelect
                style={{ width: 188, margin: 0 }}
                label="PEP base"
                name="baseBudget"
                keyedOptions={[]}
                disabled
              />
            );
          }}
        </Query>
      );
    }

    return (
      <LabeledSelect
        style={{ width: 188, margin: 0 }}
        label="PEP base"
        name="baseBudget"
        keyedOptions={[]}
        disabled
      />
    );
  };

  _handleOnInputChangeContract = value => {
    return this.setState(state => ({
      addAdditionalServiceModal: {
        ...state.addAdditionalServiceModal,
        contractId: value
      }
    }));
  };

  _handleOnEditInputChangeContract = (contracts, value) => {
    const contract = contracts.find(contract => contract.id === value);
    return this.setState(state => ({
      editAdditionalServiceModal: {
        ...state.editAdditionalServiceModal,
        contract,
        contractId: value
      }
    }));
  };

  _handleOnDeleteAdditionalServiceButton = additionalServiceId => {
    if (!!additionalServiceId) {
      this.setState(
        (state, _) => ({
          deleteAdditionalServiceModal: {
            ...state.deleteAdditionalServiceModal,
            deleteButtonDisabled: true
          }
        }),
        () =>
          deleteAdditionalService(additionalServiceId)
            .then(() => {
              window.location = "/contratos/servicios-adicionales";
            })
            .catch(err => {
              console.log(err);
              this.setState(state => ({
                deleteAdditionalServiceModal: {
                  deleteAdditionalServiceModal: false
                }
              }));
            })
      );
    }
  };

  _handleOnStatusChange = (status, currState) => {
    this.setState(state => ({
      addAdditionalServiceModal: {
        ...state.addAdditionalServiceModal,
        [status]: !currState
      }
    }));
  };

  _handleOnEditStatusChange = (status, currState) => {
    this.setState(state => ({
      editAdditionalServiceModal: {
        ...state.editAdditionalServiceModal,
        [status]: !currState
      }
    }));
  };

  _filterAdditionalServices = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnClickAddButton = () => {
    this.setState(state => ({
      addAdditionalServiceModal: {
        ...state.addAdditionalServiceModal,
        visible: true
      }
    }));
  };

  _handleOnPressEditAdditionalServiceButton = additionalServiceId => {
    const { additionalServices } = this.props;

    const additionalService = additionalServices.find(
      e => e.id === additionalServiceId
    );
    const { additionalServiceInspection } = additionalService;
    const facility =
      additionalService.facility || additionalService.fTec.facility;

    this.setState(state => ({
      editAdditionalServiceModal: {
        ...state.editAdditionalServiceModal,
        ...additionalService,
        newStatus: additionalService.status,
        budgetMXN: additionalService.budget,
        budgetUSD: additionalService.budgetUSD,
        exchangeRate: additionalService.exchangeRate,
        selectedFacilityId: facility.id,
        contractId: additionalService.contract && additionalService.contract.id,
        nameAndLastNameInput:
          additionalServiceInspection && additionalServiceInspection.fTec.id,
        startDate:
          additionalServiceInspection &&
          additionalServiceInspection.scheduledDate,
        visible: true
      }
    }));
  };

  _handleOnPressViewAdditionalServiceButton = additionalServiceId => {
    this.setState(state => ({
      viewAdditionalServiceModal: {
        ...state.viewAdditionalServiceModal,
        visible: true,
        viewAdditionalServiceId: additionalServiceId
      }
    }));
  };

  _handleOnCloseButton = () => {
    this.setState({
      addAdditionalServiceModal: { visible: false }
    });
  };

  _handleOnEditCloseButton = () => {
    this.setState({
      editAdditionalServiceModal: { visible: false }
    });
  };

  _handleOnViewCloseButton = () => {
    this.setState({
      viewAdditionalServiceModal: { visible: false }
    });
  };

  _handleOnFacilitySelectorChange = (key, value) => {
    this.setState(state => ({
      addAdditionalServiceModal: {
        ...state.addAdditionalServiceModal,
        nameAndLastNameInput: undefined,
        selectedFacilityId: undefined,
        contractId: undefined,
        [key]: value
      }
    }));
  };

  _handleOnFacilitySelect = facilityId => {
    this.setState(state => ({
      addAdditionalServiceModal: {
        ...state.addAdditionalServiceModal,
        selectedFacilityId: facilityId,
        contractId: undefined,
        nameAndLastNameInput: undefined
      }
    }));
  };

  _handleOnInputChange = e => {
    // Handle input change of the date picker component
    if (e.props) {
      return this.setState(state => ({
        addAdditionalServiceModal: {
          ...state.addAdditionalServiceModal,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    if (e.name === "budgetMXN" || e.name === "budgetUSD" || e.name === "exchangeRate") {
      return this.setState(state => ({
        addAdditionalServiceModal: {
          ...state.addAdditionalServiceModal,
          [e.name]: String(e.value)
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      addAdditionalServiceModal: {
        ...state.addAdditionalServiceModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _handleOnEditFacilitySelectorChange = (key, value) => {
    this.setState(state => ({
      editAdditionalServiceModal: {
        ...state.editAdditionalServiceModal,
        nameAndLastNameInput: undefined,
        selectedFacilityId: undefined,
        contractId: undefined,
        [key]: value
      }
    }));
  };

  _handleOnEditFacilitySelect = facilityId => {
    this.setState(state => ({
      editAdditionalServiceModal: {
        ...state.editAdditionalServiceModal,
        selectedFacilityId: facilityId,
        contractId: undefined,
        nameAndLastNameInput: undefined
      }
    }));
  };

  _handleOnEditInputChange = e => {
    // Handle input change of the date picker component
    if (e.props) {
      return this.setState(state => ({
        editAdditionalServiceModal: {
          ...state.editAdditionalServiceModal,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    if (e.name === "budgetMXN" || e.name === "budgetUSD" || e.name === "exchangeRate") {
      return this.setState(state => ({
        editAdditionalServiceModal: {
          ...state.editAdditionalServiceModal,
          [e.name]: String(e.value)
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      editAdditionalServiceModal: {
        ...state.editAdditionalServiceModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _closeAddAdditionalServiceModal = () =>
    this.setState({
      addAdditionalServiceModal: {
        visible: false,
        creatingAdditionalService: false,
        status: "null"
      }
    });

  _closeEditAdditionalServiceModal = () =>
    this.setState({
      editAdditionalServiceModal: {
        visible: false,
        editingAdditionalService: false,
        status: "null"
      }
    });

  _closeViewAdditionalServiceModal = () =>
    this.setState({
      viewAdditionalServiceModal: {
        visible: false
      }
    });

  _handleOnCreateAdditionalServiceButton = () => {
    // Save the additional service in the DB
    const {
      addAdditionalServiceModal: {
        selectedFacilityId: facilityId,
        nameAndLastNameInput,
        title,
        startDate,
        budgetMXN,
        budgetUSD,
        exchangeRate,
        contractId,
        details,
        purchaseOrder,
        jobFinished,
        invoiced
      }
    } = this.state;

    this.setState(
      (state, _) => ({
        addAdditionalServiceModal: {
          ...state.addAdditionalServiceModal,
          creatingAdditionalService: true
        }
      }),
      () =>
        createAdditionalService(
          facilityId,
          nameAndLastNameInput,
          title,
          String(new Date(startDate).getTime()),
          budgetMXN ? budgetMXN : "0",
          budgetUSD ? budgetUSD : "0",
          exchangeRate ? exchangeRate : "0",
          contractId == "N/A" ? null : contractId,
          details,
          purchaseOrder,
          jobFinished,
          invoiced
        )
          .then(() => {
            window.location = "/contratos/servicios-adicionales";
          })
          .catch(err => {
            console.log(err);
            this.setState(state => ({
              addAdditionalServiceModal: {
                ...state.addAdditionalServiceModal,
                creatingAdditionalService: false
              }
            }));
          })
    );
  };

  _handleOnEditAdditionalServiceButton = () => {
    // Save the additional service in the DB
    const {
      editAdditionalServiceModal: {
        id,
        selectedFacilityId: facilityId,
        nameAndLastNameInput,
        title,
        startDate,
        budgetMXN,
        budgetUSD,
        exchangeRate,
        contractId,
        details,
        purchaseOrder,
        jobFinished,
        invoiced
      }
    } = this.state;

    this.setState(
      (state, _) => ({
        editAdditionalServiceModal: {
          ...state.editAdditionalServiceModal,
          editingAdditionalService: true
        }
      }),
      () =>
        editAdditionalService(
          id,
          facilityId,
          nameAndLastNameInput,
          title,
          String(new Date(startDate).getTime()),
          String(budgetMXN),
          String(budgetUSD),
          String(exchangeRate),
          contractId == "N/A" ? null : contractId,
          details,
          purchaseOrder,
          jobFinished,
          invoiced
        )
          .then(() => {
            window.location = "/contratos/servicios-adicionales";
          })
          .catch(err => {
            console.log(err);
            this.setState(state => ({
              editAdditionalServiceModal: {
                ...state.editAdditionalServiceModal,
                editingAdditionalService: false
              }
            }));
          })
    );
  };

  _handleOnBackButton = () => {
    this.setState(state => ({
      addAdditionalServiceModal: {
        ...state.addAdditionalServiceModal,
        visible: true
      }
    }));
  };

  _handleOnEditBackButton = () => {
    this.setState(state => ({
      editAdditionalServiceModal: {
        ...state.editAdditionalServiceModal,
        visible: true
      }
    }));
  };
}

export default AdditionalServicesTemplate;
