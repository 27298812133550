export const CHECKLIST_FORMATS = {
  DIESEL_PUMP: "Bomba diesel",
  ELECTRIC_PUMP: "Bomba eléctrica",
  JOCKEY_PUMP: "Bomba jockey",
  WATER_TANK: "Tanque de agua",
  DRY_SYSTEM: "Sistema seco",
  HOSE_REELS: "Carretes de mangueras",
  ALARM_VALVES: "Válvulas check de alarmas",
  DRY_HYDRANT: "Hidrante seco - inspección",
  DRY_HYDRANT_TESTS: "Hidrante seco - pruebas",
  GATE_VALVES_INSPECTION: "Válvulas compuerta - inspección",
  GATE_VALVES_TESTS: "Válvulas compuerta - pruebas",
  FLOW_SENSORS_MAINTENANCE: "Mantenimiento de sensores de flujo",
  FLOW_SENSORS_INSPECTION: "Inspección de sensores de flujo",
  WALL_HYDRANT_INSPECTION: "Inspección de hidrantes de muro",
  FIREFIGHTER_CONNECTIONS_INSPECTION: "Inspección de conexiones de bomberos",
  EXTINGUISHER_INSPECTION: "Inspección de Extintores"
};

export const CHECKLIST_FORMAT_SCHEMAS = {
  DIESEL_PUMP: () => require("../schemas/dieselPumpMaintenance"),
  ELECTRIC_PUMP: () => require("../schemas/electricPumpMaintenance"),
  JOCKEY_PUMP: () => require("../schemas/jockeyPumpMaintenance"),
  WATER_TANK: () => require("../schemas/waterTankMaintenance"),
  DRY_SYSTEM: () => require("../schemas/drySystemMaintenance"),
  HOSE_REELS: () => require("../schemas/hoseReelsMaintenance"),
  ALARM_VALVES: () => require("../schemas/alarmValvesMaintenance"),
  DRY_HYDRANT: () => require("../schemas/dryHydrantMaintenance"),
  DRY_HYDRANT_TESTS: () => require("../schemas/dryHydrantTests"),
  GATE_VALVES_INSPECTION: () => require("../schemas/gateValvesInspection"),
  GATE_VALVES_TESTS: () => require("../schemas/gateValvesTests"),
  FLOW_SENSORS_MAINTENANCE: () => require("../schemas/flowSensorsMaintenance"),
  WALL_HYDRANT_INSPECTION: () => require("../schemas/wallHydrantInspection"),
  FIREFIGHTER_CONNECTIONS_INSPECTION: () =>
    require("../schemas/firefighterConnectionsInspection"),
  EXTINGUISHER_INSPECTION: () => require("../schemas/extinguisherInspection")
  // FLOW_SENSORS_INSPECTION: "Inspección de sensores de flujo"
};
