import React from "react";

const Delete = props => (
  <svg width={20} height={23} {...props}>
    <defs>
      <path
        id="garbo_svg__b"
        d="M1419.985 358.088a2.087 2.087 0 0 1-2.083 2.083h-9.788a2.087 2.087 0 0 1-2.083-2.083V343.47h13.958v14.618zm-10.415-16.616c0-.677.548-1.228 1.221-1.228h4.433c.673 0 1.222.551 1.222 1.228v.764h-6.876zm12.783.754h-4.663v-.76a2.468 2.468 0 0 0-2.466-2.466h-4.433a2.468 2.468 0 0 0-2.465 2.465v.76h-4.664a.62.62 0 0 0-.622.623.62.62 0 0 0 .622.622h1.125v14.618a3.332 3.332 0 0 0 3.327 3.327h9.788a3.332 3.332 0 0 0 3.327-3.327V343.47h1.124a.62.62 0 0 0 .622-.622.62.62 0 0 0-.622-.622z"
      />
      <path
        id="garbo_svg__d"
        d="M1413.01 357.94a.62.62 0 0 0 .621-.622v-10.995a.62.62 0 0 0-.622-.623.62.62 0 0 0-.622.623v10.99c0 .346.277.627.622.627z"
      />
      <path
        id="garbo_svg__f"
        d="M1408.95 357.253a.62.62 0 0 0 .621-.622v-9.626a.62.62 0 0 0-.622-.623.62.62 0 0 0-.622.623v9.626c0 .346.281.622.622.622z"
      />
      <path
        id="garbo_svg__h"
        d="M1417.07 357.253a.62.62 0 0 0 .621-.622v-9.626a.62.62 0 0 0-.622-.623.62.62 0 0 0-.622.623v9.626a.62.62 0 0 0 .622.622z"
      />
      <linearGradient
        id="garbo_svg__a"
        x1={1416.75}
        x2={1405.76}
        y1={343.47}
        y2={361.18}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dd4a58" />
        <stop offset={1} stopColor="#f36875" />
      </linearGradient>
      <linearGradient
        id="garbo_svg__c"
        x1={1413.24}
        x2={1412.56}
        y1={348.14}
        y2={357.81}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dd4a58" />
        <stop offset={1} stopColor="#f36875" />
      </linearGradient>
      <linearGradient
        id="garbo_svg__e"
        x1={1409.18}
        x2={1408.5}
        y1={348.55}
        y2={357.14}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dd4a58" />
        <stop offset={1} stopColor="#f36875" />
      </linearGradient>
      <linearGradient
        id="garbo_svg__g"
        x1={1417.3}
        x2={1416.62}
        y1={348.55}
        y2={357.14}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dd4a58" />
        <stop offset={1} stopColor="#f36875" />
      </linearGradient>
    </defs>
    <use
      fill="url(#garbo_svg__a)"
      xlinkHref="#garbo_svg__b"
      transform="translate(-1403 -339)"
    />
    <use
      fill="url(#garbo_svg__c)"
      xlinkHref="#garbo_svg__d"
      transform="translate(-1403 -339)"
    />
    <use
      fill="url(#garbo_svg__e)"
      xlinkHref="#garbo_svg__f"
      transform="translate(-1403 -339)"
    />
    <use
      fill="url(#garbo_svg__g)"
      xlinkHref="#garbo_svg__h"
      transform="translate(-1403 -339)"
    />
  </svg>
);

export default Delete;
