import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Modal } from "../../atoms";
import { CHECKLIST_FORMATS } from "../../../utils/checklists";
import { parseMilliseconds } from "../../../utils/date";

const DeleteInspectionModal = ({
  deleteButtonDisabled,
  deleteInspectionId,
  inspection,
  onBackButton,
  onDeleteInspectionButton
}) => {
  const inspectionTitle =
    inspection.__typename === "Inspection"
      ? CHECKLIST_FORMATS[inspection.inspectionChecklist.checklistFormat]
      : inspection.__typename === "CorrectiveActionInspection"
        ? "Acción correctiva"
        : "Servicio adicional";

  const scheduledDate = new Date(`${inspection.scheduledDate}T00:00`).getTime();
  return (
    <Container width={540}>
      <Modal.Section paddingVertical={80} paddingHorizontal={80}>
        <div style={{ fontSize: "20px" }}>
          ¿Estas seguro que deseas eliminar a la inspección{" "}
          <div style={{ fontWeight: "bold", display: "inline-block" }}>
            {inspectionTitle}
          </div>
          , con fecha agendada{" "}
          <div style={{ fontWeight: "bold", display: "inline-block" }}>
            {parseMilliseconds(scheduledDate)}
          </div>{" "}
          de tu lista?
        </div>
      </Modal.Section>
      <div style={{ marginBottom: "37px", marginLeft: "95px" }}>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={8}
            radius={7}
            paddingHorizontal={35}
            onClick={onBackButton}
            style="outline"
          >
            No, volver
          </Button>
        </div>
        <Button
          paddingVertical={8}
          radius={7}
          paddingHorizontal={30}
          onClick={() => onDeleteInspectionButton(deleteInspectionId)}
          disabled={deleteButtonDisabled}
        >
          Sí, continuar
        </Button>
      </div>
    </Container>
  );
};

DeleteInspectionModal.PropTypes = {
  deleteButtonDisabled: PropTypes.bool.isRequired,
  deleteInspectionId: PropTypes.string.isRequired,
  inspection: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  onBackButton: PropTypes.func.isRequired,
  onDeleteInspectionButton: PropTypes.func.isRequired
};

export default DeleteInspectionModal;
