import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const AvatarBubble = ({ url, width, height }) => {
  return (
    <div
      className={styles.avatarBubble}
      style={{ width: width && width, height: height && height }}
    >
      {url && <img className={styles.avatarImg} src={url} />}
    </div>
  );
};

AvatarBubble.propTypes = {
  url: PropTypes.string
};

export default AvatarBubble;
