import React from "react";

import styles from "./styles.scss";
import { ViewIcon, DeleteIcon } from "../";

const FileButton = ({ label, onClick, onRemove }) => {
  return (
    <div className={styles.button}>
      <div className={styles.innerButton} onClick={onClick}>
        <div className={styles.buttonIconWrapper}>
          <ViewIcon tint="#dd4a58" />
        </div>
        <div className={styles.labelWrapper}>{label}</div>
      </div>
      {onRemove && (
        <div onClick={onRemove} className={styles.removeIconWrapper}>
          <DeleteIcon tint="#dd4a58" />
        </div>
      )}
    </div>
  );
};

export default FileButton;
