import { gql } from "apollo-boost";
import { client } from "../apollo";

const approveInspectionReschedule = async inspectionRescheduleId =>
  await client.mutate({
    mutation: gql`
      mutation {
        approveInspectionReschedule(
          inspectionRescheduleId: "${inspectionRescheduleId}"
        )
      }
  `
  });

export default approveInspectionReschedule;
