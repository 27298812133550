import React from "react";

const Report = props => (
  <svg width={24} height={21} {...props}>
    <path
      d="M9.066 17.063c.156.286.364.514.625.683.26.17.546.254.859.254h5v1.563c0 .26-.091.481-.273.664a.904.904 0 0 1-.664.273H1.488a.904.904 0 0 1-.665-.273.904.904 0 0 1-.273-.665V1.438c0-.26.091-.481.273-.664A.904.904 0 0 1 1.488.5H9.3v5.313c0 .26.091.481.273.664a.904.904 0 0 0 .665.273h5.312v1.836l-5 4.96v3.204a.482.482 0 0 1-.352-.234c-.234-.47-.631-.762-1.191-.88-.56-.116-1.022-.019-1.387.294l-.547-1.602c-.156-.443-.455-.664-.898-.664s-.742.221-.898.664l-.704 2.11c-.078.208-.22.312-.43.312h-.468a.608.608 0 0 0-.45.176.608.608 0 0 0-.175.449c0 .182.059.332.176.45a.608.608 0 0 0 .449.175h.469c.364 0 .696-.104.996-.313.3-.208.501-.494.605-.859l.43-1.21.664 1.952c.078.26.26.404.547.43.286.026.482-.091.586-.352l.312-.585a.404.404 0 0 1 .391-.235c.182 0 .312.078.39.235zM15.55 5.265V5.5h-5v-5h.234c.26 0 .482.091.664.273l3.829 3.829a.904.904 0 0 1 .273.664zm-3.75 8.789l6.367-6.328 2.656 2.656-6.328 6.367H11.8v-2.695zm10.977-7.032c.182.209.273.45.273.723a.927.927 0 0 1-.273.684l-1.094 1.093-2.656-2.656 1.093-1.094a.927.927 0 0 1 .684-.273c.273 0 .514.091.723.273l1.25 1.25z"
      fill="#CACFD9"
      fillRule="nonzero"
    />
  </svg>
);

export default Report;
