import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { Portal, NonSelectable } from "../../atoms";

class ModalOverlay extends Component {
  static propTypes = {
    onClick: PropTypes.func
  };

  componentWillMount() {
    this.rootNode = document.getElementById("root");
  }

  componentDidMount() {
    this.rootNode.parentNode.style.cssText = "overflow: hidden;";
    this.rootNode.style.cssText =
      "filter: blur(8px); -webkit-filter: blur(8px)";
  }

  componentWillUnmount() {
    this.rootNode.parentNode.style.cssText = undefined;
    this.rootNode.style.cssText = undefined;
  }

  render() {
    const { onClick } = this.props;
    return <div onClick={onClick} className={styles.modalOverlay} />;
  }
}

const ModalPortal = ({ modal: Modal, visible, onBackdropClick }) => {
  const modalPortalNode = document.getElementById("modal-portal");
  const node =
    modalPortalNode === null ? document.createElement("div") : modalPortalNode;

  return (
    <Portal active={visible} node={node}>
      <NonSelectable>
        <div className={styles.modalWrapper}>
          <Modal />
          <ModalOverlay onClick={onBackdropClick} />
        </div>
      </NonSelectable>
    </Portal>
  );
};

ModalPortal.propTypes = {
  modal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onBackdropClick: PropTypes.func
};

ModalPortal.defaultProps = {
  visible: false
};

export default ModalPortal;
