export default {
  name: "flow_sensors_maintenance",
  title: "Mantenimiento de Sensores de Flujo",
  inputs: {
    area: {
      label: "Área"
    }
  },
  checks: {
    inspection: {
      section: "Sensor de Flujo",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Inspección",
      unit: null
    },
    test: {
      section: "Sensor de Flujo",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Pruebas",
      unit: null
    },
    time: {
      section: "Sensor de Flujo",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Tiempo",
      unit: "seg"
    }
  }
};
