import { gql } from "apollo-boost";
import { client } from "../apollo";

const deleteFacility = async facilityId =>
  await client.mutate({
    mutation: gql`
      mutation {
        deleteFacility(
          facilityId: "${facilityId}"
        )
      }
  `
  });

export default deleteFacility;
