import { gql } from "apollo-boost";

const getReport = gql`
  query getReport($reportId: ID!) {
    report(id: $reportId) {
      id
      number
      introMessage
      alarmsReportUrl
      date
      approved
      fTec {
        id
        technician {
          id
          avatarUrl
          firstName
          lastName
          email
          mobilePhone
        }
        facility {
          id
          name
          zone
          businessName {
            id
            client {
              id
              name
              state
              city
            }
          }
        }
      }
      clientSignatureUri
      inspections {
        __typename
        ... on Inspection {
          id
          inspectionChecklist {
            id
            checklistFormat
          }
          checklists {
            id
            checklistItems
            zone
            number
            signedBy {
              id
              firstName
              lastName
            }
            signatureUri
            unavailable
          }
          scheduledDate
          completionDate
        }
        ... on AdditionalServiceInspection {
          id
          completionDate
          additionalService {
            details
          }
          observation
          photos
          scheduledDate
          signatureUri
        }
        ... on CorrectiveActionInspection {
          id
          completionDate
          correctiveAction {
            details
          }
          observation
          photos
          scheduledDate
          signatureUri
        }
      }
    }
  }
`;

export default getReport;
