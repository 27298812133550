import React from "react";

const Bell = props => (
  <svg width={18} height={20} {...props}>
    <defs>
      <path
        id="bell_svg__a"
        d="M1306.363 44.504c2.283.85 3.871 1.824 4.897 2.7-.722.66-2.076.82-3.426.317-1.719-.64-2.774-2.109-2.356-3.282.004-.014.012-.026.017-.04.282.093.57.194.868.305zm-1.397 3.947c4.364 1.628 7.951.671 8.103.246.26-.734-1.41-3.182-6.4-5.042-4.989-1.86-7.773-1.171-8.064-.351-.172.483 1.996 3.52 6.361 5.147zm-4.198-9.131c2.565-5.544 3.898-7.65 8.09-7.562 1.494.03 1.134-1.086 2.272-.662 1.139.424.162 1.044 1.327 2.003 3.265 2.691 2.964 5.182 1.449 11.116-.639 2.5 1.538 2.623.677 5.045-.63 1.767-5.263 2.507-10.154.684-4.89-1.821-7.976-5.438-7.348-7.204.861-2.424 2.605-1.086 3.687-3.42z"
      />
    </defs>
    <use
      fill="#2b3034"
      xlinkHref="#bell_svg__a"
      opacity={0.4}
      transform="translate(-1297 -31)"
    />
  </svg>
);

export default Bell;
