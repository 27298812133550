import React, { Component } from "react";
import PropTypes from "prop-types";
import classJoiner from "../../../utils/classJoiner";
import styles from "./styles.scss";
import { DynamicPlaceholder, PlusIcon } from "../../atoms";
import { AssetsButton } from "../";

class LoadAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shown: false
    };
  }
  updateState() {
    this.setState(state => ({ shown: true }));
  }

  render() {
    const { buttonLabel, image, centered, defaultImage } = this.props;

    const uploadContainerClassName = centered
      ? classJoiner([styles.uploadContainer, styles.uploadContainerCentered])
      : styles.uploadContainer;

    return (
      <div className={uploadContainerClassName}>
        <div className={styles.uploadPlaceholderContainer}>
          <DynamicPlaceholder
            reload={this.state.shown}
            image={image}
            defaultImage={defaultImage}
          />
        </div>
        <div className={styles.uploadButtonContainer}>
          <AssetsButton
            icon={() => <PlusIcon tint="#dd4a58" />}
            label={buttonLabel}
            updateParent={this.updateState.bind(this)}
          />
        </div>
      </div>
    );
  }
}

LoadAsset.propTypes = {
  buttonLabel: PropTypes.string,
  image: PropTypes.string,
  centered: PropTypes.bool,
  defaultImage: PropTypes.string
};

LoadAsset.defaultProps = {
  buttonLabel: "Adjuntar Foto",
  image: "",
  centered: true,
  defaultImage: ""
};

export default LoadAsset;
