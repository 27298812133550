const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

const MONTHS_SHORT_FORM = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic"
];

export function parseMilliseconds(milliseconds) {
  if (milliseconds == undefined) {
    return undefined;
  }

  const parsed = parseInt(milliseconds);

  if (isNaN(parsed)) {
    return undefined;
  }

  const date = new Date(parsed);
  return `${MONTHS_SHORT_FORM[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

export function parseToDDMMAAAA(milliseconds) {
  if (milliseconds == undefined) {
    return undefined;
  }

  const parsed = parseInt(milliseconds);

  if (isNaN(parsed)) {
    return undefined;
  }

  const date = new Date(parsed);
  return `${date.getUTCDate() + 1}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
}

export const tsToHumanReadableDate = d => {
  if (!isNaN(parseInt(d))) {
    const date = new Date(parseInt(d));
    return `${date.getUTCDate()} de ${
      MONTHS[date.getUTCMonth()]
      } de ${date.getUTCFullYear()}`;
  }

  return undefined;
};
