import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Modal } from "../../atoms";

const DeleteUserModal = ({
  deleteUserId,
  deleteButtonDisabled,
  user,
  onBackDeleteButton,
  onDeleteUserButton
}) => {
  return (
    <Container width={540}>
      <Modal.Section paddingVertical={80} paddingHorizontal={80}>
        <div style={{ fontSize: "20px", color: "#5A5E61" }}>
          ¿Estas seguro que deseas eliminar al administrador{" "}
          <div
            style={{
              fontWeight: "bold",
              display: "inline-block",
              color: "#5A5E61 "
            }}
          >
            {user.firstName + " " + user.lastName}
          </div>{" "}
          de tu lista?
        </div>
      </Modal.Section>
      <div style={{ marginBottom: "37px", marginLeft: "95px" }}>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={8}
            radius={7}
            paddingHorizontal={35}
            onClick={onBackDeleteButton}
            style="outline"
          >
            No, volver
          </Button>
        </div>
        <Button
          paddingVertical={8}
          radius={7}
          paddingHorizontal={30}
          onClick={() => onDeleteUserButton(deleteUserId)}
          disabled={deleteButtonDisabled}
        >
          Sí, continuar
        </Button>
      </div>
    </Container>
  );
};

DeleteUserModal.PropTypes = {
  deleteUserId: PropTypes.string.isRequired,
  deleteButtonDisabled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onBackDeleteButton: PropTypes.func.isRequired,
  onDeleteUserButton: PropTypes.func.isRequired
};

export default DeleteUserModal;
