import React from "react";

const Policy = props => (
  <svg width={59} height={59} {...props}>
    <defs>
      <path
        id="policy_svg__a"
        d="M434.152 323.58h-1.91v-1.903h1.91c.527 0 .955.428.955.952a.954.954 0 0 1-.955.952zm-35.217-28.548h16.168v-1.903h-16.168zm-3.196 4.535l-1.22-1.459-5.04 4.195-1.29-1.287-1.346 1.344 2.518 2.514zm3.196 1.175h19.984v-1.903h-19.984zm0 3.806h3.797v-1.903h-3.797zm5.71 0h14.274v-1.903h-14.274zm-8.906 4.537l-1.22-1.46-5.04 4.2-1.29-1.288-1.346 1.346 2.518 2.518zm3.196 1.183h19.984v-1.913h-19.984zm0 3.797h14.275v-1.904h-14.275zm16.178 0h3.806v-1.904h-3.806zm0-22.83h3.806v-1.912h-3.806zm-19.374-1.182l-1.22-1.461-5.04 4.201-1.29-1.289-1.346 1.347 2.518 2.517zm3.196 1.183h14.275v-1.913h-14.275zm29.5 30.441h1.904v1.904h-1.904zm-33.306 0h31.403v1.904H395.13zm27.597 3.807h1.903v1.913h-1.903zm-31.249-2.855l1.749-.583v1.165zm-6.816 10.468v-47.58h36.162v34.257h-26.8l-8.564 2.855 8.564 2.855h26.8v7.613zm-4.758-52.339a2.858 2.858 0 0 1 2.855-2.855h35.235a4.705 4.705 0 0 0-.977 2.855c0 1.076.372 2.058.977 2.855h-35.235a2.858 2.858 0 0 1-2.855-2.855zm44.726 0v39.016h-1.903v-36.161h-.952a2.858 2.858 0 0 1-2.855-2.855 2.858 2.858 0 0 1 2.855-2.855 2.858 2.858 0 0 1 2.855 2.855zm9.516 39.016h-7.613v-39.016a4.763 4.763 0 0 0-4.758-4.758h-39.016a4.763 4.763 0 0 0-4.758 4.758 4.763 4.763 0 0 0 4.758 4.758V335h39.968v-5.71h3.806v-3.806h7.613a2.858 2.858 0 0 0 2.855-2.855 2.858 2.858 0 0 0-2.855-2.855z"
      />
    </defs>
    <use
      fill="#dd4a58"
      xlinkHref="#policy_svg__a"
      transform="translate(-378 -276)"
    />
  </svg>
);

export default Policy;
