import { gql } from "apollo-boost";
import { client } from "../apollo";

const removeAlarmsReportUrl = async reportId =>
  await client.mutate({
    mutation: gql`
      mutation removeAlarmsReportUrl($reportId: ID!) {
        removeAlarmsReportUrl(reportId: $reportId)
      }
    `,
    variables: {
      reportId
    }
  });

export default removeAlarmsReportUrl;
