import { gql } from "apollo-boost";
import { client } from "../apollo";

const editUser = async (
  userId,
  avatarUrl,
  firstName,
  lastName,
  emailAddress,
  mobilePhone,
  zone,
  jobTitle,
  clientPortalDisplay
) =>
  await client.mutate({
    mutation: gql`
      mutation editUser(
        $userId: ID!
        $avatarUrl: String
        $firstName: String
        $lastName: String
        $emailAddress: String
        $mobilePhone: String
        $zone: String
        $jobTitle: String
        $clientPortalDisplay: Boolean
      ) {
        editUser(
          userId: $userId
          avatarUrl: $avatarUrl
          firstName: $firstName
          lastName: $lastName
          emailAddress: $emailAddress
          mobilePhone: $mobilePhone
          zone: $zone
          jobTitle: $jobTitle
          clientPortalDisplay: $clientPortalDisplay
        ) {
          id
        }
      }
    `,
    variables: {
      userId,
      avatarUrl,
      firstName,
      lastName,
      emailAddress,
      mobilePhone,
      zone,
      jobTitle,
      clientPortalDisplay
    }
  });

export default editUser;
