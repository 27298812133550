import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import fuzzy from "fuzzy";
import format from "date-fns/format";

import { bugsnagClient } from "../../bugsnag";
import { currency } from "../../utils/formatter";
import {
  getTechniciansByFacility,
  getContractsByProject
} from "../../gql/queries";
import {
  createCorrectiveAction,
  setCorrectiveActionStatus,
  deleteCorrectiveAction,
  editCorrectiveAction
} from "../../gql/mutations";

import {
  Button,
  Container,
  CloseIcon,
  CustomDropdown,
  LabeledSelect,
  DeleteIcon,
  EditIcon,
  Heading,
  Table,
  Modal,
  Layout,
  LabeledInput,
  TextArea,
  TextInput,
  BigRemoveButton,
  PlusIcon
} from "../atoms";
import {
  FacilitySelector,
  TableContainerHeader,
  Spinner,
  ModalPortal
} from "../molecules";
import {
  DeleteCorrectiveActionModal,
  ViewCorrectiveActionModal,
  EditCorrectiveActionModal
} from "../organisms";
import styles from "./InspectionsTemplateStyles.scss";

const CORRECTIVE_ACTION_STATUS = {
  null: "Pendiente",
  PURCHASE_ORDER: "Orden de compra",
  JOB_FINISHED: "Trabajo terminado",
  INVOICED: "Facturado"
};

const CORRECTIVE_ACTION_STATUSES_DICT = {
  null: [],
  PURCHASE_ORDER: ["null"],
  JOB_FINISHED: ["null", "PURCHASE_ORDER"],
  INVOICED: ["null", "PURCHASE_ORDER", "JOB_FINISHED"]
};

class CorrectiveActionsTemplate extends PureComponent {
  state = {
    filter: undefined,
    dateRangeFilter: {},
    addCorrectiveActionModal: {
      visible: false,
      creatingCorrectiveAction: false,
      status: "null"
    },
    correctiveActionStatuses: {},
    deleteCorrectiveActionModal: {
      deleteCorrectiveActionModalVisible: false,
      deleteButtonDisabled: false
    },
    editCorrectiveActionModal: {
      visible: false,
      editCorrectiveActionId: undefined,
      editingCorrectiveAction: false,
      status: "null",
      newStatus: "null"
    },
    viewCorrectiveActionModal: {
      visible: false
    }
  };

  static propTypes = {
    loadingCorrectiveActions: PropTypes.bool.isRequired,
    correctiveActions: PropTypes.arrayOf(PropTypes.object),
    correctiveActionId: PropTypes.string
  };

  static defaultProps = {
    loadingCorrectiveActions: true,
    correctiveActions: []
  };

  componentDidUpdate(prevProps) {
    const {
      loadingCorrectiveActions,
      correctiveActions,
      correctiveActionId
    } = this.props;

    if (prevProps.loadingCorrectiveActions && !loadingCorrectiveActions) {
      if (correctiveActionId) {
        const correctiveAction = correctiveActions.find(
          correctiveAction => correctiveAction.id === correctiveActionId
        );
        // this.setState(state => ({
        //   rescheduleInspectionModal: {
        //     ...state.rescheduleInspectionModal,
        //     visible: true,
        //     correctiveAction
        //   }
        // }));
      }
    }
  }

  componentDidUpdate(_, prevState) {
    const { refetch } = this.props;
    const { dateRangeFilter: prevDateRangeFilter } = prevState;
    const { dateRangeFilter } = this.state;

    if (
      (!prevDateRangeFilter.startDate || !prevDateRangeFilter.endDate) &&
      dateRangeFilter.startDate &&
      dateRangeFilter.endDate
    ) {
      refetch({
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
    }

    if (prevDateRangeFilter.startDate && prevDateRangeFilter.endDate) {
      if (
        prevDateRangeFilter.startDate !== dateRangeFilter.startDate ||
        prevDateRangeFilter.endDate !== dateRangeFilter.endDate
      ) {
        refetch({
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
      }
    }

    if (
      prevDateRangeFilter.startDate &&
      prevDateRangeFilter.endDate &&
      (!dateRangeFilter.startDate && !dateRangeFilter.endDate)
    ) {
      refetch({ startDate: undefined, endDate: undefined });
    }
  }

  render() {
    const {
      filter,
      addCorrectiveActionModal,
      deleteCorrectiveActionModal: { deleteCorrectiveActionModalVisible },
      viewCorrectiveActionModal,
      editCorrectiveActionModal,
      dateRangeFilter
    } = this.state;
    const { loadingCorrectiveActions, correctiveActions } = this.props;

    const filteredCorrectiveActions =
      correctiveActions &&
      fuzzy.filter(filter, correctiveActions, {
        extract: el => (el.facility || {}).name || el.fTec.facility.name
      });

    const data = filter
      ? filteredCorrectiveActions &&
      filteredCorrectiveActions.map(result => result.original)
      : correctiveActions;

    const formattedData = data
      .map(d => {
        if (d.correctiveActionInspection) {
          const scheduledDate = d.correctiveActionInspection.scheduledDate;
          d.correctiveActionInspection.timestamp = new Date(
            scheduledDate
          ).getTime();
        }

        return d;
      })
      .sort((a, b) => {
        if (a.correctiveActionInspection && !b.correctiveActionInspection) {
          return -1;
        } else if (
          a.correctiveActionInspection &&
          b.correctiveActionInspection
        ) {
          const aScheduledDate = new Date(
            a.correctiveActionInspection.timestamp
          ).getTime();
          const bScheduledDate = new Date(
            b.correctiveActionInspection.timestamp
          ).getTime();
          if (aScheduledDate > bScheduledDate) {
            return -1;
          } else if (aScheduledDate < bScheduledDate) {
            return 1;
          }
        }

        return 0;
      });

    return (
      <>
        <ModalPortal
          modal={this._renderAddCorrectiveActionModal}
          visible={addCorrectiveActionModal.visible}
          onBackdropClick={this._closeAddCorrectiveActionModal}
        />
        <ModalPortal
          modal={this._renderDeleteCorrectiveActionModal}
          visible={deleteCorrectiveActionModalVisible}
          onBackdropClick={this._handleOnDeleteBackButton}
        />
        <ModalPortal
          modal={this._renderEditCorrectiveActionModal}
          visible={editCorrectiveActionModal.visible}
          onBackdropClick={this._closeEditCorrectiveActionModal}
        />
        <ModalPortal
          modal={this._renderViewCorrectiveActionModal}
          visible={viewCorrectiveActionModal.visible}
          onBackdropClick={this._closeViewCorrectiveActionModal}
        />
        <Heading>Acciones correctivas</Heading>
        <Container noPadding>
          <div style={{ overflow: "hidden", margin: "30px" }}>
            <TextInput
              width={580}
              placeholder="Buscar por nombre de planta"
              onChange={e => this._filterCorrectiveActions(e)}
            />
            <div
              style={{
                display: "inline-block",
                textAlign: "center",
                marginLeft: "30px",
                position: "absolute"
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "20px",
                  fontWeight: "bold"
                }}
              >
                Filtrar por rango de fechas
              </div>
              <LabeledInput
                index={1}
                type="date-picker"
                name="startDate"
                defaultValue={dateRangeFilter.startDate}
                componentProps={{
                  dayPickerProps: {
                    disabledDays: {
                      after: new Date(dateRangeFilter.endDate)
                    }
                  }
                }}
                onChange={this._handleOnDateInputChange}
              />
              <span style={{ marginLeft: "15px" }}>
                <LabeledInput
                  index={2}
                  type="date-picker"
                  name="endDate"
                  defaultValue={dateRangeFilter.endDate}
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(dateRangeFilter.startDate)
                      }
                    }
                  }}
                  onChange={this._handleOnDateInputChange}
                />
              </span>
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "5px"
                }}
              >
                <BigRemoveButton onClick={this._handleOnResetDateRangeFilter} />
              </span>
            </div>
            <div style={{ float: "right", marginLeft: "10px" }}>
              <Button onClick={this._handleOnClickAddButton}>
                Agregar
                <div className={styles.plusIconWrapper}>
                  <PlusIcon tint="#ffffff" />
                </div>
              </Button>
            </div>
          </div>
          {loadingCorrectiveActions && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
          {correctiveActions.length > 0 && (
            <Table.Main
              columns={[
                { "contract.facility.name": "Planta" },
                { "contract.facility.businessName.client.name": "Cliente" },
                { title: "Descripción" },
                { "correctiveActionInspection.scheduledDate": "Fecha" },
                { budget: "Monto MXN" },
                { budgetUSD: "Monto USD" },
                { status: "Estado" },
                {
                  sticky: {
                    style: {
                      position: "absolute",
                      whiteSpace: "nowrap",
                      right: 0,
                      marginTop: "-4px",
                      padding: "24px 14px",
                      background: "#ffffff",
                      borderLeft: "1px solid rgb(234, 234, 234)",
                      width: "195px"
                    }
                  }
                }
              ]}
              data={formattedData}
              row={(correctiveAction, lastRow) =>
                this._renderCorrectiveActionRow(correctiveAction, lastRow)
              }
            />
          )}
        </Container>
      </>
    );
  }

  _handleOnResetDateRangeFilter = () => {
    this.setState({
      dateRangeFilter: {}
    });
  };

  _filterCorrectiveActions = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnDateInputChange = e => {
    if (e.props) {
      return this.setState(state => ({
        dateRangeFilter: {
          ...state.dateRangeFilter,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _renderCorrectiveActionRow = (correctiveAction, lastRow) => {
    const { correctiveActionStatuses } = this.state;
    const isUpdatingStatus =
      correctiveActionStatuses[correctiveAction.id] &&
      correctiveActionStatuses[correctiveAction.id].updating;

    const scheduledDate = correctiveAction.correctiveActionInspection
      ? format(
        correctiveAction.correctiveActionInspection.scheduledDate,
        "DD/MM/YYYY"
      )
      : "N/A";

    const status = correctiveActionStatuses[correctiveAction.id]
      ? correctiveActionStatuses[correctiveAction.id].status
      : correctiveAction.status;

    const facility =
      correctiveAction.fTec === null
        ? correctiveAction.facility
        : correctiveAction.fTec.facility;

    return (
      <tr
        testid="correctiveActions-table-row"
        key={correctiveAction.id}
        style={{ borderBottom: !lastRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>{facility.name}</Table.Cell>
        <Table.Cell>{facility.businessName.client.name}</Table.Cell>
        <Table.Cell>{correctiveAction.details}</Table.Cell>
        <Table.Cell>{scheduledDate}</Table.Cell>
        <Table.Cell>{currency(correctiveAction.budget)}</Table.Cell>
        <Table.Cell>{currency(correctiveAction.budgetUSD)}</Table.Cell>
        <Table.Cell style={{ position: "static" }}>
          <CustomDropdown
            parentId={correctiveAction.id}
            options={CORRECTIVE_ACTION_STATUS}
            disabledOptions={CORRECTIVE_ACTION_STATUSES_DICT[status]}
            selected={correctiveAction.status}
            defaultValue={null}
            onChange={this._handleOnCorrectiveActionStatusChange}
            disabled={isUpdatingStatus}
          />
        </Table.Cell>
        <Table.Cell style={{ position: "static" }}>
          <Table.Cell fixed>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                style="outline"
                onClick={() =>
                  this._handleOnPressViewCorrectiveActionButton(
                    correctiveAction.id
                  )
                }
              >
                Ver
              </Button>
            </div>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                paddingHorizontal={8}
                style="silver"
                iconOnly
                onClick={() =>
                  this._handleOnPressDeleteCorrectiveActionButton(
                    correctiveAction.id
                  )
                }
              >
                <DeleteIcon />
              </Button>
            </div>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
              disabled={
                correctiveAction.status === "JOB_FINISHED" ||
                correctiveAction.status === "INVOICED"
              }
              onClick={() =>
                this._handleOnPressEditCorrectiveActionButton(
                  correctiveAction.id
                )
              }
            >
              <EditIcon />
            </Button>
          </Table.Cell>
          <div style={{ opacity: 0, zIndex: -1000 }}>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button radius={7} paddingVertical={8} style="outline">
                Ver
              </Button>
            </div>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                paddingHorizontal={8}
                style="silver"
                iconOnly
              >
                <DeleteIcon />
              </Button>
            </div>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
            >
              <EditIcon />
            </Button>
          </div>
        </Table.Cell>
      </tr>
    );
  };

  _handleOnCorrectiveActionStatusChange = (correctiveActionId, newStatus) => {
    this.setState(state => ({
      ...state,
      correctiveActionStatuses: {
        ...state.correctiveActionStatuses,
        [correctiveActionId]: { updating: true }
      }
    }));

    setCorrectiveActionStatus(correctiveActionId, newStatus)
      .then(() =>
        this.setState(state => ({
          ...state,
          correctiveActionStatuses: {
            ...state.correctiveActionStatuses,
            [correctiveActionId]: { status: newStatus, updating: false }
          }
        }))
      )
      .then(() => (window.location = "/contratos/acciones-correctivas"))
      .catch(() =>
        this.setState(state => ({
          ...state,
          correctiveActionStatuses: {
            ...state.correctiveActionStatuses,
            [correctiveActionId]: { updating: false }
          }
        }))
      );
  };

  // TODO: Move elsewhere
  _renderAddCorrectiveActionModal = () => {
    const { addCorrectiveActionModal } = this.state;

    const addButtonEnabled =
      addCorrectiveActionModal.nameAndLastNameInput &&
      addCorrectiveActionModal.title &&
      addCorrectiveActionModal.startDate &&
      addCorrectiveActionModal.budgetMXN &&
      addCorrectiveActionModal.budgetUSD &&
      addCorrectiveActionModal.details;

    return (
      <Container width={1118}>
        <div
          onClick={() => this._handleOnCloseButton()}
          style={{ position: "absolute", cursor: "pointer", right: 30 }}
        >
          <CloseIcon />
        </div>
        <Modal.Header>Acción correctiva</Modal.Header>
        <Modal.Section paddingVertical={30} paddingHorizontal={100}>
          <FacilitySelector
            selected={[
              addCorrectiveActionModal.selectedClient,
              addCorrectiveActionModal.selectedBusinessName,
              addCorrectiveActionModal.selectedFacilityId
            ]}
            onChange={(key, value) =>
              this._handleOnFacilitySelectorChange(key, value)
            }
            onFacilitySelect={facilityId =>
              this._handleOnFacilitySelect(facilityId)
            }
          />
        </Modal.Section>
        <Modal.Section
          style={{ margin: "0 50px", borderTop: "1px solid #d7d7d7" }}
          paddingVertical={30}
          paddingHorizontal={100}
        >
          <Layout.Grid>
            <Layout.Column>
              <div style={{ marginBottom: "40px" }}>
                {this._renderLabeledTechnicians(addCorrectiveActionModal)}
              </div>
              <div style={{ paddingRight: "70px" }}>
                <LabeledInput
                  index={4}
                  type="currency"
                  name="budgetMXN"
                  label="Monto MXN"
                  defaultValue={addCorrectiveActionModal.budgetMXN}
                  onChange={this._handleOnInputChange}
                />
              </div>
            </Layout.Column>
            <Layout.Column>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={2}
                  name="title"
                  label="Concepto"
                  defaultValue={addCorrectiveActionModal.title}
                  onChange={this._handleOnInputChange}
                />
              </div>
              <LabeledInput
                index={5}
                type="currency"
                name="budgetUSD"
                label="Monto USD"
                defaultValue={addCorrectiveActionModal.budgetUSD}
                onChange={this._handleOnInputChange}
              />
            </Layout.Column>
            <Layout.Column>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={3}
                  type="date-picker"
                  name="startDate"
                  label="Comienza"
                  defaultValue={addCorrectiveActionModal.startDate}
                  onChange={this._handleOnInputChange}
                />
              </div>
              {this._renderLabeledContracts(addCorrectiveActionModal)}
            </Layout.Column>
          </Layout.Grid>
        </Modal.Section>
        <Modal.Header>Información Adicional</Modal.Header>
        <Modal.Section paddingVertical={30} paddingHorizontal={100}>
          <Layout.Grid>
            <Layout.Column>
              <div
                style={{
                  marginLeft: "1px",
                  marginBottom: "8px",
                  color: "#5A5E61",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "14px"
                }}
              >
                Detalles
              </div>
              <TextArea
                name="details"
                width={524}
                height={134}
                maxLength={1000}
                onChange={this._handleOnInputChange}
              />
            </Layout.Column>
            <Layout.Column style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <CustomDropdown
                  options={CORRECTIVE_ACTION_STATUS}
                  selected="null"
                  onChange={this._handleOnStatusChange}
                />
              </div>
            </Layout.Column>
          </Layout.Grid>
        </Modal.Section>
        <Modal.Footer>
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <Button
              style="outline"
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={() => this._handleOnCloseButton()}
            >
              Volver
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={this._handleOnCreateCorrectiveActionButton}
            disabled={
              !addButtonEnabled ||
              addCorrectiveActionModal.creatingCorrectiveAction
            }
          >
            Añadir
          </Button>
        </Modal.Footer>
      </Container>
    );
  };

  _renderEditCorrectiveActionModal = () => {
    const { editCorrectiveActionModal } = this.state;

    return (
      <EditCorrectiveActionModal
        editCorrectiveActionModalState={editCorrectiveActionModal}
        editCorrectiveActionId={editCorrectiveActionModal.id}
        editLabeledTechnicians={this._renderEditLabeledTechnicians}
        handleOnEditFacilitySelectorChange={
          this._handleOnEditFacilitySelectorChange
        }
        handleOnEditInputChange={this._handleOnEditInputChange}
        editLabeledContracts={this._renderEditLabeledContracts}
        handleOnEditStatusChange={this._handleOnEditStatusChange}
        onEditCloseButton={this._handleOnEditCloseButton}
        handleOnEditFacilitySelect={this._handleOnEditFacilitySelect}
        onEditCorrectiveActionButton={this._handleOnEditCorrectiveActionButton}
      />
    );
  };

  _renderViewCorrectiveActionModal = () => {
    const {
      viewCorrectiveActionModal: { viewCorrectiveActionId }
    } = this.state;

    const { correctiveActions } = this.props;
    const correctiveAction = correctiveActions.find(
      correctiveAction => correctiveAction.id === viewCorrectiveActionId
    );

    return (
      <ViewCorrectiveActionModal
        correctiveAction={correctiveAction}
        viewCorrectiveActionId={viewCorrectiveActionId}
        onViewCloseButton={this._handleOnViewCloseButton}
      />
    );
  };

  _renderLabeledTechnicians = addCorrectiveActionModal => {
    const {
      selectedFacilityId,
      nameAndLastNameInput
    } = addCorrectiveActionModal;

    if (selectedFacilityId) {
      return (
        <Query
          query={getTechniciansByFacility}
          variables={{
            facilityId: selectedFacilityId
          }}
        >
          {({ loading, error, data }) => {
            if (error) {
              bugsnagClient.notify(error);
              console.error(error);
              return null;
            }

            if (data) {
              let technicians = [];
              if (
                data.techniciansByFacility !== undefined &&
                data.techniciansByFacility.length > 0
              ) {
                technicians = data.techniciansByFacility.map(technician => ({
                  id: technician.facilityTechnicianId,
                  name: `${technician.lastName}, ${technician.firstName}`
                }));
              }

              return (
                <LabeledSelect
                  style={{ width: 188, margin: 0 }}
                  label="Asignado a"
                  name="nameAndLastNameInput"
                  keyedOptions={technicians}
                  defaultValue={nameAndLastNameInput && nameAndLastNameInput}
                  onSelect={e =>
                    this._handleOnInputChangeTechnician(e.currentTarget.value)
                  }
                />
              );
            }

            return (
              <LabeledSelect
                style={{ width: 188, margin: 0 }}
                label="Asignado a"
                name="baseBudget"
                keyedOptions={[]}
                disabled
              />
            );
          }}
        </Query>
      );
    }

    return (
      <LabeledSelect
        style={{ width: 188, margin: 0 }}
        label="Asignado a"
        name="nameAndLastNameInput"
        keyedOptions={[]}
        disabled
      />
    );
  };

  _renderEditLabeledTechnicians = (facilityId, nameAndLastNameInput) => {
    if (facilityId) {
      return (
        <Query
          query={getTechniciansByFacility}
          variables={{
            facilityId
          }}
        >
          {({ loading, error, data }) => {
            if (error) {
              bugsnagClient.notify(error);
              console.error(error);
              return null;
            }

            if (data) {
              let technicians = [];
              if (
                data.techniciansByFacility !== undefined &&
                data.techniciansByFacility.length > 0
              ) {
                technicians = data.techniciansByFacility.map(technician => ({
                  id: technician.facilityTechnicianId,
                  name: `${technician.lastName}, ${technician.firstName}`
                }));
              }

              return (
                <LabeledSelect
                  style={{ width: 188, margin: 0 }}
                  label="Asignado a"
                  name="nameAndLastNameInput"
                  keyedOptions={technicians}
                  defaultValue={nameAndLastNameInput && nameAndLastNameInput}
                  onSelect={e =>
                    this._handleOnEditInputChangeTechnician(
                      e.currentTarget.value
                    )
                  }
                />
              );
            }

            return (
              <LabeledSelect
                style={{ width: 188, margin: 0 }}
                label="Asignado a"
                name="baseBudget"
                keyedOptions={[]}
                disabled
              />
            );
          }}
        </Query>
      );
    }

    return (
      <LabeledSelect
        style={{ width: 188, margin: 0 }}
        label="Asignado a"
        name="nameAndLastNameInput"
        keyedOptions={[]}
        disabled
      />
    );
  };

  _handleOnInputChangeTechnician = value => {
    return this.setState(state => ({
      addCorrectiveActionModal: {
        ...state.addCorrectiveActionModal,
        nameAndLastNameInput: value
      }
    }));
  };

  _handleOnEditInputChangeTechnician = value => {
    return this.setState(state => ({
      editCorrectiveActionModal: {
        ...state.editCorrectiveActionModal,
        nameAndLastNameInput: value
      }
    }));
  };

  _renderLabeledContracts = addCorrectiveActionModal => {
    const { selectedFacilityId, contractId } = addCorrectiveActionModal;

    if (selectedFacilityId) {
      return (
        <Query
          query={getContractsByProject}
          variables={{
            facilityId: selectedFacilityId
          }}
        >
          {({ loading, error, data }) => {
            if (error) {
              bugsnagClient.notify(error);
              console.error(error);
              return null;
            }

            if (data) {
              let contracts = [];
              if (
                data.contractsByProject !== undefined &&
                data.contractsByProject.length > 0
              ) {
                contracts = data.contractsByProject.map(contract => ({
                  id: contract.id,
                  name: contract.baseBudget
                }));
              }

              return (
                <LabeledSelect
                  style={{ width: 188, margin: 0 }}
                  label="PEP base"
                  name="baseBudget"
                  keyedOptions={contracts}
                  defaultValue={contractId && contractId}
                  onSelect={e =>
                    this._handleOnInputChangeContract(e.currentTarget.value)
                  }
                />
              );
            }

            return (
              <LabeledSelect
                style={{ width: 188, margin: 0 }}
                label="PEP base"
                name="baseBudget"
                keyedOptions={[]}
                disabled
              />
            );
          }}
        </Query>
      );
    }

    return (
      <LabeledSelect
        style={{ width: 188, margin: 0 }}
        label="PEP base"
        name="baseBudget"
        keyedOptions={[]}
        disabled
      />
    );
  };

  _renderEditLabeledContracts = editCorrectiveActionModal => {
    const { selectedFacilityId, contractId } = editCorrectiveActionModal;

    if (selectedFacilityId) {
      return (
        <Query
          query={getContractsByProject}
          variables={{
            facilityId: selectedFacilityId
          }}
        >
          {({ loading, error, data }) => {
            if (error) {
              bugsnagClient.notify(error);
              console.error(error);
              return null;
            }

            if (data) {
              let contracts = [];
              if (
                data.contractsByProject !== undefined &&
                data.contractsByProject.length > 0
              ) {
                contracts = data.contractsByProject.map(contract => ({
                  id: contract.id,
                  name: contract.baseBudget
                }));
              }

              return (
                <LabeledSelect
                  style={{ width: 188, margin: 0 }}
                  label="PEP base"
                  name="baseBudget"
                  keyedOptions={contracts}
                  defaultValue={contractId && contractId}
                  onSelect={e =>
                    this._handleOnEditInputChangeContract(
                      contracts,
                      e.currentTarget.value
                    )
                  }
                />
              );
            }

            return (
              <LabeledSelect
                style={{ width: 188, margin: 0 }}
                label="PEP base"
                name="baseBudget"
                keyedOptions={[]}
                disabled
              />
            );
          }}
        </Query>
      );
    }

    return (
      <LabeledSelect
        style={{ width: 188, margin: 0 }}
        label="PEP base"
        name="baseBudget"
        keyedOptions={[]}
        disabled
      />
    );
  };

  _renderDeleteCorrectiveActionModal = () => {
    const {
      deleteCorrectiveActionModal: {
        deleteButtonDisabled,
        deleteCorrectiveActionId
      }
    } = this.state;

    const { correctiveActions } = this.props;
    const correctiveAction = correctiveActions.find(
      correctiveAction => correctiveAction.id === deleteCorrectiveActionId
    );

    return (
      <DeleteCorrectiveActionModal
        deleteButtonDisabled={deleteButtonDisabled}
        deleteCorrectiveActionId={deleteCorrectiveActionId}
        correctiveAction={correctiveAction}
        onBackButton={this._handleOnDeleteBackButton}
        onDeleteCorrectiveActionButton={
          this._handleOnDeleteCorrectiveActionButton
        }
      />
    );
  };

  _handleOnInputChangeContract = value => {
    return this.setState(state => ({
      addCorrectiveActionModal: {
        ...state.addCorrectiveActionModal,
        contractId: value
      }
    }));
  };

  _handleOnEditInputChangeContract = (contracts, value) => {
    const contract = contracts.find(contract => contract.id === value);
    return this.setState(state => ({
      editCorrectiveActionModal: {
        ...state.editCorrectiveActionModal,
        contract,
        contractId: value
      }
    }));
  };

  _handleOnDeleteBackButton = () => {
    this.setState(state => ({
      deleteCorrectiveActionModal: {
        ...state.deleteCorrectiveActionModal,
        deleteCorrectiveActionModalVisible: false
      }
    }));
  };

  _handleOnStatusChange = (_, status) => {
    this.setState(state => ({
      addCorrectiveActionModal: {
        ...state.addCorrectiveActionModal,
        status
      }
    }));
  };

  _handleOnEditStatusChange = (_, status) => {
    this.setState(state => ({
      editCorrectiveActionModal: {
        ...state.editCorrectiveActionModal,
        newStatus: status
      }
    }));
  };

  _handleOnPressDeleteCorrectiveActionButton = correctiveActionId => {
    this.setState(state => ({
      deleteCorrectiveActionModal: {
        ...state.deleteCorrectiveActionModal,
        deleteCorrectiveActionModalVisible: true,
        deleteCorrectiveActionId: correctiveActionId
      }
    }));
  };

  _handleOnDeleteCorrectiveActionButton = correctiveActionId => {
    if (!!correctiveActionId) {
      this.setState(
        (state, _) => ({
          deleteCorrectiveActionModal: {
            ...state.deleteCorrectiveActionModal,
            deleteButtonDisabled: true
          }
        }),
        () =>
          deleteCorrectiveAction(correctiveActionId)
            .then(() => {
              window.location = "/contratos/acciones-correctivas";
            })
            .catch(err => {
              console.log(err);
              this.setState(state => ({
                deleteCorrectiveActionModal: {
                  deleteCorrectiveActionModal: false
                }
              }));
            })
      );
    }
  };

  _filterCorrectiveActions = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnClickAddButton = () => {
    this.setState(state => ({
      addCorrectiveActionModal: {
        ...state.addCorrectiveActionModal,
        visible: true
      }
    }));
  };

  _handleOnPressEditCorrectiveActionButton = correctiveActionId => {
    const { correctiveActions } = this.props;

    const correctiveAction = correctiveActions.find(
      e => e.id === correctiveActionId
    );
    const { correctiveActionInspection } = correctiveAction;

    this.setState(state => ({
      editCorrectiveActionModal: {
        ...state.editCorrectiveActionModal,
        ...correctiveAction,
        newStatus: correctiveAction.status,
        budgetMXN: correctiveAction.budget,
        budgetUSD: correctiveAction.budgetUSD,
        selectedFacilityId: correctiveAction.facility.id,
        contractId: correctiveAction.contract && correctiveAction.contract.id,
        nameAndLastNameInput:
          correctiveActionInspection && correctiveActionInspection.fTec.id,
        startDate:
          correctiveActionInspection &&
          correctiveActionInspection.scheduledDate,
        visible: true
      }
    }));
  };

  _handleOnCloseButton = () => {
    this.setState({
      addCorrectiveActionModal: { visible: false }
    });
  };

  _handleOnEditCloseButton = () => {
    this.setState({
      editCorrectiveActionModal: { visible: false }
    });
  };

  _handleOnFacilitySelectorChange = (key, value) => {
    this.setState(state => ({
      addCorrectiveActionModal: {
        ...state.addCorrectiveActionModal,
        nameAndLastNameInput: undefined,
        selectedFacilityId: undefined,
        contractId: undefined,
        [key]: value
      }
    }));
  };

  _handleOnEditFacilitySelect = facilityId => {
    this.setState(state => ({
      editCorrectiveActionModal: {
        ...state.editCorrectiveActionModal,
        selectedFacilityId: facilityId,
        contractId: undefined,
        nameAndLastNameInput: undefined
      }
    }));
  };

  _handleOnEditFacilitySelectorChange = (key, value) => {
    this.setState(state => ({
      editCorrectiveActionModal: {
        ...state.editCorrectiveActionModal,
        nameAndLastNameInput: undefined,
        selectedFacilityId: undefined,
        contractId: undefined,
        [key]: value
      }
    }));
  };

  _handleOnFacilitySelect = facilityId => {
    this.setState(state => ({
      addCorrectiveActionModal: {
        ...state.addCorrectiveActionModal,
        selectedFacilityId: facilityId,
        contractId: undefined,
        nameAndLastNameInput: undefined
      }
    }));
  };

  _handleOnInputChange = e => {
    // Handle input change of the date picker component
    if (e.props) {
      return this.setState(state => ({
        addCorrectiveActionModal: {
          ...state.addCorrectiveActionModal,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    if (e.name === "budgetMXN" || e.name === "budgetUSD") {
      return this.setState(state => ({
        addCorrectiveActionModal: {
          ...state.addCorrectiveActionModal,
          [e.name]: String(e.value)
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      addCorrectiveActionModal: {
        ...state.addCorrectiveActionModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _handleOnEditInputChange = e => {
    // Handle input change of the date picker component
    if (e.props) {
      return this.setState(state => ({
        editCorrectiveActionModal: {
          ...state.editCorrectiveActionModal,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    if (e.name === "budgetMXN" || e.name === "budgetUSD") {
      return this.setState(state => ({
        editCorrectiveActionModal: {
          ...state.editCorrectiveActionModal,
          [e.name]: String(e.value)
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      editCorrectiveActionModal: {
        ...state.editCorrectiveActionModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _closeAddCorrectiveActionModal = () =>
    this.setState({
      addCorrectiveActionModal: {
        visible: false,
        creatingCorrectiveAction: false,
        status: "null"
      }
    });

  _closeEditCorrectiveActionModal = () =>
    this.setState({
      editCorrectiveActionModal: {
        visible: false,
        editingCorrectiveAction: false,
        status: "null"
      }
    });

  _closeViewCorrectiveActionModal = () =>
    this.setState({
      viewCorrectiveActionModal: {
        visible: false,
        viewCorrectiveActionId: undefined
      }
    });

  _handleOnCreateCorrectiveActionButton = () => {
    // Save the additional service in the DB
    const {
      addCorrectiveActionModal: {
        selectedFacilityId: facilityId,
        nameAndLastNameInput,
        title,
        startDate,
        budgetMXN,
        budgetUSD,
        contractId,
        details,
        status
      }
    } = this.state;

    this.setState(
      (state, _) => ({
        addCorrectiveActionModal: {
          ...state.addCorrectiveActionModal,
          creatingCorrectiveAction: true
        }
      }),
      () =>
        createCorrectiveAction(
          facilityId,
          nameAndLastNameInput,
          title,
          String(new Date(startDate).getTime()),
          budgetMXN,
          budgetUSD,
          contractId,
          details,
          status
        )
          .then(() => {
            window.location = "/contratos/acciones-correctivas";
          })
          .catch(err => {
            console.log(err);
            this.setState(state => ({
              addCorrectiveActionModal: {
                ...state.addCorrectiveActionModal,
                creatingCorrectiveAction: false
              }
            }));
          })
    );
  };

  _handleOnEditCorrectiveActionButton = () => {
    // Save the additional service in the DB
    const {
      editCorrectiveActionModal: {
        id,
        selectedFacilityId: facilityId,
        nameAndLastNameInput,
        title,
        startDate,
        budgetMXN,
        budgetUSD,
        contractId,
        details,
        newStatus: status
      }
    } = this.state;

    this.setState(
      (state, _) => ({
        editCorrectiveActionModal: {
          ...state.editCorrectiveActionModal,
          editingCorrectiveAction: true
        }
      }),
      () =>
        editCorrectiveAction(
          id,
          facilityId,
          nameAndLastNameInput,
          title,
          String(new Date(startDate).getTime()),
          String(budgetMXN),
          String(budgetUSD),
          contractId,
          details,
          String(status)
        )
          .then(() => {
            window.location = "/contratos/acciones-correctivas";
          })
          .catch(err => {
            console.log(err);
            this.setState(state => ({
              editCorrectiveActionModal: {
                ...state.editCorrectiveActionModal,
                editingCorrectiveAction: false
              }
            }));
          })
    );
  };

  _handleOnPressViewCorrectiveActionButton = correctiveActionId => {
    this.setState(state => ({
      viewCorrectiveActionModal: {
        ...state.viewCorrectiveActionModal,
        visible: true,
        viewCorrectiveActionId: correctiveActionId
      }
    }));
  };

  _handleOnViewCloseButton = () => {
    this.setState({
      viewCorrectiveActionModal: { visible: false }
    });
  };

  _handleOnBackButton = () => {
    this.setState(state => ({
      addCorrectiveActionModal: {
        ...state.addCorrectiveActionModal,
        visible: true
      }
    }));
  };

  _handleOnEditBackButton = () => {
    this.setState(state => ({
      editCorrectiveActionModal: {
        ...state.editCorrectiveActionModal,
        visible: true
      }
    }));
  };
}

export default CorrectiveActionsTemplate;
