import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const BigRemoveButton = ({ onClick }) => (
  <div onClick={onClick} className={styles.removeButton} />
);

BigRemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default BigRemoveButton;