import { gql } from "apollo-boost";

const getUnreadNotificationsForClient = gql`
  query getUnreadNotificationsForClient($userId: ID!) {
    unreadNotificationsForClient(userId: $userId) {
      id
      type
      createdAt
      initiator {
        __typename
        ... on AdditionalService {
          id
          details
          contract {
            id
            facility {
              id
              name
              businessName {
                id
                client {
                  id
                  name
                  avatarUrl
                  city
                  state
                }
              }
            }
          }
        }
        ... on Report {
          id
          fTec {
            id
            facility {
              id
              name
              businessName {
                id
                client {
                  id
                  name
                  avatarUrl
                  city
                  state
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getUnreadNotificationsForClient;
