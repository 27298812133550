import { gql } from "apollo-boost";
import { client } from "../apollo";

const approveNotification = async notificationId =>
  await client.mutate({
    mutation: gql`
      mutation {
        approveNotification(
          notificationId: "${notificationId}"
        )
      }
  `
  });

export default approveNotification;
