import { gql } from "apollo-boost";
import { client } from "../apollo";

const createContractDocument = async (contractId, url, type) =>
  await client.mutate({
    mutation: gql`
      mutation {
        createContractDocument(
          contractId: "${contractId}"
          url:"${url}"
          type: "${type}"
        ) {
          id
        }
      }
  `
  });

export default createContractDocument;