import React, { Component } from "react";
import PropTypes from "prop-types";
import mixpanel from "mixpanel-browser";
import Query from "react-apollo";
import fuzzy from "fuzzy";

import { parseMilliseconds } from "../../utils/date";
import { CHECKLIST_FORMATS } from "../../utils/checklists";

import styles from "./InspectionsTemplateStyles.scss";

import {
  AvatarBubble,
  Button,
  Container,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  Heading,
  Table,
  Modal,
  Layout,
  LabeledInput,
  PlusIcon,
  FilterIcon,
  Switch,
  TextInput,
  BigRemoveButton
} from "../atoms";
import {
  approveInspectionReschedule,
  disapproveInspectionReschedule
} from "../../gql/mutations";
import { bugsnagClient } from "../../bugsnag";
import { ModalPortal, Spinner, TableContainerHeader } from "../molecules";
import {
  RescheduleInspectionModal,
  ReAssignRescheduleInspectionModal
} from "../organisms";


class RescheduledInspectionsTemplate extends Component {
  state = {
    date: new Date(),
    filter: undefined,
    dateRangeFilter: {},
    addContractModal: {
      visible: false,
      addButtonDisabled: true
    },
    addInspectionChecklistModal: {
      visible: false,
      addButtonDisabled: false,
      formats: {}
    },
    rescheduleInspectionModal: {
      visible: false,
      addButtonDisabled: false
    },
    reAssignRescheduleInspectionModal: {
      visible: false,
      addButtonDisabled: false
    }
  };

  static propTypes = {
    loadingInspections: PropTypes.bool.isRequired,
    inspectionReschedules: PropTypes.arrayOf(PropTypes.object),
    inspectionRescheduleId: PropTypes.string
  };

  static defaultProps = {
    loadingInspections: true,
    inspectionReschedules: []
  };

  componentDidUpdate(prevProps) {
    const {
      loadingInspections,
      inspectionReschedules,
      inspectionRescheduleId
    } = this.props;

    if (
      prevProps.loadingInspections &&
      !loadingInspections &&
      inspectionReschedules.length > 0
    ) {
      if (inspectionRescheduleId) {
        this._handleOnPressRescheduleInspectionButton(inspectionRescheduleId);
      }
    }
  }

  componentDidUpdate(_, prevState) {
    const { refetch } = this.props;
    const { dateRangeFilter: prevDateRangeFilter } = prevState;
    const { dateRangeFilter } = this.state;
    const { addContractModal: prevAddContractModal } = prevState;
    const { addContractModal: currAddContractModal } = this.state;

    const {
      addInspectionChecklistModal: prevAddInspectionChecklistModal
    } = prevState;

    const {
      addInspectionChecklistModal: currAddInspectionChecklistModal
    } = this.state;


    // Check for every required input field's presence
    // TODO: Turn this into a util
    // TODO: Advanced validation? (verify that endDate is greater
    // than startDate, or that the monthlyCost x 12 = annualCost, etc.)
    const requiredFields = [
      "selectedFacilityId",
      "startDate",
      "endDate",
      "monthlyCost",
      "annualCost",
      "policyNumber",
      "basePEP"
    ];

    const prevFields = requiredFields.map(rf => prevAddContractModal[rf]);
    const currFields = requiredFields.map(rf => currAddContractModal[rf]);

    const prevUnfilledFields = prevFields.filter(f => !f).length;
    const currUnfilledFields = currFields.filter(f => !f).length;

    if (prevUnfilledFields > 0 && currUnfilledFields === 0) {
      this.setState(state => ({
        addContractModal: {
          ...state.addContractModal,
          addButtonDisabled: false
        }
      }));
    }

    if (
      (!prevDateRangeFilter.startDate || !prevDateRangeFilter.endDate) &&
      dateRangeFilter.startDate &&
      dateRangeFilter.endDate
    ) {
      refetch({
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
    }

    if (prevDateRangeFilter.startDate && prevDateRangeFilter.endDate) {
      if (
        prevDateRangeFilter.startDate !== dateRangeFilter.startDate ||
        prevDateRangeFilter.endDate !== dateRangeFilter.endDate
      ) {
        refetch({
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
      }
    }

    if (
      prevDateRangeFilter.startDate &&
      prevDateRangeFilter.endDate &&
      (!dateRangeFilter.startDate && !dateRangeFilter.endDate)
    ) {
      refetch({ startDate: undefined, endDate: undefined });
    }
  }

  render() {
    const {
      rescheduleInspectionModal,
      reAssignRescheduleInspectionModal,
      dateRangeFilter,
      filter
    } = this.state;
    const { loadingInspections, inspectionReschedules } = this.props;

    const filteredInspections =
      inspectionReschedules &&
      fuzzy.filter(filter, inspectionReschedules, {
        extract: el => el.inspection.fTec.technician.firstName
      });

    const data = filter
      ? filteredInspections && filteredInspections.map(result => result.original)
      : inspectionReschedules;

    // Sort the data by created date
    // TODO: Extract to an util
    inspectionReschedules.length > 0 &&
      inspectionReschedules.sort((a, b) => {
        if (parseInt(a.createdAt) < parseInt(b.createdAt)) {
          return -1;
        }

        if (parseInt(a.createdAt) > parseInt(b.createdAt)) {
          return 1;
        }

        return 0;
      });

    return (
      <>
        <ModalPortal
          modal={this._renderRescheduleInspectionModal}
          visible={rescheduleInspectionModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderReAssignRescheduleInspectionModal}
          visible={reAssignRescheduleInspectionModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <Heading>Inspecciones</Heading>
        <Container noPadding>
          <div style={{ overflow: "hidden", margin: "30px" }}>
            <TextInput
              width={580}
              placeholder="Buscar por nombre de técnico"
              onChange={e => this._filterInspections(e)}
            />
            <div
              style={{
                display: "inline-block",
                textAlign: "center",
                marginLeft: "30px",
                position: "absolute"
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "20px",
                  fontWeight: "bold"
                }}
              >
                Filtrar por rango de fechas
              </div>
              <LabeledInput
                index={1}
                type="date-picker"
                name="startDate"
                defaultValue={dateRangeFilter.startDate}
                componentProps={{
                  dayPickerProps: {
                    disabledDays: {
                      after: new Date(dateRangeFilter.endDate)
                    }
                  }
                }}
                onChange={this._handleOnDateInputChange}
              />
              <span style={{ marginLeft: "15px" }}>
                <LabeledInput
                  index={2}
                  type="date-picker"
                  name="endDate"
                  defaultValue={dateRangeFilter.endDate}
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(dateRangeFilter.startDate)
                      }
                    }
                  }}
                  onChange={this._handleOnDateInputChange}
                />
              </span>
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "5px"
                }}
              >
                <BigRemoveButton onClick={this._handleOnResetDateRangeFilter} />
              </span>
            </div>
          </div>
          <Table.Main
            columns={[
              null,
              { name: "Técnico" },
              { "fTec.facility.name": "Planta" },
              { checklist: "Checklist de Inspección" },
              { checklistDate: "Fecha" },
              null
            ]}
            data={data}
            row={(inspectionReschedule, lastRow) =>
              this._renderInspectionRescheduleRow(inspectionReschedule, lastRow)
            }
          />
        </Container>
      </>
    );
  }

  _filterInspections = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnResetDateRangeFilter = () => {
    this.setState({
      dateRangeFilter: {}
    });
  };

  _handleOnDateInputChange = e => {
    if (e.props) {
      return this.setState(state => ({
        dateRangeFilter: {
          ...state.dateRangeFilter,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _renderInspectionRescheduleRow = (inspectionReschedule, lastRow) => {
    const {
      firstName,
      lastName
    } = inspectionReschedule.inspection.fTec.technician;
    const { inspection } = inspectionReschedule;
    const inspectionTitle =
      inspection.__typename === "Inspection"
        ? CHECKLIST_FORMATS[inspection.inspectionChecklist.checklistFormat]
        : inspection.__typename === "CorrectiveActionInspection"
          ? "Acción correctiva"
          : "Servicio adicional";

    return (
      <tr
        testid="inspectionReschedules-table-row"
        key={inspectionReschedule.id}
        style={{ borderBottom: !lastRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble
              url={
                inspectionReschedule.inspection.fTec.technician.avatarUrl &&
                inspectionReschedule.inspection.fTec.technician.avatarUrl
              }
            />
          </div>
        </Table.Cell>
        <Table.Cell>
          {firstName} {lastName}
        </Table.Cell>
        <Table.Cell>
          {inspectionReschedule.inspection.fTec.facility.name}
        </Table.Cell>
        <Table.Cell>{inspectionTitle}</Table.Cell>
        <Table.Cell>
          {parseMilliseconds(inspectionReschedule.requestedDate)}
        </Table.Cell>
        <Table.Cell>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              style="outline"
              onClick={() =>
                this._handleOnPressRescheduleInspectionButton(
                  inspectionReschedule.id
                )
              }
            >
              Ver
            </Button>
          </div>
        </Table.Cell>
      </tr>
    );
  };

  _renderRescheduleInspectionModal = () => {
    const {
      rescheduleInspectionModal: { addButtonDisabled, inspectionRescheduleId }
    } = this.state;

    const { inspectionReschedules } = this.props;
    const inspectionReschedule = inspectionReschedules.find(
      inspectionReschedule => inspectionReschedule.id === inspectionRescheduleId
    );

    return (
      <RescheduleInspectionModal
        addButtonDisabled={addButtonDisabled}
        inspectionRescheduleId={inspectionRescheduleId}
        inspectionReschedule={inspectionReschedule}
        onDeclineInspectionReschedule={
          this._handleOnDeclineInspectionReschedule
        }
        onRescheduleInspectionButton={this._handleOnRescheduleInspectionButton}
      />
    );
  };

  _handleOnDeclineInspectionButton = inspectionRescheduleId => {
    this.setState(state => ({
      rescheduleInspectionModal: {
        ...state.rescheduleInspectionModal,
        visible: false
      },
      reAssignRescheduleInspectionModal: {
        ...state.reAssignRescheduleInspectionModal,
        visible: true,
        inspectionRescheduleId: inspectionRescheduleId
      }
    }));
  };

  _handleOnPressRescheduleInspectionButton = inspectionRescheduleId => {
    this.setState(state => ({
      rescheduleInspectionModal: {
        ...state.rescheduleInspectionModal,
        visible: true,
        inspectionRescheduleId: inspectionRescheduleId
      }
    }));
  };

  _handleOnDeclineInspectionReschedule = () => {
    this.setState(state => ({
      reAssignRescheduleInspectionModal: {
        inspectionRescheduleId:
          state.rescheduleInspectionModal.inspectionRescheduleId,
        visible: true
      },
      rescheduleInspectionModal: {
        visible: false
      }
    }));
  };

  _handleOnRescheduleInspectionButton = inspectionRescheduleId => {
    if (!!inspectionRescheduleId) {
      this.setState(
        (state, _) => ({
          rescheduleInspectionModal: {
            ...state.rescheduleInspectionModal,
            addButtonDisabled: true
          }
        }),
        () =>
          approveInspectionReschedule(inspectionRescheduleId)
            .then(() => {
              window.location = "/agenda/reagendadas";
              mixpanel.track("Reagendar Inspeccion");
            })
            .catch(err => {
              bugsnagClient.notify(err);
              this.setState(state => ({
                rescheduleInspectionModal: {
                  visible: false
                }
              }));
            })
      );
    }
  };

  _renderReAssignRescheduleInspectionModal = () => {
    const {
      reAssignRescheduleInspectionModal: {
        addButtonDisabled: addButtonDisabledFromState,
        inspectionRescheduleId,
        newDate
      }
    } = this.state;

    const { inspectionReschedules } = this.props;
    const inspectionReschedule = inspectionReschedules.find(
      inspectionReschedule => inspectionReschedule.id === inspectionRescheduleId
    );

    const addButtonDisabled = !newDate || addButtonDisabledFromState;

    return (
      <ReAssignRescheduleInspectionModal
        addButtonDisabled={addButtonDisabled}
        inspectionRescheduleId={inspectionRescheduleId}
        inspectionReschedule={inspectionReschedule}
        onReAssignRescheduleInspectionButton={
          this._handleOnReAssignRescheduleInspectionButton
        }
        handleOnInputChange={this._handleOnInputChange}
      />
    );
  };

  _handleOnReAssignRescheduleInspectionButton = () => {
    const {
      reAssignRescheduleInspectionModal: { inspectionRescheduleId, newDate }
    } = this.state;
    this.setState(
      (state, _) => ({
        reAssignRescheduleInspectionModal: {
          ...state.reAssignRescheduleInspectionModal,
          addButtonDisabled: true
        }
      }),
      () =>
        disapproveInspectionReschedule(inspectionRescheduleId, newDate)
          .then(() => {
            window.location = "/agenda/reagendadas";
            mixpanel.track("Re-asignar inspeccion reagendada");
          })
          .catch(err => {
            bugsnagClient.notify(err);
            this.setState(state => ({
              reAssignRescheduleInspectionModal: {
                reAssignRescheduleInspectionModal: false
              }
            }));
          })
    );
  };

  _handleOnCloseButton = () => {
    this.setState({
      reAssignRescheduleInspectionModal: { visible: false },
      rescheduleInspectionModal: { visible: false }
    });
  };

  _handleOnInputChange = e => {
    // Handle input change of the date picker component
    if (e.props) {
      return this.setState(state => ({
        reAssignRescheduleInspectionModal: {
          ...state.reAssignRescheduleInspectionModal,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      reAssignRescheduleInspectionModal: {
        ...state.reAssignRescheduleInspectionModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };
}

export default RescheduledInspectionsTemplate;
