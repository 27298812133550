import { gql } from "apollo-boost";
import { client } from "../apollo";

const readNotification = async (notificationId, readBy) =>
  await client.mutate({
    mutation: gql`
      mutation {
        readNotification(
          notificationId: "${notificationId}"
          readBy:"${readBy}"
        ) {
          id
        }
      }
  `
  });

export default readNotification;
