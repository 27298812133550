import { gql } from "apollo-boost";
import { client } from "../apollo";

const editTechnician = async (
  technicianId,
  avatarUrl,
  firstName,
  lastName,
  email,
  mobilePhone,
  officePhone,
  address
) =>
  await client.mutate({
    mutation: gql`
      mutation {
        editTechnician(
          technicianId: "${technicianId}"
          avatarUrl: "${avatarUrl}"
          firstName: "${firstName}"
          lastName: "${lastName}"
          email: "${email}"
          mobilePhone: "${mobilePhone}"
          officePhone: "${officePhone}"
          address: "${address}"
        ) {
            id
        }
      }
  `
  });

export default editTechnician;
