import React from "react";
import PropTypes from "prop-types";

import { Container, Modal, Layout, Button } from "../../atoms";

const ViewChecklistModal = ({
  onBackButton,
  onCloseButton,
  renderViewChecklist,
  checklistFormats
}) => {
  return (
    <Container width={1300}>
      <Modal.Header>Checklist de inspección</Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist("Bomba diesel", "DIESEL_PUMP")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist("Bomba eléctrica", "ELECTRIC_PUMP")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist("Bomba jockey", "JOCKEY_PUMP")}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist("Tanque de agua", "WATER_TANK")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist("Sistema seco", "DRY_SYSTEM")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist("Carretes de manguera", "HOSE_REELS")}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist("Válvulas check de alarmas", "ALARM_VALVES")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist("Hidrante seco - inspección", "DRY_HYDRANT")}
          </Layout.Column>
          <Layout.Column style={{ borderBottom: "1px solid #f0f0f0" }}>
            {renderViewChecklist(
              "Hidrante seco - pruebas",
              "DRY_HYDRANT_TESTS"
            )}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist(
              "Válvulas compuerta - inspección",
              "GATE_VALVES_INSPECTION"
            )}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist(
              "Válvulas compuerta - pruebas",
              "GATE_VALVES_TESTS"
            )}
          </Layout.Column>
          <Layout.Column style={{ borderBottom: "1px solid #f0f0f0" }}>
            {renderViewChecklist(
              "Mantenimiento de sensores de flujo",
              "FLOW_SENSORS_MAINTENANCE"
            )}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist(
              "Inspección de sensores de flujo",
              "FLOW_SENSORS_INSPECTION"
            )}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist(
              "Inspección de hidrantes de muro",
              "WALL_HYDRANT_INSPECTION"
            )}
          </Layout.Column>
          <Layout.Column style={{ borderBottom: "1px solid #f0f0f0" }}>
            {renderViewChecklist(
              "Inspección de conexiones de bomberos",
              "FIREFIGHTER_CONNECTIONS_INSPECTION"
            )}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0"
            }}
          >
            {renderViewChecklist(
              "Inspección de Extintores",
              "EXTINGUISHER_INSPECTION"
            )}
          </Layout.Column>
          <Layout.Column />
          <Layout.Column />
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0"
            }}
          />
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0"
            }}
          />
          <Layout.Column />
        </Layout.Grid>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            style="outline"
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={onBackButton}
          >
            Volver
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={48}
          onClick={onCloseButton}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Container>
  );
};

ViewChecklistModal.propTypes = {
  onBackButton: PropTypes.func.isRequired,
  viewContractId: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired
};

export default ViewChecklistModal;
