import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import NonSelectable from "../NonSelectable";

const BUTTON_STYLES = ["primary", "outline", "silver", "uploaded"];

const Button = ({
  onClick,
  radius,
  paddingVertical,
  paddingHorizontal,
  style,
  children,
  iconOnly,
  disabled
}) => {
  return (
    <NonSelectable>
      <div 
        onClick={onClick && !disabled ? onClick : undefined}
        style={{
          paddingTop: paddingVertical && `${paddingVertical}px`,
          paddingBottom: paddingVertical && `${paddingVertical}px`,
          paddingLeft: paddingHorizontal && `${paddingHorizontal}px`,
          paddingRight: paddingHorizontal && `${paddingHorizontal}px`,
          borderRadius: `${radius}px`
        }}
        className={[
          styles.baseButton,
          styles[style],
          iconOnly && styles.iconButton,
          disabled && styles.disabled
        ].join(" ")}
      >
        {children}
      </div>
    </NonSelectable>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  radius: PropTypes.number,
  paddingVertical: PropTypes.number,
  paddingHorizontal: PropTypes.number,
  style: PropTypes.oneOf(BUTTON_STYLES),
  iconOnly: PropTypes.bool,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  style: "primary",
  iconOnly: false,
  disabled: false
};

export default Button;
