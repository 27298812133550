import React, { Component } from "react";
import { Query } from "react-apollo";

import { getClients } from "../../gql/queries";
import { ClientsTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class ClientsView extends Component {
  render() {
    return (
      <Query query={getClients}>
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div testid="error-wrapper">error</div>;
          }

          return (
            <ClientsTemplate
              loadingClients={loading}
              clients={data && data.clients}
            />
          );
        }}
      </Query>
    );
  }
}

export default ClientsView;
