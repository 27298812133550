import { gql } from "apollo-boost";

const getTechniciansByProject = gql`
  query getTechniciansByProject($facilityId: ID!) {
    inspectionReportsByFacility(facilityId: $facilityId) {
      fTec {
        id
        technician {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export default getTechniciansByProject;
