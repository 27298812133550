import { gql } from "apollo-boost";
import { client } from "../apollo";

const setCorrectiveActionStatus = async (correctiveActionId, status) =>
  await client.mutate({
    mutation: gql`
      mutation setCorrectiveActionStatus(
        $correctiveActionId: ID!
        $status: String!
      ) {
        setCorrectiveActionStatus(
          correctiveActionId: $correctiveActionId
          status: $status
        )
      }
    `,
    variables: {
      correctiveActionId,
      status
    }
  });

export default setCorrectiveActionStatus;
