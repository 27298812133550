import { gql } from "apollo-boost";
import { client } from "../apollo";

const editContractAndChecklists = async (
  contractId,
  facilityId,
  annualCost,
  monthlyCost,
  baseBudget,
  policyNumber,
  startDate,
  endDate,
  documents,
  legacyDocuments,
  inspectionChecklists
) =>
  await client.mutate({
    mutation: gql`
      mutation editContractAndChecklists(
        $contractId: ID!
        $facilityId: ID!
        $annualCost: String!
        $monthlyCost: String!
        $baseBudget: String!
        $policyNumber: String!
        $startDate: String!
        $endDate: String!
        $documents: String
        $legacyDocuments: String
        $inspectionChecklists: String!
      ) {
        editContractAndChecklists(
          contractId: $contractId
          facilityId: $facilityId
          annualCost: $annualCost
          monthlyCost: $monthlyCost
          baseBudget: $baseBudget
          policyNumber: $policyNumber
          startDate: $startDate
          endDate: $endDate
          documents: $documents
          legacyDocuments: $legacyDocuments
          inspectionChecklists: $inspectionChecklists
        )
      }
    `,
    variables: {
      contractId,
      facilityId,
      annualCost,
      monthlyCost,
      baseBudget,
      policyNumber,
      startDate,
      endDate,
      documents,
      legacyDocuments,
      inspectionChecklists
    }
  });

export default editContractAndChecklists;
