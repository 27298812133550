import React, { Component } from "react";
import { Query } from "react-apollo";

import { getTechnicians } from "../../gql/queries";
import { TechniciansTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class TechniciansView extends Component {
  render() {
    return (
      <Query query={getTechnicians}>
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div testid="error-wrapper">error</div>;
          }

          return (
            <TechniciansTemplate
              loadingTechnicians={loading}
              technicians={data && data.technicians}
            />
          );
        }}
      </Query>
    );
  }
}

export default TechniciansView;
