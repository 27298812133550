import { gql } from "apollo-boost";

const getUsers = gql`
  query getUsers {
    adminUsers {
      id
      firstName
      lastName
      avatarUrl
      jobTitle
      emailAddress
      mobilePhone
      zone
      clientPortalDisplay
    }
  }
`;

export default getUsers;
