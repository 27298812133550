import { gql } from "apollo-boost";

const getInspectionsDone = gql`
  query getInspectionsDone($startDate: String, $endDate: String) {
    inspectionsDone(startDate: $startDate, endDate: $endDate) {
      id
      reportId
      inspectionChecklist {
        id
        checklistFormat
      }
      fTec {
        id
        technician {
          id
          avatarUrl
          firstName
          lastName
        }
        facility {
          id
          name
        }
      }
      completionDate
      scheduledDate
    }
  }
`;

export default getInspectionsDone;
