import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import classJoiner from "../../../utils/classJoiner";
import { UploadPlaceholder, PlusIcon } from "../../atoms";
import { IconButton } from "../";

const UploadAsset = ({ buttonLabel, centered }) => {
  const uploadContainerClassName = centered
    ? classJoiner([styles.uploadContainer, styles.uploadContainerCentered])
    : styles.uploadContainer;

  return (
    <div className={uploadContainerClassName}>
      <div className={styles.uploadPlaceholderContainer}>
        <UploadPlaceholder />
      </div>
      <div className={styles.uploadButtonContainer}>
        <IconButton
          icon={() => <PlusIcon tint="#dd4a58" />}
          label={buttonLabel}
        />
      </div>
    </div>
  );
};

UploadAsset.propTypes = {
  buttonLabel: PropTypes.string,
  centered: PropTypes.bool
};

UploadAsset.defaultProps = {
  buttonLabel: "Adjuntar Foto",
  centered: true
};

export default UploadAsset;
