import { gql } from "apollo-boost";

const getFacilityManager = gql`
  query getFacilityManager($userId: ID!) {
    facilityManager(id: $userId) {
      facility {
        id
        name
        avatarUrl
      }
      client {
        address
      }
    }
  }
`;

export default getFacilityManager;
