import React from "react";

import styles from "./styles.scss";
import { ImageUploadIcon } from "../Icons";

// TODO: Cleanup this mess
const DynamicPlaceholder = ({ image, reload, defaultImage }) => {
	const filePath = reload
		? localStorage.getItem(image)
		: "";
	let showImage;
	let imageStyle;

	if (localStorage.getItem(image) != null && filePath != "") {
		showImage = true;
		imageStyle = {
			display: "inline-block",
			position: "relative",
			width: "133px",
			height: "133px",
			background: "#f6f6f6f",
			border: "solid 3px #eaeaea",
			borderRadius: "66.5px",
			backgroundImage: "url('" + filePath + "')",
			backgroundSize: "cover",
			backgroundPosition: "center"
		};
	} else {
		if (defaultImage != "") {
			showImage = true;
			imageStyle = {
				display: "inline-block",
				position: "relative",
				width: "133px",
				height: "133px",
				background: "#f6f6f6f",
				border: "solid 3px #eaeaea",
				borderRadius: "66.5px",
				backgroundImage: "url('" + defaultImage + "')",
				backgroundSize: "cover",
				backgroundPosition: "center"
			};
		} else {
			showImage = false;
			imageStyle = {
				display: "inline-block",
				position: "relative",
				width: "133px",
				height: "133px",
				background: "#f6f6f6f",
				border: "solid 3px #eaeaea",
				borderRadius: "66.5px"
			};
		}
	}

	return (
		<div className={styles.uploadPlaceholder} style={imageStyle}>
			<div className={styles.uploadIconWrapper}>
				{!showImage ? <ImageUploadIcon /> : <div />}
			</div>
		</div>
	);
};

export default DynamicPlaceholder;
