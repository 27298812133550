import React, { Component } from "react";
import { Query } from "react-apollo";

import { bugsnagClient } from "../bugsnag";
import { getReport } from "../gql/queries";
import { PDFReportTemplate } from "../components/templates";

class PDFReportView extends Component {
  render() {
    const { reportId } = this.props;

    return (
      <Query query={getReport} variables={{ reportId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                Cargando PDF, esto puede tardar unos minutos...
              </div>
            );
          }

          if (error) {
            bugsnagClient.notify(error);
            return (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                Hubo un problema al cargar el archivo PDF.
              </div>
            );
          }

          return <PDFReportTemplate report={data.report} />;
        }}
      </Query>
    );
  }
}

export default PDFReportView;
