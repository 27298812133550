import { gql } from "apollo-boost";
import { client } from "../apollo";

const createClient = async (
  name,
  state,
  city,
  address,
  businessNames,
  avatarUrl,
  userEmail,
  userPassword,
  managerAccounts
) =>
  await client.mutate({
    mutation: gql`
      mutation createClient(
        $name: String!
        $state: String!
        $city: String!
        $address: String!
        $businessNames: [String!]!
        $avatarUrl: String
        $userEmail: String!
        $userPassword: String!
        $managerAccounts: String
      ) {
        createClient(
          name: $name
          state: $state
          city: $city
          address: $address
          businessNames: $businessNames
          avatarUrl: $avatarUrl
          userEmail: $userEmail
          userPassword: $userPassword
          managerAccounts: $managerAccounts
        ) {
          id
        }
      }
    `,
    variables: {
      name,
      state,
      city,
      address,
      businessNames,
      avatarUrl,
      userEmail,
      userPassword,
      managerAccounts
    }
  });

export default createClient;
