import { gql } from "apollo-boost";
import { client } from "../apollo";

const createAdditionalService = async (
  facilityId,
  nameAndLastNameInput,
  title,
  startDate,
  budgetMXN,
  budgetUSD,
  exchangeRate,
  contractId,
  details,
  purchaseOrder,
  jobFinished,
  invoiced
) =>
  await client.mutate({
    mutation: gql`
      mutation createAdditionalService(
        $facilityId: ID!
        $contractId: ID
        $title: String!
        $details: String!
        $budgetMXN: String!
        $budgetUSD: String!
        $exchangeRate: String!
        $startDate: String!
        $facilityTechnicianId: ID!
        $purchaseOrder: Boolean
        $jobFinished: Boolean
        $invoiced: Boolean
      ) {
        createAdditionalService(
          facilityId: $facilityId
          contractId: $contractId
          title: $title
          details: $details
          budget: $budgetMXN
          budgetUSD: $budgetUSD
          exchangeRate: $exchangeRate
          startDate: $startDate
          facilityTechnicianId: $facilityTechnicianId
          purchaseOrder: $purchaseOrder
          jobFinished: $jobFinished
          invoiced: $invoiced
        ) {
          id
        }
      }
    `,
    variables: {
      facilityId,
      contractId,
      title,
      details,
      budgetMXN,
      budgetUSD,
      exchangeRate,
      startDate,
      facilityTechnicianId: nameAndLastNameInput,
      purchaseOrder,
      jobFinished,
      invoiced
    }
  });

export default createAdditionalService;
