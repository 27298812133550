import React, { Component } from "react";
import PropTypes from "prop-types";
import dateFormat from "date-fns/format";
import fuzzy from "fuzzy";

import { parseMilliseconds } from "../../utils/date";
import { currency } from "../../utils/formatter";
import {
  AvatarBubble,
  Button,
  Container,
  Heading,
  PreviewModal,
  Table,
  TextInput,
  Switch,
  Layout
} from "../atoms";
import {
  Spinner,
  ModalPortal,
  PreviewModalPortal,
  TableContainerHeader
} from "../molecules";
import { ViewContractModal, ViewChecklistModal } from "../organisms";

export const contractsShape = {
  id: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  policyNumber: PropTypes.string,
  annualCost: PropTypes.string,
  status: PropTypes.bool
};

const CONTRACT_STATUS_COLOR = {
  false: "linear-gradient(215deg, #dd4a58 0%, #f36875 100%)",
  true: "linear-gradient(180deg, #8ee8a5 0%, #55cd6c 100%)"
};

const CONTRACT_STATUS = {
  true: "Activo",
  false: "Inactivo"
};

const WEEKDAYS_DICT = {
  MONDAY: "Lunes",
  TUESDAY: "Martes",
  WEDNESDAY: "Miércoles",
  THURSDAY: "Jueves",
  FRIDAY: "Viernes",
  SATURDAY: "Sabado",
  SUNDAY: "Domingo"
};

const PERIODICITY_DICT = {
  WEEKLY: "Semanal",
  MONTHLY: "Mensual",
  BIMONTHLY: "Bimestral",
  TRIMONTHLY: "Trimestral",
  HALFYEAR: "Semestral",
  YEARLY: "Anual"
};

const DOCUMENT_TYPES = {
  blueprintsInputs: "FACILITY_BLUEPRINTS",
  commercialConditionsInputs: "COMMERCIAL_CONDITIONS",
  SCINotesInputs: "SCI_NOTES",
  contractPolicyInputs: "POLICIES"
};

const DOCUMENT_TYPES_DICT = {
  FACILITY_BLUEPRINTS: "blueprintsInputs",
  COMMERCIAL_CONDITIONS: "commercialConditionsInputs",
  SCI_NOTES: "SCINotesInputs",
  POLICIES: "contractPolicyInputs"
};

const LEGACY_DOCUMENT_TYPES_DICT = {
  FACILITY_BLUEPRINTS: "facilityBlueprintsUrl",
  COMMERCIAL_CONDITIONS: "commercialConditionsUrl",
  SCI_NOTES: "SCINotesUrl",
  POLICIES: "contractPolicyUrl"
};

class ContractsListTemplate extends Component {
  state = {
    viewContractModal: {
      viewContractModalVisible: false
    },
    viewChecklistModal: {
      viewChecklistModalVisible: false,
      formats: {}
    },
    previewModalVisible: false,
    filter: undefined
  };

  static propTypes = {
    loadingContracts: PropTypes.bool.isRequired,
    contracts: PropTypes.arrayOf(PropTypes.shape(contractsShape)).isRequired
  };

  static defaultProps = {
    loadingContracts: true
  };

  render() {
    const {
      viewContractModal: { viewContractModalVisible },
      viewChecklistModal: { viewChecklistModalVisible },
      filter
    } = this.state;
    const { loadingContracts, contracts } = this.props;

    const filteredContracts =
      contracts &&
      fuzzy.filter(filter, contracts, {
        extract: el => el.facility.businessName.client.name
      });

    const data = filter
      ? filteredContracts && filteredContracts.map(result => result.original)
      : contracts;

    return (
      <>
        <ModalPortal
          modal={this._renderViewContractModal}
          visible={viewContractModalVisible}
          onBackdropClick={this._handleOnBackViewButton}
        />
        <ModalPortal
          modal={this._renderViewChecklistModal}
          visible={viewChecklistModalVisible}
          onBackdropClick={this._handleOnBackViewChecklistButton}
        />
        <PreviewModalPortal
          modal={this._renderPreviewModal}
          visible={this.state.previewModalVisible}
          onBackdropClick={this._hidePreviewModal}
        />
        <Heading>Contratos</Heading>
        <Container>
          {loadingContracts && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
          {contracts && (
            <Table.Main
              columns={[
                null,
                { client: "Cliente" },
                { startDate: "Fecha de inicio" },
                { endDate: "Fecha de finalización" },
                { policyNumber: "Nombre de póliza" },
                { annualCost: "Monto" },
                { status: "Estatus" },
                { view: "Información del contrato" }
              ]}
              data={data}
              row={(contract, lastRow) =>
                this._renderContractRow(contract, lastRow)
              }
            />
          )}
        </Container>
      </>
    );
  }

  _filterContracts = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _renderContractRow = (contract, lastRow) => {
    const startDate = new Date(`${contract.startDate}T00:00`).getTime();

    const endDate = new Date(`${contract.endDate}T00:00`).getTime();

    return (
      <tr
        testid="contracts-table-row"
        key={contract.id}
        style={{ borderBottom: !lastRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble
              url={
                contract.facility.businessName.client.avatarUrl &&
                contract.facility.businessName.client.avatarUrl
              }
            />
          </div>
        </Table.Cell>
        <Table.Cell>{contract.facility.businessName.client.name}</Table.Cell>
        <Table.Cell>{parseMilliseconds(startDate)}</Table.Cell>
        <Table.Cell>{parseMilliseconds(endDate)}</Table.Cell>
        <Table.Cell>{contract.policyNumber}</Table.Cell>
        <Table.Cell>{currency(contract.annualCost)}</Table.Cell>
        <Table.Cell>
          <div
            style={{
              backgroundImage: CONTRACT_STATUS_COLOR[contract.status],
              width: "77px",
              height: "26px",
              marginTop: "5px",
              borderRadius: "13px",
              textAlign: "center",
              color: "white",
              position: "absolute",
              fontFamily: "Open Sans",
              fontSize: "12px"
            }}
          >
            <div style={{ marginTop: "6px" }} />
            {CONTRACT_STATUS[contract.status]}
          </div>
        </Table.Cell>
        <Table.Cell style={{ position: "static" }}>
          <Table.Cell fixed>
            <div style={{ marginRight: "170px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                style="outline"
                onClick={() =>
                  this._handleOnPressViewContractButton(contract.id)
                }
              >
                Ver
              </Button>
            </div>
          </Table.Cell>
          <div style={{ opacity: 0, zIndex: -1000 }}>
            <div style={{ marginRight: "170px", display: "inline-block" }}>
              <Button radius={7} paddingVertical={8} style="outline">
                Ver
              </Button>
            </div>
          </div>
        </Table.Cell>
      </tr>
    );
  };

  _renderViewContractModal = () => {
    const {
      viewContractModal: { viewContractId }
    } = this.state;

    const { contracts } = this.props;
    const contract = contracts.find(contract => contract.id === viewContractId);

    return (
      <ViewContractModal
        viewContractId={viewContractId}
        contract={contract}
        onBackButton={this._handleOnBackViewButton}
        onPreviewButton={this._showPreviewModal}
        onViewChecklistButton={this._handleOnViewChecklist}
      />
    );
  };

  _renderViewChecklistModal = () => {
    const {
      viewContractModal: { viewContractId }
    } = this.state;

    const { contracts } = this.props;
    const contract = contracts.find(contract => contract.id === viewContractId);

    return (
      <ViewChecklistModal
        viewContractId={viewContractId}
        contract={contract}
        onBackButton={this._handleOnBackViewChecklistButton}
        onPreviewButton={this._showPreviewModal}
        onCloseButton={this._handleOnCloseButton}
        checklistFormats={contract.inspectionChecklists}
        renderViewChecklist={this._renderViewChecklist}
      />
    );
  };

  _handleOnBackViewChecklistButton = () => {
    this.setState(state => ({
      viewChecklistModal: {
        ...state.viewChecklistModal,
        viewChecklistModalVisible: false
      },
      viewContractModal: {
        ...state.viewContractModal,
        viewContractModalVisible: true
      }
    }));
  };

  _handleOnCloseButton = () => {
    this.setState(state => ({
      viewChecklistModal: {
        viewChecklistModalVisible: false,
        formats: {}
      },
      viewContractModal: {
        viewContractModalVisible: false
      }
    }));
  };

  _renderViewChecklist = (title, format) => {
    const { viewChecklistModal } = this.state;

    console.log(viewChecklistModal);

    const status =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].status;

    const quantity =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].quantity;

    const periodicity =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].periodicity;

    const recurrence =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].recurrence;

    const startOn =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].startOn;

    const Field = ({ label, value }) => (
      <div style={{ width: "100%", margin: "0 auto", marginBottom: 40 }}>
        <div style={{ color: "#5A5E61", marginBottom: 10 }}>{label}</div>
        <div
          style={{
            color: "#5A5E61",
            fontWeight: "bold"
          }}
        >
          {value}
        </div>
        {viewChecklistModal.formats[format] &&
          viewChecklistModal.formats[format].quantity > 0 && (
            <div
              style={{ marginTop: "7px", borderBottom: "1px solid #cccccc" }}
            />
          )}
      </div>
    );
    return (
      <div style={{ padding: 30 }}>
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              float: "left",
              fontSize: 16,
              color: "#5A5E61",
              marginBottom: 30,
              maxWidth: "216px"
            }}
          >
            {title}
          </div>
          <div style={{ float: "right" }}>
            <Switch active={status} disabled />
          </div>
        </div>
        <div>
          <Layout.Grid>
            <Layout.Column style={{ marginRight: 30 }}>
              <div>
                <Field label="Cantidad" value={quantity} />
                <Field label="Se repite" value={recurrence} />
              </div>
            </Layout.Column>
            <Layout.Column style={{ marginRight: 30, color: "#5A5E61" }}>
              <div>
                <Field label="Periodicidad" value={periodicity} />
                <Field label="Inicia el" value={startOn} />
              </div>
            </Layout.Column>
          </Layout.Grid>
        </div>
      </div>
    );
  };

  _handleOnViewChecklist = () => {
    this.setState(state => ({
      viewContractModal: {
        ...state.viewContractModal,
        viewContractModalVisible: false
      },
      viewChecklistModal: {
        ...state.viewChecklistModal,
        viewChecklistModalVisible: true
      }
    }));
  };

  _renderPreviewModal = () => {
    const { previewUri } = this.state;

    return (
      <>
        <PreviewModal.Header
          onClick={this._hidePreviewModal}
          filePath={previewUri}
        >
          {previewUri}
        </PreviewModal.Header>
        <PreviewModal.Section
          downloadPath={previewUri}
          streamPath={previewUri}
          paddingVertical={0}
          paddingHorizontal={0}
        />
      </>
    );
  };

  _showPreviewModal = previewUri => {
    this.setState({
      previewModalVisible: true,
      previewUri
    });
  };

  _handleOnBackViewButton = () => {
    this.setState(state => ({
      viewContractModal: {
        ...state.viewContractModal,
        viewContractModalVisible: false
      }
    }));
  };

  _hidePreviewModal = () => {
    this.setState({
      previewModalVisible: false
    });
  };

  _handleOnPressViewContractButton = contractId => {
    const { contracts } = this.props;
    const contract = contracts.find(contract => contract.id === contractId);
    const formats = {};
    contract.inspectionChecklists.map(checklist => {
      formats[checklist.checklistFormat] = {
        status: checklist.status,
        quantity: checklist.quantity,
        periodicity: PERIODICITY_DICT[checklist.recurrence],
        recurrence: WEEKDAYS_DICT[checklist.repeatOn],
        startOn:
          checklist.startOn && dateFormat(checklist.startOn, "DD/MM/YYYY")
      };
    });

    this.setState(state => ({
      viewContractModal: {
        ...state.viewContractModal,
        viewContractModalVisible: true,
        viewContractId: contractId
      },
      viewChecklistModal: {
        ...state.viewChecklistModal,
        formats
      }
    }));
  };
}

export default ContractsListTemplate;
