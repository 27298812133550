import React, { Component } from "react";
import { Query } from "react-apollo";

import {
  getUnreadNotificationsForAdmin,
  getUnreadNotificationsForClient
} from "../../gql/queries";
import { NotificationsTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class NotificationsView extends Component {
  render() {
    const { userId, forAdmin } = this.props;

    const notificationsQuery = forAdmin
      ? getUnreadNotificationsForAdmin
      : getUnreadNotificationsForClient;

    return (
      <Query query={notificationsQuery} variables={{ userId }}>
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div testid="error-wrapper">error</div>;
          }
          return (
            <div>
              <NotificationsTemplate
                loading={loading}
                notifications={
                  data.unreadNotificationsForAdmin ||
                  data.unreadNotificationsForClient
                }
                forAdmin={!!data.unreadNotificationsForAdmin}
                userId={userId}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default NotificationsView;
