import { gql } from "apollo-boost";

const getDelayedInspections = gql`
  query getDelayedInspections($startDate: String, $endDate: String) {
    delayedInspections(startDate: $startDate, endDate: $endDate) {
      id
      inspectionChecklist {
        id
        checklistFormat
      }
      fTec {
        id
        technician {
          id
          avatarUrl
          firstName
          lastName
        }
        facility {
          id
          name
        }
      }
      scheduledDate
    }
  }
`;

export default getDelayedInspections;
