import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import NonSelectable from "../NonSelectable";

const Heading = ({ children }) => {
  return (
    <NonSelectable>
      <span className={styles.heading}>{children}</span>
    </NonSelectable>
  );
};

Heading.propTypes = {
  children: PropTypes.string.isRequired
};

export default Heading;
