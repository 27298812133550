import { gql } from "apollo-boost";

const getFacilitiesByClient = gql`
  query getFacilitiesByClient($userId: ID!) {
    clientByUserId(userId: $userId) {
      name
      address
      businessNames {
        name
        facilities {
          id
          name
          avatarUrl
        }
      }
    }
  }
`;

export default getFacilitiesByClient;
