import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

class Portal extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.element
    ]).isRequired,
    active: PropTypes.bool,
    node: PropTypes.instanceOf(HTMLDivElement)
  };

  static defaultProps = {
    active: false,
    node: document.createElement("div")
  };

  componentDidMount() {
    const { node } = this.props;

    if (!node instanceof HTMLDivElement) {
      // Log an error as this would most likely be a programmer error.
      console.error("Not a valid HTML node passed (must be HTMLDivElement)");

      const tempNode = document.createElement("div");
      tempNode.setAttribute("id", "portal");

      document.body.appendChild(tempNode);
    } else {
      document.body.appendChild(node);
    }
  }

  render() {
    const { children, active, node } = this.props;

    if (active) {
      return ReactDOM.createPortal(children, node);
    }

    return null;
  }
}

export default Portal;
