import mixpanel from "mixpanel-browser";

export const USER_ACTIONS = {
  ATTEMPT_LOGIN: "[USER] ATTEMPT_LOGIN",
  LOGIN_REQUEST: "[USER] LOGIN_REQUEST",
  LOGIN_SUCCESS: "[USER] LOGIN_SUCCESS",
  LOGIN_ERROR: "[USER] LOGIN_ERROR",
  LOGOUT: "[USER] LOGOUT"
};

export const attemptLogin = (emailAddress, password) => dispatch => {
  dispatch(loginRequest());

  return fetch(`${process.env.AUTH_ENDPOINT}/generateTokens`, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      emailAddress,
      password
    })
  })
    .then(response => {
      response
        .clone()
        .json()
        .then(json => {
          if (response.ok) {
            mixpanel.identify(emailAddress);
            mixpanel.register({
              "correo": emailAddress,
              "rol": json.tokens.kind
            });
            response.json().then(json => {
              if (json.tokens.kind === "TECHNICIAN") {
                mixpanel.track(
                  `Forbidden access for use of type ${json.tokens.kind}`
                );
                return dispatch(loginError("FORBIDDEN"));
              }

              return dispatch(loginSuccess(json));
            });
          } else {
            mixpanel.track("Login error");
            return dispatch(loginError(json));
          }
        });
    })
    .catch(err => {
      mixpanel.track("Login error");
      return dispatch(loginError(err));
    });
};

export const logout = () => ({
  type: USER_ACTIONS.LOGOUT
});

export const loginRequest = () => ({
  type: USER_ACTIONS.LOGIN_REQUEST
});

export const loginSuccess = response => ({
  type: USER_ACTIONS.LOGIN_SUCCESS,
  payload: {
    authToken: response.tokens.authToken,
    refreshToken: response.tokens.refreshToken,
    uid: response.tokens.uid,
    kind: response.tokens.kind
  }
});

export const loginError = response => ({
  type: USER_ACTIONS.LOGIN_ERROR,
  payload: {
    error: response.error
  }
});
