import React from "react";

const Calendar = props => (
  <svg width={20} height={21} {...props}>
    <defs>
      <path
        id="calendar_svg__a"
        d="M45.232 498.268a.463.463 0 0 0-.463-.463H43.15a.463.463 0 0 0-.463.463v1.618c0 .256.207.463.463.463h1.618a.463.463 0 0 0 .463-.463v-1.618z"
      />
      <path
        id="calendar_svg__b"
        d="M49.285 498.268a.464.464 0 0 0-.464-.463h-1.625a.464.464 0 0 0-.465.463v1.618c0 .256.208.463.465.463h1.625a.464.464 0 0 0 .464-.463z"
      />
      <path
        id="calendar_svg__c"
        d="M53.329 498.268a.464.464 0 0 0-.465-.463h-1.625a.464.464 0 0 0-.465.463v1.618c0 .256.208.463.465.463h1.625a.464.464 0 0 0 .465-.463z"
      />
      <path
        id="calendar_svg__d"
        d="M45.232 502.312a.463.463 0 0 0-.463-.464H43.15a.463.463 0 0 0-.463.464v1.617c0 .256.207.463.463.463h1.618a.463.463 0 0 0 .463-.463v-1.617z"
      />
      <path
        id="calendar_svg__e"
        d="M49.285 502.312a.464.464 0 0 0-.464-.464h-1.625a.464.464 0 0 0-.465.464v1.617c0 .256.208.463.465.463h1.625a.464.464 0 0 0 .464-.463z"
      />
      <path
        id="calendar_svg__f"
        d="M53.329 502.312a.464.464 0 0 0-.465-.464h-1.625a.464.464 0 0 0-.465.464v1.617c0 .256.208.463.465.463h1.625a.464.464 0 0 0 .465-.463v-1.617z"
      />
      <path
        id="calendar_svg__g"
        d="M55.612 504.687a.792.792 0 0 1-.791.792H41.146a.792.792 0 0 1-.79-.792v-7.484a.79.79 0 0 1 .79-.792h13.675a.79.79 0 0 1 .79.792v7.484zm.602-15.408v2.47a2.018 2.018 0 0 1-2.022 2.017h-1.276c-1.117 0-2.035-.9-2.035-2.016v-2.48h-5.75v2.48a2.028 2.028 0 0 1-2.035 2.016H41.82c-1.116 0-2.022-.9-2.022-2.016v-2.471a1.84 1.84 0 0 0-1.778 1.83v14.479a1.84 1.84 0 0 0 1.83 1.841h16.311c1.01 0 1.831-.832 1.831-1.841v-14.48c0-.992-.802-1.8-1.778-1.83z"
      />
      <path
        id="calendar_svg__h"
        d="M41.813 492.437h1.262c.383 0 .694-.31.694-.693v-4.05a.693.693 0 0 0-.694-.694h-1.262a.693.693 0 0 0-.694.693v4.05c0 .384.31.694.694.694z"
      />
      <path
        id="calendar_svg__i"
        d="M52.897 492.437h1.262c.383 0 .693-.31.693-.693v-4.05a.693.693 0 0 0-.693-.694h-1.262a.693.693 0 0 0-.694.693v4.05c0 .384.31.694.694.694z"
      />
    </defs>
    <use
      fill="#c0c5d2"
      xlinkHref="#calendar_svg__a"
      transform="translate(-38 -487)"
    />
    <use
      fill="#c0c5d2"
      xlinkHref="#calendar_svg__b"
      transform="translate(-38 -487)"
    />
    <use
      fill="#c0c5d2"
      xlinkHref="#calendar_svg__c"
      transform="translate(-38 -487)"
    />
    <use
      fill="#c0c5d2"
      xlinkHref="#calendar_svg__d"
      transform="translate(-38 -487)"
    />
    <use
      fill="#c0c5d2"
      xlinkHref="#calendar_svg__e"
      transform="translate(-38 -487)"
    />
    <use
      fill="#c0c5d2"
      xlinkHref="#calendar_svg__f"
      transform="translate(-38 -487)"
    />
    <use
      fill="#c0c5d2"
      xlinkHref="#calendar_svg__g"
      transform="translate(-38 -487)"
    />
    <use
      fill="#c0c5d2"
      xlinkHref="#calendar_svg__h"
      transform="translate(-38 -487)"
    />
    <use
      fill="#c0c5d2"
      xlinkHref="#calendar_svg__i"
      transform="translate(-38 -487)"
    />
  </svg>
);

export default Calendar;
