import React, { Component } from "react";
import { connect } from "react-redux";

class ProtectedApp extends Component {
  componentDidMount() {
    const { user } = this.props;

    if (!user.loggedIn) {
      window.location.replace("/iniciar-sesion");
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(ProtectedApp);
