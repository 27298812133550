import { applyMiddleware, createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import createFilter from "redux-persist-transform-filter";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

import userReducer from "./reducers/user";

const reducer = combineReducers({
  user: userReducer
});

const filterAuthState = createFilter("user", [
  "loggedIn",
  "tokens",
  "uid",
  "kind"
]);

const persistConfig = {
  key: "root",
  storage,
  transforms: [filterAuthState]
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

export default {
  store,
  persistor
};
