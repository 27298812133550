import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { Button } from "../../atoms";

const IconButton = ({ icon, label }) => {
  return (
    <Button
      style="outline"
      className="outline"
      paddingVertical={8}
      paddingHorizontal={10}
      radius={7}
    >
      <div className={styles.buttonIconWrapper}>{icon()}</div>
      {label}
    </Button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default IconButton;