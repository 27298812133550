import React from "react";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";

import styles from "./styles.scss";

import { NonSelectable, HelpIcon } from "../../atoms";
import { NotificationsIconModal } from "..";
import { getEmailAddressForUser } from "../../../gql/queries";
import { bugsnagClient } from "../../../bugsnag";

const dkfireMark = require("../../../../assets/images/dkfire-mark.svg");

const isUUID = uuid =>
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    uuid
  );

const Topbar = ({
  notificationsFor,
  notificationsQuery,
  logoLinksTo,
  navigation,
  userType
}) => {
  if (notificationsFor) {
    return (
      <Query
        query={getEmailAddressForUser}
        variables={{ id: notificationsFor }}
      >
        {({ loading, error, data }) => {
          if (error) {
            bugsnagClient.notify(error);
            return null;
          }

          if (data) {
            const userEmailAddress = data && data.emailAddressForUser;

            return (
              <NonSelectable>
                <div className={styles.topbar}>
                  <div className={styles.markContainer}>
                    <Link to={logoLinksTo}>
                      <img className={styles.mark} src={dkfireMark} />
                    </Link>
                  </div>
                  <div className={styles.navigationContainer}>
                    {navigation.map(nav => {
                      const basePath = isUUID(
                        window.location.pathname.split("/")[1]
                      )
                        ? {
                          path: `/${nav.basePath.split("/")[1]}/${
                            window.location.pathname.split("/")[2]
                          }`,
                          type: "variable"
                        }
                        : {
                          path: window.location.pathname.split("/")[1],
                          type: "normal"
                        };

                      if (basePath.type === "normal") {
                        if (nav.basePath === `/${basePath.path}`) {
                          return nav.links.map((link, idx) => (
                            <NavLink
                              key={idx}
                              exact
                              to={link.linkTo}
                              activeClassName={styles.activeNavigationLink}
                              style={{ overflow: "hidden" }}
                            >
                              {link.label}
                            </NavLink>
                          ));
                        }
                      } else if (basePath.type === "variable") {
                        if (nav.basePath === basePath.path) {
                          return nav.links.map((link, idx) => (
                            <NavLink
                              key={idx}
                              exact
                              to={link.linkTo}
                              activeClassName={styles.activeNavigationLink}
                            >
                              {link.label}
                            </NavLink>
                          ));
                        }
                      }
                    })}
                  </div>
                  <div className={styles.accountContainer}>
                    <div style={{ float: "left" }}>
                      <NotificationsIconModal
                        notificationsFor={notificationsFor}
                        notificationsQuery={notificationsQuery}
                      />
                    </div>
                    <div className={styles.profile}>
                      <div className={styles.profileName}>
                        {userEmailAddress}
                      </div>
                      <div className={styles.profileDropdownTriggerArea}>
                        <div className={styles.profileDropdown}>
                          <Link to="/cerrar-sesion">Cerrar sesión</Link>
                        </div>
                      </div>
                    </div>
                    {userType === "admin" ? (
                      <div className={styles.border}>
                        <div className={styles.help}>
                          <a href="/ayuda#/">
                            <HelpIcon />
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.border}>
                        <div className={styles.help}>
                          <a href="/ayuda#/cliente">
                            <HelpIcon />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </NonSelectable>
            );
          }
        }}
      </Query>
    );
  }
};

Topbar.propTypes = {
  logoLinksTo: PropTypes.string,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      basePath: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          linkTo: PropTypes.string,
          label: PropTypes.string.isRequired
        }).isRequired
      )
    })
  ),
  notificationsFor: PropTypes.string.isRequired,
  notificationsQuery: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired
};

Topbar.defaultProps = {
  logoLinksTo: "/",
  navigation: []
};

export default Topbar;
