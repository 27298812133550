import React, { Component } from "react";
import PropTypes from "prop-types";

import { AvatarBubble, Button, Container, Heading, Table } from "../atoms";
import { Spinner } from "../molecules";

export const usersShape = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  mobilePhone: PropTypes.string,
  jobTitle: PropTypes.string
};

class TechnicianListTemplate extends Component {
  static propTypes = {
    loadingUsers: PropTypes.bool.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape(usersShape)).isRequired
  };

  render() {
    const { loadingUsers, users } = this.props;
    return (
      <>
        <Heading>Contactos</Heading>

        <Container>
          <div style={{ marginTop: "70px" }}>
            {loadingUsers && (
              <div style={{ padding: "100px" }}>
                <Spinner />
              </div>
            )}
            {users && (
              <Table.Main
                columns={[
                  null,
                  { firstName: "Nombre" },
                  { emailAddress: "Correo" },
                  { mobilePhone: "Teléfono" },
                  { zone: "Zona" },
                  { jobTitle: "Cargo" }
                ]}
                data={users}
                row={(user, lastUserRow) =>
                  this._renderUserRow(user, lastUserRow)
                }
              />
            )}
          </div>
        </Container>
      </>
    );
  }

  _renderUserRow = (user, lastUserRow) => {
    return (
      <tr
        testid="users-table-row"
        key={user.id}
        style={{ borderBottom: !lastUserRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble url={user.avatarUrl && user.avatarUrl} />
          </div>
        </Table.Cell>
        <Table.Cell>{user.firstName + " " + user.lastName}</Table.Cell>
        <Table.Cell>{user.emailAddress}</Table.Cell>
        <Table.Cell>{user.mobilePhone}</Table.Cell>
        <Table.Cell>{user.zone}</Table.Cell>
        <Table.Cell>{user.jobTitle}</Table.Cell>
      </tr>
    );
  };
}

export default TechnicianListTemplate;
