import React from "react";

const SelectDropdown = props => (
  <svg width={11} height={7} {...props}>
    <defs>
      <path id="Triangle_2_svg__a" d="M1050.5 521l-5.5-7h11z" />
    </defs>
    <use
      fill="#d8d8d8"
      xlinkHref="#Triangle_2_svg__a"
      transform="translate(-1045 -514)"
    />
  </svg>
);

export default SelectDropdown;
