import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";

import { getContractsByStatus } from "../../gql/queries";
import { DashboardTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class DashboardView extends Component {
  render() {
    return (
      <Query query={getContractsByStatus}>
        {({ loading, error, data, refetch }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div>error</div>;
          }

          if (data) {
            return (
              <DashboardTemplate
                contracts={data.contractsByStatus}
                previousYearContracts={data.previousYearContracts}
                additionalServices={data.additionalServices}
                loading={loading}
                refetch={refetch}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default DashboardView;
