export default {
  name: "extinguisher_inspection",
  title: "Inspección de Extintores",
  inputs: {
    area: {
      label: "Contenido Neto Kg/L"
    },
    key: {
      label: "Clave extintor"
    }
  },
  checks: {
    manometer: {
      warning: false,
      type: "BOOL",
      label: "Manómetro en buen estado",
      unit: null
    },
    transporterHandle: {
      warning: false,
      type: "BOOL",
      label: "Manija para transportar en buen estado",
      unit: null
    },
    hoseState: {
      warning: false,
      type: "BOOL",
      label: "Manguera en buen estado",
      unit: null
    },
    labelIdentificator: {
      warning: false,
      type: "BOOL",
      label: "Cuenta con etiqueta de identificación",
      unit: null
    },
    obstructionFree: {
      warning: false,
      type: "BOOL",
      label: "Sin obstrucciones",
      unit: null
    },
    mouthpieceState: {
      warning: false,
      type: "BOOL",
      label: "Boquilla de descarga en buen estado",
      unit: null
    },
    valveOperation: {
      type: "BOOL",
      label: "Sin dificultad para operar válvula",
      unit: null
    },
    rechargeCertificate: {
      warning: false,
      type: "BOOL",
      label: "Cuenta con certificado de Recarga",
      unit: null
    },
    rechargeState: {
      warning: false,
      type: "NUMBER",
      label: "Recarga",
      unit: "P.S.I."
    },
    chargeState: {
      warning: false,
      type: "NUMBER",
      label: "Cargado",
      unit: "P.S.I."
    },
    overChargeState: {
      warning: false,
      type: "NUMBER",
      label: "Sobrecarga",
      unit: "P.S.I."
    }
  }
};
