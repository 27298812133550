import { gql } from "apollo-boost";

const getTechniciansByFacility = gql`
  query getTechniciansByFacility($facilityId: ID!) {
    techniciansByFacility(facilityId: $facilityId) {
      id
      facilityTechnicianId
      firstName
      lastName
    }
  }
`;

export default getTechniciansByFacility;
