import React from "react";

import styles from "./style.scss";
import { Dropdown } from "../../atoms";

const DashboardFilters = () => {
  return (
    <div className={styles.dropdownsWrapper}>
      <div className={styles.centeredWrapper}>
        <Dropdown
          label="Filtro Fecha"
          options={["Hoy", "Mañana", "Pasado mañana"]}
        />
      </div>
    </div>
  );
};

export default DashboardFilters;
