import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const HIGHLIGHT_ORIENTATION = ["vertical", "horizontal"];

const HighlightedButton = ({
  orientation,
  thickness,
  content,
  active,
  ...props
}) => {
  return (
    <div
      style={{
        borderWidth: `${thickness}px`,
        width: orientation === "vertical" && `calc(100% - ${thickness}px`,
        ...props.style
      }}
      className={[
        styles.highlightedButton,
        active && styles[`${orientation}Highlight`]
      ].join(" ")}
    >
      {typeof content === "function" ? (
        <div className={styles.highlightedButtonContent}>{content()}</div>
      ) : (
        content
      )}
    </div>
  );
};

HighlightedButton.propTypes = {
  orientation: PropTypes.oneOf(HIGHLIGHT_ORIENTATION),
  thickness: PropTypes.number,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  active: PropTypes.bool
};

HighlightedButton.defaultProps = {
  orientation: "horizontal",
  thickness: 3,
  active: false
};

export default HighlightedButton;
