import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { getInspectionsForAdmin } from "../../gql/queries";

// Local imports
import { ScheduleTemplate } from "../../components/templates";
import { bugsnagClient } from "../../bugsnag";

class ScheduleView extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    return (
      <Query
        query={getInspectionsForAdmin}
        variables={{
          month: String(new Date().getUTCMonth()),
          year: String(new Date().getUTCFullYear())
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (error) {
            bugsnagClient.notify(error);
            console.error(error);
            return <div>Error</div>;
          }

          if (data) {
            const actualData = {
              inspectionsForAdmin: data.inspectionsForAdmin
                ? data.inspectionsForAdmin
                : [],
              regularInspectionsForAdmin: data.regularInspectionsForAdmin
                ? data.regularInspectionsForAdmin
                : []
            };

            return (
              <ScheduleTemplate
                loadingData={loading}
                data={actualData}
                refetch={refetch}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default ScheduleView;
