import { gql } from "apollo-boost";

const getRescheduledInspections = gql`
  query getRescheduledInspections($startDate: String, $endDate: String) {
    inspectionReschedules(startDate: $startDate, endDate: $endDate) {
      id
      note
      requestedDate
      inspectionId
      inspection {
        ... on Inspection {
          id
          inspectionChecklist {
            id
            checklistFormat
          }
          fTec {
            id
            technician {
              id
              avatarUrl
              firstName
              lastName
            }
            facility {
              id
              name
            }
          }
        }
        ... on AdditionalServiceInspection {
          id
          fTec {
            id
            technician {
              id
              firstName
              lastName
            }
            facility {
              id
              name
            }
          }
        }
        ... on CorrectiveActionInspection {
          id
          fTec {
            id
            technician {
              id
              firstName
              lastName
            }
            facility {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default getRescheduledInspections;
