import { gql } from "apollo-boost";
import { client } from "../apollo";

const editFacility = async (
  facilityId,
  businessNameId,
  avatarUrl,
  name,
  zone,
  facilityManagers
) =>
  await client.mutate({
    mutation: gql`
      mutation editFacility(
        $facilityId: ID!
        $businessNameId: ID!
        $avatarUrl: String
        $name: String!
        $zone: String!
        $facilityManagers: String!
      ) {
        editFacility(
          facilityId: $facilityId
          businessNameId: $businessNameId
          avatarUrl: $avatarUrl
          name: $name
          zone: $zone
          facilityManagers: $facilityManagers
        ) {
          id
        }
      }
    `,
    variables: {
      facilityId,
      businessNameId,
      avatarUrl,
      name,
      zone,
      facilityManagers
    }
  });

export default editFacility;
