import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { attemptLogin } from "../../store/actions/user";
import { LoginTemplate } from "../../components/templates";

class LoginView extends Component {
  static propTypes = {
    attemptLogin: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  render() {
    const { attemptLogin, user } = this.props;
    return <LoginTemplate attemptLogin={attemptLogin} user={user} />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ attemptLogin }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(LoginView);
