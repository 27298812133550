import React from "react";

const EditTechnician = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23"
    height="23"
    viewBox="0 0 30 30"
  >
    <defs>
      <path
        id="6xs4a"
        d="M1453 350.482v-3.964h24v3.964zm21.873-3.482h-19.837a1.037 1.037 0 0 0 0 2.074h19.837a1.036 1.036 0 1 0 0-2.074z"
      />
      <path
        id="6xs4b"
        d="M1455 346.977v-11.954h21v11.954zm19.092-.976c0-4.652-5.724-10.002-8.158-9.002 1.197.603 3.378 2.082 3.88 5.043a.31.31 0 0 1-.255.359h-.052a.311.311 0 0 1-.315-.255c-.556-3.33-3.568-4.639-4.146-4.862-.578.223-3.59 1.532-4.146 4.862a.312.312 0 0 1-.306.259h-.052a.31.31 0 0 1-.263-.363c.494-2.96 2.675-4.44 3.874-5.043-3.56-1.212-8.154 4.351-8.153 9.002z"
      />
      <path
        id="6xs4c"
        d="M1454 361v-12h22v12zm11.025-5.952a5.05 5.05 0 0 1-5.05-5.048H1455v1.084l2.734.89c.169.63.422 1.238.751 1.803l-1.267 2.59 1.484 1.485 2.56-1.302a7.406 7.406 0 0 0 1.81.744l.937 2.728h2.1l.89-2.734c.633-.17 1.24-.42 1.806-.748l2.591 1.262 1.485-1.482-1.304-2.562a7.465 7.465 0 0 0 .744-1.807l2.728-.933V350h-4.975a5.048 5.048 0 0 1-5.047 5.048h-.002z"
      />
      <path
        id="6xs4d"
        d="M1474.873 347h-19.837a1.037 1.037 0 0 0 0 2.074h19.837a1.036 1.036 0 1 0 0-2.074z"
      />
      <path
        id="6xs4f"
        d="M1474.092 346.001c0-4.652-5.724-10.002-8.158-9.002 1.197.603 3.378 2.082 3.88 5.043a.31.31 0 0 1-.255.359h-.052a.311.311 0 0 1-.315-.255c-.556-3.33-3.568-4.639-4.146-4.862-.578.223-3.59 1.532-4.146 4.862a.312.312 0 0 1-.306.259h-.052a.31.31 0 0 1-.263-.363c.494-2.96 2.675-4.44 3.874-5.043-3.56-1.212-8.154 4.351-8.153 9.002z"
      />
      <path
        id="6xs4h"
        d="M1465.025 355.048a5.05 5.05 0 0 1-5.05-5.048H1455v1.084l2.734.89c.169.63.422 1.238.751 1.803l-1.267 2.59 1.484 1.485 2.56-1.302a7.406 7.406 0 0 0 1.81.744l.937 2.728h2.1l.89-2.734c.633-.17 1.24-.42 1.806-.748l2.591 1.262 1.485-1.482-1.304-2.562a7.465 7.465 0 0 0 .744-1.807l2.728-.933V350h-4.975a5.048 5.048 0 0 1-5.047 5.048h-.002z"
      />
      <path id="6xs4j" d="M1469 357a7 7 0 1 1 14 0 7 7 0 0 1-14 0z" />
      <path
        id="6xs4k"
        d="M1479.241 355.598l-1.839-1.84c.234-.192.525-.298.832-.298a1.302 1.302 0 0 1 1.306 1.306c0 .308-.106.598-.299.832zm-4.498 4.507l-.752-.752 4.178-4.178.752.752zm2.322-6.025l.767.77-4.17 4.178-.767-.77zm2.417-.562a1.764 1.764 0 0 0-2.497 0l-4.579 4.577a.229.229 0 0 0-.065.131l-.339 2.513a.228.228 0 0 0 .065.193.233.233 0 0 0 .162.068c.01 0 .02 0 .03-.002l1.514-.204a.23.23 0 0 0-.061-.457l-1.214.164.237-1.753 1.845 1.845a.226.226 0 0 0 .324 0l4.578-4.578c.334-.334.518-.777.518-1.25 0-.472-.184-.915-.518-1.247z"
      />
      <clipPath id="6xs4e">
        <use fill="#fff" xlinkHref="#6xs4a" />
      </clipPath>
      <clipPath id="6xs4g">
        <use fill="#fff" xlinkHref="#6xs4b" />
      </clipPath>
      <clipPath id="6xs4i">
        <use fill="#fff" xlinkHref="#6xs4c" />
      </clipPath>
      <linearGradient
        id="6xs4l"
        x1="1477.5"
        x2="1473.09"
        y1="354.59"
        y2="360.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#dd4a58" />
        <stop offset="1" stopColor="#f36875" />
      </linearGradient>
    </defs>
    <use
      fill="#fff"
      fillOpacity="0"
      stroke="#dd4a58"
      strokeMiterlimit="50"
      strokeWidth="2"
      clipPath='url("#6xs4e")'
      xlinkHref="#6xs4d"
      transform="translate(-1453 -335)"
    />
    <use
      fill="#fff"
      fillOpacity="0"
      stroke="#dd4a58"
      strokeMiterlimit="50"
      strokeWidth="2"
      clipPath='url("#6xs4g")'
      xlinkHref="#6xs4f"
      transform="translate(-1453 -335)"
    />
    <use
      fill="#fff"
      fillOpacity="0"
      stroke="#dd4a58"
      strokeMiterlimit="50"
      strokeWidth="2"
      clipPath='url("#6xs4i")'
      xlinkHref="#6xs4h"
      transform="translate(-1453 -335)"
    />
    <g>
      <g transform="translate(-1453 -335)">
        <use fill="#fafafa" xlinkHref="#6xs4j" />
        <use
          fill="#fff"
          fillOpacity="0"
          stroke="#dd4a58"
          strokeMiterlimit="50"
          xlinkHref="#6xs4j"
        />
      </g>
      <g transform="translate(-1453 -335)">
        <use fill="url(#6xs4l)" xlinkHref="#6xs4k" />
        <use
          fill="#fff"
          fillOpacity="0"
          stroke="#dd4a58"
          strokeMiterlimit="50"
          strokeWidth="0.2"
          xlinkHref="#6xs4k"
        />
      </g>
    </g>
  </svg>
);

export default EditTechnician;
