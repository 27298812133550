export default {
  name: "dry_hydrant_maintenance",
  title: "Mantenimiento de hidrante seco",
  inputs: {
    area: {
      label: "Área"
    },
    key: {
      label: "Clave hidrante tipo seco"
    }
  },
  checks: {
    accessible: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de fácil acceso",
      unit: null,
      correctiveAction: {
        on: true,
        message: "Hacer las maniobras necesarias para que quede accesible"
      }
    },
    // iceOrWaterPresence: {
    //   required: true,
    //   warning: false,
    //   type: "BOOL",
    //   label: "Presencia de agua o hielo",
    //   unit: null,
    //   correctiveAction: {
    //     on: true,
    //     message: "Reparar y escurrir"
    //   }
    // },
    waterLeaks: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de fugas de agua",
      unit: null,
      correctiveAction: {
        on: true,
        message:
          "Reparar o reemplazar las juntas, empaques opartes que sean necesarias"
      }
    },
    cylinderRevision: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de estado físico en el cilindro",
      unit: null,
      correctiveAction: {
        on: true,
        message: "Reparar o reemplazar"
      }
    },
    tightLids: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de tapas en hidrantes",
      unit: null,
      correctiveAction: {
        on: true,
        message: "Lubricar y/o apretar si es necesario"
      }
    },
    worn: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de roscas",
      unit: null,
      correctiveAction: {
        on: true,
        message: "Reparar o reemplazar"
      }
    },
    wornNut: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de tuercas",
      unit: null,
      correctiveAction: {
        on: true,
        message: "Reparar o reemplazar"
      }
    },
    accessoryRevision: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de accesorios",
      unit: null,
      correctiveAction: {
        on: true,
        message: "Verificar que la llave esté disponible"
      }
    },
    badLabels: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de etiquetas",
      unit: null,
      correctiveAction: {
        on: true,
        message: "Reemplazar"
      }
    }
  }
};
