import React, { Component } from "react";
import PropTypes from "prop-types";
import fuzzy from "fuzzy";
import dateFormat from "date-fns/format";
import mixpanel from "mixpanel-browser";

import { parseMilliseconds } from "../../utils/date";
import { currency } from "../../utils/formatter";
import {
  createContractAndChecklists,
  deleteContract,
  editContractAndChecklists,
  setContractStatus
} from "../../gql/mutations";

import {
  AvatarBubble,
  Button,
  Container,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  FileButton,
  Heading,
  Table,
  Modal,
  PreviewModal,
  Layout,
  LabeledInput,
  Switch,
  BigRemoveButton,
  TextInput,
  PlusIcon
} from "../atoms";
import {
  FacilitySelector,
  TableContainerHeader,
  Spinner,
  ModalPortal,
  PreviewModalPortal,
  UploadButton
} from "../molecules";

import {
  DeleteContractModal,
  ViewContractModal,
  ViewChecklistModal,
  EditContractModal,
  EditChecklistModal
} from "../organisms";

const PERIODICITY = {
  Semanal: "WEEKLY",
  Mensual: "MONTHLY",
  Bimestral: "BIMONTHLY",
  Trimestral: "TRIMONTHLY",
  Semestral: "HALFYEAR",
  Anual: "YEARLY"
};

const WEEKDAYS = {
  Lunes: "MONDAY",
  Martes: "TUESDAY",
  Miércoles: "WEDNESDAY",
  Jueves: "THURSDAY",
  Viernes: "FRIDAY",
  Sábado: "SATURDAY",
  Domingo: "SUNDAY"
};

const WEEKDAYS_DICT = {
  MONDAY: "Lunes",
  TUESDAY: "Martes",
  WEDNESDAY: "Miércoles",
  THURSDAY: "Jueves",
  FRIDAY: "Viernes",
  SATURDAY: "Sabado",
  SUNDAY: "Domingo"
};

const PERIODICITY_DICT = {
  WEEKLY: "Semanal",
  MONTHLY: "Mensual",
  BIMONTHLY: "Bimestral",
  TRIMONTHLY: "Trimestral",
  HALFYEAR: "Semestral",
  YEARLY: "Anual"
};

const DOCUMENT_TYPES = {
  blueprintsInputs: "FACILITY_BLUEPRINTS",
  commercialConditionsInputs: "COMMERCIAL_CONDITIONS",
  SCINotesInputs: "SCI_NOTES",
  contractPolicyInputs: "POLICIES"
};

const DOCUMENT_TYPES_DICT = {
  FACILITY_BLUEPRINTS: "blueprintsInputs",
  COMMERCIAL_CONDITIONS: "commercialConditionsInputs",
  SCI_NOTES: "SCINotesInputs",
  POLICIES: "contractPolicyInputs"
};

const LEGACY_DOCUMENT_TYPES_DICT = {
  FACILITY_BLUEPRINTS: "facilityBlueprintsUrl",
  COMMERCIAL_CONDITIONS: "commercialConditionsUrl",
  SCI_NOTES: "SCINotesUrl",
  POLICIES: "contractPolicyUrl"
};

const initialModalStates = {
  addContractModal: {
    blueprintsInputs: [],
    commercialConditionsInputs: [],
    SCINotesInputs: [],
    contractPolicyInputs: [],
    visible: false,
    addButtonDisabled: true,
    annualCost: "0",
    monthlyCost: "0"
  },
  addInspectionChecklistModal: {
    visible: false,
    addButtonDisabled: false,
    formats: {}
  },
  deleteContractModal: {
    deleteContractModalVisible: false,
    deleteButtonDisabled: false
  },
  editContractModal: {
    visible: false,
    blueprintsInputs: [],
    commercialConditionsInputs: [],
    SCINotesInputs: [],
    contractPolicyInputs: [],
    legacyDocuments: {
      contractPolicyUrl: undefined,
      SCINotesUrl: undefined,
      commercialConditionsUrl: undefined,
      facilityBlueprintsUrl: undefined
    },
    facilitySelectors: {
      0: {}
    },
    selectedFacilityId: undefined
  },
  editChecklistModal: {
    editChecklistModalVisible: false,
    saveButtonDisabled: false
  },
  viewContractModal: {
    viewContractModalVisible: false
  },
  viewChecklistModal: {
    viewChecklistModalVisible: false
  }
};

import styles from "./InspectionsTemplateStyles.scss";

class ContractsTemplate extends Component {
  state = {
    previewModalVisible: false,
    filter: undefined,
    dateRangeFilter: {},
    addContractModal: initialModalStates.addContractModal,
    addInspectionChecklistModal: initialModalStates.addInspectionChecklistModal,
    deleteContractModal: initialModalStates.deleteContractModal,
    editContractModal: initialModalStates.editContractModal,
    editChecklistModal: initialModalStates.editChecklistModal,
    viewContractModal: initialModalStates.viewContractModal,
    viewChecklistModal: initialModalStates.viewChecklistModal
  };

  static propTypes = {
    loadingContracts: PropTypes.bool.isRequired,
    contracts: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    loadingContracts: true,
    contracts: []
  };

  componentDidUpdate(_, prevState) {
    const { refetch } = this.props;
    const { dateRangeFilter: prevDateRangeFilter } = prevState;
    const { dateRangeFilter } = this.state;

    if (
      (!prevDateRangeFilter.startDate || !prevDateRangeFilter.endDate) &&
      dateRangeFilter.startDate &&
      dateRangeFilter.endDate
    ) {
      refetch({
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
    }

    if (prevDateRangeFilter.startDate && prevDateRangeFilter.endDate) {
      if (
        prevDateRangeFilter.startDate !== dateRangeFilter.startDate ||
        prevDateRangeFilter.endDate !== dateRangeFilter.endDate
      ) {
        refetch({
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
      }
    }

    if (
      prevDateRangeFilter.startDate &&
      prevDateRangeFilter.endDate &&
      (!dateRangeFilter.startDate && !dateRangeFilter.endDate)
    ) {
      refetch({ startDate: undefined, endDate: undefined });
    }
  }

  render() {
    const {
      filter,
      addContractModal,
      editContractModal,
      editChecklistModal: { editChecklistModalVisible },
      addInspectionChecklistModal,
      deleteContractModal: { deleteContractModalVisible },
      viewContractModal: { viewContractModalVisible },
      viewChecklistModal: { viewChecklistModalVisible },
      previewModalVisible,
      dateRangeFilter
    } = this.state;
    const { loadingContracts, contracts } = this.props;

    const filteredContracts =
      contracts &&
      fuzzy.filter(filter, contracts, {
        extract: el => el.facility.name
      });

    const data = filter
      ? filteredContracts && filteredContracts.map(result => result.original)
      : contracts;

    const withUSD = data.map(contract => ({
      ...contract,
      annualCostUSD: Math.floor((contract.annualCost / 20.03) * 100) / 100
    }));

    return (
      <>
        <ModalPortal
          modal={this._renderAddContractModal}
          visible={addContractModal.visible}
          onBackdropClick={() =>
            this._handleOnBackdropClick("addContractModal")
          }
        />
        <ModalPortal
          modal={this._renderAddInspectionChecklistModal}
          visible={addInspectionChecklistModal.visible}
        />
        <ModalPortal
          modal={this._renderDeleteContractModal}
          visible={deleteContractModalVisible}
          onBackdropClick={this._handleOnBackDeleteButton}
        />
        <ModalPortal
          modal={this._renderEditContractModal}
          visible={editContractModal.visible}
          onBackdropClick={() =>
            this._handleOnBackdropClick("editContractModal")
          }
        />
        <ModalPortal
          modal={this._renderEditChecklistModal}
          visible={editChecklistModalVisible}
          onBackdropClick={this._handleOnBackEditChecklistButton}
        />
        <ModalPortal
          modal={this._renderViewContractModal}
          visible={viewContractModalVisible}
          onBackdropClick={this._handleOnBackViewButton}
        />
        <ModalPortal
          modal={this._renderViewChecklistModal}
          visible={viewChecklistModalVisible}
          onBackdropClick={this._handleOnBackViewChecklistButton}
        />
        <PreviewModalPortal
          modal={this._renderPreviewModal}
          visible={previewModalVisible}
          onBackdropClick={this._hidePreviewModal}
        />
        <Heading>Contratos</Heading>
        <Container noPadding>
          <div style={{ overflow: "hidden", margin: "30px" }}>
            <TextInput
              width={580}
              placeholder="Buscar por nombre de planta"
              onChange={e => this._filterContracts(e)}
            />
            <div
              style={{
                display: "inline-block",
                textAlign: "center",
                marginLeft: "30px",
                position: "absolute"
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "20px",
                  fontWeight: "bold"
                }}
              >
                Filtrar por rango de fechas
              </div>
              <LabeledInput
                index={1}
                type="date-picker"
                name="startDate"
                defaultValue={dateRangeFilter.startDate}
                componentProps={{
                  dayPickerProps: {
                    disabledDays: {
                      after: new Date(dateRangeFilter.endDate)
                    }
                  }
                }}
                onChange={this._handleOnDateInputChange}
              />
              <span style={{ marginLeft: "15px" }}>
                <LabeledInput
                  index={2}
                  type="date-picker"
                  name="endDate"
                  defaultValue={dateRangeFilter.endDate}
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(dateRangeFilter.startDate)
                      }
                    }
                  }}
                  onChange={this._handleOnDateInputChange}
                />
              </span>
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "5px"
                }}
              >
                <BigRemoveButton onClick={this._handleOnResetDateRangeFilter} />
              </span>
            </div>
            <div style={{ float: "right", marginLeft: "10px" }}>
              <Button onClick={this._handleOnClickAddButton}>
                Agregar
                <div className={styles.plusIconWrapper}>
                  <PlusIcon tint="#ffffff" />
                </div>
              </Button>
            </div>
          </div>
          {contracts.length > 0 && (
            <Table.Main
              columns={[
                null,
                { name: "Planta" },
                { startDate: "Fecha de inicio" },
                { endDate: "Fecha de finalización" },
                { baseBudget: "PEP base" },
                { annualCost: "Monto MXN" },
                { status: "Estatus" },
                { view: "Información del contrato" }
              ]}
              data={withUSD}
              row={(contract, lastContractRow) =>
                this._renderContractRow(contract, lastContractRow)
              }
            />
          )}
          {loadingContracts && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
        </Container>
      </>
    );
  }

  _renderContractRow = (contract, lastContactRow) => {
    const startDate = new Date(`${contract.startDate}T00:00`).getTime();

    const endDate = new Date(`${contract.endDate}T00:00`).getTime();

    return (
      <tr
        testid="contracts-table-row"
        key={contract.id}
        style={{ borderBottom: !lastContactRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble
              url={contract.facility.avatarUrl && contract.facility.avatarUrl}
            />
          </div>
        </Table.Cell>
        <Table.Cell>{contract.facility.name}</Table.Cell>
        <Table.Cell>{parseMilliseconds(startDate)}</Table.Cell>
        <Table.Cell>{parseMilliseconds(endDate)}</Table.Cell>
        <Table.Cell>{contract.baseBudget}</Table.Cell>
        <Table.Cell>{currency(contract.annualCost)}</Table.Cell>
        <Table.Cell>
          <Switch
            active={contract.status}
            labels={{ active: "Activo", inactive: "Inactivo" }}
            onToggle={state => this._setContractStatus(contract.id, state)}
          />
        </Table.Cell>
        <Table.Cell style={{ position: "static" }}>
          <Table.Cell fixed>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                style="outline"
                onClick={() =>
                  this._handleOnPressViewContractButton(contract.id)
                }
              >
                Ver
              </Button>
            </div>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                paddingHorizontal={8}
                style="silver"
                iconOnly
                onClick={() =>
                  this._handleOnPressDeleteContractButton(contract.id)
                }
              >
                <DeleteIcon />
              </Button>
            </div>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
              onClick={() => this._handleOnPressEditContractButton(contract.id)}
            >
              <EditIcon />
            </Button>
          </Table.Cell>
          <div style={{ opacity: 0 }}>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                style="outline"
                onClick={() =>
                  this._handleOnPressViewContractButton(contract.id)
                }
              >
                Ver
              </Button>
            </div>
            <div style={{ marginRight: "14px", display: "inline-block" }}>
              <Button
                radius={7}
                paddingVertical={8}
                paddingHorizontal={8}
                style="silver"
                iconOnly
                onClick={() =>
                  this._handleOnPressDeleteContractButton(contract.id)
                }
              >
                <DeleteIcon />
              </Button>
            </div>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
              onClick={() => this._handleOnPressEditContractButton(contract.id)}
            >
              <EditIcon />
            </Button>
          </div>
        </Table.Cell>
      </tr>
    );
  };

  _setContractStatus = (contractId, state) => {
    setContractStatus(contractId, state);
  };

  // TODO: Move elsewhere
  _renderAddContractModal = () => {
    const { addContractModal } = this.state;

    const addButtonEnabled =
      addContractModal.annualCost &&
      parseFloat(addContractModal.annualCost) > 0 &&
      addContractModal.baseBudget &&
      addContractModal.endDate &&
      addContractModal.monthlyCost &&
      parseFloat(addContractModal.monthlyCost) > 0 &&
      addContractModal.policyNumber &&
      addContractModal.selectedFacilityId &&
      addContractModal.startDate;

    const {
      blueprintsInputs,
      commercialConditionsInputs,
      contractPolicyInputs,
      SCINotesInputs
    } = addContractModal;

    return (
      <Container width={1118}>
        <div
          onClick={() => this._handleOnCloseButton()}
          style={{ position: "absolute", cursor: "pointer", right: 30 }}
        >
          <CloseIcon />
        </div>
        <Modal.Header>Datos de contrato</Modal.Header>
        <Modal.Section paddingVertical={30} paddingHorizontal={100}>
          <FacilitySelector
            selected={[
              addContractModal.selectedClient,
              addContractModal.selectedBusinessName,
              addContractModal.selectedFacilityId
            ]}
            onChange={(key, value) =>
              this._handleOnFacilitySelectorChange(key, value)
            }
            onFacilitySelect={facilityId =>
              this._handleOnFacilitySelect(facilityId)
            }
          />
        </Modal.Section>
        <Modal.Section
          style={{ margin: "0 50px", borderTop: "1px solid #d7d7d7" }}
          paddingVertical={30}
          paddingHorizontal={100}
        >
          <Layout.Grid>
            <Layout.Column>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={1}
                  type="date-picker"
                  name="startDate"
                  label="Fecha de inicio de contrato"
                  defaultValue={addContractModal.startDate}
                  onChange={this._handleOnInputChange}
                />
              </div>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={4}
                  type="currency"
                  name="monthlyCost"
                  label="Costo total mensual"
                  defaultValue={addContractModal.monthlyCost}
                  onChange={this._handleOnInputChange}
                />
              </div>
            </Layout.Column>
            <Layout.Column>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={2}
                  type="date-picker"
                  name="endDate"
                  label="Fecha de finalización de contrato"
                  defaultValue={addContractModal.endDate}
                  onChange={this._handleOnInputChange}
                />
              </div>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={5}
                  name="baseBudget"
                  label="PEP base"
                  defaultValue={addContractModal.baseBudget}
                  onChange={this._handleOnInputChange}
                />
              </div>
            </Layout.Column>
            <Layout.Column>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={3}
                  type="currency"
                  name="annualCost"
                  label="Costo total anual"
                  defaultValue={addContractModal.annualCost}
                  onChange={this._handleOnInputChange}
                />
              </div>
              <div style={{ marginBottom: "40px" }}>
                <LabeledInput
                  index={6}
                  name="policyNumber"
                  label="Nombre de póliza"
                  defaultValue={addContractModal.policyNumber}
                  onChange={this._handleOnInputChange}
                />
              </div>
            </Layout.Column>
          </Layout.Grid>
        </Modal.Section>
        <Modal.Header>Documentos</Modal.Header>
        <Modal.Section paddingVertical={30} paddingHorizontal={100}>
          <div>
            <div style={{ marginBottom: 20 }}>
              <div
                style={{
                  marginBottom: 20,
                  fontSize: 16,
                  fontWeight: 700,
                  color: "#5A5E61"
                }}
              >
                Póliza del contrato
              </div>
              <div>
                <UploadButton
                  label="Adjuntar póliza"
                  onSuccessfulUpload={(fileName, uri) =>
                    this._handleContractDocumentUploaded(
                      "contractPolicyInputs",
                      fileName,
                      uri
                    )
                  }
                />
              </div>
              {contractPolicyInputs.length > 0 &&
                this._renderContractDocuments(
                  "contractPolicyInputs",
                  contractPolicyInputs
                )}
            </div>
            <div style={{ marginBottom: 20 }}>
              <div
                style={{
                  marginBottom: 20,
                  fontSize: 16,
                  fontWeight: 700,
                  color: "#5A5E61"
                }}
              >
                Información del SCI
              </div>
              <div>
                <UploadButton
                  label="Adjuntar información"
                  onSuccessfulUpload={(fileName, uri) =>
                    this._handleContractDocumentUploaded(
                      "SCINotesInputs",
                      fileName,
                      uri
                    )
                  }
                />
              </div>
              {SCINotesInputs.length > 0 &&
                this._renderContractDocuments("SCINotesInputs", SCINotesInputs)}
            </div>
            <div style={{ marginBottom: 20 }}>
              <div
                style={{
                  marginBottom: 20,
                  fontSize: 16,
                  fontWeight: 700,
                  color: "#5A5E61"
                }}
              >
                Condiciones comerciales
              </div>
              <div>
                <UploadButton
                  label="Adjuntar condiciones"
                  onSuccessfulUpload={(fileName, uri) =>
                    this._handleContractDocumentUploaded(
                      "commercialConditionsInputs",
                      fileName,
                      uri
                    )
                  }
                />
              </div>
              {commercialConditionsInputs.length > 0 &&
                this._renderContractDocuments(
                  "commercialConditionsInputs",
                  commercialConditionsInputs
                )}
            </div>
            <div>
              <div
                style={{
                  marginBottom: 20,
                  fontSize: 16,
                  fontWeight: 700,
                  color: "#5A5E61"
                }}
              >
                Planos de planta
              </div>
              <div>
                <UploadButton
                  label="Adjuntar planos"
                  onSuccessfulUpload={(fileName, uri) =>
                    this._handleContractDocumentUploaded(
                      "blueprintsInputs",
                      fileName,
                      uri
                    )
                  }
                />
              </div>
              {blueprintsInputs.length > 0 &&
                this._renderContractDocuments(
                  "blueprintsInputs",
                  blueprintsInputs
                )}
            </div>
          </div>
        </Modal.Section>
        <Modal.Footer>
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <Button
              style="outline"
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={() => this._handleOnCloseButton()}
            >
              Volver
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={this._handleOnCreateChecklistButton}
            disabled={!addButtonEnabled}
          >
            Crear Checklist
          </Button>
        </Modal.Footer>
      </Container>
    );
  };

  _renderContractDocuments = (type, documents) => {
    return (
      <div
        style={{
          paddingTop: 20,
          marginLeft: -20,
          marginRight: -20
        }}
      >
        {documents.map(file => {
          const fileURI = Object.keys(file)[0];
          const fileName = Object.values(file)[0];
          return (
            <div
              style={{
                display: "inline-block",
                width: "calc(33% - 40px)",
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: 20
              }}
              key={fileURI}
            >
              <FileButton
                label={fileName}
                onClick={() => this._showPreviewModal(fileURI)}
                onRemove={() => this._removeContractDocument(type, fileURI)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  _renderEditContractDocuments = (type, documents) => {
    return (
      <div
        style={{
          paddingTop: 20,
          marginLeft: -20,
          marginRight: -20
        }}
      >
        {documents.map(file => {
          return (
            <div
              style={{
                display: "inline-block",
                width: "calc(33% - 40px)",
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: 20
              }}
              key={file.url}
            >
              <FileButton
                label={file.name}
                onClick={() => this._showPreviewModal(file.url)}
                onRemove={() =>
                  this._editRemoveContractDocument(file.type, file.url)
                }
              />
            </div>
          );
        })}
      </div>
    );
  };

  _renderAddInspectionChecklistModal = () => {
    const { addInspectionChecklistModal } = this.state;
    const { formats } = addInspectionChecklistModal;

    const formatKeys = Object.keys(addInspectionChecklistModal.formats);
    const requiredFormats =
      formatKeys.length > 0
        ? formatKeys.filter(format => formats[format].required)
        : false;

    const createButtonEnabled =
      requiredFormats.length > 0 && requiredFormats.every(e => e)
        ? formatKeys.every(format => {
            if (formats[format] && formats[format].required) {
              if (
                formats[format].quantity > 0 &&
                formats[format].periodicity &&
                formats[format].recurrence
              ) {
                return true;
              }

              return false;
            }

            return true;
          })
        : false;

    return (
      <Container width={1300}>
        <Modal.Header>Checklist de inspección</Modal.Header>
        <Modal.Section paddingVertical={30} paddingHorizontal={100}>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Bomba diesel", "DIESEL_PUMP")}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Bomba eléctrica", "ELECTRIC_PUMP")}
            </Layout.Column>
            <Layout.Column
              style={{
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Bomba jockey", "JOCKEY_PUMP")}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Tanque de agua", "WATER_TANK")}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Sistema seco", "DRY_SYSTEM")}
            </Layout.Column>
            <Layout.Column
              style={{
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Carretes de manguera", "HOSE_REELS")}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Válvulas check de alarmas",
                "ALARM_VALVES"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Hidrante seco - inspección",
                "DRY_HYDRANT"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Hidrante seco - pruebas",
                "DRY_HYDRANT_TESTS"
              )}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Válvulas compuerta - inspección",
                "GATE_VALVES_INSPECTION"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Válvulas compuerta - pruebas",
                "GATE_VALVES_TESTS"
              )}
            </Layout.Column>
            <Layout.Column style={{ borderBottom: "1px solid #f0f0f0" }}>
              {this._renderChecklist(
                "Mantenimiento de sensores de flujo",
                "FLOW_SENSORS_MAINTENANCE"
              )}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Inspección de sensores de flujo",
                "FLOW_SENSORS_INSPECTION"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Inspección de hidrantes de muro",
                "WALL_HYDRANT_INSPECTION"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Inspección de conexiones de bomberos",
                "FIREFIGHTER_CONNECTIONS_INSPECTION"
              )}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Inspección de Extintores",
                "EXTINGUISHER_INSPECTION"
              )}
            </Layout.Column>
            <Layout.Column />
            <Layout.Column />
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0"
              }}
            />
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0"
              }}
            />
            <Layout.Column />
          </Layout.Grid>
        </Modal.Section>
        <Modal.Footer>
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <Button
              style="outline"
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={() => this._handleOnBackButton()}
            >
              Volver
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={this._handleOnCreateInspectionChecklistButton}
            disabled={
              !createButtonEnabled ||
              addInspectionChecklistModal.addButtonDisabled
            }
          >
            Crear
          </Button>
        </Modal.Footer>
      </Container>
    );
  };

  // TODO: Move to its own component
  _renderChecklist = (title, format) => {
    const { addContractModal, addInspectionChecklistModal } = this.state;
    const isChecklistRequired = addInspectionChecklistModal.formats[format]
      ? addInspectionChecklistModal.formats[format].required
        ? true
        : false
      : false;

    const quantity =
      addInspectionChecklistModal.formats[format] &&
      addInspectionChecklistModal.formats[format].quantity;

    const periodicity =
      addInspectionChecklistModal.formats[format] &&
      addInspectionChecklistModal.formats[format].periodicity;

    const recurrence =
      addInspectionChecklistModal.formats[format] &&
      addInspectionChecklistModal.formats[format].recurrence;

    const startOn =
      addInspectionChecklistModal.formats[format] &&
      addInspectionChecklistModal.formats[format].startOn;

    const startOnValue =
      new Date(startOn) >= new Date(addContractModal.startDate) &&
      new Date(startOn) <= new Date(addContractModal.endDate)
        ? startOn
        : undefined;

    return (
      <div style={{ padding: 30 }}>
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              float: "left",
              fontSize: 16,
              color: "#4a4a4a",
              marginBottom: 30,
              maxWidth: "216px"
            }}
          >
            {title}
          </div>
          <div style={{ float: "right" }}>
            <Switch
              active={isChecklistRequired}
              onToggle={state => this._handleOnChecklistToggle(format, state)}
            />
          </div>
        </div>
        <div>
          <Layout.Grid>
            <Layout.Column style={{ marginRight: 30 }}>
              <LabeledInput
                fullWidth
                type="number"
                name="quantity"
                label="Cantidad"
                disabled={!isChecklistRequired}
                onChange={e =>
                  this._handleOnChecklistInputChange(
                    format,
                    "quantity",
                    e.currentTarget.value
                  )
                }
                defaultValue={quantity}
              />
              <div style={{ marginTop: 30 }}>
                <LabeledInput
                  fullWidth
                  name="recurrence"
                  label="Repetir (Días)"
                  type="select"
                  selectOptions={Object.keys(WEEKDAYS)}
                  disabled={!isChecklistRequired}
                  onChange={e =>
                    this._handleOnChecklistInputChange(
                      format,
                      "recurrence",
                      e.currentTarget.value
                    )
                  }
                  defaultValue={recurrence}
                />
              </div>
            </Layout.Column>
            <Layout.Column>
              <LabeledInput
                fullWidth
                name="periodicity"
                label="Periodicidad"
                type="select"
                selectOptions={Object.keys(PERIODICITY)}
                disabled={!isChecklistRequired}
                onChange={e =>
                  this._handleOnChecklistInputChange(
                    format,
                    "periodicity",
                    e.currentTarget.value
                  )
                }
                defaultValue={periodicity}
              />
              <div style={{ marginTop: 30 }}>
                <LabeledInput
                  fullWidth
                  type="date-picker"
                  name="startOn"
                  label="Fecha de inicio"
                  disabled={!isChecklistRequired}
                  onChange={e =>
                    this._handleOnChecklistInputChange(
                      format,
                      "startOn",
                      e.state.value
                    )
                  }
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(addContractModal.startDate),
                        after: new Date(addContractModal.endDate)
                      }
                    }
                  }}
                  defaultValue={startOnValue}
                />
              </div>
            </Layout.Column>
          </Layout.Grid>
        </div>
      </div>
    );
  };

  _renderPreviewModal = () => {
    const { previewUri } = this.state;
    const downloadPath = `${process.env.DOWNLOAD_ASSETS_URL}${previewUri}`;

    return (
      <>
        <PreviewModal.Header
          onClick={this._hidePreviewModal}
          filePath={previewUri}
        >
          {previewUri}
        </PreviewModal.Header>
        <PreviewModal.Section
          downloadPath={previewUri}
          streamPath={previewUri}
          paddingVertical={0}
          paddingHorizontal={0}
        />
      </>
    );
  };

  _renderDeleteContractModal = () => {
    const {
      deleteContractModal: { deleteButtonDisabled, deleteContractId }
    } = this.state;

    const { contracts } = this.props;
    const contract = contracts.find(
      contract => contract.id === deleteContractId
    );

    return (
      <DeleteContractModal
        deleteButtonDisabled={deleteButtonDisabled}
        deleteContractId={deleteContractId}
        contract={contract}
        onBackButton={this._handleOnBackDeleteButton}
        onDeleteContractButton={this._handleOnDeleteContractButton}
      />
    );
  };

  _renderEditContractModal = () => {
    const { editContractModal } = this.state;
    const { contracts } = this.props;
    const contract = contracts.find(
      contract => contract.id === editContractModal.editContractId
    );

    return (
      <EditContractModal
        editContractModalState={editContractModal}
        contract={contract}
        facilitySelector={editContractModal.facilitySelector}
        facilitySelectors={editContractModal.facilitySelectors}
        editContractId={editContractModal.editContractId}
        onCloseButton={this._handleOnCloseButton}
        onFacilitySelectorChange={this._handleOnEditFacilitySelectorChange}
        onViewChecklistButton={this._handleOnViewEditChecklist}
        onFacilitySelect={this._handleOnEditFacilitySelect}
        onInputChange={this._handleOnEditInputChange}
        showPreviewModal={this._showPreviewModal}
        handleDocumentUploaded={this._handleEditDocumentUploaded}
        documentRenderer={this._renderEditContractDocuments}
      />
    );
  };

  _renderEditChecklistModal = contract => {
    const {
      editContractModal: { editContractId }
    } = this.state;

    const { editChecklistModal } = this.state;
    const { formats } = editChecklistModal;

    const formatKeys = Object.keys(editChecklistModal.formats);
    const requiredFormats =
      formatKeys.length > 0
        ? formatKeys.filter(format => formats[format].status)
        : false;

    const saveButtonEnabled =
      requiredFormats.length > 0 && requiredFormats.every(e => e)
        ? formatKeys.every(format => {
            if (formats[format] && formats[format].status) {
              if (
                formats[format].quantity > 0 &&
                formats[format].periodicity &&
                formats[format].recurrence
              ) {
                return true;
              }

              return false;
            }

            return true;
          })
        : false;

    return (
      <EditChecklistModal
        editContractId={editContractId}
        contract={contract}
        onBackButton={this._handleOnBackEditChecklistButton}
        onPreviewButton={this._showPreviewModal}
        onSaveButton={this._handleOnEditContract}
        saveButtonDisabled={
          !saveButtonEnabled || editChecklistModal.saveButtonDisabled
        }
        checklistFormats={contract.inspectionChecklists}
        renderEditChecklist={this._renderEditChecklist}
      />
    );
  };

  _renderEditChecklist = (title, format) => {
    const { editContractModal, editChecklistModal } = this.state;
    const isChecklistRequired = editChecklistModal.formats[format]
      ? editChecklistModal.formats[format].status
        ? true
        : false
      : false;

    const quantity =
      editChecklistModal.formats[format] &&
      editChecklistModal.formats[format].quantity;

    const periodicity =
      editChecklistModal.formats[format] &&
      editChecklistModal.formats[format].periodicity;

    const recurrence =
      editChecklistModal.formats[format] &&
      editChecklistModal.formats[format].recurrence;

    const startOn =
      editChecklistModal.formats[format] &&
      editChecklistModal.formats[format].startOn;

    const parsedStartDate = isNaN(editContractModal.startDate)
      ? new Date(editContractModal.startDate).getTime()
      : new Date(parseInt(editContractModal.startDate)).getTime();

    const parsedEndDate = isNaN(editContractModal.endDate)
      ? new Date(editContractModal.endDate).getTime()
      : new Date(parseInt(editContractModal.endDate)).getTime();

    const startOnValue =
      new Date(startOn).getTime() >= parsedStartDate &&
      new Date(startOn).getTime() <= parsedEndDate
        ? startOn
        : undefined;

    return (
      <div style={{ padding: 30 }}>
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              float: "left",
              fontSize: 16,
              color: "#4a4a4a",
              marginBottom: 30,
              maxWidth: "216px"
            }}
          >
            {title}
          </div>
          <div style={{ float: "right" }}>
            <Switch
              active={isChecklistRequired}
              onToggle={state =>
                this._handleOnEditChecklistToggle(format, state)
              }
            />
          </div>
        </div>
        <div>
          <Layout.Grid>
            <Layout.Column style={{ marginRight: 30 }}>
              <LabeledInput
                fullWidth
                type="number"
                name="quantity"
                label="Cantidad"
                disabled={!isChecklistRequired}
                onChange={e =>
                  this._handleOnEditChecklistInputChange(
                    format,
                    "quantity",
                    e.currentTarget.value
                  )
                }
                defaultValue={quantity}
              />
              <div style={{ marginTop: 30 }}>
                <LabeledInput
                  fullWidth
                  name="recurrence"
                  label="Repetir (Días)"
                  type="select"
                  selectOptions={Object.keys(WEEKDAYS)}
                  disabled={!isChecklistRequired}
                  onChange={e =>
                    this._handleOnEditChecklistInputChange(
                      format,
                      "recurrence",
                      e.currentTarget.value
                    )
                  }
                  defaultValue={recurrence}
                />
              </div>
            </Layout.Column>
            <Layout.Column>
              <LabeledInput
                fullWidth
                name="periodicity"
                label="Periodicidad"
                type="select"
                selectOptions={Object.keys(PERIODICITY)}
                disabled={!isChecklistRequired}
                onChange={e =>
                  this._handleOnEditChecklistInputChange(
                    format,
                    "periodicity",
                    e.currentTarget.value
                  )
                }
                defaultValue={periodicity}
              />
              <div style={{ marginTop: 30 }}>
                <LabeledInput
                  fullWidth
                  type="date-picker"
                  name="startOn"
                  label="Fecha de inicio"
                  disabled={!isChecklistRequired}
                  onChange={e =>
                    this._handleOnEditChecklistInputChange(
                      format,
                      "startOn",
                      e.state.value
                    )
                  }
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(editContractModal.startDate),
                        after: new Date(editContractModal.endDate)
                      }
                    }
                  }}
                  defaultValue={startOnValue}
                />
              </div>
            </Layout.Column>
          </Layout.Grid>
        </div>
      </div>
    );
  };

  _renderViewContractModal = () => {
    const {
      viewContractModal: { viewContractId }
    } = this.state;

    const { contracts } = this.props;
    const contract = contracts.find(contract => contract.id === viewContractId);

    return (
      <ViewContractModal
        viewContractId={viewContractId}
        contract={contract}
        onBackButton={this._handleOnBackViewButton}
        onPreviewButton={this._showPreviewModal}
        onViewChecklistButton={this._handleOnViewChecklist}
      />
    );
  };

  _handleOnEditContract = () => {
    const {
      editContractModal: {
        editContractId,
        selectedFacilityId,
        annualCost,
        monthlyCost,
        baseBudget,
        policyNumber,
        startDate,
        endDate,
        contractPolicyInputs,
        SCINotesInputs,
        commercialConditionsInputs,
        blueprintsInputs,
        legacyDocuments
      },
      editChecklistModal: { formats }
    } = this.state;

    const documents = {
      POLICIES: contractPolicyInputs.filter(e => !e.legacy),
      SCI_NOTES: SCINotesInputs.filter(e => !e.legacy),
      COMMERCIAL_CONDITIONS: commercialConditionsInputs.filter(e => !e.legacy),
      FACILITY_BLUEPRINTS: blueprintsInputs.filter(e => !e.legacy)
    };

    const parsedStartDate = isNaN(startDate)
      ? new Date(startDate).getTime()
      : new Date(parseInt(startDate)).getTime();

    const parsedEndDate = isNaN(endDate)
      ? new Date(endDate).getTime()
      : new Date(parseInt(endDate)).getTime();

    // Filter out those formats that are not required
    const requiredFormats = Object.keys(formats).filter(
      format => formats[format].status
    );

    const inspectionChecklists = requiredFormats.map(format => ({
      id: formats[format].id,
      status: formats[format].status,
      checklistFormat: format,
      quantity: parseInt(formats[format].quantity),
      recurrence: PERIODICITY[formats[format].periodicity],
      repeatOn: WEEKDAYS[formats[format].recurrence],
      startOn:
        formats[format].startOn && new Date(formats[format].startOn).getTime()
    }));

    this.setState(
      state => ({
        editChecklistModal: {
          ...state.editChecklistModal,
          saveButtonDisabled: true
        }
      }),
      () =>
        editContractAndChecklists(
          editContractId,
          selectedFacilityId,
          String(annualCost),
          String(monthlyCost),
          baseBudget,
          policyNumber,
          String(parsedStartDate),
          String(parsedEndDate),
          JSON.stringify(documents),
          JSON.stringify(legacyDocuments),
          JSON.stringify(inspectionChecklists)
        )
          .then(response => {
            window.location = "/contratos";
          })
          .catch(err => {
            console.log(err);
            this.setState(state => ({
              editChecklistModal: {
                ...state.editChecklistModal,
                saveButtonDisabled: false
              }
            }));
          })
    );
  };

  _renderViewChecklistModal = () => {
    const {
      viewContractModal: { viewContractId }
    } = this.state;

    const { contracts } = this.props;
    const contract = contracts.find(contract => contract.id === viewContractId);

    return (
      <ViewChecklistModal
        viewContractId={viewContractId}
        contract={contract}
        onBackButton={this._handleOnBackViewChecklistButton}
        onPreviewButton={this._showPreviewModal}
        onCloseButton={this._handleOnCloseButton}
        checklistFormats={contract.inspectionChecklists}
        renderViewChecklist={this._renderViewChecklist}
      />
    );
  };

  _renderViewChecklist = (title, format) => {
    const { viewChecklistModal } = this.state;

    const status =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].status;

    const quantity =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].quantity;

    const periodicity =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].periodicity;

    const recurrence =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].recurrence;

    const startOn =
      viewChecklistModal.formats[format] &&
      viewChecklistModal.formats[format].startOn;

    const Field = ({ label, value }) => (
      <div style={{ width: "100%", margin: "0 auto", marginBottom: 40 }}>
        <div style={{ color: "#5A5E61", marginBottom: 10 }}>{label}</div>
        <div
          style={{
            color: "#5A5E61",
            fontWeight: "bold"
          }}
        >
          {value}
        </div>
        {viewChecklistModal.formats[format] &&
          viewChecklistModal.formats[format].quantity > 0 && (
          <div
            style={{ marginTop: "7px", borderBottom: "1px solid #cccccc" }}
          />
        )}
      </div>
    );
    return (
      <div style={{ padding: 30 }}>
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              float: "left",
              fontSize: 16,
              color: "#5A5E61",
              marginBottom: 30,
              maxWidth: "216px"
            }}
          >
            {title}
          </div>
          <div style={{ float: "right" }}>
            <Switch active={status} disabled />
          </div>
        </div>
        <div>
          <Layout.Grid>
            <Layout.Column style={{ marginRight: 30 }}>
              <div>
                <Field label="Cantidad" value={quantity} />
                <Field label="Se repite" value={recurrence} />
              </div>
            </Layout.Column>
            <Layout.Column style={{ marginRight: 30, color: "#5A5E61" }}>
              <div>
                <Field label="Periodicidad" value={periodicity} />
                <Field label="Inicia el" value={startOn} />
              </div>
            </Layout.Column>
          </Layout.Grid>
        </div>
      </div>
    );
  };

  _handleOnViewEditChecklist = () => {
    this.setState(state => ({
      editContractModal: {
        ...state.editContractModal,
        editContractModalVisible: false
      },
      editChecklistModal: {
        ...state.editChecklistModal,
        editChecklistModalVisible: true
      }
    }));
  };

  _handleOnResetDateRangeFilter = () => {
    this.setState({
      dateRangeFilter: {}
    });
  };

  _handleOnDateInputChange = e => {
    if (e.props) {
      return this.setState(state => ({
        dateRangeFilter: {
          ...state.dateRangeFilter,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };


  _handleOnBackEditChecklistButton = () => {
    this.setState(state => ({
      editChecklistModal: {
        ...state.editChecklistModal,
        editChecklistModalVisible: false
      },
      editContractModal: {
        ...state.editContractModal,
        editContractModalVisible: true
      }
    }));
  };

  _handleOnViewChecklist = () => {
    this.setState(state => ({
      viewContractModal: {
        ...state.viewContractModal,
        viewContractModalVisible: false
      },
      viewChecklistModal: {
        ...state.viewChecklistModal,
        viewChecklistModalVisible: true
      }
    }));
  };

  _handleOnBackViewChecklistButton = () => {
    this.setState(state => ({
      viewChecklistModal: {
        ...state.viewChecklistModal,
        viewChecklistModalVisible: false
      },
      viewContractModal: {
        ...state.viewContractModal,
        viewContractModalVisible: true
      }
    }));
  };

  _handleOnBackViewButton = () => {
    this.setState(state => ({
      viewContractModal: {
        ...state.viewContractModal,
        viewContractModalVisible: false
      }
    }));
  };

  _handleOnPressViewContractButton = contractId => {
    const { contracts } = this.props;
    const contract = contracts.find(contract => contract.id === contractId);
    const formats = {};
    contract.inspectionChecklists.map(checklist => {
      formats[checklist.checklistFormat] = {
        status: checklist.status,
        quantity: checklist.quantity,
        periodicity: PERIODICITY_DICT[checklist.recurrence],
        recurrence: WEEKDAYS_DICT[checklist.repeatOn],
        startOn:
          checklist.startOn && dateFormat(checklist.startOn, "DD/MM/YYYY")
      };
    });

    this.setState(state => ({
      viewContractModal: {
        ...state.viewContractModal,
        viewContractModalVisible: true,
        viewContractId: contractId
      },
      viewChecklistModal: {
        ...state.viewChecklistModal,
        formats
      }
    }));
  };

  _filterContracts = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnClickAddButton = () => {
    this.setState(state => ({
      addContractModal: { ...state.addContractModal, visible: true }
    }));
  };

  _handleOnCloseButton = () => {
    this.setState({
      addContractModal: initialModalStates.addContractModal,
      addInspectionChecklistModal:
        initialModalStates.addInspectionChecklistModal,
      editContractModal: initialModalStates.editContractModal,
      viewChecklistModal: initialModalStates.viewChecklistModal,
      editChecklistModal: initialModalStates.editChecklistModal
    });
  };

  _handlePreviewOnCloseButton = () => {
    this.setState({
      addContractModal: { visible: false }
    });
  };

  _handleOnBackdropClick = modalStateKey => {
    this.setState(state => ({
      [modalStateKey]: {
        ...state[modalStateKey],
        visible: false
      }
    }));
  };

  _handleOnFacilitySelectorChange = (key, value) => {
    if (key === "selectedClient") {
      this.setState(state => ({
        addContractModal: {
          ...state.addContractModal,
          selectedClient: value,
          selectedBusinessName: undefined,
          selectedFacilityId: undefined
        }
      }));
    } else if (key === "selectedBusinessName") {
      this.setState(state => ({
        addContractModal: {
          ...state.addContractModal,
          selectedBusinessName: value,
          selectedFacilityId: undefined
        }
      }));
    }
  };

  _handleOnFacilitySelect = facilityId => {
    this.setState(state => ({
      addContractModal: {
        ...state.addContractModal,
        selectedFacilityId: facilityId
      }
    }));
  };

  _handleOnInputChange = e => {
    // Handle input change of the date picker component
    if (e.props) {
      return this.setState(state => ({
        addContractModal: {
          ...state.addContractModal,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    if (e.name === "annualCost" || e.name === "monthlyCost") {
      return this.setState(state => ({
        addContractModal: {
          ...state.addContractModal,
          [e.name]: String(e.value)
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      addContractModal: {
        ...state.addContractModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _handleOnChecklistToggle = (format, toggleState) => {
    const { addInspectionChecklistModal } = this.state;

    if (!addInspectionChecklistModal.formats[format]) {
      return this.setState(state => ({
        addInspectionChecklistModal: {
          ...state.addInspectionChecklistModal,
          formats: {
            ...state.addInspectionChecklistModal.formats,
            [format]: {
              required: toggleState,
              quantity: undefined,
              recurrence: undefined,
              repeatOn: undefined
            }
          }
        }
      }));
    }

    return this.setState(state => ({
      addInspectionChecklistModal: {
        ...state.addInspectionChecklistModal,
        formats: {
          ...state.addInspectionChecklistModal.formats,
          [format]: {
            ...state.addInspectionChecklistModal.formats[format],
            required: toggleState
          }
        }
      }
    }));
  };

  _handleOnEditChecklistToggle = (format, toggleState) => {
    const { editChecklistModal } = this.state;

    if (!editChecklistModal.formats[format]) {
      return this.setState(state => ({
        editChecklistModal: {
          ...state.editChecklistModal,
          formats: {
            ...state.editChecklistModal.formats,
            [format]: {
              status: toggleState,
              quantity: undefined,
              recurrence: undefined,
              repeatOn: undefined
            }
          }
        }
      }));
    }

    return this.setState(state => ({
      editChecklistModal: {
        ...state.editChecklistModal,
        formats: {
          ...state.editChecklistModal.formats,
          [format]: {
            ...state.editChecklistModal.formats[format],
            status: toggleState
          }
        }
      }
    }));
  };

  _handleOnChecklistInputChange = (format, input, value) => {
    const { formats } = this.state.addInspectionChecklistModal;

    if (formats[format]) {
      this.setState(state => ({
        addInspectionChecklistModal: {
          ...state.addInspectionChecklistModal,
          formats: {
            ...state.addInspectionChecklistModal.formats,
            [format]: {
              ...state.addInspectionChecklistModal.formats[format],
              [input]: value
            }
          }
        }
      }));
    }
  };

  _handleOnEditChecklistInputChange = (format, input, value) => {
    const { formats } = this.state.editChecklistModal;

    if (formats[format]) {
      this.setState(state => ({
        editChecklistModal: {
          ...state.editChecklistModal,
          formats: {
            ...state.editChecklistModal.formats,
            [format]: {
              ...state.editChecklistModal.formats[format],
              [input]: value
            }
          }
        }
      }));
    }
  };

  _handleOnCreateChecklistButton = () => {
    // Save the contract in the DB
    this.setState(state => ({
      addContractModal: {
        ...state.addContractModal,
        visible: false
      },
      addInspectionChecklistModal: {
        ...state.addInspectionChecklistModal,
        visible: true
      }
    }));
  };

  _showPreviewModal = previewUri => {
    this.setState(state => ({
      previewModalVisible: true,
      previewUri
    }));
  };

  _handleEditDocumentUploaded = (type, name, result) => {
    this.setState(state => ({
      editContractModal: {
        ...state.editContractModal,
        [type]: [
          ...state.editContractModal[type],
          {
            name,
            url: result,
            type: DOCUMENT_TYPES[type]
          }
        ]
      }
    }));
  };

  _handleContractDocumentUploaded = (type, name, result) =>
    this.setState(state => ({
      addContractModal: {
        ...state.addContractModal,
        [type]: [...state.addContractModal[type], { [result]: name }]
      }
    }));

  _editRemoveContractDocument = (type, URI) => {
    const { editContractModal } = this.state;
    const actualType = DOCUMENT_TYPES_DICT[type];

    const doc = editContractModal[actualType].find(e => e.url === URI);
    if (doc.legacy) {
      const withoutURI = editContractModal[actualType].filter(
        e => e.url !== URI
      );

      this.setState(state => ({
        editContractModal: {
          ...state.editContractModal,
          legacyDocuments: {
            ...state.editContractModal.legacyDocuments,
            [LEGACY_DOCUMENT_TYPES_DICT[doc.type]]: null
          },
          [actualType]: withoutURI
        }
      }));
    } else {
      const withoutURI = editContractModal[actualType].filter(
        e => e.url !== URI
      );

      this.setState(state => ({
        editContractModal: {
          ...state.editContractModal,
          [actualType]: withoutURI
        }
      }));
    }
  };

  _removeContractDocument = (type, URI) => {
    const { addContractModal } = this.state;
    const withoutURI = addContractModal[type].filter(
      e => Object.keys(e)[0] !== URI
    );

    this.setState(state => ({
      addContractModal: {
        ...state.addContractModal,
        [type]: withoutURI
      }
    }));
  };

  _hidePreviewModal = () => {
    this.setState({
      previewModalVisible: false
    });
  };

  _handleOnBackDeleteButton = () => {
    this.setState(state => ({
      deleteContractModal: {
        ...state.deleteContractModal,
        deleteContractModalVisible: false
      }
    }));
  };

  _handleOnPressDeleteContractButton = contractId => {
    this.setState(state => ({
      deleteContractModal: {
        ...state.deleteContractModal,
        deleteContractModalVisible: true,
        deleteContractId: contractId
      }
    }));
  };

  _handleOnDeleteContractButton = contractId => {
    if (!!contractId) {
      this.setState(
        (state, _) => ({
          deleteContractModal: {
            ...state.deleteContractModal,
            deleteButtonDisabled: true
          }
        }),
        () =>
          deleteContract(contractId)
            .then(() => {
              window.location = "/contratos";
            })
            .catch(err => {
              console.log(err);
              this.setState(state => ({
                deleteContractModal: {
                  deleteContractModal: false
                }
              }));
            })
      );
    }
  };

  _handleOnPressEditContractButton = contractId => {
    const { contracts } = this.props;
    const contract = contracts.find(contract => contract.id === contractId);

    const facilitySelector = {
      0: {
        selectedClient: contract.facility.businessName.client.name,
        selectedBusinessName: contract.facility.businessName.name,
        selectedFacilityId: contract.facility.id
      }
    };

    const formats = {};
    contract.inspectionChecklists.map(checklist => {
      formats[checklist.checklistFormat] = {
        id: checklist.id,
        status: checklist.status,
        required: checklist.quantity > 0,
        quantity: checklist.quantity,
        periodicity: PERIODICITY_DICT[checklist.recurrence],
        recurrence: WEEKDAYS_DICT[checklist.repeatOn],
        startOn:
          checklist.startOn && dateFormat(checklist.startOn, "MM/DD/YYYY")
      };
    });

    const contractPolicies = contract.contractDocuments.filter(
      e => e.type === "POLICIES"
    );

    const SCINotes = contract.contractDocuments.filter(
      e => e.type === "SCI_NOTES"
    );

    const commercialConditions = contract.contractDocuments.filter(
      e => e.type === "COMMERCIAL_CONDITIONS"
    );

    const blueprints = contract.contractDocuments.filter(
      e => e.type === "FACILITY_BLUEPRINTS"
    );

    contract.contractPolicyUrl &&
      contractPolicies.push({
        type: "POLICIES",
        legacy: true,
        name: contract.contractPolicyUrl,
        url: contract.contractPolicyUrl
      });

    contract.SCINotesUrl &&
      SCINotes.push({
        type: "SCI_NOTES",
        legacy: true,
        name: contract.SCINotesUrl,
        url: contract.SCINotesUrl
      });

    contract.commercialConditionsUrl &&
      commercialConditions.push({
        type: "COMMERCIAL_CONDITIONS",
        legacy: true,
        name: contract.commercialConditionsUrl,
        url: contract.commercialConditionsUrl
      });

    contract.facilityBlueprintsUrl &&
      blueprints.push({
        type: "FACILITY_BLUEPRINTS",
        legacy: true,
        name: contract.facilityBlueprintsUrl,
        url: contract.facilityBlueprintsUrl
      });

    this.setState(state => ({
      editContractModal: {
        ...state.editContractModal,
        visible: true,
        editContractId: contractId,
        startDate: contract.startDate,
        monthlyCost: contract.monthlyCost,
        dataSheet: contract.dataSheet,
        endDate: contract.endDate,
        baseBudget: contract.baseBudget,
        annualCost: contract.annualCost,
        policyNumber: contract.policyNumber,
        facilitySelector,
        selectedFacilityId: contract.facility.id,
        contractPolicyInputs: contractPolicies,
        SCINotesInputs: SCINotes,
        commercialConditionsInputs: commercialConditions,
        blueprintsInputs: blueprints
      },
      editChecklistModal: {
        ...state.editChecklistModal,
        formats
      }
    }));
  };

  _handleOnEditFacilitySelect = (selector, facilityId) => {
    this.setState(state => ({
      editContractModal: {
        ...state.editContractModal,
        selectedFacilityId: facilityId,
        facilitySelectors: {
          ...state.editContractModal.facilitySelectors,
          [selector]: {
            ...state.editContractModal.facilitySelectors[selector],
            selectedFacilityId: facilityId
          }
        }
      }
    }));
  };

  _handleOnEditInputChange = (e, day) => {
    if (e.props) {
      return this.setState(state => ({
        editContractModal: {
          ...state.editContractModal,
          [e.props.inputProps.name]: String(new Date(day).getTime())
        }
      }));
    }

    if (e.name === "annualCost" || e.name === "monthlyCost") {
      return this.setState(state => ({
        editContractModal: {
          ...state.editContractModal,
          [e.name]: String(e.value)
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      editContractModal: {
        ...state.editContractModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _handleOnEditFacilitySelectorChange = (selector, key, value) => {
    if (key === "selectedClient") {
      this.setState(state => ({
        editContractModal: {
          ...state.editContractModal,
          selectedFacilityId: undefined,
          facilitySelectors: {
            ...state.editContractModal.facilitySelectors,
            [selector]: {
              selectedClient: value,
              selectedBusinessName: undefined,
              selectedFacilityId: undefined
            }
          }
        }
      }));
    } else if (key === "selectedBusinessName") {
      this.setState(state => ({
        editContractModal: {
          ...state.editContractModal,
          selectedFacilityId: undefined,
          facilitySelectors: {
            ...state.editContractModal.facilitySelectors,
            [selector]: {
              ...state.editContractModal.facilitySelectors[selector],
              selectedBusinessName: value,
              selectedFacilityId: undefined
            }
          }
        }
      }));
    }
  };

  _handleOnBackButton = () => {
    this.setState(state => ({
      addContractModal: {
        ...state.addContractModal,
        visible: true
      },
      addInspectionChecklistModal: {
        ...state.addInspectionChecklistModal,
        visible: false
      }
    }));
  };

  _handleOnCreateInspectionChecklistButton = () => {
    // Save the contract in the DB
    const {
      addContractModal: {
        selectedFacilityId,
        annualCost,
        monthlyCost,
        baseBudget,
        policyNumber,
        startDate,
        endDate,
        contractPolicyInputs,
        SCINotesInputs,
        commercialConditionsInputs,
        blueprintsInputs
      },
      addInspectionChecklistModal: { formats }
    } = this.state;

    const documents = {
      POLICIES: contractPolicyInputs,
      SCI_NOTES: SCINotesInputs,
      COMMERCIAL_CONDITIONS: commercialConditionsInputs,
      FACILITY_BLUEPRINTS: blueprintsInputs
    };

    const parsedStartDate = new Date(startDate).getTime();
    const parsedEndDate = new Date(endDate).getTime();

    // Filter out those formats that are not required
    const requiredFormats = Object.keys(formats).filter(
      format => formats[format].required
    );

    const inspectionChecklists = requiredFormats.map(format => ({
      checklistFormat: format,
      quantity: parseInt(formats[format].quantity),
      recurrence: PERIODICITY[formats[format].periodicity],
      repeatOn: WEEKDAYS[formats[format].recurrence],
      startOn:
        formats[format].startOn && new Date(formats[format].startOn).getTime()
    }));

    this.setState(
      (state, _) => ({
        addInspectionChecklistModal: {
          ...state.addInspectionChecklistModal,
          addButtonDisabled: true
        }
      }),
      () =>
        createContractAndChecklists(
          selectedFacilityId,
          annualCost,
          monthlyCost,
          baseBudget,
          policyNumber,
          String(parsedStartDate),
          String(parsedEndDate),
          JSON.stringify(documents),
          JSON.stringify(inspectionChecklists)
        )
          .then(response => {
            window.location = "/contratos";
            mixpanel.track("Nuevo contrato creado");
          })
          .catch(err => {
            console.log(err);
            this.setState(state => ({
              addInspectionChecklistModal: {
                ...state.addInspectionChecklistModal,
                addButtonDisabled: false
              }
            }));
          })
    );
  };
}

export default ContractsTemplate;
