import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Modal } from "../../atoms";

const ApproveReportModal = ({
  addButtonDisabled,
  approveReportId,
  report,
  onBackApproveButton,
  onApproveReportButton
}) => {
  return (
    <Container width={540}>
      <Modal.Section paddingVertical={80} paddingHorizontal={80}>
        <div style={{ fontSize: "20px" }}>
          ¿Estas seguro que deseas aprobar el reporte de la planta{" "}
          <div style={{ fontWeight: "bold", display: "inline-block" }}>
            {report.fTec.facility.name}?
          </div>
        </div>
      </Modal.Section>
      <div style={{ marginBottom: "37px", marginLeft: "95px" }}>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={8}
            radius={7}
            paddingHorizontal={35}
            onClick={onBackApproveButton}
            style="outline"
          >
            No, volver
          </Button>
        </div>
        <Button
          paddingVertical={8}
          radius={7}
          paddingHorizontal={30}
          onClick={() => onApproveReportButton(approveReportId)}
          disabled={addButtonDisabled}
        >
          Sí, continuar
        </Button>
      </div>
    </Container>
  );
};

ApproveReportModal.PropTypes = {
  addButtonDisabled: PropTypes.bool.isRequired,
  approveReportId: PropTypes.string.isRequired,
  report: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  onBackApproveButton: PropTypes.func.isRequired,
  onApproveReportButton: PropTypes.func.isRequired
};

export default ApproveReportModal;
