import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const Container = ({ style, width, noPadding, children }) => {
  return (
    <div
      style={{ width: width && width, ...style, padding: noPadding && 0 }}
      className={styles.container}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  style: PropTypes.object,
  width: PropTypes.number,
  noPadding: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element
  ])
};

Container.defaultProps = {
  noPadding: false
};

export default Container;
