import React, { Component } from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import CurrencyInput from "react-currency-input";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";

if (process.env.NODE_ENV !== "test") {
  require("react-day-picker/lib/style.css");
}

import styles from "./styles.scss";
import { SelectDropdownIcon } from "../Icons";

const LABELED_INPUT_TYPES = [
  "text",
  "number",
  "select",
  "currency",
  "password",
  "date-picker",
  "time-picker"
];

class LabeledInput extends Component {
  state = {
    active: false
  };

  static propTypes = {
    index: PropTypes.number,
    width: PropTypes.number,
    fullWidth: PropTypes.bool,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(LABELED_INPUT_TYPES),
    selectOptions: PropTypes.arrayOf(PropTypes.string),
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    componentProps: PropTypes.object
  };

  static defaultProps = {
    type: "text",
    disabled: false
  };

  componentDidMount() {
    const { defaultValue } = this.props;
    this.setState({ value: defaultValue });
  }

  render() {
    const { active } = this.state;
    const {
      index,
      width,
      fullWidth,
      label,
      type,
      selectOptions,
      defaultValue,
      name,
      onChange,
      disabled,
      componentProps
    } = this.props;

    const style = { width: width && `${width}px` };

    if (fullWidth) style.width = "100%";

    return (
      <div
        className={
          active
            ? styles.labeledInputContainerActive
            : styles.labeledInputContainer
        }
        style={{ opacity: disabled && 0.4, width: fullWidth && "100%" }}
      >
        <div className={styles.inputLabel} style={style}>
          {label}
        </div>
        <div className={styles.inputContainer} style={style}>
          {(() => {
            switch (type) {
              case "number":
              case "text":
                return (
                  <input
                    tabIndex={index}
                    onFocus={this._setActive}
                    onBlur={this._setInactive}
                    onChange={onChange}
                    name={name}
                    type={type}
                    style={style}
                    disabled={disabled}
                    defaultValue={defaultValue}
                  />
                );
              case "currency":
                const { value } = this.state;

                return (
                  <CurrencyInput
                    tabIndex={index}
                    onFocus={this._setActive}
                    onBlur={this._setInactive}
                    onChangeEvent={(e, maskedValue, floatValue) =>
                      this.setState(
                        { value: floatValue },
                        onChange && onChange({ name, value: floatValue })
                      )
                    }
                    name={name}
                    style={style}
                    value={value}
                    defaultValue={defaultValue}
                  />
                );
              case "select":
                return (
                  <div className={styles.selectInputContainer}>
                    <select
                      tabIndex={index}
                      onFocus={this._setActive}
                      onBlur={this._setInactive}
                      onChange={onChange}
                      name={name && name}
                      defaultValue={defaultValue}
                      style={{ style, color: "#5A5E61", width: "100%" }}
                      disabled={disabled}
                    >
                      <option hidden selected={!defaultValue} />
                      {selectOptions &&
                        selectOptions.map((option, i) => (
                          <option key={i}>{option}</option>
                        ))}
                    </select>
                    <div className={styles.selectInputIcon}>
                      <SelectDropdownIcon />
                    </div>
                  </div>
                );
              case "password":
                return (
                  <input
                    tabIndex={index}
                    onFocus={this._setActive}
                    onBlur={this._setInactive}
                    onChange={onChange}
                    name={name && name}
                    type="password"
                    style={style}
                    defaultValue={defaultValue}
                  />
                );
              case "date-picker":
                return (
                  <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder="MM/DD/AAAA"
                    inputProps={{
                      name: name,
                      autocomplete: false,
                      readOnly: true
                    }}
                    format="MM/DD/YYYY"
                    value={defaultValue}
                    onDayChange={(day, modifiers, input) =>
                      onChange(input, day)
                    }
                    {...componentProps}
                  />
                );
              case "time-picker":
                return (
                  <input
                    style={{ width: 250 }}
                    id="time"
                    label="Alarm clock"
                    type="time"
                    name={name}
                    value={defaultValue}
                    onChange={onChange}
                  />
                );
            }
          })()}
        </div>
      </div>
    );
  }

  _setActive = () => this.setState({ active: true });

  _setInactive = () => this.setState({ active: false });
}

export default LabeledInput;
