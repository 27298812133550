import React from "react";

const Edit = props => (
  <svg width={22} height={22} {...props}>
    <defs>
      <path
        id="edit_svg__b"
        d="M1478.905 347.143l-5.049-5.058a3.57 3.57 0 0 1 2.284-.82c.96 0 1.858.375 2.536 1.05a3.558 3.558 0 0 1 1.048 2.54c0 .85-.29 1.646-.819 2.288zm-12.363 12.396l-2.066-2.068 11.481-11.49 2.066 2.067zm6.418-16.568l2.116 2.116-11.498 11.499-2.116-2.116zm6.615-1.547a4.852 4.852 0 0 0-6.867 0l-12.59 12.588a.63.63 0 0 0-.18.36l-.932 6.91a.628.628 0 0 0 .178.53.64.64 0 0 0 .446.188c.028 0 .056 0 .084-.005l4.162-.562a.634.634 0 0 0-.168-1.257l-3.338.45.652-4.819 5.072 5.073a.62.62 0 0 0 .89 0l12.591-12.588a4.827 4.827 0 0 0 1.425-3.436 4.808 4.808 0 0 0-1.425-3.432z"
      />
      <linearGradient
        id="edit_svg__a"
        x1={1474.12}
        x2={1462}
        y1={344.38}
        y2={361.77}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dd4a58" />
        <stop offset={1} stopColor="#f36875" />
      </linearGradient>
    </defs>
    <use
      fill="url(#edit_svg__a)"
      xlinkHref="#edit_svg__b"
      transform="translate(-1459 -340)"
    />
  </svg>
);

export default Edit;
