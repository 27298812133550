import React from "react";
import PropTypes from "prop-types";

const Filter = ({ tint }) => (
  <svg width={24} height={24}>
    <image
      id="image0"
      width="19"
      height="20"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAMAAABYi/ZGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAclBMVEXdSljfVWLfU2H54eT+
/P3leIL+/f7leIPrl5/65efvrbPvrLP77e7hY2/dSlj88PHgXGnupKv42tzsmqL42t3sm6LpipPo
iZL88fL32dvoipP54OPmeYT54OL99fbeUl/zwcbyub7jbnnxtrz+/v7///9LBnSqAAAAA3RSTlMA
AAD6dsTeAAAAAWJLR0QlwwHJDwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+MBFBIOCUbi
yMAAAAB7SURBVBjTlZA3FoAgDEBjAcGCYu/9/mcUfOCADJoh+flDEgCwh/fKAP4rf3YIo6dDOJCF
0JAS5QiNbo4TlsTKaU4zlqXKaeZ5kXMAx3cEl1XJ5cAaN2J427ViVS9ZbxxGNk7GffPCltlw67Zv
q+Hc4zzc/++1OtufmnEBQYYFsTryRPoAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDEtMjFUMDE6
MTQ6MDktMDc6MDCyQaRJAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTAxLTIxVDAxOjE0OjA5LTA3
OjAwwxwc9QAAAABJRU5ErkJggg=="
    />
  </svg>
);

Filter.propTypes = {
  tint: PropTypes.string
};

export default Filter;
