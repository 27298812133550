import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const Card = ({ children, height, margins }) => {
  const style = {
    height: height && `${height}px`,
    marginTop: margins && margins.top && `${margins.top}px`,
    marginBottom: margins && margins.bottom && `${margins.bottom}px`,
    marginRight: margins && margins.right && `${margins.right}px`,
    marginLeft: margins && margins.left && `${margins.left}px`
  };

  return (
    <div style={style} className={styles.card}>
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.element.isRequired,
  height: PropTypes.number,
  margins: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  })
};

export default Card;
