import { gql } from "apollo-boost";

const getReportsByProject = gql`
  query getReportsByProject($facilityId: ID!) {
    inspectionReportsByFacility(facilityId: $facilityId) {
      id
      introMessage
      number
      pdfUrl
      date
      approved
      fTec {
        id
        technician {
          id
          avatarUrl
          firstName
          lastName
          email
          mobilePhone
        }
        facility {
          id
          name
          zone
          businessName {
            id
            client {
              id
              name
              state
              city
            }
          }
        }
      }
      clientSignatureUri
      inspections {
        __typename
        ... on Inspection {
          id
          completionDate
          inspectionChecklist {
            id
            checklistFormat
          }
          checklists {
            id
            checklistItems
            zone
            number
            signedBy {
              id
              firstName
              lastName
            }
            signatureUri
          }
          scheduledDate
          completionDate
        }
      }
    }
  }
`;

export default getReportsByProject;
