import React, { Component } from "react";
import PropTypes from "prop-types";
import fuzzy from "fuzzy";

import { createUser, deleteUser, editUser } from "../../gql/mutations";
import {
  AvatarBubble,
  Button,
  Container,
  Heading,
  Table,
  DeleteIcon,
  EditIcon,
  CloseIcon,
  Modal,
  Layout
} from "../atoms";
import { ModalPortal, Spinner, TableContainerHeader } from "../molecules";
import { EditUserModal, DeleteUserModal, AddUserModal } from "../organisms";

export const usersShape = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  mobilePhone: PropTypes.string,
  jobTitle: PropTypes.string
};

const initialModalState = {
  addUserModal: {
    visible: false,
    addButtonDisabled: false
  },
  deleteUserModal: {
    visible: false,
    deleteButtonDisabled: false
  },
  editUserModal: {
    visible: false,
    addButtonDisabled: false
  },
  userDetailModal: {
    visible: false
  }
};

const initialState = {
  filter: undefined,
  ...initialModalState
};

class UsersTemplate extends Component {
  state = initialState;

  static propTypes = {
    loadingUsers: PropTypes.bool.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape(usersShape)).isRequired
  };

  static defaultProps = {
    loadingUsers: true
  };

  render() {
    const {
      filter,
      addUserModal,
      deleteUserModal,
      editUserModal,
      userDetailModal
    } = this.state;
    const { loadingUsers, users } = this.props;

    const filteredUsers =
      users &&
      fuzzy.filter(filter, users, {
        extract: el => `${el.firstName} ${el.lastName}`
      });

    const data = filter
      ? filteredUsers && filteredUsers.map(result => result.original)
      : users;
    return (
      <>
        <ModalPortal
          modal={this._renderAddUserModal}
          visible={addUserModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderDeleteUserModal}
          visible={deleteUserModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderEditUserModal}
          visible={editUserModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderUserDetailModal}
          visible={userDetailModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <Heading>Administradores</Heading>
        <Container noPadding>
          <TableContainerHeader
            placeHolder="Buscar por nombre de usuario"
            onInputChange={e => this._filterUsers(e)}
            onClickAddButton={this._handleOnAddUser}
          />
          {loadingUsers && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
          {users && (
            <Table.Main
              columns={[
                null,
                { firstName: "Nombre" },
                { emailAddress: "Correo" },
                { mobilePhone: "Teléfono" },
                { zone: "Zona" },
                { jobTitle: "Cargo" },
                null
              ]}
              data={data}
              row={(user, lastUserRow) =>
                this._renderUserRow(user, lastUserRow)
              }
            />
          )}
        </Container>
      </>
    );
  }

  _renderUserRow = (user, lastUserRow) => {
    return (
      <tr
        testid="users-table-row"
        key={user.id}
        style={{ borderBottom: !lastUserRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble url={user.avatarUrl && user.avatarUrl} />
          </div>
        </Table.Cell>
        <Table.Cell>{user.firstName + " " + user.lastName}</Table.Cell>
        <Table.Cell>{user.emailAddress}</Table.Cell>
        <Table.Cell>{user.mobilePhone}</Table.Cell>
        <Table.Cell>{user.zone}</Table.Cell>
        <Table.Cell>{user.jobTitle}</Table.Cell>
        <Table.Cell>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              style="outline"
              onClick={() => this._handleOnViewClick(user.id)}
            >
              Ver
            </Button>
          </div>
          <div style={{ marginRight: "10px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
              onClick={() => this._handleOnPressEditUserButton(user.id)}
            >
              <EditIcon />
            </Button>
          </div>
          <Button
            radius={7}
            paddingVertical={8}
            paddingHorizontal={8}
            style="silver"
            iconOnly
            onClick={() => this._handleOnPressDeleteUserButton(user.id)}
          >
            <DeleteIcon />
          </Button>
        </Table.Cell>
      </tr>
    );
  };

  _renderUserDetailModal = () => {
    const { viewUserId } = this.state.userDetailModal;
    const { users } = this.props;
    const user = users.find(user => user.id === viewUserId);

    const Field = ({ label, value }) => (
      <div style={{ width: "50%", margin: "0 auto", marginBottom: 40 }}>
        <div style={{ color: "#5A5E61", marginBottom: 10 }}>{label}</div>
        <div style={{ color: "#5A5E61", fontWeight: "bold" }}>{value}</div>
      </div>
    );

    const Facility = ({ avatarUrl, clientName, facilityName }) => (
      <div style={{ marginBottom: 40 }}>
        <div style={{ float: "left" }}>
          <AvatarBubble url={avatarUrl} />
        </div>
        <div style={{ display: "inline-block", marginLeft: 20 }}>
          <div style={{ marginBottom: 10, color: "#5A5E61" }}>{clientName}</div>
          <div style={{ color: "#5A5E61", fontWeight: "bold" }}>
            {facilityName}
          </div>
        </div>
      </div>
    );

    return (
      <>
        <Container width={1300}>
          <div
            onClick={this._handleOnCloseButton}
            style={{ position: "absolute", cursor: "pointer", right: 30 }}
          >
            <CloseIcon />
          </div>
          <Modal.Header>Datos del Administrador</Modal.Header>
          <Modal.Section paddingVertical={50} paddingHorizontal={100}>
            <AvatarBubble
              width={120}
              height={120}
              url={user.avatarUrl && user.avatarUrl}
            />
            <Layout.Grid
              style={{ width: 640, margin: "0 auto", marginTop: 40 }}
            >
              <Layout.Column>
                <Field label="Nombre" value={user.firstName} />
                <Field label="Correo electronico" value={user.emailAddress} />
                <Field label="Zona" value={user.zone} />
              </Layout.Column>
              <Layout.Column>
                <Field label="Apellido" value={user.lastName} />
                <Field label="Teléfono móvl" value={user.mobilePhone} />
                <Field label="Cargo" value={user.jobTitle} />
              </Layout.Column>
            </Layout.Grid>
          </Modal.Section>
          <Modal.Footer>
            <Button
              paddingVertical={10}
              paddingHorizontal={55}
              onClick={this._handleOnCloseButton}
              style="outline"
            >
              Volver
            </Button>
          </Modal.Footer>
        </Container>
      </>
    );
  };

  _handleOnViewClick = userId => {
    this.setState(state => ({
      userDetailModal: {
        ...state.userDetailModal,
        visible: true,
        viewUserId: userId
      }
    }));
  };

  // Create User

  _renderAddUserModal = () => {
    const { addUserModal } = this.state;

    return (
      <AddUserModal
        addUserModalState={addUserModal}
        onClickCloseButton={this._handleOnCloseButton}
        onInputChange={this._handleOnInputChange}
        onBackButton={this._handleOnCloseButton}
        onCreateUser={this._handleOnCreateUserButton}
      />
    );
  };

  _filterUsers = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnAddUser = () => {
    this.setState(state => ({
      addUserModal: { ...state.addUserModal, visible: true }
    }));
  };

  _handleOnCloseButton = () => {
    localStorage.removeItem("file_editarfoto");
    this.setState({
      addUserModal: { visible: false },
      editUserModal: { visible: false },
      userDetailModal: { visible: false }
    });
  };

  _handleOnInputChange = event => {
    this.setState({
      addUserModal: {
        ...this.state.addUserModal,
        [event.target.name]: event.target.value
      }
    });
  };

  _handleOnCreateUserButton = () => {
    const {
      addUserModal: {
        userConfirmPasswordInput,
        userEmailInput,
        userFirstNameInput,
        userJobTitleInput,
        userLastNameInput,
        userMobilePhoneInput,
        userPasswordInput,
        userZoneInput,
        clientPortalDisplay
      }
    } = this.state;

    const avatarUrlFromLocalStorage = localStorage.getItem("file_adjuntarfoto");

    if (
      !!userConfirmPasswordInput &&
      !!userEmailInput &&
      !!userFirstNameInput &&
      !!userJobTitleInput &&
      !!userLastNameInput &&
      !!userMobilePhoneInput &&
      !!userPasswordInput &&
      !!userZoneInput
    ) {
      if (userConfirmPasswordInput !== userPasswordInput) {
        return alert("Las contraseñas no coinciden.");
      }

      if (userConfirmPasswordInput.length < 6) {
        return alert("La contraseña debe ser de más de 5 carácteres.");
      }

      this.setState(
        state => ({
          addUserModal: {
            ...state.addUserModal,
            addButtonDisabled: true
          }
        }),
        () =>
          createUser(
            userFirstNameInput,
            userLastNameInput,
            userJobTitleInput,
            userZoneInput,
            avatarUrlFromLocalStorage,
            userEmailInput,
            userPasswordInput,
            userMobilePhoneInput,
            clientPortalDisplay || false
          )
            .then(() => {
              localStorage.removeItem("file_adjuntarfoto");
              window.location = "/usuarios";
              mixpanel.track("Nuevo administrador creado");
            })
            .catch(err => {
              // Log the error
              console.error(err);
              if (err.message.indexOf("not_unique: emailAddress") > -1) {
                alert(`El correo ${userEmailInput} ya existe`);
              }

              this.setState(state => ({
                addUserModal: {
                  ...state.addUserModal,
                  addButtonDisabled: false
                }
              }));
            })
      );
    } else {
      alert("Campos faltantes.");
    }
  };

  // Edit User

  _renderEditUserModal = () => {
    const { editUserModal } = this.state;

    const { users } = this.props;
    const user = users.find(user => user.id === editUserModal.editUserId);

    !localStorage.getItem("file_editarfoto") &&
      localStorage.setItem("file_editarfoto", user.avatarUrl);

    return (
      <EditUserModal
        user={user}
        editUserModalState={editUserModal}
        onClickCloseButton={this._handleOnCloseButton}
        onCancelButton={this._handleOnCloseButton}
        onInputChange={this._handleOnEditInputChange}
        onEditUser={() => this._handleOnEditUser(editUserModal.editUserId)}
        addButtonDisabled={editUserModal.addButtonDisabled}
      />
    );
  };

  _handleOnPressEditUserButton = userId => {
    const { users } = this.props;
    const user = users.find(user => user.id === userId);

    this.setState(state => ({
      editUserModal: {
        ...state.editUserModal,
        visible: true,
        editUserId: userId,
        userAvatarUrl: user.avatarUrl,
        userFirstNameInput: user.firstName,
        userLastNameInput: user.lastName,
        userEmailInput: user.email,
        userMobilePhoneInput: user.mobilePhone,
        userOfficePhoneInput: user.officePhone,
        userAddressInput: user.address
      }
    }));
  };

  _handleOnEditInputChange = event => {
    this.setState({
      editUserModal: {
        ...this.state.editUserModal,
        [event.target.name]: event.target.value
      }
    });
  };

  _handleOnEditUser = userId => {
    const {
      editUserModal: {
        userAvatarUrl,
        userFirstNameInput,
        userLastNameInput,
        userEmailInput,
        userMobilePhoneInput,
        userOfficePhoneInput,
        userAddressInput,
        clientPortalDisplay
      }
    } = this.state;
    const { users } = this.props;

    const user = users.find(user => user.id === userId);
    const avatarUrl = localStorage.getItem("file_editarfoto");
    const url =
      avatarUrl !== "null" ? avatarUrl : userAvatarUrl || user.avatarUrl;

    this.setState(
      state => ({
        editUserModal: {
          ...state.editUserModal,
          addButtonDisabled: true
        }
      }),
      () =>
        editUser(
          userId,
          url,
          userFirstNameInput,
          userLastNameInput,
          userEmailInput,
          userMobilePhoneInput,
          userOfficePhoneInput,
          userAddressInput,
          clientPortalDisplay || false
        )
          .then(() => {
            localStorage.removeItem("file_editarfoto");
            window.location = "/usuarios";
            mixpanel.track("Editar datos de administrador");
          })
          .catch(err => {
            // Log the error
            console.error(err);

            this.setState(state => ({
              editUserModal: {
                ...state.editUserModal,
                addButtonDisabled: false
              }
            }));
          })
    );
  };

  // Delete
  _renderDeleteUserModal = () => {
    const {
      deleteUserModal: { deleteUserId, deleteButtonDisabled }
    } = this.state;

    const { users } = this.props;
    const user = users.find(user => user.id === deleteUserId);

    return (
      <DeleteUserModal
        deleteUserId={deleteUserId}
        deleteButtonDisabled={deleteButtonDisabled}
        user={user}
        onBackDeleteButton={this._handleOnBackDeleteButton}
        onDeleteUserButton={this._handleOnDeleteUserButton}
      />
    );
  };

  _handleOnBackDeleteButton = () => {
    this.setState(state => ({
      deleteUserModal: {
        ...state.deleteUserModal,
        visible: false
      }
    }));
  };

  _handleOnPressDeleteUserButton = userId => {
    this.setState(state => ({
      deleteUserModal: {
        ...state.deleteUserModal,
        visible: true,
        deleteUserId: userId
      }
    }));
  };

  _handleOnDeleteUserButton = userId => {
    if (!!userId) {
      this.setState(
        (state, _) => ({
          deleteUserModal: {
            ...state.deleteUserModal,
            deleteButtonDisabled: true
          }
        }),
        () =>
          deleteUser(userId)
            .then(() => {
              window.location = "/usuarios";
              mixpanel.track("Borrar administrador");
            })
            .catch(err => {
              bugsnagClient.notify(err);
              this.setState(state => ({
                deleteUserModal: {
                  visible: false
                }
              }));
            })
      );
    }
  };
}

export default UsersTemplate;
