export default {
  name: "dry_hydrant_maintenance",
  title: "Mantenimiento de hidrante seco",
  inputs: {
    area: {
      label: "Área"
    },
    key: {
      label: "Clave hidrante tipo seco"
    }
  },
  checks: {
    openable: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Abrir y cerrar el hidrante",
      unit: null
    },
    waterFlow: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Mantener el flujo de agua al menos un minuto",
      unit: null
    },
    drainOperation: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Verificar desague adecuado despues de cada operación",
      unit: null
    },
    drainTime: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Tiempo de drenado no mas de 60 minutos",
      unit: null
    },
    drainIce: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Desague no tapado por hielo",
      unit: null,
      correctiveAction: {
        on: true,
        message:
          "Identificar claramente el hidrante indicando que se debe bombear despues de la operación para eliminar el hielo"
      }
    }
  }
};
