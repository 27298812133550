export default {
  name: "diesel_pump_maintenance",
  title: "Mantenimiento de Bomba Diesel",
  checks: {
    autopilotLight: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Luz de piloto automático",
      unit: null
    },
    suctionValve: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Válvula de succión abierta",
      unit: null
    },
    dischargeValve: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Válvula de descarga abierta",
      unit: null
    },
    coolingSystemValves: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Válvulas del sistema de enfriamiento abiertas",
      unit: null
    },
    securityLock: {
      section: "Bomba Diesel",
      type: "BOOL",
      label: "Valvulas con candado de seguridad",
      unit: null
    },
    fireNetworkPressure: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Presión de la red de incendio",
      unit: "P.S.I."
    },
    engineTemperature: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Temperatura del motor en reposo",
      unit: "°C"
    },
    oilLevel: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Nivel de aceite",
      unit: "%"
    },
    waterLevel: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Nivel de agua",
      unit: "%"
    },
    fuelLevel: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Nivel de combustible",
      unit: "%"
    },
    dieselTankPurging: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Purgado de tanque diesel",
      unit: null
    },
    batteryVoltage1: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Voltaje de batería 1",
      unit: "Volts"
    },
    batteryVoltage2: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Voltaje de batería 2",
      unit: "Volts"
    },
    batteryAmmeterCurrent1: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente en amperímetro batería 1",
      unit: "Ampers"
    },
    batteryAmmeterCurrent2: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Corriente en amperímetro batería 2",
      unit: "Ampers"
    },
    batteryCleaning: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Limpieza de baterías",
      unit: null
    },
    batteryLiquidLevel: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Nivel liquido de baterías",
      unit: null
    },
    batteryTerminalsCleaning: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Limpieza de terminales de batería",
      unit: null
    },
    engineHorometer: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Horómetro del motor",
      unit: "horas"
    },
    systemOp30MIN: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Operación del sistema por 30min",
      unit: null
    },
    coolingSystemSolenoid: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label:
        "Confirmar que el solenoide del sistema de  enfriamiento este abierto",
      unit: null
    },
    engineSpeed: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Velocidad del motor",
      unit: "R.P.M."
    },
    engineOpTemp: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Temperatura del motor en operación",
      unit: "°C"
    },
    antifreezeLevel: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Nivel de anticongelante",
      unit: "%"
    },
    reliefValveOp: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Operación de la válvula de alivio",
      unit: "P.S.I."
    },
    towPressAdjustment: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión del estado de prensa estopas",
      unit: null
    },
    motorBehavior: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Supervisar indicadores y comportamiento del motor",
      unit: null
    },
    suctionRecords: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Lectura de los registros de la succión",
      unit: "P.S.I."
    },
    dischargeRecords: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Lectura de los registros de la descarga",
      unit: "P.S.I."
    },
    waterLeaks: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de fugas de agua",
      unit: null
    },
    nutTightening: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de condición de tuercas",
      unit: null
    },
    noisesOrVibrations: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Revisión de ruidos extraños o vibraciones",
      unit: null
    },
    pumpPressure: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Registro de la presión de la bomba",
      unit: "P.S.I."
    },
    automaticOp: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Función en Operación Automatica",
      unit: null
    },
    startPressure: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Presión de Arranque",
      unit: "P.S.I."
    },
    stopPressure: {
      section: "Bomba Diesel",
      type: "NUMBER",
      label: "Presión de Paro",
      unit: "P.S.I."
    },
    stopDelay: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "NUMBER",
      label: "Tiempo de Retardo al paro / Paro Manual",
      unit: "minutos"
    },
    abnormalities: {
      section: "Bomba Diesel",
      required: false,
      warning: false,
      type: "STRING",
      label: "Registrar cualquier anormalidad",
      unit: null
    },
    pumpRoomTemp: {
      section: "Condiciones del cuarto de bombas",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Temperatura  adecuada no menos de 40°f (5°C)",
      unit: null
    },
    ventilationGrilles: {
      section: "Condiciones del cuarto de bombas",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Rejillas de ventilación libres para operar",
      unit: null
    },
    oilPressure: {
      section: "Bomba Diesel",
      type: "NUMBER",
      label: "Presión de aceite",
      unit: "P.S.I."
    },
    hoseConditions: {
      section: "Bomba Diesel",
      type: "BOOL",
      label: "Revisión de condición de mangueras",
      unit: null
    },
    turnOnTime: {
      section: "Bomba Diesel",
      type: "NUMBER",
      label: "Tiempo de encendido de la bomba",
      unit: "minutos"
    },
    timeToMaxSpeed: {
      section: "Bomba Diesel",
      type: "NUMBER",
      label: "Tiempo en alcanzar velocidad máxima",
      unit: "minutos"
    },
    generalCleaning: {
      section: "Bomba Diesel",
      required: true,
      warning: false,
      type: "BOOL",
      label: "Limpieza general en cuarto de bombas",
      unit: null
    }
  }
};
