import { gql } from "apollo-boost";

const getInspectionsTodo = gql`
  query getInspectionsTodo($startDate: String, $endDate: String) {
    inspectionsTodo(startDate: $startDate, endDate: $endDate) {
      id
      inspectionChecklist {
        id
        checklistFormat
        quantity
      }
      fTec {
        id
        technician {
          id
          avatarUrl
          firstName
          lastName
        }
        facility {
          id
          name
          businessName {
            id
            name
            client {
              id
              name
            }
          }
        }
      }
      scheduledDate
    }
  }
`;

export default getInspectionsTodo;
