import { gql } from "apollo-boost";

const getUnreadNotificationsForAdmin = gql`
  query getUnreadNotificationsForAdmin($userId: ID!) {
    unreadNotificationsForAdmin(userId: $userId) {
      id
      type
      createdAt
      initiator {
        __typename
        ... on AdditionalService {
          id
          details
          facility {
            id
            name
            businessName {
              id
              client {
                id
                name
                avatarUrl
                city
                state
              }
            }
          }
        }
        ... on CorrectiveAction {
          id
          details
          fTec {
            id
            facility {
              id
              name
              businessName {
                id
                client {
                  id
                  name
                  avatarUrl
                  city
                  state
                }
              }
            }
          }
        }
        ... on Report {
          id
          fTec {
            id
            facility {
              id
              name
              businessName {
                id
                client {
                  id
                  name
                  avatarUrl
                  city
                  state
                }
              }
            }
          }
        }
        ... on InspectionReschedule {
          id
          inspection {
            __typename
            ... on Inspection {
              id
              fTec {
                id
                facility {
                  id
                  name
                  businessName {
                    id
                    name
                    client {
                      id
                      name
                      city
                      state
                    }
                  }
                }
              }
            }
            ... on AdditionalServiceInspection {
              id
              fTec {
                id
                facility {
                  id
                  name
                  businessName {
                    id
                    name
                    client {
                      id
                      name
                      city
                      state
                    }
                  }
                }
              }
            }
            ... on CorrectiveActionInspection {
              id
              fTec {
                id
                facility {
                  id
                  name
                  businessName {
                    id
                    name
                    client {
                      id
                      name
                      city
                      state
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getUnreadNotificationsForAdmin;
