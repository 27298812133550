import { gql } from "apollo-boost";
import { client } from "../apollo";

const setContractStatus = async (contractId, status) =>
  await client.mutate({
    mutation: gql`
      mutation setContractStatus($contractId: ID!, $status: Boolean!) {
        setContractStatus(contractId: $contractId, status: $status) {
          id
        }
      }
    `,
    variables: {
      contractId,
      status
    }
  });

export default setContractStatus;
