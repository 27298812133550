import { gql } from "apollo-boost";
import { client } from "../apollo";

const editInspectionAndChecklist = async (
  inspectionId,
  facilityTechnicianId,
  scheduledDate,
  inspectionChecklist
) =>
  await client.mutate({
    mutation: gql`
      mutation editInspectionAndChecklist(
        $inspectionId: ID!
        $facilityTechnicianId: ID
        $scheduledDate: String
        $inspectionChecklist: String!
      ) {
        editInspectionAndChecklist(
          inspectionId: $inspectionId
          facilityTechnicianId: $facilityTechnicianId
          scheduledDate: $scheduledDate
          inspectionChecklist: $inspectionChecklist
        )
      }
    `,
    variables: {
      inspectionId,
      facilityTechnicianId,
      scheduledDate,
      inspectionChecklist
    }
  });

export default editInspectionAndChecklist;
