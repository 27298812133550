import React from "react";
import PropTypes from "prop-types";

import {
  CloseIcon,
  Container,
  Modal,
  Layout,
  LabeledInput,
  Button
} from "../../atoms";
import { LoadAsset } from "../../molecules";

const AddTechnicianModal = ({
  addTechnicianModalState: addTechnicianModal,
  onClickCloseButton,
  onInputChange,
  onBackButton,
  onAddFacilities
}) => {
  return (
    <Container style={{ maxWidth: "80%" }} width={1300}>
      <div
        onClick={onClickCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>Datos del técnico</Modal.Header>
      <Modal.Section paddingVertical={100} paddingHorizontal={250}>
        <LoadAsset
          buttonLabel="Adjuntar foto"
          image="file_adjuntarfoto"
          centered={true}
        />
        <Layout.Grid style={{ marginTop: "180px", flexWrap: "nowrap" }}>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={1}
                fullWidth
                label="Nombre"
                name="technicianFirstNameInput"
                onChange={onInputChange}
                defaultValue={addTechnicianModal.technicianFirstNameInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={3}
                fullWidth
                label="Email"
                name="technicianEmailInput"
                onChange={onInputChange}
                defaultValue={addTechnicianModal.technicianEmailInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={5}
                fullWidth
                label="Teléfono Oficina"
                name="technicianOfficePhoneInput"
                onChange={onInputChange}
                defaultValue={addTechnicianModal.technicianOfficePhoneInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={7}
                fullWidth
                label="Contraseña"
                type="password"
                name="technicianPasswordInput"
                onChange={onInputChange}
                defaultValue={addTechnicianModal.technicianPasswordInput}
              />
            </div>
          </Layout.Column>
          <Layout.Column style={{ padding: "0 40px" }}>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={2}
                fullWidth
                label="Apellido"
                name="technicianLastNameInput"
                onChange={onInputChange}
                defaultValue={addTechnicianModal.technicianLastNameInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={4}
                fullWidth
                label="Teléfono Móvil"
                name="technicianMobilePhoneInput"
                onChange={onInputChange}
                defaultValue={addTechnicianModal.technicianMobilePhoneInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={6}
                fullWidth
                label="Dirección"
                name="technicianAddressInput"
                onChange={onInputChange}
                defaultValue={addTechnicianModal.technicianAddressInput}
              />
            </div>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={8}
                fullWidth
                label="Repetir Contraseña"
                type="password"
                name="technicianConfirmPasswordInput"
                onChange={onInputChange}
                defaultValue={addTechnicianModal.technicianConfirmPasswordInput}
              />
            </div>
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={10}
            paddingHorizontal={55}
            onClick={() => onBackButton(0)}
            style="outline"
          >
            Volver
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={30}
          onClick={onAddFacilities}
          radius={10}
        >
          Asignar Plantas
        </Button>
      </Modal.Footer>
    </Container>
  );
};

AddTechnicianModal.propTypes = {
  addTechnicianModalState: PropTypes.shape({
    technicianFirstNameInput: PropTypes.string,
    technicianEmailInput: PropTypes.string,
    technicianOfficePhoneInput: PropTypes.string,
    technicianPasswordInput: PropTypes.string,
    technicianLastNameInput: PropTypes.string,
    technicianMobilePhoneInput: PropTypes.string,
    technicianAddressInput: PropTypes.string,
    technicianConfirmPasswordInput: PropTypes.string
  }).isRequired,
  onClickCloseButton: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onBackButton: PropTypes.func.isRequired,
  onAddFacilities: PropTypes.func.isRequired
};

export default AddTechnicianModal;
