export default {
  name: "alarm_valves_maintenance",
  title: "Inspección de Valvulas Check de Alarmas",
  checks: {
    pressureMaintenance: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Mantiene presion",
      unit: null
    },
    damageFree: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Libre de daños fisicos",
      unit: null
    },
    positionOpen: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Posición normal: Abierta",
      unit: null
    },
    positionClosed: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Posición normal: Cerrada",
      unit: null
    },
    noLeaks: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "No fugas en drenes",
      unit: null
    },
    plateStatus: {
      required: true,
      warning: false,
      type: "BOOL",
      label: "Placa en buen estado",
      unit: null
    }
  }
};
