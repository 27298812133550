import React from "react";

const Rectangle = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 224 224"
  >
    <g
      fill="none"
      strokeMiterlimit="10"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
    >
      <path d="M0,224v-224h224v224z" />
      <g fill="#ccc">
        <path
          d="M49.28,17.92c-17.2725,0 -31.36,14.0875 -31.36,31.36v125.44c0,17.2725 14.0875,31.36 31.36,31.36h125.44c17.2725,0 31.36,-14.0875 31.36,-31.36v-125.44c0,-17.2725 -14.0875,-31.36 -31.36,-31.36zM49.28,26.88h125.44c12.425,0 22.4,9.975 22.4,22.4v125.44c0,12.425 -9.975,22.4 -22.4,22.4h-125.44c-12.425,0 -22.4,-9.975 -22.4,-22.4v-125.44c0,-12.425 9.975,-22.4 22.4,-22.4z"
          id="surface1"
        />
      </g>
    </g>
  </svg>
);

export default Rectangle;
