import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { BigRemoveButton } from "../../atoms";
import { UploadButton } from "../../molecules";

const RemovableUploadButton = ({ onRemove, ...props }) => {
  return (
    <div className={styles.removableInputContainer}>
      <UploadButton {...props} />
      <div className={styles.removeButtonContainer}>
        <BigRemoveButton onClick={() => onRemove(props.name)} />
      </div>
    </div>
  );
};

RemovableUploadButton.propTypes = {
  onRemove: PropTypes.func.isRequired
};

export default RemovableUploadButton;
