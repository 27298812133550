import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import classJoiner from "../../../utils/classJoiner";

const Grid = ({ children, marginHorizontal, ...props }) => {
  const style = {
    marginLeft: marginHorizontal && `${marginHorizontal}px`,
    marginRight: marginHorizontal && `${marginHorizontal}px`
  };

  return (
    <div style={style} className={styles.grid} {...props}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  marginHorizontal: PropTypes.number
};

const Column = ({ children, marginHorizontal, stackable, ...props }) => {
  const columnClass = stackable ? styles.stackColumn : styles.column;
  const style = {
    marginLeft: marginHorizontal && `${marginHorizontal}px`,
    marginRight: marginHorizontal && `${marginHorizontal}px`
  };

  return (
    <div style={style} className={columnClass} {...props}>
      {children}
    </div>
  );
};

Column.propTypes = {
  marginHorizontal: PropTypes.number,
  stackable: PropTypes.bool
};

Column.defaultProps = {
  stackable: false
};

export default {
  Grid,
  Column
};
