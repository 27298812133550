import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import mixpanel from "mixpanel-browser";

import { ErrorBoundary } from "./bugsnag";
import { client } from "./gql/apollo";
import store from "./store";
import App from "./App";

mixpanel.init(process.env.MIXPANEL_API_KEY);

const rootContainer = document.getElementById("root");

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <Provider store={store.store}>
        <PersistGate persistor={store.persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </ErrorBoundary>,
  rootContainer
);
