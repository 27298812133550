import React from "react";

import styles from "./styles.scss";
import { ImageUploadIcon } from "../Icons";

const UploadPlaceholder = () => (
  <div className={styles.uploadPlaceholder}>
    <div className={styles.uploadIconWrapper}>
      <ImageUploadIcon />
    </div>
  </div>
);

export default UploadPlaceholder;
