import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";

import fragmentTypes from "./fragmentTypes.json";
import store from "../store";
import { logout } from "../store/actions/user";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes
});

let { user: userState } = store.store.getState();

store.store.subscribe(() => {
  let prevUserState = userState;
  userState = store.store.getState().user;

  if (prevUserState.loggedIn && !userState.loggedIn) {
    localStorage.removeItem("token");
  }

  if (
    (!prevUserState.tokens.authToken && userState.tokens.authToken) ||
    prevUserState.tokens.authToken !== userState.tokens.authToken
  ) {
    localStorage.setItem("token", store.store.getState().user.tokens.authToken);
  }
});

const token = localStorage.getItem("token");

const request = async op => {
  op.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ""
    }
  });
};

export const client = new ApolloClient({
  uri: process.env.GRAPHQL_ENDPOINT,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          if (message === "EXPIRED_TOKEN") {
            // Remove tokens from local storage
            localStorage.removeItem("token");

            // Set the user's loggedIn to false
            store.store.dispatch(logout());

            // Redirect to login page
            return (window.location.href = "/iniciar-sesion");
          }
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new ApolloLink(
      (operation, forward) =>
        new Observable(observer => {
          let handle;
          Promise.resolve(operation)
            .then(op => request(op))
            .then(() => {
              handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              });
            })
            .catch(observer.error.bind(observer));

          return () => {
            if (handle) handle.unsubscribe();
          };
        })
    ),
    new HttpLink({
      uri: process.env.GRAPHQL_ENDPOINT,
      credentials: "same-origin"
    })
  ]),
  cache: new InMemoryCache({ fragmentMatcher })
});
