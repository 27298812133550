import React from "react";
import format from "date-fns/format";

import {
  Button,
  Container,
  CloseIcon,
  Modal,
  Layout,
  LabeledInput,
  TextArea,
  CustomDropdown,
  AvatarBubble
} from "../../atoms";
import { FacilitySelector } from "../../molecules";

const ADDITIONAL_SERVICE_STATUS = {
  null: "Pendiente",
  PURCHASE_ORDER: "Orden de compra",
  JOB_FINISHED: "Trabajo terminado",
  INVOICED: "Facturado"
};

const ADDITIONAL_SERVICE_STATUSES_DICT = {
  null: [],
  PURCHASE_ORDER: ["null"],
  JOB_FINISHED: ["null", "PURCHASE_ORDER"],
  INVOICED: ["null", "PURCHASE_ORDER", "JOB_FINISHED"]
};

const EditAdditionalServiceModal = ({
  editAdditionalServiceModalState: editAdditionalServiceModal,
  additionalService,
  editAdditionalServiceId,
  editLabeledTechnicians,
  editLabeledContracts,
  handleOnEditStatusChange,
  onEditCloseButton,
  handleOnEditFacilitySelectorChange,
  handleOnEditFacilitySelect,
  handleOnEditInputChange,
  onEditAdditionalServiceButton
}) => {
  const addButtonEnabled =
    editAdditionalServiceModal.nameAndLastNameInput &&
    editAdditionalServiceModal.title &&
    editAdditionalServiceModal.startDate &&
    editAdditionalServiceModal.budgetMXN &&
    editAdditionalServiceModal.budgetUSD &&
    editAdditionalServiceModal.details;

  const { facility: fac, fTec } = editAdditionalServiceModal;
  const facility = fac || fTec.facility;

  const purchaseOrderStatus = editAdditionalServiceModal.purchaseOrder;
  const jobFinishedStatus = editAdditionalServiceModal.jobFinished;
  const invoicedStatus = editAdditionalServiceModal.invoiced;

  return (
    <Container width={1118}>
      <div
        onClick={onEditCloseButton}
        style={{ position: "absolute", cursor: "pointer", right: 30 }}
      >
        <CloseIcon />
      </div>
      <Modal.Header>Servicio Adicional</Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <FacilitySelector
          selected={[
            facility.businessName.client.name,
            facility.businessName.name,
            facility.id
          ]}
          onChange={(key, value) =>
            handleOnEditFacilitySelectorChange(key, value)
          }
          onFacilitySelect={facilityId =>
            handleOnEditFacilitySelect(facilityId)
          }
        />
      </Modal.Section>
      <Modal.Section
        style={{ margin: "0 50px", borderTop: "1px solid #d7d7d7" }}
        paddingVertical={30}
        paddingHorizontal={100}
      >
        <Layout.Grid>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              {editLabeledTechnicians(editAdditionalServiceModal)}
            </div>
            <div style={{ paddingRight: "70px", marginBottom: "40px" }}>
              <LabeledInput
                index={4}
                type="currency"
                name="budgetMXN"
                label="Monto MXN"
                defaultValue={editAdditionalServiceModal.budgetMXN}
                onChange={handleOnEditInputChange}
              />
            </div>
            <div>{editLabeledContracts(editAdditionalServiceModal)}</div>
          </Layout.Column>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={2}
                name="title"
                label="Concepto"
                defaultValue={editAdditionalServiceModal.title}
                onChange={handleOnEditInputChange}
              />
            </div>
            <LabeledInput
              index={5}
              type="currency"
              name="budgetUSD"
              label="Monto USD"
              defaultValue={editAdditionalServiceModal.budgetUSD}
              onChange={handleOnEditInputChange}
            />
          </Layout.Column>
          <Layout.Column>
            <div style={{ marginBottom: "40px" }}>
              <LabeledInput
                index={3}
                type="date-picker"
                name="startDate"
                label="Comienza"
                defaultValue={format(
                  editAdditionalServiceModal.startDate,
                  "MM/DD/YYYY"
                )}
                onChange={handleOnEditInputChange}
              />
            </div>
            <LabeledInput
              index={6}
              type="currency"
              name="exchangeRate"
              label="Tipo de cambio"
              defaultValue={editAdditionalServiceModal.exchangeRate}
              onChange={handleOnEditInputChange}
            />
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Header>Información Adicional</Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <Layout.Grid>
          <Layout.Column>
            <div
              style={{
                marginLeft: "1px",
                marginBottom: "8px",
                color: "rgba(43, 48, 52, 0.4)",
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "14px"
              }}
            >
              Detalles
            </div>
            <TextArea
              name="details"
              width={524}
              height={134}
              maxLength={1000}
              onChange={handleOnEditInputChange}
              value={editAdditionalServiceModal.details}
            />
          </Layout.Column>
          <Layout.Column style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                position: "absolute",
                top: "50%",
                left: "50%",
                width: 100,
                transform: "translate(-50%, -50%)"
              }}
            >
              <div>
                <div style={{ marginBottom: 10, fontWeight: "700" }}>OC</div>
                <div>
                  <input
                    type="checkbox"
                    checked={purchaseOrderStatus}
                    onChange={() =>
                      handleOnEditStatusChange(
                        "purchaseOrder",
                        purchaseOrderStatus
                      )
                    }
                  />
                </div>
              </div>
              <div>
                <div style={{ marginBottom: 10, fontWeight: "700" }}>TT</div>
                <div>
                  <input
                    type="checkbox"
                    checked={jobFinishedStatus}
                    onChange={() =>
                      handleOnEditStatusChange("jobFinished", jobFinishedStatus)
                    }
                  />
                </div>
              </div>
              <div>
                <div style={{ marginBottom: 10, fontWeight: "700" }}>F</div>
                <div>
                  <input
                    type="checkbox"
                    checked={invoicedStatus}
                    onChange={() =>
                      handleOnEditStatusChange("invoiced", invoicedStatus)
                    }
                  />
                </div>
              </div>
            </div>
          </Layout.Column>
        </Layout.Grid>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            style="outline"
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={onEditCloseButton}
          >
            Volver
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={48}
          onClick={onEditAdditionalServiceButton}
          disabled={
            !addButtonEnabled ||
            editAdditionalServiceModal.editingAdditionalService
          }
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default EditAdditionalServiceModal;
