import { gql } from "apollo-boost";
import { client } from "../apollo";

const scheduleInspection = async (
  facilityTechnicianId,
  scheduledDate,
  inspectionChecklists
) =>
  await client.mutate({
    mutation: gql`
      mutation scheduleInspection(
        $facilityTechnicianId: ID!
        $scheduledDate: String!
        $inspectionChecklists: String!
      ) {
        scheduleInspection(
          facilityTechnicianId: $facilityTechnicianId
          scheduledDate: $scheduledDate
          inspectionChecklists: $inspectionChecklists
        ) {
          id
        }
      }
    `,
    variables: {
      facilityTechnicianId,
      scheduledDate,
      inspectionChecklists
    }
  });

export default scheduleInspection;
