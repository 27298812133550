import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./LoginTemplateStyles.scss";
import { RoundInput, RoundButton } from "../atoms";

const dkfireLogo = require("../../../assets/images/dkfire-logo.png");

class LoginTemplate extends Component {
  static propTypes = {
    attemptLogin: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  state = {
    inputs: {
      emailAddress: undefined,
      password: undefined
    }
  };

  componentDidMount() {
    const { user } = this.props;
    if (user.loggedIn) {
      window.location.replace("/");
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (!prevProps.user.loggedIn && user.loggedIn) {
      window.location.replace("/");
    }
  }

  render() {
    const { inputs } = this.state;
    const { user } = this.props;

    const loginButtonDisabled =
      !inputs.emailAddress ||
      !inputs.password ||
      user.authState === "ATTEMPTING_LOGIN";

    return (
      <div className={styles.loginContainer}>
        <div className={styles.logoContainer}>
          <img src={dkfireLogo} width="100%" />
        </div>
        <div className={styles.inputContainer}>
          <RoundInput
            name="emailAddress"
            onChange={e => this._handleOnChangeInput(e)}
            placeholder="Correo electrónico"
            type="email"
          />
        </div>
        <div className={styles.inputContainer}>
          <RoundInput
            name="password"
            onChange={e => this._handleOnChangeInput(e)}
            placeholder="Contraseña"
            type="password"
          />
        </div>
        <RoundButton
          onClick={this._handleOnClickLogin}
          disabled={loginButtonDisabled}
        >
          Iniciar sesión
        </RoundButton>
      </div>
    );
  }

  _handleOnChangeInput = e => {
    const { name, value } = e.currentTarget;

    this.setState(state => ({
      inputs: {
        ...state.inputs,
        [name]: value
      }
    }));
  };

  _handleOnClickLogin = () => {
    const { inputs } = this.state;
    const { attemptLogin } = this.props;

    attemptLogin(inputs.emailAddress, inputs.password);
  };
}

export default LoginTemplate;
