import { gql } from "apollo-boost";

const getClientsFacilities = gql`
  query getClientsFacilities {
    clientFacilities {
      id
      name
      avatarUrl
      state
      city
      address
      updatedAt
      createdAt

      businessNames {
        id
        name

        facilities {
          id
          name
          color
          zone
        }
      }
    }
  }
`;

export default getClientsFacilities;
