import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Modal } from "../../atoms";

const DeleteFacilityModal = ({
  deleteButtonDisabled,
  deleteFacilityId,
  facility,
  onBackButton,
  onDeleteFacilityButton
}) => {
  return (
    <Container width={540}>
      <Modal.Section paddingVertical={80} paddingHorizontal={80}>
        <div style={{ fontSize: "20px", color: "#5A5E61" }}>
          ¿Estas seguro que deseas eliminar a la planta{" "}
          <div
            style={{
              fontWeight: "bold",
              display: "inline-block",
              color: "#5A5E61"
            }}
          >
            {facility.name}
          </div>{" "}
          de tu lista?
        </div>
      </Modal.Section>
      <div style={{ marginBottom: "37px", marginLeft: "95px" }}>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            paddingVertical={8}
            radius={7}
            paddingHorizontal={35}
            onClick={onBackButton}
            style="outline"
          >
            No, volver
          </Button>
        </div>
        <Button
          paddingVertical={8}
          radius={7}
          paddingHorizontal={30}
          onClick={() => onDeleteFacilityButton(deleteFacilityId)}
          disabled={deleteButtonDisabled}
        >
          Sí, continuar
        </Button>
      </div>
    </Container>
  );
};

DeleteFacilityModal.PropTypes = {
  deleteButtonDisabled: PropTypes.bool.isRequired,
  deleteFacilityId: PropTypes.string.isRequired,
  facility: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  onBackButton: PropTypes.func.isRequired,
  onDeleteFacilityButton: PropTypes.func.isRequired
};

export default DeleteFacilityModal;