import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import {
  Button,
  PlusIcon,
  TextInput,
  LabeledInput,
  BigRemoveButton
} from "../../atoms";

const TableContainerHeader = ({
  onInputChange,
  onClickAddButton,
  placeHolder
}) => {
  return (
    <div className={styles.clientsContainerHeader}>
      {onInputChange && placeHolder && (
        <TextInput
          width={580}
          placeholder={placeHolder}
          onChange={onInputChange}
        />
      )}
      {onClickAddButton && (
        <div className={styles.containerHeaderRightArea}>
          <Button onClick={() => onClickAddButton()}>
            Agregar
            <div className={styles.plusIconWrapper}>
              <PlusIcon tint="#ffffff" />
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

TableContainerHeader.propTypes = {
  placeHolder: PropTypes.string,
  onClickAddButton: PropTypes.func.isRequired
};

export default TableContainerHeader;
