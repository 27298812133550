import React, { Component } from "react";
import PropTypes from "prop-types";
import fuzzy from "fuzzy";
import mixpanel from "mixpanel-browser";

import {
  createTechnician,
  deleteTechnician,
  editTechnician,
  editTechnicianFacilities
} from "../../gql/mutations";
import {
  AvatarBubble,
  Button,
  Container,
  Heading,
  EditTechnicianIcon,
  EditFacilitiesIcon,
  DeleteIcon,
  CloseIcon,
  Modal,
  Table,
  Layout
} from "../atoms";
import { ModalPortal, Spinner, TableContainerHeader } from "../molecules";
import {
  AddTechnicianModal,
  AddFacilitiesModal,
  DeleteTechnicianModal,
  EditFacilitiesModal,
  EditTechnicianModal
} from "../organisms";
import { bugsnagClient } from "../../bugsnag";

export const techniciansShape = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  status: PropTypes.bool,
  mobilePhone: PropTypes.string,
  officePhone: PropTypes.string,
  address: PropTypes.string
};

const initialModalState = {
  addTechnicianModal: {
    visible: false,
    addButtonDisabled: true
  },
  addFacilityModal: {
    visible: false,
    addButtonDisabled: false,
    facilitySelectors: {
      0: {}
    },
    selectedFacilityIds: []
  },
  deleteTechnicianModal: {
    visible: false,
    deleteButtonDisabled: false
  },
  editTechnicianModal: {
    visible: false,
    addButtonDisabled: false
  },
  editFacilityModal: {
    visible: false,
    addButtonDisabled: false,
    facilitySelectors: {
      0: {}
    },
    selectedFacilityIds: []
  },
  technicianDetailModal: {
    visible: false
  }
};

const initialState = {
  filter: undefined,
  ...initialModalState
};

class TechniciansTemplate extends Component {
  state = initialState;

  static propTypes = {
    loadingTechnicians: PropTypes.bool.isRequired,
    technicians: PropTypes.arrayOf(PropTypes.shape(techniciansShape)).isRequired
  };

  static defaultProps = {
    loadingTechnicians: true
  };

  render() {
    const {
      filter,
      addTechnicianModal,
      addFacilityModal,
      deleteTechnicianModal,
      editTechnicianModal,
      editFacilityModal,
      technicianDetailModal
    } = this.state;
    const { loadingTechnicians, technicians } = this.props;

    const filteredTechnicians =
      technicians &&
      fuzzy.filter(filter, technicians, {
        extract: el => `${el.firstName} ${el.lastName}`
      });

    const data = filter
      ? filteredTechnicians &&
      filteredTechnicians.map(result => result.original)
      : technicians;

    return (
      <>
        <ModalPortal
          modal={this._renderAddTechnicianModal}
          visible={addTechnicianModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderAddFacilitiesModal}
          visible={addFacilityModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderDeleteTechnicianModal}
          visible={deleteTechnicianModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderEditTechnicianModal}
          visible={editTechnicianModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderEditFacilityModal}
          visible={editFacilityModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderTechnicianDetailModal}
          visible={technicianDetailModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <Heading>Técnicos</Heading>
        <Container noPadding>
          <TableContainerHeader
            placeHolder="Buscar por nombre de técnico"
            onInputChange={e => this._filterTechnicians(e)}
            onClickAddButton={() => this._handleOnAddTechnician()}
          />
          {loadingTechnicians && (
            <div style={{ padding: "100px" }}>
              <Spinner />
            </div>
          )}
          {technicians && (
            <Table.Main
              columns={[
                null,
                { firstName: "Nombre" },
                { email: "Correo" },
                { mobilePhone: "Teléfono" },
                { _: "Clientes" },
                { "facilities.length": "Plantas" },
                null
              ]}
              data={data}
              row={(technician, lastTechnicianRow) =>
                this._renderTechnicianRow(technician, lastTechnicianRow)
              }
            />
          )}
        </Container>
      </>
    );
  }

  _renderTechnicianRow = (technician, lastTechnicianRow) => {
    const clients = [];
    technician.facilities.map(facility => {
      if (
        !clients.find(client => client === facility.businessName.client.name)
      ) {
        clients.push(facility.businessName.client.name);
      }
    });

    return (
      <tr
        testid="technicians-table-row"
        key={technician.id}
        style={{ borderBottom: !lastTechnicianRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble url={technician.avatarUrl && technician.avatarUrl} />
          </div>
        </Table.Cell>
        <Table.Cell>
          {technician.firstName + " " + technician.lastName}
        </Table.Cell>
        <Table.Cell>{technician.email}</Table.Cell>
        <Table.Cell>{technician.mobilePhone}</Table.Cell>
        <Table.Cell>{clients.length}</Table.Cell>
        <Table.Cell>{technician.facilities.length}</Table.Cell>
        <Table.Cell>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              style="outline"
              onClick={() => this._handleOnViewClick(technician.id)}
            >
              Ver
            </Button>
          </div>
          <div style={{ marginRight: "10px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
              onClick={() =>
                this._handleOnPressDeleteTechnicianButton(technician.userId)
              }
            >
              <DeleteIcon />
            </Button>
          </div>
          <div style={{ marginRight: "10px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              iconOnly
              onClick={() =>
                this._handleOnPressEditTechnicianFacilitiesButton(technician.id)
              }
            >
              <EditFacilitiesIcon />
            </Button>
          </div>
          <Button
            radius={7}
            paddingVertical={8}
            paddingHorizontal={8}
            style="silver"
            iconOnly
            onClick={() =>
              this._handleOnPressEditTechnicianButton(technician.id)
            }
          >
            <EditTechnicianIcon />
          </Button>
        </Table.Cell>
      </tr>
    );
  };

  // Add

  _renderAddTechnicianModal = () => {
    const { addTechnicianModal } = this.state;

    return (
      <AddTechnicianModal
        addTechnicianModalState={addTechnicianModal}
        onClickCloseButton={this._handleOnCloseButton}
        onInputChange={this._handleOnInputChange}
        onBackButton={this._handleOnBackButton}
        onAddFacilities={this._handleOnAddFacilities}
      />
    );
  };

  _renderAddFacilitiesModal = () => {
    const {
      facilitySelectors,
      addButtonDisabled,
      selectedFacilityIds
    } = this.state.addFacilityModal;

    return (
      <AddFacilitiesModal
        facilitySelectors={facilitySelectors}
        addButtonDisabled={addButtonDisabled}
        selectedFacilityIds={selectedFacilityIds}
        onCloseButton={this._handleOnCloseButton}
        onFacilitySelectorChange={this._handleOnFacilitySelectorChange}
        onFacilitySelect={this._handleOnFacilitySelect}
        onFacilityAddButton={this._handleOnFacilityAddButton}
        onBackButton={this._handleOnBackButton}
        onCreateTechnicianButton={this._handleOnCreateTechnicianButton}
        onRemoveFacilityInput={this._handleOnRemoveFacilityInput}
      />
    );
  };

  // Delete

  _renderDeleteTechnicianModal = () => {
    const {
      deleteTechnicianModal: { deleteTechnicianId, deleteButtonDisabled }
    } = this.state;

    const { technicians } = this.props;
    const technician = technicians.find(
      technician => technician.userId === deleteTechnicianId
    );

    return (
      <DeleteTechnicianModal
        deleteTechnicianId={deleteTechnicianId}
        deleteButtonDisabled={deleteButtonDisabled}
        technician={technician}
        onBackDeleteButton={this._handleOnBackDeleteButton}
        onDeleteTechnicianButton={this._handleOnDeleteTechnicianButton}
      />
    );
  };

  // Edit

  _renderEditTechnicianModal = () => {
    const {
      editTechnicianModal: { editTechnicianId, addButtonDisabled }
    } = this.state;

    const { technicians } = this.props;
    const technician = technicians.find(
      technician => technician.id === editTechnicianId
    );

    !localStorage.getItem("file_editarfoto") &&
      localStorage.setItem("file_editarfoto", technician.avatarUrl);

    return (
      <EditTechnicianModal
        technician={technician}
        editTechnicianId={editTechnicianId}
        onClickCloseButton={this._handleOnCloseButton}
        onCancelButton={this._handleOnCloseButton}
        onInputChange={this._handleOnEditInputChange}
        onEditTechnician={() => this._handleOnEditTechnician(editTechnicianId)}
        addButtonDisabled={addButtonDisabled}
      />
    );
  };

  _renderEditFacilityModal = () => {
    const {
      facilitySelectors,
      addButtonDisabled,
      editTechnicianId,
      selectedFacilityIds
    } = this.state.editFacilityModal;
    const { technicians } = this.props;
    const technician = technicians.find(
      technician => technician.id === editTechnicianId
    );

    return (
      <EditFacilitiesModal
        technician={technician}
        editTechnicianId={editTechnicianId}
        facilitySelectors={facilitySelectors}
        addButtonDisabled={addButtonDisabled}
        selectedFacilityIds={selectedFacilityIds}
        onCloseButton={this._handleOnCloseButton}
        onFacilitySelectorChange={this._handleOnEditFacilitySelectorChange}
        onFacilitySelect={this._handleOnEditFacilitySelect}
        onFacilityAddButton={this._handleOnEditFacilityAddButton}
        onCancelButton={this._handleOnCloseButton}
        onEditTechnicianFacilitiesButton={() =>
          this._handleOnEditTechnicianFacilitiesButton(editTechnicianId)
        }
        onRemoveFacilityInput={this._handleOnRemoveEditFacilityInput}
        selectedFacilityIds={selectedFacilityIds}
      />
    );
  };

  _renderTechnicianDetailModal = () => {
    const { viewTechnicianId } = this.state.technicianDetailModal;
    const { technicians } = this.props;
    const technician = technicians.find(
      technician => technician.id === viewTechnicianId
    );

    const halfWayThrough = Math.floor(technician.facilities.length / 2);
    const facilitiesHalfOne = technician.facilities.slice(0, halfWayThrough);
    const facilitiesHalfTwo = technician.facilities.slice(
      halfWayThrough,
      technician.facilities.length
    );

    const Field = ({ label, value }) => (
      <div style={{ width: "50%", margin: "0 auto", marginBottom: 40 }}>
        <div style={{ color: "#5A5E61", marginBottom: 10 }}>{label}</div>
        <div style={{ color: "#5A5E61", fontWeight: "bold" }}>{value}</div>
      </div>
    );

    const Facility = ({ avatarUrl, clientName, facilityName }) => (
      <div style={{ marginBottom: 40 }}>
        <div style={{ float: "left" }}>
          <AvatarBubble url={avatarUrl} />
        </div>
        <div style={{ display: "inline-block", marginLeft: 20 }}>
          <div style={{ marginBottom: 10, color: "#5A5E61" }}>{clientName}</div>
          <div style={{ color: "#5A5E61", fontWeight: "bold" }}>
            {facilityName}
          </div>
        </div>
      </div>
    );

    return (
      <>
        <Container width={1300}>
          <div
            onClick={this._handleOnCloseButton}
            style={{ position: "absolute", cursor: "pointer", right: 30 }}
          >
            <CloseIcon />
          </div>
          <Modal.Header>Datos del técnico</Modal.Header>
          <Modal.Section paddingVertical={50} paddingHorizontal={100}>
            <AvatarBubble
              width={120}
              height={120}
              url={technician.avatarUrl && technician.avatarUrl}
            />
            <Layout.Grid
              style={{ width: 640, margin: "0 auto", marginTop: 40 }}
            >
              <Layout.Column>
                <Field label="Nombre" value={technician.firstName} />
                <Field label="Email" value={technician.email} />
                <Field
                  label="Teléfono oficina"
                  value={technician.officePhone}
                />
              </Layout.Column>
              <Layout.Column>
                <Field label="Apellido" value={technician.lastName} />
                <Field label="Teléfono móvl" value={technician.mobilePhone} />
                <Field label="Dirección" value={technician.address} />
              </Layout.Column>
            </Layout.Grid>
          </Modal.Section>
          <Modal.Header>Plantas asignadas</Modal.Header>
          <Modal.Section paddingVertical={50} paddingHorizontal={100}>
            <Layout.Grid
              style={{ width: 640, margin: "0 auto", marginTop: 40 }}
            >
              <Layout.Column>
                {facilitiesHalfTwo.map(facility => (
                  <Facility
                    avatarUrl={facility.avatarUrl}
                    clientName={facility.businessName.client.name}
                    facilityName={facility.name}
                  />
                ))}
              </Layout.Column>
              <Layout.Column>
                {facilitiesHalfOne.map(facility => (
                  <Facility
                    avatarUrl={facility.avatarUrl}
                    clientName={facility.businessName.client.name}
                    facilityName={facility.name}
                  />
                ))}
              </Layout.Column>
            </Layout.Grid>
          </Modal.Section>
          <Modal.Footer>
            <Button
              paddingVertical={10}
              paddingHorizontal={55}
              onClick={this._handleOnCloseButton}
              style="outline"
            >
              Volver
            </Button>
          </Modal.Footer>
        </Container>
      </>
    );
  };

  // Add

  _handleOnAddTechnician = () => {
    this.setState(state => ({
      addTechnicianModal: { ...state.addTechnicianModal, visible: true }
    }));
  };

  _handleOnCloseButton = () => {
    localStorage.removeItem("file_editarfoto");
    this.setState({
      addTechnicianModal: { visible: false },
      addFacilityModal: { visible: false },
      editTechnicianModal: { visible: false },
      editFacilityModal: { visible: false },
      technicianDetailModal: { visible: false }
    });
  };

  _handleOnInputChange = event => {
    this.setState({
      addTechnicianModal: {
        ...this.state.addTechnicianModal,
        [event.target.name]: event.target.value
      }
    });
  };

  _handleOnAddFacilities = () => {
    const {
      addTechnicianModal: {
        technicianPasswordInput,
        technicianConfirmPasswordInput,
        technicianFirstNameInput,
        technicianLastNameInput,
        technicianEmailInput,
        technicianMobilePhoneInput,
        technicianOfficePhoneInput,
        technicianAddressInput
      }
    } = this.state;

    if (
      !!technicianFirstNameInput &&
      !!technicianLastNameInput &&
      !!technicianEmailInput &&
      !!technicianMobilePhoneInput &&
      !!technicianOfficePhoneInput &&
      !!technicianAddressInput &&
      !!technicianPasswordInput &&
      !!technicianConfirmPasswordInput
    ) {
      if (technicianPasswordInput === technicianConfirmPasswordInput) {
        this.setState(state => ({
          addTechnicianModal: { ...state.addTechnicianModal, visible: false },
          addFacilityModal: { ...state.addFacilityModal, visible: true }
        }));
      } else {
        alert("Passwords don't match");
      }
    } else {
      alert("Missing data");
    }
  };

  _handleOnFacilityAddButton = () => {
    const { facilitySelectors } = this.state.addFacilityModal;
    let isValid = false;
    Object.keys(facilitySelectors).map(selector => {
      if (facilitySelectors[selector].selectedFacilityId) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    const selectorsLength = Object.keys(facilitySelectors).length;
    const lastSelectorIdx = Object.keys(facilitySelectors)[selectorsLength - 1];

    if (isValid) {
      this.setState(state => ({
        addFacilityModal: {
          ...state.addFacilityModal,
          facilitySelectors: {
            ...state.addFacilityModal.facilitySelectors,
            [parseInt(lastSelectorIdx) + 1]: {}
          }
        }
      }));
    } else {
      alert("Missing data");
    }
  };

  _handleOnRemoveFacilityInput = selectorId => {
    const {
      facilitySelectors,
      selectedFacilityIds
    } = this.state.addFacilityModal;
    const { [selectorId]: _a, ...newFacilitySelectors } = facilitySelectors;
    const { [selectorId]: _b, ...newSelectedFacilityIds } = selectedFacilityIds;

    this.setState(state => ({
      addFacilityModal: {
        ...state.addFacilityModal,
        facilitySelectors: newFacilitySelectors,
        selectedFacilityIds: newSelectedFacilityIds
      }
    }));
  };

  _handleOnFacilitySelectorChange = (selector, key, value) => {
    this.setState(state => ({
      addFacilityModal: {
        ...state.addFacilityModal,
        facilitySelectors: {
          ...state.addFacilityModal.facilitySelectors,
          [selector]: {
            ...state.addFacilityModal.facilitySelectors[selector],
            [key]: value
          }
        }
      }
    }));
  };

  _handleOnFacilitySelect = (selector, facilityId) => {
    return this.setState(state => ({
      addFacilityModal: {
        ...state.addFacilityModal,
        selectedFacilityIds: {
          ...state.addFacilityModal.selectedFacilityIds,
          [selector]: facilityId
        },
        facilitySelectors: {
          ...state.addFacilityModal.facilitySelectors,
          [selector]: {
            ...state.addFacilityModal.facilitySelectors[selector],
            selectedFacilityId: facilityId
          }
        }
      }
    }));
  };

  _filterTechnicians = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnAddTechnician = () => {
    this.setState(state => ({
      addTechnicianModal: { ...state.addTechnicianModal, visible: true }
    }));
  };

  _handleOnCloseButton = () => {
    this.setState(state => ({
      ...state,
      ...initialModalState
    }));
  };

  _handleOnBackButton = index => {
    if (index === 0) {
      this.setState({
        addTechnicianModal: initialModalState.addTechnicianModal,
        addFacilityModal: initialModalState.addFacilityModal
      });
    } else if (index === 1) {
      this.setState(state => ({
        addTechnicianModal: { ...state.addTechnicianModal, visible: true },
        addFacilityModal: { ...state.addFacilityModal, visible: false }
      }));
    }
  };

  _handleOnCreateTechnicianButton = () => {
    const {
      addTechnicianModal: {
        technicianFirstNameInput,
        technicianLastNameInput,
        technicianEmailInput,
        technicianMobilePhoneInput,
        technicianOfficePhoneInput,
        technicianAddressInput,
        technicianPasswordInput
      },
      addFacilityModal: { selectedFacilityIds }
    } = this.state;

    const facilityIds = Object.values(selectedFacilityIds);
    const avatarUrlFromLocalStorage = localStorage.getItem("file_adjuntarfoto");

    if (
      !!technicianFirstNameInput &&
      !!technicianLastNameInput &&
      !!technicianEmailInput &&
      !!technicianMobilePhoneInput &&
      !!technicianOfficePhoneInput &&
      !!technicianAddressInput &&
      facilityIds.length > 0
    ) {
      this.setState(
        state => ({
          addFacilityModal: {
            ...state.addFacilityModal,
            addButtonDisabled: true
          }
        }),
        () =>
          createTechnician(
            avatarUrlFromLocalStorage,
            technicianFirstNameInput,
            technicianLastNameInput,
            technicianEmailInput,
            technicianMobilePhoneInput,
            technicianOfficePhoneInput,
            technicianAddressInput,
            facilityIds,
            technicianPasswordInput
          )
            .then(() => {
              localStorage.removeItem("file_adjuntarfoto");
              window.location = "/tecnicos";
              mixpanel.track("Nuevo tecnico creado");
            })
            .catch(err => {
              // Log the error
              console.error(err);
              if (err.message.indexOf("not_unique: emailAddress") > -1) {
                alert(`El correo ${technicianEmailInput} ya existe`);
              }

              this.setState(state => ({
                addFacilityModal: {
                  ...state.addFacilityModal,
                  addButtonDisabled: false
                }
              }));
            })
      );
    } else {
      alert("Missing data");
    }
  };

  // Edit

  _handleOnPressEditTechnicianButton = technicianId => {
    const { technicians } = this.props;
    const technician = technicians.find(
      technician => technician.id === technicianId
    );

    this.setState(state => ({
      editTechnicianModal: {
        ...state.editTechnicianModal,
        visible: true,
        editTechnicianId: technicianId,
        technicianAvatarUrl: technician.avatarUrl,
        technicianFirstNameInput: technician.firstName,
        technicianLastNameInput: technician.lastName,
        technicianEmailInput: technician.email,
        technicianMobilePhoneInput: technician.mobilePhone,
        technicianOfficePhoneInput: technician.officePhone,
        technicianAddressInput: technician.address
      }
    }));
  };

  _handleOnPressEditTechnicianFacilitiesButton = technicianId => {
    const { technicians } = this.props;
    const technician = technicians.find(
      technician => technician.id === technicianId
    );

    const facilitySelectors = {};
    const selectedFacilityIds = technician.facilities.map(
      facility => facility.id
    );

    technician.facilities.map((facility, idx) => {
      facilitySelectors[idx] = {
        selectedClient: facility.businessName.client.name,
        selectedBusinessName: facility.businessName.name,
        selectedFacilityId: facility.id
      };
    });

    // If no facilities are assigned to the current technician, we display the initial
    // facility selector.
    if (Object.keys(facilitySelectors).length === 0) {
      facilitySelectors[0] = {};
    }

    this.setState(state => ({
      editFacilityModal: {
        ...state.editFacilityModal,
        editTechnicianId: technicianId,
        visible: true,
        facilitySelectors,
        selectedFacilityIds
      }
    }));
  };

  _handleOnEditInputChange = event => {
    this.setState({
      editTechnicianModal: {
        ...this.state.editTechnicianModal,
        [event.target.name]: event.target.value
      }
    });
  };

  _handleOnRemoveEditFacilityInput = selectorId => {
    const {
      facilitySelectors,
      selectedFacilityIds
    } = this.state.editFacilityModal;
    const prevSelected = this.state.editFacilityModal.facilitySelectors[
      selectorId
    ].selectedFacilityId;
    const currentSelectors = facilitySelectors;
    delete currentSelectors[selectorId];
    let currentSelectedIds = selectedFacilityIds.filter(
      facilityId => facilityId !== prevSelected
    );
    const { [selectorId]: _a, ...newFacilitySelectors } = facilitySelectors;
    const { [selectorId]: _b, ...newSelectedFacilityIds } = selectedFacilityIds;

    this.setState(state => ({
      editFacilityModal: {
        ...state.editFacilityModal,
        facilitySelectors: currentSelectors,
        selectedFacilityIds: currentSelectedIds
      }
    }));
  };

  _handleOnEditTechnician = technicianId => {
    const {
      editTechnicianModal: {
        technicianAvatarUrl,
        technicianFirstNameInput,
        technicianLastNameInput,
        technicianEmailInput,
        technicianMobilePhoneInput,
        technicianOfficePhoneInput,
        technicianAddressInput
      }
    } = this.state;

    const avatarUrl = localStorage.getItem("file_editarfoto");

    this.setState(
      state => ({
        editTechnicianModal: {
          ...state.editTechnicianModal,
          addButtonDisabled: true
        }
      }),
      () =>
        editTechnician(
          technicianId,
          avatarUrl,
          technicianFirstNameInput,
          technicianLastNameInput,
          technicianEmailInput,
          technicianMobilePhoneInput,
          technicianOfficePhoneInput,
          technicianAddressInput
        )
          .then(() => {
            localStorage.removeItem("file_editarfoto");
            window.location = "/tecnicos";
            mixpanel.track("Editar datos de tecnico");
          })
          .catch(err => {
            // Log the error
            console.error(err);

            this.setState(state => ({
              editTechnicianModal: {
                ...state.editTechnicianModal,
                addButtonDisabled: false
              }
            }));
          })
    );
  };

  _handleOnEditFacilitySelectorChange = (selector, key, value) => {
    const {
      facilitySelectors,
      selectedFacilityIds
    } = this.state.editFacilityModal;
    const currentSelectedFacilityId =
      facilitySelectors[selector].selectedFacilityId;

    let newSelectedFacilityIds = selectedFacilityIds;

    if (currentSelectedFacilityId) {
      newSelectedFacilityIds = selectedFacilityIds.filter(
        facilityId => facilityId !== currentSelectedFacilityId
      );
    }

    if (key === "selectedClient") {
      this.setState(state => ({
        editFacilityModal: {
          ...state.editFacilityModal,
          selectedFacilityIds: newSelectedFacilityIds,
          facilitySelectors: {
            ...state.editFacilityModal.facilitySelectors,
            [selector]: {
              selectedClient: value,
              selectedBusinessName: undefined,
              selectedFacilityId: undefined
            }
          }
        }
      }));
    } else if (key === "selectedBusinessName") {
      this.setState(state => ({
        editFacilityModal: {
          ...state.editFacilityModal,
          selectedFacilityIds: newSelectedFacilityIds,
          facilitySelectors: {
            ...state.editFacilityModal.facilitySelectors,
            [selector]: {
              ...state.editFacilityModal.facilitySelectors[selector],
              selectedBusinessName: value,
              selectedFacilityId: undefined
            }
          }
        }
      }));
    }
  };

  _handleOnEditFacilitySelect = (selector, facilityId) => {
    const prevSelected = this.state.editFacilityModal.facilitySelectors[
      selector
    ].selectedFacilityId;
    const { selectedFacilityIds } = this.state.editFacilityModal;
    let currentSelectedIds = selectedFacilityIds.filter(
      facilityId => facilityId !== prevSelected
    );
    currentSelectedIds.push(facilityId);

    this.setState(state => ({
      editFacilityModal: {
        ...state.editFacilityModal,
        selectedFacilityIds: currentSelectedIds,
        facilitySelectors: {
          ...state.editFacilityModal.facilitySelectors,
          [selector]: {
            ...state.editFacilityModal.facilitySelectors[selector],
            selectedFacilityId: facilityId
          }
        }
      }
    }));
  };

  _handleOnEditFacilityAddButton = () => {
    const { facilitySelectors } = this.state.editFacilityModal;
    let isValid = false;
    let last = 0;
    Object.keys(facilitySelectors).map(selector => {
      if (facilitySelectors[selector].selectedFacilityId) {
        isValid = true;
      } else {
        isValid = false;
      }
      last = parseInt(selector);
    });
    if (isValid) {
      this.setState(state => ({
        editFacilityModal: {
          ...state.editFacilityModal,
          facilitySelectors: {
            ...state.editFacilityModal.facilitySelectors,
            [last + 1]: {}
          }
        }
      }));
    } else {
      alert("Missing data");
    }
  };

  _handleOnEditTechnicianFacilitiesButton = technicianId => {
    const {
      editFacilityModal: { selectedFacilityIds }
    } = this.state;

    const facilityIds = Object.values(selectedFacilityIds);

    if (facilityIds.length > 0) {
      this.setState(
        state => ({
          editFacilityModal: {
            ...state.editFacilityModal,
            addButtonDisabled: true
          }
        }),
        () =>
          editTechnicianFacilities(technicianId, facilityIds)
            .then(() => {
              window.location = "/tecnicos";
              mixpanel.track("Editar plantas de tecnico");
            })
            .catch(err => {
              // Log the error
              console.error(err);

              this.setState(state => ({
                editFacilityModal: {
                  ...state.editFacilityModal,
                  addButtonDisabled: false
                }
              }));
            })
            .then(() => {
              window.location = "/tecnicos";
            })
      );
    } else {
      alert("Missing data");
    }
  };

  // Delete

  _handleOnBackDeleteButton = () => {
    this.setState(state => ({
      deleteTechnicianModal: {
        ...state.deleteTechnicianModal,
        visible: false
      }
    }));
  };

  _handleOnViewClick = technicianId => {
    this.setState(state => ({
      technicianDetailModal: {
        ...state.technicianDetailModal,
        visible: true,
        viewTechnicianId: technicianId
      }
    }));
  };

  _handleOnPressDeleteTechnicianButton = userId => {
    this.setState(state => ({
      deleteTechnicianModal: {
        ...state.deleteTechnicianModal,
        visible: true,
        deleteTechnicianId: userId
      }
    }));
  };

  _handleOnDeleteTechnicianButton = userId => {
    if (!!userId) {
      this.setState(
        (state, _) => ({
          deleteTechnicianModal: {
            ...state.deleteTechnicianModal,
            deleteButtonDisabled: true
          }
        }),
        () =>
          deleteTechnician(userId)
            .then(() => {
              window.location = "/tecnicos";
              mixpanel.track("Borrar tecnico");
            })
            .catch(err => {
              bugsnagClient.notify(err);
              this.setState(state => ({
                deleteTechnicianModal: {
                  visible: false
                }
              }));
            })
      );
    }
  };

  _handleOnBackDeleteButton = () => {
    this.setState(state => ({
      deleteTechnicianModal: {
        ...state.deleteTechnicianModal,
        visible: false
      }
    }));
  };
}

export default TechniciansTemplate;
