import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";
import { CheckIcon14, DropdowChevronIcon, NonSelectable } from "../";
import classJoiner from "../../../utils/classJoiner";

class CustomDropdown extends Component {
  state = {
    open: false
  };

  componentDidMount() {
    const { selected } = this.props;

    this.setState({
      selected
    });

    document.addEventListener("mousedown", this._handleClick);
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;

    if (prevProps.selected !== selected) {
      this.setState({ selected });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleClick);
  }

  render() {
    const { open, selected } = this.state;
    const {
      parentId,
      options,
      disabledOptions,
      defaultValue,
      disabled
    } = this.props;

    return (
      <NonSelectable style={{ position: "static", opacity: disabled && 0.5 }}>
        <div
          className={styles.dropdownBody}
          onClick={() => !disabled && this._handleOnClick()}
          ref={node => (this.parentNode = node)}
        >
          <div className={styles.selectedContainer}>
            {selected ? options[selected] : options[defaultValue]}
          </div>
          <div className={styles.dropdownIconContainer}>
            <DropdowChevronIcon />
          </div>
        </div>
        {open && (
          <div
            className={styles.dropdownContainer}
            ref={node => (this.node = node)}
          >
            {Object.keys(options).map(option => {
              const optionDisabled =
                disabledOptions.includes(option) || disabled;
              const classNames = [styles.optionContainer];
              optionDisabled && classNames.push(styles.disabledOptionContainer);

              return (
                <div
                  className={classJoiner(classNames)}
                  onClick={() =>
                    !optionDisabled &&
                    this._handleOnClickOption(parentId, option, selected)
                  }
                >
                  <div className={styles.checkIconContainer}>
                    {options[option] === options[selected] && <CheckIcon14 />}
                  </div>
                  <div className={styles.optionLabel}>{options[option]}</div>
                </div>
              );
            })}
          </div>
        )}
      </NonSelectable>
    );
  }

  _handleOnClick = () => this.setState(state => ({ open: !state.open }));

  _handleOnClickOption = (parentId, selectedOption, selected) =>
    selectedOption !== selected &&
    this.setState({ open: false, selected: selectedOption }, () =>
      this.props.onChange(parentId, selectedOption)
    );

  _handleClick = evt => {
    if (
      (this.node && this.node.contains(evt.target)) ||
      (this.parentNode && this.parentNode.contains(evt.target))
    ) {
      return;
    }

    this.setState({ open: false });
  };
}

CustomDropdown.propTypes = {
  parentId: PropTypes.string,
  options: PropTypes.object.isRequired,
  disabledOptions: PropTypes.array,
  selected: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

CustomDropdown.defaultProps = {
  disabledOptions: [],
  disabled: false
};

export default CustomDropdown;
