import { gql } from "apollo-boost";
import { client } from "../apollo";

const updateClient = async (
  clientId,
  userId,
  avatarUrl,
  name,
  state,
  city,
  address,
  emailAddress,
  businessNames
) =>
  await client.mutate({
    mutation: gql`
      mutation updateClient(
        $clientId: ID!
        $userId: ID!
        $avatarUrl: String
        $name: String
        $state: String
        $city: String
        $address: String
        $emailAddress: String
        $businessNames: String!
      ) {
        updateClient(
          clientId: $clientId
          userId: $userId
          avatarUrl: $avatarUrl
          name: $name
          state: $state
          city: $city
          address: $address
          emailAddress: $emailAddress
          businessNames: $businessNames
        )
      }
    `,
    variables: {
      clientId,
      userId,
      avatarUrl,
      name,
      state,
      city,
      address,
      emailAddress,
      businessNames
    }
  });

export default updateClient;
