import { gql } from "apollo-boost";
import { client } from "../apollo";

const updateReport = async (reportId, introMessage, report) =>
  await client.mutate({
    mutation: gql`
      mutation updateReport(
        $reportId: ID!
        $introMessage: String
        $report: String!
      ) {
        updateReport(
          reportId: $reportId
          introMessage: $introMessage
          report: $report
        )
      }
    `,
    variables: {
      reportId,
      introMessage,
      report
    }
  });

export default updateReport;
