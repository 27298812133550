import React, { Component } from "react";

import PropTypes from "prop-types";
import fuzzy from "fuzzy";
import mixpanel from "mixpanel-browser";

import { parseMilliseconds } from "../../utils/date";
import { CHECKLIST_FORMATS } from "../../utils/checklists";
import { bugsnagClient } from "../../bugsnag";

import styles from "./InspectionsTemplateStyles.scss";

import {
  AvatarBubble,
  Button,
  Container,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  Heading,
  Table,
  Modal,
  Layout,
  LabeledInput,
  RedLabeledInput,
  PlusIcon,
  FilterIcon,
  Switch,
  LabeledSelect,
  BigRemoveButton,
  TextInput
} from "../atoms";
import {
  FacilitySelector,
  TableContainerHeader,
  Spinner,
  ModalPortal,
  UploadButton
} from "../molecules";
import {
  DeleteInspectionModal,
  EditInspectionModal,
  EditInspectionChecklistModal
} from "../organisms";
import { Query } from "react-apollo";
import {
  getTechniciansByFacility,
  getRescheduledInspections
} from "../../gql/queries";
import {
  scheduleInspection,
  deleteInspection,
  editInspectionAndChecklist
} from "../../gql/mutations";
import statesAndCities from "../../utils/statesAndCities";

const ACTIVITIES = {
  Carretes: "Carretes de Manguera",
  Lamina: "Lámina de 1/2 pulgada"
};

const LOCATIONS = {
  "Zona Norte, Chihuahua": "Zona Norte, Chihuahua"
};

const ASIGNEDTECHNICIAN = {
  "Evan Andrews": "Evan Andrews"
};

const DROPDOWNYEARS = {
  "2019": "2019",
  "2020": "2020"
};

const DROPDOWNMONTHS = {
  Enero: "Enero",
  Febrero: "Febrero",
  Marzo: "Marzo",
  Abril: "Abril",
  Mayo: "Mayo",
  Junio: "Junio",
  Julio: "Julio",
  Agosto: "Agosto",
  Septiembre: "Septiembre",
  Octubre: "Octubre",
  Noviembre: "Noviembre",
  Diciembre: "Diciembre"
};

const VISITDAYS = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "10": "10",
  "11": "11",
  "12": "12",
  "13": "13",
  "14": "14",
  "15": "15",
  "16": "16",
  "17": "17",
  "18": "18",
  "19": "19",
  "20": "20",
  "21": "21",
  "22": "22",
  "23": "23",
  "24": "24",
  "25": "25",
  "26": "26",
  "27": "27",
  "28": "28",
  "29": "29",
  "30": "30",
  "31": "31"
};

const PERIODICITY = {
  Semanal: "WEEKLY",
  Mensual: "MONTHLY",
  Bimestral: "BIMONTHLY",
  Trimestral: "TRIMONTHLY",
  Semestral: "HALFYEAR",
  Anual: "YEARLY"
};

const WEEKDAYS = {
  Lunes: "MONDAY",
  Martes: "TUESDAY",
  Miércoles: "WEDNESDAY",
  Jueves: "THURSDAY",
  Viernes: "FRIDAY",
  Sábado: "SATURDAY",
  Domingo: "SUNDAY"
};

class InspectionsTemplate extends Component {
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  onChange = date => this.setState({ date });

  state = {
    date: new Date(),
    filter: undefined,
    dateRangeFilter: {},
    addInspectionModal: {
      visible: false,
      addButtonDisabled: true
    },
    addInspectionChecklistModal: {
      visible: false,
      addButtonDisabled: false,
      formats: {}
    },
    deleteInspectionModal: {
      deleteInspectionModalVisible: false,
      deleteButtonDisabled: false
    },
    editInspectionModal: {
      visible: false,
      facilitySelectors: {
        0: {}
      },
      selectedFacilityId: undefined
    },
    editChecklistModal: {
      editChecklistModalVisible: false,
      saveButtonDisabled: false
    }
  };

  static propTypes = {
    loadingInspections: PropTypes.bool.isRequired,
    inspections: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    loadingInspections: true,
    inspections: []
  };

  componentDidUpdate(_, prevState) {
    const { refetch, refetchReschedule } = this.props;
    const { dateRangeFilter: prevDateRangeFilter } = prevState;
    const { dateRangeFilter } = this.state;

    if (
      (!prevDateRangeFilter.startDate || !prevDateRangeFilter.endDate) &&
      dateRangeFilter.startDate &&
      dateRangeFilter.endDate
    ) {
      refetch({
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
      refetchReschedule({
        startDate: dateRangeFilter.startDate,
        endDate: dateRangeFilter.endDate
      });
    }

    if (prevDateRangeFilter.startDate && prevDateRangeFilter.endDate) {
      if (
        prevDateRangeFilter.startDate !== dateRangeFilter.startDate ||
        prevDateRangeFilter.endDate !== dateRangeFilter.endDate
      ) {
        refetch({
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
        refetchReschedule({
          startDate: dateRangeFilter.startDate,
          endDate: dateRangeFilter.endDate
        });
      }
    }

    if (
      prevDateRangeFilter.startDate &&
      prevDateRangeFilter.endDate &&
      (!dateRangeFilter.startDate && !dateRangeFilter.endDate)
    ) {
      refetch({ startDate: undefined, endDate: undefined });
      refetchReschedule({
        startDate: undefined,
        endDate: undefined
      });
    }
  }

  render() {
    const {
      filter,
      addInspectionModal,
      addInspectionChecklistModal,
      deleteInspectionModal: { deleteInspectionModalVisible },
      editInspectionModal,
      editChecklistModal: { editChecklistModalVisible },
      dateRangeFilter
    } = this.state;
    const {
      loadingInspections,
      inspections,
      inspectionReschedules
    } = this.props;

    if (inspectionReschedules) {
      if (inspectionReschedules.length > 0) {
        inspections.map(inspection => {
          var result = inspectionReschedules.filter(
            reschedule => reschedule.inspectionId == inspection.id
          );
          if (result.length > 0) {
            inspection.inspectionReschedule = true;
          }
        });
      }
    }

    const filteredInspections =
      inspections &&
      fuzzy.filter(filter, inspections, {
        extract: el => el.fTec.technician.firstName
      });

    const data = filter
      ? filteredInspections &&
        filteredInspections.map(result => result.original)
      : inspections;

    // Sort the data by created date
    // TODO: Extract to an util
    inspections.length > 0 &&
      inspections.sort((a, b) => {
        if (parseInt(a.createdAt) < parseInt(b.createdAt)) {
          return -1;
        }

        if (parseInt(a.createdAt) > parseInt(b.createdAt)) {
          return 1;
        }

        return 0;
      });

    return (
      <>
        <ModalPortal
          modal={this._renderAddInspectionModal}
          visible={addInspectionModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderAddInspectionChecklistModal}
          visible={addInspectionChecklistModal.visible}
        />
        <ModalPortal
          modal={this._renderDeleteInspectionModal}
          visible={deleteInspectionModalVisible}
          onBackdropClick={this._handleOnBackDeleteButton}
        />
        <ModalPortal
          modal={this._renderEditInspectionModal}
          visible={editInspectionModal.visible}
          onBackdropClick={this._handleOnCloseButton}
        />
        <ModalPortal
          modal={this._renderEditChecklistModal}
          visible={editChecklistModalVisible}
          onBackdropClick={this._handleOnBackEditChecklistButton}
        />
        <Heading>Inspecciones Por Realizar</Heading>
        <Container noPadding>
          <div style={{ overflow: "hidden", margin: "30px" }}>
            <TextInput
              width={580}
              placeholder="Buscar por nombre de técnico"
              onChange={e => this._filterInspections(e)}
            />
            <div
              style={{
                display: "inline-block",
                textAlign: "center",
                marginLeft: "30px",
                position: "absolute"
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "20px",
                  fontWeight: "bold"
                }}
              >
                Filtrar por rango de fechas
              </div>
              <LabeledInput
                index={1}
                type="date-picker"
                name="startDate"
                defaultValue={dateRangeFilter.startDate}
                componentProps={{
                  dayPickerProps: {
                    disabledDays: {
                      after: new Date(dateRangeFilter.endDate)
                    }
                  }
                }}
                onChange={this._handleOnDateInputChange}
              />
              <span style={{ marginLeft: "15px" }}>
                <LabeledInput
                  index={2}
                  type="date-picker"
                  name="endDate"
                  defaultValue={dateRangeFilter.endDate}
                  componentProps={{
                    dayPickerProps: {
                      disabledDays: {
                        before: new Date(dateRangeFilter.startDate)
                      }
                    }
                  }}
                  onChange={this._handleOnDateInputChange}
                />
              </span>
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "5px"
                }}
              >
                <BigRemoveButton onClick={this._handleOnResetDateRangeFilter} />
              </span>
            </div>
            <div style={{ float: "right", marginLeft: "10px" }}>
              <Button onClick={this._handleOnClickAddInspection}>
                Agregar
                <div className={styles.plusIconWrapper}>
                  <PlusIcon tint="#ffffff" />
                </div>
              </Button>
            </div>
          </div>
          <Table.Main
            columns={[
              null,
              { "fTec.technician.firstName": "Técnico" },
              { "fTec.facility.name": "Planta" },
              {
                "inspectionChecklist.checklistFormat": "Checklist de Inspección"
              },
              null,
              { scheduledDate: "Fecha" },
              null
            ]}
            data={data}
            row={(inspection, lastRow) =>
              this._renderInspectionRow(inspection, lastRow)
            }
          />
        </Container>
      </>
    );
  }

  _filterInspections = evt => {
    this.setState({
      filter: evt.currentTarget.value
    });
  };

  _handleOnResetDateRangeFilter = () => {
    this.setState({
      dateRangeFilter: {}
    });
  };

  _handleOnDateInputChange = e => {
    if (e.props) {
      return this.setState(state => ({
        dateRangeFilter: {
          ...state.dateRangeFilter,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _renderInspectionRow = (inspection, lastRow) => {
    const { firstName, lastName } = inspection.fTec.technician;
    const inspectionTitle =
      inspection.__typename === "Inspection"
        ? CHECKLIST_FORMATS[inspection.inspectionChecklist.checklistFormat]
        : inspection.__typename === "CorrectiveActionInspection"
        ? "Acción correctiva"
        : "Servicio adicional";

    const scheduledDate = new Date(
      `${inspection.scheduledDate}T00:00`
    ).getTime();

    return (
      <tr
        testid="inspections-table-row"
        key={inspection.id}
        style={{ borderBottom: !lastRow && "solid 1px #eaeaea" }}
      >
        <Table.Cell>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <AvatarBubble
              url={
                inspection.fTec.technician.avatarUrl &&
                inspection.fTec.technician.avatarUrl
              }
            />
          </div>
        </Table.Cell>
        <Table.Cell>
          {firstName} {lastName}
        </Table.Cell>
        <Table.Cell>{inspection.fTec.facility.name}</Table.Cell>
        <Table.Cell>{inspectionTitle}</Table.Cell>
        <Table.Cell>
          {inspection.inspectionReschedule ? "Reagendada" : ""}
        </Table.Cell>
        <Table.Cell>{parseMilliseconds(scheduledDate)}</Table.Cell>
        <Table.Cell>
          <div style={{ marginRight: "14px", display: "inline-block" }}>
            <Button
              radius={7}
              paddingVertical={8}
              paddingHorizontal={8}
              style="silver"
              disabled={inspection.inspectionReschedule ? true : false}
              iconOnly
              onClick={() =>
                this._handleOnPressDeleteInspectionButton(inspection.id)
              }
            >
              <DeleteIcon />
            </Button>
          </div>
          <Button
            radius={7}
            paddingVertical={8}
            paddingHorizontal={8}
            disabled={inspection.inspectionReschedule ? true : false}
            style="silver"
            iconOnly
            onClick={() =>
              this._handleOnPressEditInspectionButton(inspection.id)
            }
          >
            <EditIcon />
          </Button>
        </Table.Cell>
      </tr>
    );
  };

  _renderDeleteInspectionModal = () => {
    const {
      deleteInspectionModal: { deleteButtonDisabled, deleteInspectionId }
    } = this.state;

    const { inspections } = this.props;
    const inspection = inspections.find(
      inspection => inspection.id === deleteInspectionId
    );

    return (
      <DeleteInspectionModal
        deleteButtonDisabled={deleteButtonDisabled}
        deleteInspectionId={deleteInspectionId}
        inspection={inspection}
        onBackButton={this._handleOnBackDeleteButton}
        onDeleteInspectionButton={this._handleOnDeleteInspectionButton}
      />
    );
  };

  _handleOnBackDeleteButton = () => {
    this.setState(state => ({
      deleteInspectionModal: {
        ...state.deleteInspectionModal,
        deleteInspectionModalVisible: false
      }
    }));
  };

  _handleOnPressDeleteInspectionButton = inspectionId => {
    this.setState(state => ({
      deleteInspectionModal: {
        ...state.deleteInspectionModal,
        deleteInspectionModalVisible: true,
        deleteInspectionId: inspectionId
      }
    }));
  };

  _handleOnDeleteInspectionButton = inspectionId => {
    const { inspections } = this.props;
    const inspection = inspections.find(
      inspection => inspection.id === inspectionId
    );

    const inspectionChecklistId = inspection.inspectionChecklist.id;

    if (!!inspectionId) {
      this.setState(
        (state, _) => ({
          deleteInspectionModal: {
            ...state.deleteInspectionModal,
            deleteButtonDisabled: true
          }
        }),
        () =>
          deleteInspection(inspectionChecklistId)
            .then(() => {
              window.location = "/agenda/inspecciones";
            })
            .catch(err => {
              console.log(err);
              this.setState(state => ({
                deleteInspectionModal: {
                  deleteInspectionModal: false
                }
              }));
            })
      );
    }
  };

  _renderEditInspectionModal = () => {
    const { editInspectionModal } = this.state;
    const { inspections } = this.props;
    const inspection = inspections.find(
      inspection => inspection.id === editInspectionModal.editInspectionId
    );

    const editButtonDisabled =
      !editInspectionModal.scheduledDate ||
      !editInspectionModal.nameAndLastNameInput;

    return (
      <EditInspectionModal
        editInspectionModalState={editInspectionModal}
        inspection={inspection}
        scheduledDate={editInspectionModal.scheduledDate}
        facilitySelector={editInspectionModal.facilitySelector}
        facilitySelectors={editInspectionModal.facilitySelectors}
        editInspectionId={editInspectionModal.editInspectionId}
        onCloseButton={this._handleOnCloseButton}
        onFacilitySelectorChange={this._handleOnEditFacilitySelectorChange}
        onViewChecklistButton={this._handleOnViewEditChecklist}
        onFacilitySelect={this._handleOnEditFacilitySelect}
        onInputChange={this._handleOnEditInputChange}
        renderLabeledTechnicians={this._renderEditLabeledTechnicians}
        editButtonDisabled={editButtonDisabled}
      />
    );
  };

  _renderEditChecklistModal = () => {
    const {
      editChecklistModal: { inspectionChecklist }
    } = this.state;

    const { editChecklistModal } = this.state;
    const requiredFormat = Object.keys(editChecklistModal.formats).find(
      format => {
        return editChecklistModal.formats[format].required;
      }
    );

    const saveButtonEnabled =
      requiredFormat && editChecklistModal.formats[requiredFormat].quantity > 0;

    return (
      <EditInspectionChecklistModal
        onBackButton={this._handleOnBackEditChecklistButton}
        onSaveButton={this._handleOnEditInspection}
        saveButtonDisabled={
          !saveButtonEnabled || editChecklistModal.saveButtonDisabled
        }
        renderEditChecklist={this._renderEditChecklist}
      />
    );
  };

  _renderEditChecklist = (title, format) => {
    const { editChecklistModal } = this.state;
    const requiredFormat = Object.keys(editChecklistModal.formats).find(
      format => {
        return editChecklistModal.formats[format].required;
      }
    );

    return (
      <div
        style={{
          padding: 30,
          opacity: requiredFormat && requiredFormat !== format && 0.5
        }}
      >
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              float: "left",
              fontSize: 16,
              color: "#4a4a4a",
              marginBottom: 30,
              maxWidth: "216px"
            }}
          >
            {title}
          </div>
          <div style={{ float: "right" }}>
            <Switch
              disabled={requiredFormat && requiredFormat !== format}
              active={requiredFormat === format}
              onToggle={state =>
                this._handleOnEditChecklistToggle(format, state)
              }
            />
          </div>
        </div>
        <div>
          <Layout.Grid>
            <Layout.Column style={{ marginRight: 30 }}>
              <LabeledInput
                fullWidth
                type="number"
                name="quantity"
                label="Cantidad"
                disabled={requiredFormat !== format}
                onChange={e =>
                  this._handleOnEditChecklistInputChange(
                    format,
                    "quantity",
                    e.currentTarget.value
                  )
                }
                defaultValue={
                  requiredFormat === format &&
                  editChecklistModal.formats[requiredFormat].quantity
                }
              />
            </Layout.Column>
          </Layout.Grid>
        </div>
      </div>
    );
  };

  _handleOnEditInspection = () => {
    const {
      editInspectionModal: {
        editInspectionId,
        dateInput,
        nameAndLastNameInput
      },
      editChecklistModal
    } = this.state;

    const parsedScheduledDate =
      dateInput && new Date(parseInt(dateInput)).getTime();

    const requiredFormat = Object.keys(editChecklistModal.formats).find(
      format => {
        return editChecklistModal.formats[format].required;
      }
    );

    const inspectionChecklist = {
      id: editChecklistModal.inspectionChecklist.id,
      format: requiredFormat,
      quantity: editChecklistModal.formats[requiredFormat].quantity
    };

    this.setState(
      state => ({
        editChecklistModal: {
          ...state.editChecklistModal,
          saveButtonDisabled: true
        }
      }),
      () =>
        editInspectionAndChecklist(
          editInspectionId,
          nameAndLastNameInput,
          parsedScheduledDate && String(parsedScheduledDate),
          JSON.stringify(inspectionChecklist)
        )
          .then(response => {
            window.location = "/agenda/inspecciones";
          })
          .catch(err => {
            console.log(err);
            this.setState(state => ({
              editChecklistModal: {
                ...state.editChecklistModal,
                saveButtonDisabled: false
              }
            }));
          })
    );
  };

  _handleOnViewEditChecklist = inspectionChecklist => {
    this.setState(state => ({
      editInspectionModal: {
        ...state.editInspectionModal,
        editInspectionModalVisible: false
      },
      editChecklistModal: {
        ...state.editChecklistModal,
        inspectionChecklist,
        formats: {
          [inspectionChecklist.checklistFormat]: {
            quantity: inspectionChecklist.quantity,
            required: true
          }
        },
        editChecklistModalVisible: true
      }
    }));
  };

  _handleOnBackEditChecklistButton = () => {
    this.setState(state => ({
      editChecklistModal: {
        ...state.editChecklistModal,
        editChecklistModalVisible: false
      },
      editInspectionModal: {
        ...state.editInspectionModal,
        editInspectionModalVisible: true
      }
    }));
  };

  _handleOnEditChecklistToggle = (format, toggleState) => {
    return this.setState(state => ({
      editChecklistModal: {
        ...state.editChecklistModal,
        formats: {
          ...state.editChecklistModal.formats,
          [format]: {
            ...(state.editChecklistModal.formats[format] || {}),
            required: toggleState
          }
        }
      }
    }));
  };

  _handleOnEditChecklistInputChange = (format, input, value) =>
    this.setState(state => ({
      editChecklistModal: {
        ...state.editChecklistModal,
        formats: {
          ...state.editChecklistModal.formats,
          [format]: {
            ...(state.editChecklistModal.formats[format] || {}),
            [input]: value
          }
        }
      }
    }));

  _handleOnPressEditInspectionButton = inspectionId => {
    const { inspections } = this.props;
    const inspection = inspections.find(
      inspection => inspection.id === inspectionId
    );

    const facilitySelector = {
      0: {
        selectedClient: inspection.fTec.facility.businessName.client.name,
        selectedBusinessName: inspection.fTec.facility.businessName.name,
        selectedFacilityId: inspection.fTec.facility.id
      }
    };

    const formats = {};

    this.setState(state => ({
      editInspectionModal: {
        ...state.editInspectionModal,
        visible: true,
        editInspectionId: inspectionId,
        scheduledDate: inspection.scheduledDate,
        facilitySelector,
        nameAndLastNameInput: inspection.fTec.id,
        selectedFacilityId: inspection.fTec.facility.id
      },
      editChecklistModal: {
        ...state.editChecklistModal,
        formats
      }
    }));
  };

  _handleOnEditFacilitySelect = facilityId => {
    this.setState(state => ({
      editInspectionModal: {
        ...state.editInspectionModal,
        nameAndLastNameInput: undefined,
        selectedFacilityId: facilityId
      }
    }));
  };

  _handleOnEditInputChange = (e, day) => {
    if (e.props) {
      return this.setState(state => ({
        editInspectionModal: {
          ...state.editInspectionModal,
          [e.props.inputProps.name]: String(new Date(day).getTime())
        }
      }));
    }

    if (e.name === "annualCost" || e.name === "monthlyCost") {
      return this.setState(state => ({
        editInspectionModal: {
          ...state.editInspectionModal,
          [e.name]: String(e.value)
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      editInspectionModal: {
        ...state.editInspectionModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _handleOnEditFacilitySelectorChange = (key, value) => {
    this.setState(state => ({
      editInspectionModal: {
        ...state.editInspectionModal,
        nameAndLastNameInput: undefined,
        selectedFacilityId: undefined,
        [key]: value
      }
    }));
  };

  _renderAddInspectionModal = () => {
    const { addInspectionModal } = this.state;

    const addButtonEnabled =
      addInspectionModal.selectedFacilityId &&
      addInspectionModal.dateInput &&
      addInspectionModal.nameAndLastNameInput;

    return (
      <Container width={1100}>
        <div
          onClick={() => this._handleOnCloseButton()}
          style={{
            position: "absolute",
            cursor: "pointer",
            right: "20px",
            top: "20px"
          }}
        >
          <CloseIcon />
        </div>
        <Modal.Header>Crear Inspección</Modal.Header>
        <Modal.Header>Asignar Planta</Modal.Header>
        <Modal.Section>
          <div style={{ marginLeft: 100, marginRight: 100, marginTop: 40 }}>
            <FacilitySelector
              selected={[
                addInspectionModal.selectedClient,
                addInspectionModal.selectedBusinessName,
                addInspectionModal.selectedFacilityId
              ]}
              onChange={(key, value) =>
                this._handleOnFacilitySelectorChange(key, value)
              }
              onFacilitySelect={facilityId =>
                this._handleOnFacilitySelect(facilityId)
              }
            />
          </div>
        </Modal.Section>
        <Modal.Header>Fecha</Modal.Header>
        <div className={styles.sectionContainer}>
          <LabeledInput
            index={1}
            label="Fecha"
            type="date-picker"
            name="dateInput"
            defaultValue={addInspectionModal.dateInput}
            onChange={this._handleOnInputChange}
          />
        </div>
        <Modal.Header>Asignar a Técnico</Modal.Header>
        <Modal.Section style={{ padding: 40 }}>
          <div className={styles.sectionContainer}>
            <div className={styles.sectionItem}>
              {this._renderLabeledTechnicians(addInspectionModal)}
            </div>
          </div>
        </Modal.Section>
        <div style={{ marginTop: 40 }}>
          <Modal.Footer>
            <div style={{ display: "inline-block", marginRight: 20 }}>
              <Button
                style="outline"
                paddingVertical={10}
                paddingHorizontal={48}
                onClick={() => this._handleOnCloseButton()}
              >
                Cancelar
              </Button>
            </div>
            <Button
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={this._handleOnClickAddInspectionChecklist}
              disabled={!addButtonEnabled}
            >
              Asignar checklist
            </Button>
          </Modal.Footer>
        </div>
      </Container>
    );
  };

  _renderLabeledTechnicians = addInspectionModal => {
    const { selectedFacilityId } = addInspectionModal;

    const placeholderInput = () => (
      <LabeledInput
        index={4}
        fullWidth
        label="Nombre y Apellido"
        type="select"
        selectOptions={[]}
        disabled={true}
        name="nameAndLastNameInput"
        defaultValue={addInspectionModal.nameAndLastNameInput}
        onChange={this._handleOnInputChangeTechnician}
      />
    );

    if (selectedFacilityId) {
      return (
        <Query
          query={getTechniciansByFacility}
          variables={{ facilityId: selectedFacilityId }}
        >
          {({ loading, error, data }) => {
            if (loading || error) {
              if (error) {
                bugsnagClient.notify(error);
                console.error(error);
              }

              return placeholderInput();
            }

            if (data) {
              let technicians = [];
              if (
                data.techniciansByFacility !== undefined &&
                data.techniciansByFacility.length > 0
              ) {
                technicians = data.techniciansByFacility.map(technician => ({
                  id: technician.facilityTechnicianId,
                  name: `${technician.lastName}, ${technician.firstName}`
                }));
              }

              return (
                <div style={{ width: "100%" }}>
                  <LabeledSelect
                    label="Nombre y Apellido"
                    name="nameAndLastNameInput"
                    keyedOptions={technicians}
                    defaultValue={addInspectionModal.nameAndLastNameInput}
                    onSelect={e =>
                      this._handleOnInputChangeTechnician(e.currentTarget.value)
                    }
                  />
                </div>
              );
            }
          }}
        </Query>
      );
    }

    return placeholderInput();
  };

  _renderEditLabeledTechnicians = editInspectionModal => {
    const { selectedFacilityId } = editInspectionModal;

    const placeholderInput = () => (
      <LabeledInput
        index={4}
        fullWidth
        label="Nombre y Apellido"
        type="select"
        selectOptions={[]}
        disabled={true}
        name="nameAndLastNameInput"
        defaultValue={editInspectionModal.nameAndLastNameInput}
        onChange={this._handleOnEditInputChangeTechnician}
      />
    );

    if (selectedFacilityId) {
      return (
        <Query
          query={getTechniciansByFacility}
          variables={{ facilityId: selectedFacilityId }}
        >
          {({ loading, error, data }) => {
            if (loading || error) {
              if (error) {
                bugsnagClient.notify(error);
                console.error(error);
              }

              return placeholderInput();
            }

            if (data) {
              let technicians = [];
              if (
                data.techniciansByFacility !== undefined &&
                data.techniciansByFacility.length > 0
              ) {
                technicians = data.techniciansByFacility.map(technician => ({
                  id: technician.facilityTechnicianId,
                  name: `${technician.lastName}, ${technician.firstName}`
                }));
              }

              return (
                <div style={{ width: "100%" }}>
                  <LabeledSelect
                    label="Nombre y Apellido"
                    name="nameAndLastNameInput"
                    keyedOptions={technicians}
                    defaultValue={editInspectionModal.nameAndLastNameInput}
                    onSelect={e =>
                      this._handleOnEditInputChangeTechnician(
                        e.currentTarget.value
                      )
                    }
                  />
                </div>
              );
            }
          }}
        </Query>
      );
    }

    return placeholderInput();
  };

  _renderAddInspectionChecklistModal = () => {
    const { addInspectionChecklistModal } = this.state;
    const { formats } = addInspectionChecklistModal;

    const formatKeys = Object.keys(addInspectionChecklistModal.formats);
    const requiredFormats =
      formatKeys.length > 0
        ? formatKeys.filter(format => formats[format].required)
        : false;

    const createButtonEnabled =
      requiredFormats.length > 0 && requiredFormats.every(e => e)
        ? formatKeys.every(format => {
            if (formats[format] && formats[format].required) {
              if (formats[format].quantity > 0) {
                return true;
              }

              return false;
            }

            return true;
          })
        : false;

    return (
      <Container width={1300}>
        <Modal.Header>Checklist de inspección</Modal.Header>
        <Modal.Section paddingVertical={30} paddingHorizontal={100}>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Bomba diesel", "DIESEL_PUMP")}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Bomba eléctrica", "ELECTRIC_PUMP")}
            </Layout.Column>
            <Layout.Column
              style={{
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Bomba jockey", "JOCKEY_PUMP")}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Tanque de agua", "WATER_TANK")}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Sistema seco", "DRY_SYSTEM")}
            </Layout.Column>
            <Layout.Column
              style={{
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist("Carretes de manguera", "HOSE_REELS")}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Válvulas check de alarmas",
                "ALARM_VALVES"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Hidrante seco - inspección",
                "DRY_HYDRANT"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Hidrante seco - pruebas",
                "DRY_HYDRANT_TESTS"
              )}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Válvulas compuerta - inspección",
                "GATE_VALVES_INSPECTION"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Válvulas compuerta - pruebas",
                "GATE_VALVES_TESTS"
              )}
            </Layout.Column>
            <Layout.Column style={{ borderBottom: "1px solid #f0f0f0" }}>
              {this._renderChecklist(
                "Mantenimiento de sensores de flujo",
                "FLOW_SENSORS_MAINTENANCE"
              )}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0"
              }}
            />
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0"
              }}
            />
            <Layout.Column />
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Inspección de sensores de flujo",
                "FLOW_SENSORS_INSPECTION"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0",
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Inspección de hidrantes de muro",
                "WALL_HYDRANT_INSPECTION"
              )}
            </Layout.Column>
            <Layout.Column
              style={{
                borderBottom: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Inspección de conexiones de bomberos",
                "FIREFIGHTER_CONNECTIONS_INSPECTION"
              )}
            </Layout.Column>
          </Layout.Grid>
          <Layout.Grid>
            <Layout.Column
              style={{
                borderRight: "1px solid #f0f0f0"
              }}
            >
              {this._renderChecklist(
                "Inspección de Extintores",
                "EXTINGUISHER_INSPECTION"
              )}
            </Layout.Column>
            <Layout.Column />
            <Layout.Column />
          </Layout.Grid>
        </Modal.Section>
        <Modal.Footer>
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <Button
              style="outline"
              paddingVertical={10}
              paddingHorizontal={48}
              onClick={() => this._handleOnBackButton()}
            >
              Volver
            </Button>
          </div>
          <Button
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={this._handleOnCreateInspectionChecklistButton}
            disabled={
              !createButtonEnabled ||
              addInspectionChecklistModal.addButtonDisabled
            }
          >
            Crear
          </Button>
        </Modal.Footer>
      </Container>
    );
  };

  // TODO: Move to its own component
  _renderChecklist = (title, format) => {
    const { addInspectionChecklistModal } = this.state;
    const isChecklistRequired = addInspectionChecklistModal.formats[format]
      ? addInspectionChecklistModal.formats[format].required
        ? true
        : false
      : false;

    const quantity =
      addInspectionChecklistModal.formats[format] &&
      addInspectionChecklistModal.formats[format].quantity;

    return (
      <div style={{ padding: 30 }}>
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              float: "left",
              fontSize: 16,
              color: "#4a4a4a",
              marginBottom: 30,
              maxWidth: "216px"
            }}
          >
            {title}
          </div>
          <div style={{ float: "right" }}>
            <Switch
              active={isChecklistRequired}
              onToggle={state => this._handleOnChecklistToggle(format, state)}
            />
          </div>
        </div>
        <div>
          <Layout.Grid>
            <Layout.Column style={{ marginRight: 30 }}>
              <LabeledInput
                fullWidth
                type="number"
                name="quantity"
                label="Cantidad"
                disabled={!isChecklistRequired}
                onChange={e =>
                  this._handleOnChecklistInputChange(
                    format,
                    "quantity",
                    e.currentTarget.value
                  )
                }
                defaultValue={quantity}
              />
            </Layout.Column>
          </Layout.Grid>
        </div>
      </div>
    );
  };

  _handleOnChecklistInputChange = (format, input, value) => {
    const { formats } = this.state.addInspectionChecklistModal;

    if (formats[format]) {
      this.setState(state => ({
        addInspectionChecklistModal: {
          ...state.addInspectionChecklistModal,
          formats: {
            ...state.addInspectionChecklistModal.formats,
            [format]: {
              ...state.addInspectionChecklistModal.formats[format],
              [input]: value
            }
          }
        }
      }));
    }
  };

  _handleOnClickAddInspection = () => {
    this.setState(state => ({
      addInspectionModal: { ...state.addInspectionModal, visible: true }
    }));
  };

  _handleOnClickAddInspectionChecklist = () => {
    this.setState(state => ({
      addInspectionModal: { ...state.addInspectionModal, visible: false },
      addInspectionChecklistModal: {
        ...state.addInspectionChecklistModal,
        visible: true
      }
    }));
  };

  _handleOnBackButton = () => {
    this.setState(state => ({
      addInspectionModal: {
        ...state.addInspectionModal,
        visible: true
      },
      addInspectionChecklistModal: {
        ...state.addInspectionChecklistModal,
        visible: false
      }
    }));
  };

  _handleOnCloseButton = () => {
    this.setState({
      addInspectionModal: {
        visible: false,
        addButtonDisabled: true
      },
      addInspectionChecklistModal: {
        visible: false,
        addButtonDisabled: false,
        formats: {}
      },
      editInspectionModal: {
        visible: false,
        addButtonDisabled: true
      }
    });
  };

  _handleOnInputChange = e => {
    // Handle input change of the date picker component
    if (e.props) {
      return this.setState(state => ({
        addInspectionModal: {
          ...state.addInspectionModal,
          [e.props.inputProps.name]: e.state.value
        }
      }));
    }

    e.persist();

    return this.setState(state => ({
      addInspectionModal: {
        ...state.addInspectionModal,
        [e.nativeEvent.target.name]: e.nativeEvent.target.value
      }
    }));
  };

  _handleOnInputChangeTechnician = value =>
    this.setState(state => ({
      addInspectionModal: {
        ...state.addInspectionModal,
        nameAndLastNameInput: value
      }
    }));

  _handleOnEditInputChangeTechnician = value =>
    this.setState(state => ({
      editInspectionModal: {
        ...state.editInspectionModal,
        nameAndLastNameInput: value
      }
    }));

  _handleOnFacilitySelectorChange = (key, value) => {
    this.setState(state => ({
      addInspectionModal: {
        ...state.addInspectionModal,
        nameAndLastNameInput: undefined,
        selectedFacilityId: undefined,
        [key]: value
      }
    }));
  };

  _handleOnFacilitySelect = facilityId => {
    this.setState(state => ({
      addInspectionModal: {
        ...state.addInspectionModal,
        nameAndLastNameInput: undefined,
        selectedFacilityId: facilityId
      }
    }));
  };

  _handleOnChecklistToggle = (format, toggleState) => {
    const { addInspectionChecklistModal } = this.state;

    if (!addInspectionChecklistModal.formats[format]) {
      return this.setState(state => ({
        addInspectionChecklistModal: {
          ...state.addInspectionChecklistModal,
          formats: {
            ...state.addInspectionChecklistModal.formats,
            [format]: {
              required: toggleState,
              quantity: undefined,
              recurrence: undefined,
              repeatOn: undefined
            }
          }
        }
      }));
    }

    return this.setState(state => ({
      addInspectionChecklistModal: {
        ...state.addInspectionChecklistModal,
        formats: {
          ...state.addInspectionChecklistModal.formats,
          [format]: {
            ...state.addInspectionChecklistModal.formats[format],
            required: toggleState
          }
        }
      }
    }));
  };

  _handleOnCreateInspectionChecklistButton = () => {
    // Save the inspection in the DB
    const {
      addInspectionModal: { nameAndLastNameInput, dateInput },
      addInspectionChecklistModal: { formats }
    } = this.state;

    const parsedDate = new Date(dateInput).getTime();

    // Filter out those formats that are not required
    const requiredFormats = Object.keys(formats).filter(
      format => formats[format].required
    );

    const inspectionChecklists = requiredFormats.map(format => ({
      checklistFormat: format,
      quantity: parseInt(formats[format].quantity)
    }));

    this.setState(
      (state, _) => ({
        addInspectionChecklistModal: {
          ...state.addInspectionChecklistModal,
          addButtonDisabled: true
        }
      }),
      () =>
        scheduleInspection(
          nameAndLastNameInput,
          String(parsedDate),
          JSON.stringify(inspectionChecklists)
        )
          .then(response => {
            window.location = "/agenda/inspecciones";
            mixpanel.track("Crear inspeccion especifica");
          })
          .catch(err => {
            console.log(err);
            this.setState(state => ({
              addInspectionChecklistModal: {
                ...state.addInspectionChecklistModal,
                addButtonDisabled: false
              }
            }));
          })
    );
  };
}

export default InspectionsTemplate;
