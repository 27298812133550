import React from "react";
import PropTypes from "prop-types";

import { Container, Modal, Layout, Button } from "../../atoms";

const EditChecklistModal = ({
  onBackButton,
  onSaveButton,
  renderEditChecklist,
  checklistFormats,
  saveButtonDisabled
}) => {
  return (
    <Container width={1300}>
      <Modal.Header>Checklist de inspección</Modal.Header>
      <Modal.Section paddingVertical={30} paddingHorizontal={100}>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist("Bomba diesel", "DIESEL_PUMP")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist("Bomba eléctrica", "ELECTRIC_PUMP")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist("Bomba jockey", "JOCKEY_PUMP")}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist("Tanque de agua", "WATER_TANK")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist("Sistema seco", "DRY_SYSTEM")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist("Carretes de manguera", "HOSE_REELS")}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist("Válvulas check de alarmas", "ALARM_VALVES")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist("Hidrante seco - inspección", "DRY_HYDRANT")}
          </Layout.Column>
          <Layout.Column
            style={{
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist(
              "Hidrante seco - pruebas",
              "DRY_HYDRANT_TESTS"
            )}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist(
              "Válvulas compuerta - inspección",
              "GATE_VALVES_INSPECTION"
            )}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist(
              "Válvulas compuerta - pruebas",
              "GATE_VALVES_TESTS"
            )}
          </Layout.Column>
          <Layout.Column style={{ borderBottom: "1px solid #f0f0f0" }}>
            {renderEditChecklist(
              "Mantenimiento de sensores de flujo",
              "FLOW_SENSORS_MAINTENANCE"
            )}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist(
              "Inspección de sensores de flujo",
              "FLOW_SENSORS_INSPECTION"
            )}
          </Layout.Column>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist(
              "Inspección de hidrantes de muro",
              "WALL_HYDRANT_INSPECTION"
            )}
          </Layout.Column>
          <Layout.Column
            style={{
              borderBottom: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist(
              "Inspección de conexiones de bomberos",
              "FIREFIGHTER_CONNECTIONS_INSPECTION"
            )}
          </Layout.Column>
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0"
            }}
          >
            {renderEditChecklist(
              "Inspección de Extintores",
              "EXTINGUISHER_INSPECTION"
            )}
          </Layout.Column>
          <Layout.Column />
          <Layout.Column />
        </Layout.Grid>
        <Layout.Grid>
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0"
            }}
          />
          <Layout.Column
            style={{
              borderRight: "1px solid #f0f0f0"
            }}
          />
          <Layout.Column />
        </Layout.Grid>
      </Modal.Section>
      <Modal.Footer>
        <div style={{ display: "inline-block", marginRight: 20 }}>
          <Button
            style="outline"
            paddingVertical={10}
            paddingHorizontal={48}
            onClick={onBackButton}
          >
            Volver
          </Button>
        </div>
        <Button
          paddingVertical={10}
          paddingHorizontal={48}
          onClick={onSaveButton}
          disabled={saveButtonDisabled}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Container>
  );
};

EditChecklistModal.propTypes = {
  onBackButton: PropTypes.func.isRequired,
  editContractId: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired,
  saveButtonDisabled: PropTypes.bool.isRequired
};

export default EditChecklistModal;
